import { APPCONFIG, MORESELECTABLEPAYMENTMETHODS } from "../config"
import { ALLROUTES } from "../routes"
import {
  consoleLog,
  formatDateToString,
  handleApiError,
  reduceApiData,
  zPad
} from "."
import { logicCalendar } from "./logic-calendar"
import { leaseIdInSetup, leaseIdOnDash } from "./logic"

export const computePlanJson = ({
  planPaymentDates,
  planPaymentDay,
  selectedSchedule
}) => {
  const planJson = {}
  planJson.date1 = planPaymentDates?.[0] || 0
  planJson.date2 = planPaymentDates?.[1] || 0
  planJson.dayofweek = !!planPaymentDay
    ? planPaymentDay - APPCONFIG.api.dayIndexAdjust
    : selectedSchedule?.paymentDates?.defaultDayofweek
  planJson.paymentstartdate = !!selectedSchedule?.paymentDates
    ?.defaultPaymentStartDate
    ? selectedSchedule?.paymentDates?.defaultPaymentStartDate
    : typeof selectedSchedule?.paymentDates?.paymentStartDate === "string"
    ? selectedSchedule?.paymentDates?.paymentStartDate
    : formatDateToString(new Date())
  if (selectedSchedule?.plan === APPCONFIG.api.planSlugs.ALTERNATING) {
    const altPaymentStartDate = new Date(planJson.paymentstartdate)
    altPaymentStartDate.setDate(planPaymentDates?.[0] || 1)
    planJson.paymentstartdate = formatDateToString(altPaymentStartDate)
    planJson.date1 = 0
    planJson.date2 = 0
  }
  return planJson
}

export const logicPlan = {
  onUpdateSelectedPlan: ({ selectedPlan, setSelectedSchedule }) => {
    consoleLog("[LOGICPLAN Plan]")
    setSelectedSchedule(selectedPlan?.paymentPlans?.[0])
  },

  onUpdateSelectedSchedule: ({
    startDate,
    selectedSchedule,
    setPaymentStartDate,
    setPlanPaymentDates,
    setPlanPaymentDay
  }) => {
    consoleLog("[LOGICPLAN Schedule]")
    setPlanPaymentDates([])
    setPlanPaymentDay(0)
    let applicablePlanPaymentDay = null
    if (
      !!selectedSchedule?.paymentDates?.defaultDayofweek ||
      !!selectedSchedule?.paymentDates?.dayoftheweek
    ) {
      applicablePlanPaymentDay =
        (selectedSchedule?.paymentDates?.defaultDayofweek ||
          selectedSchedule?.paymentDates?.dayoftheweek?.[0]) +
        APPCONFIG.api.dayIndexAdjust
      setPlanPaymentDay(applicablePlanPaymentDay)
    }
    const computedPaymentDates = logicCalendar.computedPaymentDates({
      planPaymentDay: applicablePlanPaymentDay - APPCONFIG.api.dayIndexAdjust,
      selectedSchedule,
      ...(selectedSchedule?.plan === APPCONFIG.api.planSlugs.ALTERNATING
        ? {}
        : { startDate })
    })
    setPaymentStartDate(computedPaymentDates?.paymentStartDate)
    setPlanPaymentDates(computedPaymentDates?.paymentDates)
  },

  onSelectPlanPaymentDate: ({
    newPaymentDate,
    index,
    planPaymentDates,
    selectedSchedule,
    setPlanPaymentDates,
    setPlanPaymentDay
  }) => {
    consoleLog("[LOGICPLAN PlanPaymentDates]")
    const newPlanPaymentDates = [...planPaymentDates]
    newPlanPaymentDates[index] = newPaymentDate
    setPlanPaymentDates(newPlanPaymentDates)
    if (selectedSchedule?.plan === APPCONFIG.api.planSlugs.ALTERNATING) {
      const paymentRefStartDate =
        selectedSchedule?.paymentDates?.defaultPaymentStartDate
      let paymentDateObj = new Date(
        `${new Date(paymentRefStartDate).getFullYear()}-${zPad(
          new Date(paymentRefStartDate).getMonth() + 1,
          2
        )}-01`
      )
      paymentDateObj.setDate(newPlanPaymentDates?.[0])
      setPlanPaymentDay(paymentDateObj.getDay())
    }
  },

  onSelectPlanPaymentDay: ({
    startDate,
    newPlanPaymentDay,
    selectedSchedule,
    setPlanPaymentDay,
    setPlanPaymentDates
  }) => {
    consoleLog("[LOGICPLAN PlanPaymentDay]")
    consoleLog(`startDate ${startDate}`)
    const computedPaymentDates = logicCalendar.computedPaymentDates({
      planPaymentDay: newPlanPaymentDay,
      selectedSchedule,
      startDate
    })
    setPlanPaymentDay(newPlanPaymentDay + APPCONFIG.api.dayIndexAdjust)
    setPlanPaymentDates(computedPaymentDates?.paymentDates)
  },

  onUpdateAvailablePaymentPlans: ({
    isAccountSetup = false,
    dataResidents,
    nav,
    dataAvailablePaymentPlans,
    isErrorAvailablePaymentPlans,
    isFetchingAvailablePaymentPlans,
    setSelectedPlan
  }) => {
    consoleLog("[LOGICPLAN AvailablePaymentPlans]")
    if (isAccountSetup && isErrorAvailablePaymentPlans) {
      const leaseId =
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
      if (!leaseId) {
        nav(ALLROUTES.accountSetupChildren.leaseId)
      }
    }
    if (
      dataAvailablePaymentPlans &&
      !isErrorAvailablePaymentPlans &&
      !isFetchingAvailablePaymentPlans &&
      dataAvailablePaymentPlans?.data?.data?.filter(plan => !!plan?.isAvailable)
        .length > 0
    ) {
      setSelectedPlan(
        dataAvailablePaymentPlans?.data?.data?.find(
          plan => plan?.isSelected === true
        )
      )
    }
  },

  onUpdateResidentLeasePaymentMethods: ({
    dataResidentLeasePaymentMethods,
    setAllPaymentMethods,
    setSelectedPaymentMethod,
    suggestedDefault
  }) => {
    consoleLog("[LOGICPLAN PaymentMethods]")
    if (!!dataResidentLeasePaymentMethods) {
      const reducedResidentLeasePaymentMethods = reduceApiData(
        dataResidentLeasePaymentMethods,
        "resident_lease_payment_methods"
      )
      setAllPaymentMethods([
        ...reducedResidentLeasePaymentMethods?.bankAccountList,
        ...reducedResidentLeasePaymentMethods?.cardList,
        ...MORESELECTABLEPAYMENTMETHODS
      ])
      if (!!suggestedDefault) {
        if (!!suggestedDefault?.bankId) {
          reducedResidentLeasePaymentMethods.selectedBankId =
            suggestedDefault?.bankId
        }
        if (!!suggestedDefault?.cardId) {
          reducedResidentLeasePaymentMethods.selectedCardId =
            suggestedDefault?.cardId
        }
        if (!!suggestedDefault?.globalPaymentId) {
          reducedResidentLeasePaymentMethods.selectedGlobalPaymentId =
            suggestedDefault?.globalPaymentId
        }
      }
      const selectablePaymentMethod =
        !!reducedResidentLeasePaymentMethods?.selectedBankId
          ? reducedResidentLeasePaymentMethods?.bankAccountList?.find(
              paymentMethod =>
                paymentMethod?.bankAccountId ===
                reducedResidentLeasePaymentMethods?.selectedBankId
            )
          : !!reducedResidentLeasePaymentMethods?.selectedCardId
          ? reducedResidentLeasePaymentMethods?.cardList?.find(
              paymentMethod =>
                paymentMethod?.cardId ===
                reducedResidentLeasePaymentMethods?.selectedCardId
            )
          : !!reducedResidentLeasePaymentMethods?.selectedGlobalPaymentId
          ? {
              type:
                reducedResidentLeasePaymentMethods?.selectedGlobalPaymentId ===
                APPCONFIG.api.globalPaymentMethods.CASHAPP.id
                  ? APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
                  : APPCONFIG.api.globalPaymentMethods.CASH.slug,
              title:
                reducedResidentLeasePaymentMethods?.selectedGlobalPaymentId ===
                APPCONFIG.api.globalPaymentMethods.CASHAPP.id
                  ? APPCONFIG.api.globalPaymentMethods.CASHAPP.label
                  : APPCONFIG.api.globalPaymentMethods.CASH.label
            }
          : null
      setSelectedPaymentMethod({ ...selectablePaymentMethod })
    }
  },

  onSubmitSelectPlan: ({
    dataResidents,
    nav,
    planPaymentDates,
    planPaymentDay,
    selectedSchedule,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    updatePaymentPlanMutation
  }) => {
    consoleLog("[LOGICPLAN SelectPlan]")
    const planJson = computePlanJson({
      planPaymentDates,
      planPaymentDay,
      selectedSchedule
    })
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId,
      planId: selectedSchedule?.planId,
      planJson: JSON.stringify(planJson)
    }
    if (APPCONFIG.debugGlobal) console.log("requestParams", requestParams)
    const handleUpdatePaymentPlanMutationError = data => {
      handleApiError({
        customHeading: "Failed to select payment plan",
        nav,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        ...data
      })
    }
    updatePaymentPlanMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          nav(ALLROUTES.accountSetupChildren.selectPaymentMethod)
        } else {
          handleUpdatePaymentPlanMutationError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleUpdatePaymentPlanMutationError({
          ...errorData
        })
      }
    })
  },

  onSubmitModifyPlan: ({
    // supports Schedule an Exception with corresponding `modifyPlanMutation` value
    dataResidents,
    nav,
    planPaymentDates,
    planPaymentDay,
    selectedSchedule,
    selectedPaymentMethod,
    selectedPlanPaymentSetting,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    modifyPlanDebugSlug = "ModifyPlan",
    modifyPlanMutation,
    modifyPlanMutationExtraParams = {},
    onSuccess,
    onSettled
  }) => {
    consoleLog(`[LOGICPLAN ${modifyPlanDebugSlug}]`)
    const planJsonObject = computePlanJson({
      planPaymentDates,
      planPaymentDay,
      selectedSchedule
    })
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId,
      paymentSettings: selectedPlanPaymentSetting === "autopay" ? "A" : "M",
      planId: selectedSchedule?.planId,
      planJson: JSON.stringify(planJsonObject),
      startDate: formatDateToString(new Date()),
      perPayment: true,
      applyToFutureMonths: true,
      isChangeDefaultPlan: true,
      jsonPlanObject: planJsonObject,
      ...modifyPlanMutationExtraParams
    }
    switch (selectedPaymentMethod?.type) {
      case APPCONFIG.api.bankSlug:
        requestParams["bankAccountId"] = selectedPaymentMethod?.bankAccountId
        break
      case APPCONFIG.api.cardSlug:
        requestParams["cardId"] = selectedPaymentMethod?.cardId
        break
      case APPCONFIG.api.globalPaymentMethods.CASH.slug:
        requestParams["globalPaymentId"] =
          APPCONFIG.api.globalPaymentMethods.CASH.id
        break
      case APPCONFIG.api.globalPaymentMethods.CASHAPP.slug:
        requestParams["globalPaymentId"] =
          APPCONFIG.api.globalPaymentMethods.CASHAPP.id
        break
    }
    if (APPCONFIG.debugGlobal)
      console.log("selectedPaymentMethod", selectedPaymentMethod)
    if (APPCONFIG.debugGlobal) console.log("requestParams", requestParams)
    const handlePlanMutationError = data => {
      if (APPCONFIG.debugGlobal) console.log("ERROR")
      handleApiError({
        customHeading: "Failed to update payment plan",
        nav,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        ...data
      })
    }
    modifyPlanMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          onSuccess()
        } else {
          handlePlanMutationError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handlePlanMutationError({
          ...errorData
        })
      },
      onSettled: () => {
        if (!!onSettled) onSettled()
      }
    })
  },

  onSubmitChangePlan: ({
    dataResidents,
    nav,
    planPaymentDates,
    planPaymentDay,
    selectedSchedule,
    selectedPaymentMethod,
    selectedPlanPaymentSetting,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    paymentId,
    changePlanMutation,
    onSuccess,
    onSettled
  }) => {
    consoleLog("[LOGICPLAN ChangePlan]")
    const planJsonObject = computePlanJson({
      planPaymentDates,
      planPaymentDay,
      selectedSchedule
    })
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId,
      paymentId,
      paymentSettings: selectedPlanPaymentSetting === "autopay" ? "A" : "M",
      planId: selectedSchedule?.planId,
      planJson: JSON.stringify(planJsonObject),
      startDate: formatDateToString(new Date()),
      perPayment: true,
      applyToFutureMonths: false,
      isChangeDefaultPlan: false,
      jsonPlanObject: planJsonObject
    }
    switch (selectedPaymentMethod?.type) {
      case APPCONFIG.api.bankSlug:
        requestParams["bankAccountId"] = selectedPaymentMethod?.bankAccountId
        break
      case APPCONFIG.api.cardSlug:
        requestParams["cardId"] = selectedPaymentMethod?.cardId
        break
      case APPCONFIG.api.globalPaymentMethods.CASH.slug:
        requestParams["globalPaymentId"] =
          APPCONFIG.api.globalPaymentMethods.CASH.id
        break
      case APPCONFIG.api.globalPaymentMethods.CASHAPP.slug:
        requestParams["globalPaymentId"] =
          APPCONFIG.api.globalPaymentMethods.CASHAPP.id
        break
    }
    if (APPCONFIG.debugGlobal)
      console.log("selectedPaymentMethod", selectedPaymentMethod)
    if (APPCONFIG.debugGlobal) console.log("requestParams", requestParams)
    const handlePlanMutationError = data => {
      if (APPCONFIG.debugGlobal) console.log("ERROR")
      handleApiError({
        customHeading: "Failed to change payment plan",
        nav,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        ...data
      })
    }
    changePlanMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          onSuccess()
        } else {
          handlePlanMutationError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handlePlanMutationError({
          ...errorData
        })
      },
      onSettled: () => {
        if (!!onSettled) onSettled()
      }
    })
  },

  onDeletePlan: ({
    // supports (Schedule an Exception)
    dataResidents,
    nav,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    paymentId,
    deletePlanMutation,
    onSuccess,
    onSettled
  }) => {
    consoleLog(`[LOGICPLAN DeletePlan]`)
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      paymentId
    }
    if (APPCONFIG.debugGlobal) console.log("requestParams", requestParams)
    const handlePlanMutationError = data => {
      if (APPCONFIG.debugGlobal) console.log("ERROR")
      handleApiError({
        customHeading: "Failed to delete payment plan",
        nav,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        ...data
      })
    }
    deletePlanMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200].indexOf(status) > -1) {
          onSuccess()
        } else {
          handlePlanMutationError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handlePlanMutationError({
          ...errorData
        })
      },
      onSettled: () => {
        if (!!onSettled) onSettled()
      }
    })
  }
}
