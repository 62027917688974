import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Carousel } from "react-bootstrap"

import { APPCONFIG } from "../../../config"
import {
  CircaLoadingAnimationFS,
  GraphicSignupCarousel1,
  GraphicSignupCarousel2,
  GraphicSignupCarousel3
} from "../../../assets"
import { ALLROUTES } from "../../../routes"
import {
  clearOldMsalTokenIfFound,
  manageMsalAuthJwtToken,
  manualMsalLogout
} from "../../../utils"
import { validation } from "../../../utils/validation"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import GenericForm from "../../../components/elements/generic-form"
import MessageBubble from "../../../components/elements/message-bubble/message-bubble"
import { DashboardWrapperNoAuth } from "../../../components/wrappers/dashboard"

import { useOnboardingMutation } from "../../../utils/mutations"

import "./signup.scss"

const ScreenSignup = () => {
  const nav = useNavigate()

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const validateEmailMutation = useOnboardingMutation.useValidateEmailMutation()
  const signupMutation = useOnboardingMutation.useSignupMutation()

  useEffect(() => {
    const msalAuthJwtToken = manageMsalAuthJwtToken.get()
    if (!!msalAuthJwtToken) {
      clearOldMsalTokenIfFound()
      window.location = ALLROUTES.dashboardChildren.dashboard
    } else {
      manualMsalLogout()
    }
  }, [])

  const [userPassword, setUserPassword] = useState("")
  const [userPasswordConfirm, setUserPasswordConfirm] = useState("")

  const handleEmailChange = event => {
    if (APPCONFIG.validateEmailRegex.test(event.target.value) === false) return
    const validateEmailParams = {
      email: event.target.value
    }
    if (validateEmailParams.email === "") return
    validateEmailMutation.mutate(validateEmailParams, {
      onSuccess: ({ data, status }) => {
        if (
          status === 200 &&
          data.status === true &&
          data.httpStatusCode === 200
        ) {
        } else {
          handleError(data)
        }
      },
      onError: axiosError => {
        handleError(
          axiosError?.response?.data || axiosError?.data || { apiError: true }
        )
      }
    })
  }

  const formFields = [
    {
      label: "Email",
      name: "email",
      type: "email",
      rules: {
        required: true,
        validate: { ...validation.email }
      },
      onBlur: handleEmailChange
    },
    {
      label: "Create Password",
      name: "password",
      type: "password",
      rules: {
        required: true,
        validate: { ...validation.password }
      },
      onChange: pass => {
        setUserPassword(pass)
        setUserPasswordConfirm("")
      }
    },
    {
      label: "Confirm Password",
      name: "confirm_password",
      type: "password",
      rules: {
        required: true,
        validate: {
          match: confirmPass => {
            return confirmPass === userPassword || "Passwords should match"
          }
        }
      },
      value: userPasswordConfirm,
      onChange: confirmPass => {
        setUserPasswordConfirm(confirmPass)
      }
    }
  ]

  const [messageBubbleVisible, setMessageBubbleVisible] = useState(false)
  const [messageBubbleHeadingText, setMessageBubbleHeadingText] = useState("")
  const [messageBubbleContentText, setMessageBubbleContentText] = useState("")

  const handleError = ({
    status,
    httpStatusCode,
    code,
    message,
    errordescription,
    apiError
  }) => {
    if (apiError) {
      nav(ALLROUTES.serverDown)
      return
    }
    setMessageBubbleHeadingText("Signup Failed")
    setMessageBubbleContentText(message || errordescription)
    setMessageBubbleVisible(true)
  }

  const onSubmit = formValues => {
    setUpdateInProgress(true)
    signupMutation.mutate(formValues, {
      onSuccess: ({ data, status }) => {
        if (
          status === 200 &&
          data.status === true &&
          data.httpStatusCode === 200
        ) {
          nav(ALLROUTES.emailVerification, {
            state: {
              email: formValues.email,
              password: formValues.password,
              message: data.message
            }
          })
        } else {
          handleError(data)
        }
      },
      onError: axiosError => {
        handleError(
          axiosError?.response?.data || axiosError?.data || { apiError: true }
        )
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  if (!!updateInProgress) {
    return <CircaLoadingAnimationFS />
  }

  return (
    <>
      <MessageBubble
        onHide={() => {
          setMessageBubbleVisible(false)
        }}
        show={messageBubbleVisible}
        contentClassName={`global-message-bubble`}
        headingText={messageBubbleHeadingText}
        messageText={messageBubbleContentText}
      ></MessageBubble>

      <DashboardWrapperNoAuth
        bodyClass={`screen__signup d-flex flex-row-reverse flex-wrap`}
      >
        <div className="column-right flex-2">
          <FullScreenCentered className={`w-100`} vw100={false}>
            <div className="generic-form-wrap narrow-centered">
              <h1>Create Account</h1>
              <GenericForm
                formClass={"mb-3"}
                submitButtonText={"Continue"}
                onSubmit={onSubmit}
                formFields={formFields}
              />
              <div className="hr-with-text mt-3">
                <span>or</span>
              </div>
              <button
                className="btn btn-text d-block mx-auto"
                onClick={() => {
                  nav(ALLROUTES.dashboardContainer)
                }}
              >
                Sign In
              </button>
            </div>
          </FullScreenCentered>
        </div>

        <div className="column-left flex-1 text-center">
          <FullScreenCentered className={`w-100`} vw100={false}>
            <Carousel
              className={`screen__signup--carousel d-flex flex-column-reverse`}
            >
              <Carousel.Item>
                <h2 className="billboard">Choice</h2>
                <p>
                  Pay rent in 1, 2 or 4 installments on a schedule that makes
                  sense for you.
                </p>
                <GraphicSignupCarousel1 />
              </Carousel.Item>

              <Carousel.Item>
                <h2 className="billboard">Flexibility</h2>
                <p>Make last minute changes to your plan and payment method.</p>
                <GraphicSignupCarousel2 />
              </Carousel.Item>

              <Carousel.Item>
                <h2 className="billboard">Communication</h2>
                <p>
                  Receive payment notifications
                  <br />
                  to keep you up-to-date.
                </p>
                <GraphicSignupCarousel3 />
              </Carousel.Item>
            </Carousel>
          </FullScreenCentered>
        </div>
      </DashboardWrapperNoAuth>
    </>
  )
}

export default ScreenSignup
