import React from "react"

import { GraphicCardSecurityInfo } from "../../../assets"

const OrgnInfoCardSecurity = () => {
  return (
    <>
      <div className="freeze-to-top">
        <h2 className="billboard text-center mx-n2 mb-3 pb-2">
          Using cards wisely
        </h2>
      </div>
      <div className="color-grayscale-iron">
        <p className="mb-3 pb-2">
          Know the pros, cons, and alternatives to paying rent with a credit or
          debit card.
        </p>
        <div className="d-flex justify-content-center my-4 pb-2">
          <GraphicCardSecurityInfo />
        </div>
        <p className="mb-2">
          If you can pay your credit card bill on-time and in-full, it may be a
          good way to earn points and benefits offered by your card.
        </p>
        <p className="mb-2">
          However, large credit balances usually incur 20-30% APRs and can
          negatively impact your credit score. Consider Circa’s flexible payment
          plans as a cheaper alternative.
        </p>
        <p className="mb-2">
          Paying with debit? Consider Circa's flexible payment plans as a cheaper alternative, if your property offers that option.
        </p>
      </div>
    </>
  )
}
export default OrgnInfoCardSecurity
