import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { iOS } from "../../../utils"
import { ALLROUTES } from "../../../routes"
import { GraphicBeRightBack } from "../../../assets"
import FullScreenCentered from "../../../components/wrappers/full-screen-centered"

import "./mobile-app.scss"

const ScreenMobileApp = () => {
  const nav = useNavigate()

  return (
    <FullScreenCentered className={`mobile-app-splash text-center`}>
      <h1>Circa for mobile devices</h1>

      <p>
        Circa is not yet supported by mobile browsers. To use Circa, please
        download our mobile app or sign in using a desktop.
      </p>

      <GraphicBeRightBack />

      <div className="button-wrap mb-4">
        <button
          className="btn btn-primary btn-cta"
          onClick={() =>
            nav(iOS() ? ALLROUTES.mobileAppIos : ALLROUTES.mobileAppAndroid)
          }
        >
          Download the App
        </button>
      </div>

      <p>
        Already installed?
        <br />
        Open the app to continue
      </p>
    </FullScreenCentered>
  )
}

export default ScreenMobileApp
