import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"

import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import {
  CircaLoadingAnimationFS,
  GraphicNeverMissAPayment
} from "../../../assets"
import {
  calculateAccountSetupPercentage,
  consoleLog,
  handleApiError
} from "../../../utils"
import {
  leaseIdInSetup,
  leaseIdOnDash,
  redirectPerLeaseSetupProgress
} from "../../../utils/logic"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import { Overlay, Tooltip } from "react-bootstrap"

import { useDashboardQueries } from "../../../utils/queries"
import { useAccountSetupMutation } from "../../../utils/mutations"

import "./select-autopay.scss"

const ScreenSetupSelectAutopay = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup
  } = useOutletContext()

  const { pathname, state: fwdState } = useLocation()
  const nav = useNavigate()

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [showAutopayTooltip, setShowAutopayTooltip] = useState(false)
  const autopayTooltipTarget = useRef(null)

  useEffect(() => {
    document.body.classList.add("page__select-autopay")
    setContainerClass("screen__select-autopay")
    setDashboardProgressPerc(calculateAccountSetupPercentage(pathname))
    if (fwdState?.showWelcomeBackPopup) {
      showDashboardPopup(dashboardPopups.WelcomeBack)
    }
    if (APPCONFIG.debugGlobal && APPCONFIG.debugRedirection)
      consoleLog(`[SCREEN] select-autopay`)
    return () => {
      document.body.classList.remove("page__select-autopay")
      setContainerClass("")
      setDashboardProgressPerc(0)
    }
  }, [])

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  useEffect(() => {
    if ((dataResidents || errorResidents) && !isFetchingResidents) {
      const redirectApplicable = redirectPerLeaseSetupProgress({
        nav,
        pathname,
        dataResidents,
        errorResidents,
        leaseId:
          leaseIdInSetup.get() ||
          dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
      })
      if (!!redirectApplicable) redirectApplicable()
    }
  }, [dataResidents, isFetchingResidents, isErrorResidents])

  const updatePaymentSetupMutation = useAccountSetupMutation.useUpdatePaymentSetupMutation()

  const handleScreenApiError = ({
    customHeading = "Failed to update payment setup",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const handleUpdatePaymentSetup = ({ setupMode }) => {
    const leaseId =
      leaseIdInSetup.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseId,
      setupMode
    }
    setUpdateInProgress(true)
    updatePaymentSetupMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          setTimeout(() => {
            setDashboardProgressPerc(100)
          }, 1 * 1000)
          setTimeout(() => {
            setUpdateInProgress(false)
            leaseIdInSetup.set(leaseId)
            leaseIdOnDash.set(leaseId)
            nav(ALLROUTES.dashboardContainer)
          }, 2 * 1000)
        } else {
          handleScreenApiError({
            ...data
          })
          setUpdateInProgress(false)
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
        setUpdateInProgress(false)
      }
    })
  }

  if (updateInProgress) {
    return <CircaLoadingAnimationFS />
  }

  return (
    <FullScreenCentered className={`w-100`} vw100={false}>
      <div className="generic-form-wrap narrow-centered d-flex flex-column flex-grow-1 text-center">
        <h1 className="billboard text-center">
          Never miss a payment
          <span
            className="icon-help"
            ref={autopayTooltipTarget}
            onClick={() => {
              setShowAutopayTooltip(!showAutopayTooltip)
            }}
          ></span>
          <Overlay
            target={autopayTooltipTarget.current}
            show={showAutopayTooltip}
            placement="bottom-end"
          >
            {props => (
              <Tooltip id={`calendar-tooltip`} {...props}>
                <h2 className="h1">Payment Settings</h2>
                <h3>Autopay</h3>
                <p>
                  With autopay, Circa will withdraw your scheduled payments
                  automatically from your account. Circa always sends a reminder
                  according to your notification preferences before withdrawing
                  any funds, giving you the opportunity to make last minute
                  changes when possible.
                </p>
                <h3>Manual</h3>
                <p>
                  To pay manually, sign in and navigate to your Circa Wallet
                  each time you need to make a payment. You will still receive
                  payment reminders according to your notification preferences.
                </p>
                <span
                  className="close"
                  onClick={() => {
                    setShowAutopayTooltip(false)
                  }}
                ></span>
              </Tooltip>
            )}
          </Overlay>
        </h1>
        <GraphicNeverMissAPayment />
        <p>
          Have your payments automatically withdrawn from your account. We will
          always send a notification beforehand.
        </p>
        <button
          className="btn btn-primary d-block mx-auto w-auto"
          onClick={() => {
            handleUpdatePaymentSetup({ setupMode: "A" })
          }}
        >
          Turn on Autopay
        </button>
        <button
          className="btn btn-text d-block mx-auto w-auto"
          onClick={() => {
            handleUpdatePaymentSetup({ setupMode: "M" })
          }}
        >
          Pay Manually
        </button>
        <div className="flex-grow-1"></div>
        <p className="bottom-text">
          By turning on autopay, you agree to our{" "}
          <a href={APPCONFIG.staticLinks.directDebitAuth} target="_blank">
            direct debit authorization form
          </a>
          .
        </p>
      </div>
    </FullScreenCentered>
  )
}

export default ScreenSetupSelectAutopay
