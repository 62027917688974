import React, { useEffect } from "react"

import { APPCONFIG } from "../../../config"
import { displayAmount } from "../../../utils"

import { AnimationLoadingFullScreen } from "../../../assets"

import {
  useDashboardQueries,
  useStatementQueries
} from "../../../utils/queries"

const OrgnWizardPaymentDetails = ({ paymentId, arrearsPaymentId }) => {
  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusPaymentDetails,
    data: dataPaymentDetails,
    isFetching: isFetchingPaymentDetails,
    isError: isErrorPaymentDetails,
    error: errorPaymentDetails,
    refetch: refetchPaymentDetails
  } = useStatementQueries.usePaymentDetailsQuery({
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    paymentId,
    arrearsPaymentId
  })

  useEffect(() => {
    if (APPCONFIG.debugGlobal) {
      if (!!dataPaymentDetails) {
        console.log(dataPaymentDetails)
      }
      console.log(paymentId, arrearsPaymentId)
    }
  }, [dataPaymentDetails])

  if (isFetchingResidents || isFetchingPaymentDetails) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="widget freeze-to-top">
        <h2 className="billboard text-center mb-4 mx-n2">
          {
            dataPaymentDetails?.data?.paymentDetailsInformation
              ?.paymentDateHeader
          }
        </h2>
      </div>
      <div className="widget generic-form">
        <h3>Plan</h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <div className="payment-option">
              <div className="payment-option__icon"></div>
              <div className="payment-option__title">
                {dataPaymentDetails?.data?.paymentDetailsInformation
                  ?.paymentPlanInformation?.planTypeDisplayName ||
                  dataPaymentDetails?.data?.paymentDetailsInformation
                    ?.paymentPlanInformation?.planTypeName}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Method
        </h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <div className="payment-option">
              <div className="payment-option__icon icon-bank"></div>
              <div className="payment-option__title">
                {
                  dataPaymentDetails?.data?.paymentDetailsInformation
                    ?.paymentPlanInformation?.paymentMethodName
                }
              </div>
              <div className="payment-option__setting small-button">
                {!!dataPaymentDetails?.data?.paymentDetailsInformation
                  ?.paymentPlanInformation?.isAutoPay
                  ? "AutoPay"
                  : "Manual"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Details
        </h3>

        <div className="payment-breakup block low-elevation br-medium">
          <div className="payment-breakup__heading d-flex justify-content-between mb-3">
            <div>Amount Due</div>
            <div>
              {`$${displayAmount(
                dataPaymentDetails?.data?.paymentDetailsInformation?.amountDue
              )}`}
            </div>
          </div>

          {dataPaymentDetails?.data?.paymentDetailsInformation?.amountDueDetails?.map(
            (detailItem, i) => (
              <div key={i}>
                <div className="payment-breakup__head d-flex justify-content-between mb-2">
                  <div>{detailItem?.itemName}</div>
                  <div>
                    $
                    {displayAmount(
                      detailItem?.chargeTypes?.reduce(
                        (total, chrg) => total + chrg?.chargeTypeAmount,
                        0
                      )
                    )}
                  </div>
                </div>
                {detailItem?.chargeTypes?.map((charge, j) => (
                  <div
                    className="payment-breakup__detail d-flex justify-content-between ps-3"
                    key={j}
                  >
                    <div>{charge?.chargeTypeName}</div>
                    <div>{`$${displayAmount(charge?.chargeTypeAmount)}`}</div>
                  </div>
                ))}
              </div>
            )
          )}

          <div className="payment-breakup__heading font-medium d-flex justify-content-between mb-3 mt-4">
            <div>Payment Amount</div>
            <div>
              {`$${displayAmount(
                dataPaymentDetails?.data?.paymentDetailsInformation
                  ?.paymentAmount
              )}`}
            </div>
          </div>

          {dataPaymentDetails?.data?.paymentDetailsInformation?.paymentAmountDetails?.map(
            (detailItem, i) => (
              <div key={i}>
                <div className="payment-breakup__head d-flex justify-content-between mb-2">
                  <div>{detailItem?.itemName}</div>
                  <div>
                    $
                    {displayAmount(
                      detailItem?.chargeTypes?.reduce(
                        (total, chrg) => total + chrg?.chargeTypeAmount,
                        0
                      )
                    )}
                  </div>
                </div>
                {detailItem?.chargeTypes?.map((charge, j) => (
                  <div
                    className="payment-breakup__detail d-flex justify-content-between ps-3"
                    key={j}
                  >
                    <div>{charge?.chargeTypeName}</div>
                    <div>{`$${displayAmount(charge?.chargeTypeAmount)}`}</div>
                  </div>
                ))}
              </div>
            )
          )}
        </div>
        <p className="mt-4">
          Payment amounts subject to change. Amounts are confirmed 2 business
          days before the payment date, according to your rent bill and plan
          settings.
        </p>
      </div>
    </>
  )
}

export default OrgnWizardPaymentDetails
