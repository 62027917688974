import React from "react"

import { useSelector } from "react-redux"

import { APPCONFIG } from "../../../config"
import ContactTile from "../contact-tile/contact-tile"

import "./help-panel.scss"

const HelpPanel = ({}) => {
  const { dashboardDetails } = useSelector(state => state.appData)

  const propertyManagerWidgetInfo = dashboardDetails?.widgetInformation?.find(
    widget => !!widget?.propertyManagerDetails
  )

  return (
    <div className="help-panel">
      <button
        className="btn btn-bordered w-100 mb-4 clickable"
        onClick={() => {
          window.open(APPCONFIG.staticLinks.knowledgebase)
        }}
      >
        Visit Support Center
      </button>
      <h3 className="mb-3">Contacts</h3>
      <div className="contact-tiles d-flex justify-content-center">
        {propertyManagerWidgetInfo && (
          <>
            {propertyManagerWidgetInfo?.propertyManagerDetails?.map(
              (propertyManager, i) => (
                <ContactTile
                  className={`flex-grow-1`}
                  fullName={propertyManager?.name}
                  department={propertyManager?.shortDescription || "-"}
                  phone={propertyManager?.phoneNumber}
                  email={propertyManager?.email}
                  key={i}
                />
              )
            )}
          </>
        )}
        <ContactTile
          className={`flex-grow-1`}
          fullName={`Circa`}
          department={`Support Team`}
          phone={APPCONFIG.supportTelephone}
          email={APPCONFIG.supportEmail}
        />
      </div>
    </div>
  )
}
export default HelpPanel
