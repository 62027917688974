// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"

import { APPCONFIG } from "./config"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// `firebaseConfig` imported from APPCONFIG

// Initialize Firebase
export const firebaseApp = initializeApp(APPCONFIG.firebase.firebaseConfig)
