export const setModalBreadcrumb = breadcrumb => {
  return dispatch => {
    dispatch({
      type: "set_breadcrumb",
      payload: breadcrumb
    })
  }
}

export const setModalClass = className => {
  return dispatch => {
    dispatch({
      type: "set_class",
      payload: className
    })
  }
}

export const setModalComponent = component => {
  return dispatch => {
    dispatch({
      type: "set_component",
      payload: component
    })
  }
}

export const setModalVisibility = visibility => {
  return dispatch => {
    dispatch({
      type: "set_visibility",
      payload: visibility
    })
  }
}
