import React, { useEffect, useState } from "react"
import Lottie from "lottie-react"
import circaLoadingAnim from "./lottie/loading.json"

export const CircaLoadingAnimation = () => {
  const animationTexts = [
    "Our mission is to build stronger communities through payment innovation.",
    "Did you know that paying directly from your bank account is one of the cheapest, most secure ways to pay?",
    "Over 70% of renters in the U.S. need payment flexibility at least once per year.",
    "Circa lets you make changes to your payment settings up to 1 business day before your scheduled payment date.",
    "Circa’s payment flexibility helps you pay on a schedule that works for you without incurring late fees.",
    "Circa reduces evictions by making it 4x easier for residents to pay rent on-time."
  ]

  const [animTextIndex, setAnimTextIndex] = useState(
    Math.floor(Math.random() * animationTexts.length)
  )

  let animTextInterval
  const setAnimTextInterval = () => {
    animTextInterval = setTimeout(() => {
      setAnimTextIndex((animTextIndex + 1) % animationTexts.length)
    }, 5 * 1000)
  }
  useEffect(() => {
    setAnimTextInterval()
    return () => {
      clearInterval(animTextInterval)
    }
  })

  return (
    <>
      <Lottie
        animationData={circaLoadingAnim}
        className={`circa-loading-animation`}
        loop={true}
      />
      <h3>{animationTexts[animTextIndex]}</h3>
    </>
  )
}
