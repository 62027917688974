import React, { useState } from "react"

import { AllIcons } from "../../assets"

import { showModalMakeAPayment } from "../organisms/payment/make-a-payment"

import "./widgets.scss"

const DashWidgetWallet = ({
  refetchDashboard,
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  walletInformation
}) => {
  return (
    <div
      className={`dash-widget wallet${
        walletInformation?.data?.balanceAmount < 0 ||
        !!walletInformation?.data?.isArrearsIncluded
          ? " danger"
          : ""
      } flex-grow-1 block high-elevation br-xlarge p-4`}
    >
      <div className="d-flex align-items-center">
        <h2 className="h1 flex-grow-1 m-0">Wallet</h2>
        {/*<div className="icon-dots-vertical clickable"></div>*/}
      </div>
      <div className="current-balance text-center mt-4">
        <p>Current balance</p>
        <h2 className="billboard">{`${
          walletInformation?.data?.balanceAmount < 0 ? "-" : ""
        }$${Math.abs(walletInformation?.data?.balanceAmount)}`}</h2>
      </div>
      {walletInformation?.data?.isMakeAPayment && (
        <div
          className="block btn-tile clickable mt-4"
          onClick={() => {
            showModalMakeAPayment({
              refetchDashboard,
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              setMessageBubbleComponent,
              setMessageBubbleVisibility,
              walletInformation
            })
          }}
        >
          <AllIcons.PaymentOptionPayNow />
          <h4>Make a Payment</h4>
        </div>
      )}
    </div>
  )
}
export default DashWidgetWallet
