const reducer = (
  state = {
    dashboardDetails: {}
  },
  action
) => {
  switch (action.type) {
    case "set_dashboarddetails":
      return { ...state, dashboardDetails: action.payload }

    default:
      return state
  }
}
export default reducer
