import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js/pure"
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { consoleLog, handleApiError, zPad } from "../../../utils"
import { validation } from "../../../utils/validation"

import {
  AnimationLoadingFullScreen,
  GraphicPoweredByStripe
} from "../../../assets"
import GenericForm from "../../elements/generic-form"
import OrgnInfoCardSecurity from "./card-security-info"
import HelpIconWithTooltip from "../../elements/form/help-icon-with-tooltip"

import {
  useDashboardQueries,
  usePaymentMethodQueries
} from "../../../utils/queries"
import { usePaymentMethodMutation } from "../../../utils/mutations"

export const showModalCardInformation = ({
  cardId,
  onSubmitCallback,
  onRemoveCallback,
  setModalBreadcrumb,
  setModalClass,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  setModalBreadcrumb([{ label: "" }])
  setModalClass("modal--card-info narrow-content")
  setModalComponent(() => (
    <div className="modal-content-height d-flex flex-column">
      <OrgnFormCardInformation
        cardId={cardId}
        cardCreatedCallback={onSubmitCallback}
        onRemove={!!cardId ? onRemoveCallback : () => {}}
        setMessageBubbleComponent={setMessageBubbleComponent}
        setMessageBubbleVisibility={setMessageBubbleVisibility}
      />
    </div>
  ))
  setModalVisibility(true)
}

const OrgnFormCardInformation = ({
  cardId,
  cardCreatedCallback,
  onRemove,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const cardNicknameFieldLabel = "Card Nickname (Optional)"

  const CardInfoTooltip = () => (
    <>
      <h2 className="h1 mb-3 pb-2">Card Security</h2>
      <p className="mb-2">
        Your card information is passed directly to Stripe, a market leader in
        debit and credit card transactions. Circa never sees or has access to
        your card information.
      </p>
      <p>
        To give you the highest degree of privacy and security, Stripe securely
        stores your card information and faciliates all card transactions on
        behalf of Circa.
      </p>
      <div className="d-flex justify-content-center mt-3">
        <GraphicPoweredByStripe />
      </div>
    </>
  )

  const showModalCardSecurity = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--card-security narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnInfoCardSecurity />
      </div>
    ))
    setModalVisibility(true)
  }

  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [stripeClientSecret, setStripeClientSecret] = useState(null)

  const {
    status: statusCardsPublicKey,
    data: dataCardsPublicKey,
    isFetching: isFetchingCardsPublicKey
  } = usePaymentMethodQueries.useCardsPublicKeyQuery({
    config: { refetchOnMount: true },
    enabled: !cardId
  })

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusCards,
    data: dataCards,
    isFetching: isFetchingCards,
    isError: isErrorCards,
    error: errorCards,
    refetch: refetchCards
  } = usePaymentMethodQueries.useCardsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const {
    status: statusCard,
    data: dataCard,
    isFetching: isFetchingCard
  } = usePaymentMethodQueries.useCardQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success" && !!cardId,
    residentId: dataResidents?.data?.residentData?.id,
    cardId
  })

  const [allCards, setAllCards] = useState(null)
  const [newCard, setNewCard] = useState(null)

  useEffect(() => {
    if (!!dataCards && !!dataCards?.data?.data) {
      if (allCards === null) {
        consoleLog("Initial retrieval of cards", "urgent")
      } else if (
        allCards?.length === 0 &&
        dataCards?.data?.data?.length === 1
      ) {
        consoleLog("Retrieved cards when NO card exists", "urgent")
        setNewCard(dataCards?.data?.data?.[0])
      } else {
        consoleLog("Retrieved cards when cards exist", "urgent")
        dataCards?.data?.data?.forEach(card => {
          const savedCard = allCards?.find(
            savedCard => savedCard?.cardId === card?.cardId
          )
          if (!savedCard) {
            setNewCard(card)
          }
        })
      }
      setAllCards(dataCards?.data?.data || [])
    }
  }, [dataCards])

  let refetchCardsInterval
  const [refetchCardsNow, setRefetchCardsNow] = useState(false)

  useEffect(() => {
    if (!!newCard) {
      clearInterval(refetchCardsInterval)
      setUpdateInProgress(false)
      setModalVisibility(false)
      refetchCards()
      if (!!cardCreatedCallback) cardCreatedCallback({ ...newCard })
      // reset everything
      setStripeClientSecret(null)
      setAllCards(null)
      setNewCard(null)
      setRefetchCardsNow(false)
      setStripePromise(null)
      setEditFormFields([])
    }
  }, [newCard])

  useEffect(() => {
    if (!!refetchCardsNow) {
      refetchCardsInterval = setInterval(refetchCards, 5000)
    }
    return () => {
      if (!!refetchCardsInterval) {
        clearInterval(refetchCardsInterval)
      }
    }
  }, [refetchCardsNow])

  const createStripSetupIntentMutation =
    usePaymentMethodMutation.useCreateStripeSetupIntentMutation()

  const handleScreenApiError = ({
    customHeading = "Failed to create card",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const [stripePromise, setStripePromise] = useState(null)
  useEffect(() => {
    if (!!dataCardsPublicKey) {
      setStripePromise(loadStripe(dataCardsPublicKey?.data?.publicKey))
      setUpdateInProgress(true)
      createStripSetupIntentMutation.mutate(
        {},
        {
          onSuccess: ({ data, status }) => {
            if ([200].indexOf(status) > -1) {
              setStripeClientSecret(data?.clientSecret)
            } else {
              handleScreenApiError({
                ...data
              })
            }
          },
          onError: axiosError => {
            const errorData = axiosError?.response?.data || { apiError: true }
            handleScreenApiError({
              ...errorData
            })
          },
          onSettled: () => {
            setUpdateInProgress(false)
          }
        }
      )
    }
  }, [dataCardsPublicKey])

  const addFormFields = [
    {
      label: cardNicknameFieldLabel,
      formFieldClassName: "form-field--card-nickname",
      name: "cardNickName",
      rules: {
        validate: { ...validation.alphaNumericMax26 }
      }
    }
  ]

  if (!cardId) {
    addFormFields.push({
      label: "I am authorized to make electronic transactions using this card.",
      name: "account_auth",
      compType: "checkbox-switch",
      rules: {
        required: true
      }
    })
    addFormFields.push({
      label: "I authorize Circa to charge this card per my payment schedule.",
      name: "authorized_circa",
      compType: "checkbox-switch",
      rules: {
        required: true
      }
    })
  }

  const AddCardForm = () => {
    const stripe = useStripe()
    const elements = useElements()

    const cardElementOptions = {
      style: {
        base: {
          "::placeholder": {
            color: "#a0a3bd"
          }
        },
        invalid: {
          color: "#cc1658",
          iconColor: "#cc1658"
        }
      }
    }

    const onAddCard = async formValues => {
      if (elements == null) {
        return
      }

      const cardElement = elements.getElement(CardElement)

      stripe
        .confirmCardSetup(stripeClientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: [
                dataResidents?.data?.residentData?.firstName,
                dataResidents?.data?.residentData?.lastName
              ]
                .filter(item => !!item)
                .join(" "),
              email: dataResidents?.data?.residentData?.emailId
            },
            metadata: {
              cardname: formValues?.cardNickName || "",
              iamauthorized: true,
              automaticPayment: true
            }
          }
        })
        .then(result => {
          if (result?.setupIntent?.status === "succeeded") {
            setUpdateInProgress(true)
            setRefetchCardsNow(true)
          }
        })
    }

    return (
      <>
        <div className="generic-form d-flex flex-column stripe-card-element-form-wrap">
          <form>
            <div className="form-field form-field--stripe-card-element col-sm-12">
              <label className="field-label has-tooltip form-label">
                Card Details
                <HelpIconWithTooltip
                  isOverModal={true}
                  placement={"left-start"}
                  TooltipComponent={() => <CardInfoTooltip />}
                />
              </label>
              <CardElement options={cardElementOptions} />
            </div>
          </form>
        </div>
        <GenericForm
          formClass={""}
          submitButtonText={"Continue"}
          onSubmit={onAddCard}
          formFields={addFormFields}
          StripeCardElement={() => <></>}
        />
      </>
    )
  }

  const [editFormFields, setEditFormFields] = useState([])

  useEffect(() => {
    if (!!dataCard && !isFetchingCard) {
      setEditFormFields([
        {
          label: cardNicknameFieldLabel,
          name: "cardNickName",
          rules: {
            validate: { ...validation.alphaNumericMax26 }
          },
          defaultValue: dataCard?.data?.data?.cardNickName
        },
        {
          label: "Card Number",
          name: "cardNumber",
          disabled: true,
          defaultValue: `****${dataCard?.data?.data?.cardLast4Digits}`
        },
        {
          label: "Expiry Date",
          name: "expiryDate",
          disabled: true,
          defaultValue: `${zPad(dataCard?.data?.data?.cardExpiryMonth, 2)}/${dataCard?.data?.data?.cardExpiryYear}`
        }
      ])
    }
  }, [dataCard, isFetchingCard])

  const updateCardMutation = usePaymentMethodMutation.useUpdateCardMutation()
  const deleteCardMutation = usePaymentMethodMutation.useDeleteCardMutation()

  const EditCardForm = () => {
    const onEditCard = formValues => {
      setUpdateInProgress(true)
      const { cardNumber, ...requestParams } = formValues
      updateCardMutation.mutate(
        {
          residentId: dataResidents?.data?.residentData?.id,
          cardId,
          ...requestParams
        },
        {
          onSuccess: ({ data, status }) => {
            if ([200].indexOf(status) > -1) {
              setModalVisibility(false)
              refetchCards()
            } else {
              handleScreenApiError({
                ...data
              })
            }
          },
          onError: axiosError => {
            const errorData = axiosError?.response?.data || { apiError: true }
            handleScreenApiError({
              ...errorData
            })
          },
          onSettled: () => {
            setUpdateInProgress(false)
          }
        }
      )
    }

    const onRemoveCard = () => {
      setUpdateInProgress(true)
      deleteCardMutation.mutate(
        {
          residentId: dataResidents?.data?.residentData?.id,
          cardId
        },
        {
          onSuccess: ({ data, status }) => {
            if ([200].indexOf(status) > -1) {
              setModalVisibility(false)
              refetchCards()
            } else {
              handleScreenApiError({
                ...data
              })
            }
          },
          onError: axiosError => {
            const errorData = axiosError?.response?.data || { apiError: true }
            handleScreenApiError({
              ...errorData
            })
          },
          onSettled: () => {
            setUpdateInProgress(false)
          }
        }
      )
    }

    return (
      <GenericForm
        formClass={""}
        submitButtonText={"Save Changes"}
        onSubmit={onEditCard}
        formFields={editFormFields}
        hasSecondaryButton={true}
        secondaryButtonClass={`btn-danger btn-bordered`}
        secondaryButtonAction={onRemove || onRemoveCard}
        secondaryButtonText={"Remove"}
      />
    )
  }

  if (
    updateInProgress ||
    (!cardId &&
      (isFetchingCardsPublicKey ||
        stripePromise === null ||
        stripeClientSecret === null)) ||
    (!!cardId && isFetchingCard)
  ) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  const stripeElementOptions = {
    clientSecret: stripeClientSecret
  }

  return (
    <>
      <h2 className="billboard text-center mb-3 pb-2">Card Information</h2>
      <Elements stripe={stripePromise} options={stripeElementOptions}>
        {!!cardId ? <EditCardForm /> : <AddCardForm />}
      </Elements>
      {!cardId && (
        <>
          <div className="d-flex justify-content-center mt-3 pt-2">
            <button
              className="btn btn-text text-only"
              onClick={showModalCardSecurity}
            >
              Learn More
            </button>
          </div>
          <p className="mt-3 pt-2 text-center">
            By continuing, you acknowledge that card transactions incur a 3%
            processing fee.
          </p>
        </>
      )}
    </>
  )
}
export default OrgnFormCardInformation
