import React, { useState } from "react"
import { Button } from "react-bootstrap"

import "./elements.scss"

function SubmitButtonPair({
  submitButtonText,
  onSubmit,
  secondaryButtonAction,
  secondaryButtonText,
  buttonType,
  ...props
}) {
  return (
    <>
      <div className={`d-flex justify-content-between submit-btn-pair`}>
        <Button
          variant="primary"
          type={buttonType || "button"}
          disabled={props.disabled}
          className={`submit-btn`}
          onClick={onSubmit}
        >
          <span>{props.text || "Submit"}</span>
        </Button>
        <Button
          variant="secondary"
          className={`submit-btn`}
          onClick={secondaryButtonAction}
        >
          <span>{secondaryButtonText}</span>
        </Button>
      </div>
    </>
  )
}

export default SubmitButtonPair
