import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ALLROUTES } from "../../../routes"
import { validation } from "../../../utils/validation"

import { DashboardWrapperNoAuth } from "../../../components/wrappers/dashboard"
import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import GenericForm from "../../../components/elements/generic-form"
import MessageBubble from "../../../components/elements/message-bubble/message-bubble"

import { useResetPasswordMutation } from "../../../utils/mutations"

import "./reset-password.scss"

const ScreenResetPassword = () => {
  const nav = useNavigate()
  const resetPasswordMutation = useResetPasswordMutation()

  const [userPassword, setUserPassword] = useState("")

  const formFields = [
    {
      label: "New Password",
      name: "new_password",
      type: "password",
      rules: {
        required: true,
        validate: { ...validation.password }
      },
      onChange: pass => {
        setUserPassword(pass)
      }
    },
    {
      label: "Confirm New Password",
      name: "confirm_new_password",
      type: "password",
      rules: {
        required: true,
        validate: {
          match: confirmPass => {
            return confirmPass === userPassword || "Passwords should match"
          }
        }
      }
    }
  ]

  const [messageBubbleVisible, setMessageBubbleVisible] = useState(false)
  const [messageBubbleHeadingText, setMessageBubbleHeadingText] = useState("")
  const [messageBubbleContentText, setMessageBubbleContentText] = useState("")

  const handleError = ({ status, httpStatusCode, message, apiError }) => {
    if (apiError) {
      nav(ALLROUTES.serverDown)
      return
    }
    setMessageBubbleHeadingText("Signup Failed")
    setMessageBubbleContentText(message)
    setMessageBubbleVisible(true)
  }

  const onSubmit = formValues => {
    resetPasswordMutation.mutate(formValues, {
      onSuccess: ({ response }) => {
        if (response.status === true && response.httpStatusCode === 200) {
          nav(ALLROUTES.emailVerification, {
            state: { email: formValues.email, message: response.message }
          })
        } else {
          handleError(response)
        }
      },
      onError: axiosError => {
        handleError(axiosError?.response?.data || { apiError: true })
      }
    })
  }

  return (
    <>
      <MessageBubble
        onHide={() => {
          setMessageBubbleVisible(false)
        }}
        show={messageBubbleVisible}
        contentClassName={`global-message-bubble`}
        headingText={messageBubbleHeadingText}
        messageText={messageBubbleContentText}
      ></MessageBubble>

      <DashboardWrapperNoAuth
        bodyClass={`screen__reset-password d-flex flex-row-reverse flex-wrap`}
      >
        <FullScreenCentered className={`w-100`} vw100={false}>
          <div className="generic-form-wrap narrow-centered">
            <h1 className="h-with-p">Reset your password</h1>
            <GenericForm
              formClass={"mb-3"}
              submitButtonText={"Continue"}
              onSubmit={onSubmit}
              formFields={formFields}
            />
          </div>
        </FullScreenCentered>
      </DashboardWrapperNoAuth>
    </>
  )
}

export default ScreenResetPassword
