import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { Col, Container, Overlay, Row, Tooltip } from "react-bootstrap"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import {
  ADDMETHODSLUG_BANK_LABEL_SETUP,
  ADDMETHODSLUG_CARD_LABEL_SETUP,
  APPCONFIG
} from "../../../config"
import { ALLROUTES } from "../../../routes"
import {
  calculateAccountSetupPercentage,
  consoleLog,
  handleApiError
} from "../../../utils"
import {
  leaseIdInSetup,
  redirectPerLeaseSetupProgress
} from "../../../utils/logic"

import OrgnFormBankInformation from "../../../components/organisms/bank-information/bank-information"
import OrgnFormCardInformation from "../../../components/organisms/card-information/card-information"
import { TooltipContentPaymentMethods } from "../../../components/organisms/tooltip-content/payment-methods"
import OrgnFormCashPayment from "../../../components/organisms/cash-payment/cash-payment"
import OrgnInfoCashApp from "../../../components/organisms/cash-app/cash-app"

import {
  useAccountSetupQueries,
  useDashboardQueries
} from "../../../utils/queries"
import {
  useAccountSetupMutation,
  usePaymentMethodMutation
} from "../../../utils/mutations"

import "./select-payment-method.scss"

const ScreenSetupSelectPaymentMethod = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup
  } = useOutletContext()

  const nav = useNavigate()
  const { pathname, state: fwdState } = useLocation()

  const [activePaymentMethod, setActivePaymentMethod] = useState(false)
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([])

  const [showPaymentMethodTooltip, setShowPaymentMethodTooltip] =
    useState(false)
  const paymentMethodTooltipTarget = useRef(null)

  const addPaymentMethods = [
    {
      title: ADDMETHODSLUG_BANK_LABEL_SETUP,
      iconSlug: "bank-account",
      clickHandler: () => {
        setModalBreadcrumb([{ label: "" }])
        setModalClass("modal--bank-info narrow-content")
        setModalComponent(() => (
          <div className="modal-content-height d-flex flex-column">
            <OrgnFormBankInformation
              submitButtonText={"Continue"}
              onSubmit={responseData => {
                setModalVisibility(false)
                refetchPaymentMethods()
                updatePaymentMethods({ ...responseData })
              }}
            />
          </div>
        ))
        setModalVisibility(true)
      }
    },
    {
      title: ADDMETHODSLUG_CARD_LABEL_SETUP,
      subTitle: "+ 3% processing fee",
      iconSlug: "debit-credit-card",
      clickHandler: () => {
        setModalBreadcrumb([{ label: "" }])
        setModalClass("modal--card-info narrow-content")
        setModalComponent(() => (
          <div className="modal-content-height d-flex flex-column">
            <OrgnFormCardInformation
              setMessageBubbleComponent={setMessageBubbleComponent}
              setMessageBubbleVisibility={setMessageBubbleVisibility}
              cardCreatedCallback={({ cardId }) => {
                if (!!APPCONFIG.debugGlobal)
                  console.log("[cardCreatedCallback]", cardId)
                updatePaymentMethods({ cardId })
              }}
            />
          </div>
        ))
        setModalVisibility(true)
      }
    }
  ]

  const showModalCashPayment = ({ callback }) => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--cash-payment narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnFormCashPayment cashPaymentCallback={callback} />
      </div>
    ))
    setModalVisibility(true)
  }

  const showModalCashApp = ({ callback }) => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--cash-app narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnInfoCashApp cashAppPaymentCallback={callback} />
      </div>
    ))
    setModalVisibility(true)
  }

  const paymentMethods = [
    ...(!!savedPaymentMethods && savedPaymentMethods.length > 0
      ? savedPaymentMethods
      : addPaymentMethods),
    {
      title: APPCONFIG.api.globalPaymentMethods.CASH.label,
      subTitle: "+ agent fee",
      iconSlug: APPCONFIG.api.globalPaymentMethods.CASH.slug,
      clickHandler: () => {
        showModalCashPayment({
          callback: () => {
            updatePaymentMethods({
              globalPaymentId: APPCONFIG.api.globalPaymentMethods.CASH.id
            })
          }
        })
      }
    },
    {
      title: APPCONFIG.api.globalPaymentMethods.CASHAPP.label,
      subTitle: "+ 2.75% processing fee",
      iconSlug: APPCONFIG.api.globalPaymentMethods.CASHAPP.slug,
      clickHandler: () => {
        showModalCashApp({
          callback: () => {
            updatePaymentMethods({
              globalPaymentId: APPCONFIG.api.globalPaymentMethods.CASHAPP.id
            })
          }
        })
      }
    },
    ...(!!savedPaymentMethods && savedPaymentMethods.length > 0
      ? addPaymentMethods
      : [])
  ]

  useEffect(() => {
    document.body.classList.add("page__select-payment-method")
    setContainerClass("screen__select-payment-method")
    setDashboardProgressPerc(calculateAccountSetupPercentage(pathname))
    if (fwdState?.showWelcomeBackPopup) {
      showDashboardPopup(dashboardPopups.WelcomeBack)
    }
    if (APPCONFIG.debugGlobal && APPCONFIG.debugRedirection)
      consoleLog(`[SCREEN] select-payment-method`)
    return () => {
      document.body.classList.remove("page__select-payment-method")
      setContainerClass("")
      setDashboardProgressPerc(0)
    }
  }, [])

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  useEffect(() => {
    if ((dataResidents || errorResidents) && !isFetchingResidents) {
      const redirectApplicable = redirectPerLeaseSetupProgress({
        nav,
        pathname,
        dataResidents,
        errorResidents,
        leaseId:
          leaseIdInSetup.get() ||
          dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
      })
      if (!!redirectApplicable) redirectApplicable()
    }
  }, [dataResidents, isFetchingResidents, isErrorResidents])

  const {
    status: statusPaymentMethods,
    data: dataPaymentMethods,
    isFetching: isFetchingPaymentMethods,
    isError: isErrorPaymentMethods,
    error: errorPaymentMethods,
    refetch: refetchPaymentMethods
  } = useAccountSetupQueries.usePaymentMethodsQuery({
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    config: { refetchOnMount: true }
  })

  useEffect(() => {
    if (dataPaymentMethods && !isFetchingResidents) {
      const savedMethods = []
      dataPaymentMethods?.data?.bankAccountList?.forEach((method, i) => {
        savedMethods.push({
          title:
            method?.bankAccountNickName ||
            `Bank account ending ${method?.bankAccountNumber?.slice(-4)}`,
          iconSlug: "bank-account",
          clickHandler: () => {
            updatePaymentMethods({ bankAccountid: method?.bankAccountId })
          }
        })
      })
      dataPaymentMethods?.data?.cardList?.forEach((method, i) => {
        savedMethods.push({
          title:
            method?.cardNickName || `Card ending ${method?.cardLast4Digits}`,
          iconSlug: "debit-credit-card",
          clickHandler: () => {
            updatePaymentMethods({ cardId: method?.cardId })
          }
        })
      })
      setSavedPaymentMethods(savedMethods)
    }
  }, [dataPaymentMethods, isFetchingPaymentMethods, isErrorPaymentMethods])

  const addBankAccountMutation =
    usePaymentMethodMutation.useAddBankAccountMutation()
  const updatePaymentMethodsMutation =
    useAccountSetupMutation.useUpdatePaymentMethodsMutation()

  const handleScreenApiError = ({
    customHeading = "Failed to update payment method",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const updatePaymentMethods = ({ bankAccountid, cardId, ...params }) => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId,
      ...(!!bankAccountid ? { bankAccountid } : {}),
      ...(!!cardId ? { cardId } : {}),
      ...params
    }
    updatePaymentMethodsMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          nav(ALLROUTES.accountSetupChildren.selectAutopay)
        } else {
          handleScreenApiError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setModalVisibility(false)
      }
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div
            className={`content-wrap d-flex flex-column flex-grow-1 align-items-center vert-offset`}
          >
            <h1 className="billboard text-center">
              Select Payment Method
              <span
                className="icon-help"
                ref={paymentMethodTooltipTarget}
                onClick={() => {
                  setShowPaymentMethodTooltip(!showPaymentMethodTooltip)
                }}
              ></span>
              <Overlay
                target={paymentMethodTooltipTarget.current}
                show={showPaymentMethodTooltip}
                placement="bottom-end"
              >
                {props => (
                  <Tooltip id={`payment-method-tooltip`} {...props}>
                    <TooltipContentPaymentMethods />
                    <span
                      className="close"
                      onClick={() => setShowPaymentMethodTooltip(false)}
                    ></span>
                  </Tooltip>
                )}
              </Overlay>
            </h1>
            <div className="payment-methods flex-grow-1">
              <SimpleBar
                style={{
                  maxHeight:
                    "calc(100vh - (var(--header-body-offset) * 2 + var(--header-height) + 104px))"
                }}
                autoHide={false}
              >
                {paymentMethods.map((paymentMethod, iPaymentMethod) => (
                  <div
                    className={`payment-method block block--selectable${
                      activePaymentMethod === paymentMethod.title
                        ? " selected"
                        : ""
                    } low-elevation br-medium`}
                    key={iPaymentMethod}
                    onClick={() => {
                      setActivePaymentMethod(paymentMethod.title)
                      paymentMethod.clickHandler()
                    }}
                  >
                    <div
                      className={`icon-wrap icon--${paymentMethod.iconSlug}`}
                    ></div>
                    <div className="payment-method__details">
                      <h3 className="payment-method__title">
                        {paymentMethod.title}
                      </h3>
                      {paymentMethod.subTitle ? (
                        <div className="payment-method__sub-title">
                          {paymentMethod.subTitle}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              </SimpleBar>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ScreenSetupSelectPaymentMethod
