import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dropdown, Form } from "react-bootstrap"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { AnimationLoadingFullScreen } from "../../../assets"
import {
  ADDMETHODSLUG_BANK,
  ADDMETHODSLUG_CARD,
  APPCONFIG
} from "../../../config"
import {
  consoleLog,
  displayAmount,
  formatDateToHumanReadable,
  formatDateToString,
  handleApiError
} from "../../../utils"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"
import { logicPlan } from "../../../utils/logic-plan"
import { ALLROUTES } from "../../../routes"

import CalendarMonth from "../../elements/calendar/calendar-month"
import HelpIconWithTooltip from "../../elements/form/help-icon-with-tooltip"
import { showModalScheduleTheRest } from "../plan/schedule-the-rest"
import { showModalMakeAPayment } from "./make-a-payment"
import { showModalPaymentDetails } from "./payment-details"
import { showModalBankInformation } from "../bank-information/bank-information"
import { showModalCardInformation } from "../card-information/card-information"

import {
  useDashboardQueries,
  useWalletPaymentQueries
} from "../../../utils/queries"
import { usePaymentMutation } from "../../../utils/mutations"

const OrgnWizardPayment = ({
  refetchDashboard,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  walletInformation
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const rentAssistEnabled =
    walletInformation?.rentAssistStatus ===
    APPCONFIG.api.rentAssistStatus.enabled

  const arrears = 5

  const [selectionIsValid, setSelectionIsValid] = useState(false)
  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [selectedWalletPayment, setSelectedWalletPayment] = useState(null)
  const [allPaymentMethods, setAllPaymentMethods] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

  const [selectedPayment, setSelectedPayment] = useState({})
  const [selectedAmountDetail, setSelectedAmountDetail] = useState("")
  const [amountEntered, setAmountEntered] = useState("")
  const minAmount = 1
  const [amountLeftToPay, setAmountLeftToPay] = useState(arrears || 0)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusWalletPaymentDetails,
    data: dataWalletPaymentDetails,
    isFetching: isFetchingWalletPaymentDetails,
    isError: isErrorWalletPaymentDetails,
    error: errorWalletPaymentDetails,
    refetch: refetchWalletPaymentDetails
  } = useWalletPaymentQueries.useWalletPaymentDetailsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  const {
    status: statusResidentLeasePaymentMethods,
    data: dataResidentLeasePaymentMethods,
    isFetching: isFetchingResidentLeasePaymentMethods,
    isError: isErrorResidentLeasePaymentMethods
  } = useDashboardQueries.useResidentLeasePaymentMethodsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  useEffect(() => {
    if (!!dataWalletPaymentDetails && selectedWalletPayment === null) {
      setSelectedWalletPayment(
        dataWalletPaymentDetails?.data?.paymentItemDetails?.[0]
      )
    }
  }, [dataWalletPaymentDetails])

  useEffect(() => {
    if (!!selectedWalletPayment) {
      logicPlan.onUpdateResidentLeasePaymentMethods({
        dataResidentLeasePaymentMethods,
        setAllPaymentMethods,
        setSelectedPaymentMethod,
        suggestedDefault: selectedWalletPayment?.defaultPaymentMethodSelected
      })
    }
  }, [dataResidentLeasePaymentMethods, selectedWalletPayment])

  useEffect(() => {
    if (!!selectedWalletPayment) {
      setSelectedPayment(selectedWalletPayment)
      setSelectedAmountDetail("")
      setAmountEntered("")
      if (!!allPaymentMethods) {
        setSelectedPaymentMethod(
          allPaymentMethods?.find(paymentMethod => {
            if (
              !!selectedWalletPayment?.defaultPaymentMethodSelected?.bankId &&
              paymentMethod?.type === APPCONFIG.api.bankSlug &&
              paymentMethod?.bankAccountId ===
                selectedWalletPayment?.defaultPaymentMethodSelected?.bankId
            ) {
              return paymentMethod
            }
            if (
              !!selectedWalletPayment?.defaultPaymentMethodSelected?.cardId &&
              paymentMethod?.type === APPCONFIG.api.cardSlug &&
              paymentMethod?.cardId ===
                selectedWalletPayment?.defaultPaymentMethodSelected?.cardId
            ) {
              return paymentMethod
            }
            if (
              !!selectedWalletPayment?.defaultPaymentMethodSelected
                ?.globalPaymentId &&
              paymentMethod?.id ===
                selectedWalletPayment?.defaultPaymentMethodSelected
                  ?.globalPaymentId
            ) {
              return paymentMethod
            }
            return null
          })
        )
      }
    }
  }, [selectedWalletPayment, allPaymentMethods])

  useEffect(() => {
    let amountLeftToPay
    if (
      selectedAmountDetail?.description === APPCONFIG.api.amountOtherSpecify
    ) {
      if (
        !!amountEntered &&
        amountEntered * 1 > 0 &&
        amountEntered >= minAmount
      ) {
        amountLeftToPay = selectedPayment?.amount - amountEntered + arrears
      }
    } else if (!!selectedAmountDetail) {
      amountLeftToPay =
        selectedPayment?.amount - selectedAmountDetail?.amount + arrears
    }
    setAmountLeftToPay(amountLeftToPay)
  }, [selectedPayment, selectedAmountDetail, amountEntered])

  const [showDateSelectorDropdown, setShowDateSelectorDropdown] =
    useState(false)
  const [chosenDateForSelection, setChosenDateForSelection] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  useEffect(() => {
    setSelectionIsValid(
      !!selectedWalletPayment &&
        !!selectedDate &&
        (selectedAmountDetail?.description === APPCONFIG.api.amountOtherSpecify
          ? amountEntered * 1 >= minAmount
          : !!selectedAmountDetail) &&
        !!selectedPaymentMethod
    )
  }, [
    selectedWalletPayment,
    selectedDate,
    selectedAmountDetail,
    amountEntered,
    selectedPaymentMethod
  ])

  const schedulePaymentMutation =
    usePaymentMutation.useSchedulePaymentMutation()
  const scheduleArrearPaymentMutation =
    usePaymentMutation.useScheduleArrearPaymentMutation()
  const onSubmit = () => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      ...(!!selectedWalletPayment?.customerPaymentId
        ? { customerPaymentId: selectedWalletPayment?.customerPaymentId }
        : {}),
      ...(!!selectedWalletPayment?.contractArrearsId
        ? { contractArrearId: selectedWalletPayment?.contractArrearsId }
        : {}),
      amount:
        selectedAmountDetail?.description === APPCONFIG.api.amountOtherSpecify
          ? amountEntered * 1
          : selectedAmountDetail?.amount,
      date: formatDateToString(selectedDate),
      paymentMethodInformation: {
        ...([
          APPCONFIG.api.globalPaymentMethods.CASH.slug,
          APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
        ].indexOf(selectedPaymentMethod?.type) > -1
          ? {
              globalPaymentId:
                selectedPaymentMethod?.type ===
                APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
                  ? APPCONFIG.api.globalPaymentMethods.CASHAPP.id
                  : APPCONFIG.api.globalPaymentMethods.CASH.id
            }
          : false),
        ...(selectedPaymentMethod?.type === APPCONFIG.api.bankSlug
          ? { bankId: selectedPaymentMethod?.bankAccountId }
          : false),
        ...(selectedPaymentMethod?.type === APPCONFIG.api.cardSlug
          ? { cardId: selectedPaymentMethod?.cardId }
          : false)
      }
    }
    const applicableMutation = !!selectedWalletPayment?.contractArrearsId
      ? scheduleArrearPaymentMutation
      : schedulePaymentMutation
    setUpdateInProgress(true)
    applicableMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200].indexOf(status) > -1) {
          setModalVisibility(false)
          refetchDashboard()
        } else {
          handleScreenApiError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        if (
          errorData?.code === 400 &&
          errorData?.errordescription.indexOf(
            APPCONFIG.api.insufficientAmount
          ) > -1
        ) {
          showModalScheduleTheRest({
            breadcrumbItems: [
              {
                label: "Make A Payment",
                onClick: () => {
                  showModalMakeAPayment({
                    refetchDashboard,
                    setModalClass,
                    setModalBreadcrumb,
                    setModalComponent,
                    setModalVisibility,
                    setMessageBubbleComponent,
                    setMessageBubbleVisibility,
                    walletInformation
                  })
                }
              }
            ],
            failedRequestParams: requestParams,
            refetchDashboard,
            walletInformation,
            setModalClass,
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility,
            setMessageBubbleComponent,
            setMessageBubbleVisibility
          })
          return
        }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const handleScreenApiError = ({
    customHeading = "Failed to schedule payment",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  if (
    updateInProgress ||
    isFetchingResidents ||
    isFetchingWalletPaymentDetails
  ) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="freeze-to-top">
        <h2 className="billboard text-center">Make A Payment</h2>
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Item
        </h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <Dropdown>
              <Dropdown.Toggle>
                <div className="payment-option">
                  <div className="payment-option__icon icon-list"></div>
                  <div className="payment-option__title">
                    {selectedWalletPayment?.paymentMethodDescription}
                  </div>
                  <div className="payment-option__caret"></div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dataWalletPaymentDetails?.data?.paymentItemDetails?.map(
                  (walletPayment, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedWalletPayment(walletPayment)
                      }}
                      key={i}
                    >
                      {walletPayment?.paymentMethodDescription}
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {!!selectedWalletPayment && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Date
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              <Dropdown show={showDateSelectorDropdown}>
                <Dropdown.Toggle
                  onClick={() => {
                    setShowDateSelectorDropdown(!showDateSelectorDropdown)
                  }}
                >
                  <div className="payment-option">
                    <div className="payment-option__icon"></div>
                    <div className="payment-option__title">
                      {!!selectedDate
                        ? formatDateToHumanReadable(selectedDate)
                        : `Select date`}
                    </div>
                    <div className="payment-option__caret"></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <CalendarMonth
                    compact={true}
                    selectDateMode={true}
                    selectDateCallback={setChosenDateForSelection}
                    startDate={
                      selectedWalletPayment?.paymentDateDetails
                        ?.availableDates?.[0]
                    }
                    selectDateModeDefaultSelectedDate={
                      selectedWalletPayment?.paymentDateDetails
                        ?.defaultSeletedDate || null
                    }
                    selectDateModeAvailableDates={
                      selectedWalletPayment?.paymentDateDetails
                        ?.availableDates || []
                    }
                  />
                  <div className="d-flex justify-content-center pt-4 pb-4 mb-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setSelectedDate(chosenDateForSelection || new Date())
                        setShowDateSelectorDropdown(false)
                      }}
                    >
                      Select Date
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Amount
        </h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <Dropdown>
              <Dropdown.Toggle>
                <div className="payment-option">
                  <div className="payment-option__icon icon-dollar"></div>
                  <div className="payment-option__title">
                    {selectedAmountDetail === "" ? "Select amount" : ""}
                    <span className="font-medium">
                      {selectedAmountDetail === ""
                        ? ""
                        : selectedAmountDetail?.description ===
                          APPCONFIG.api.amountOtherSpecify
                        ? "Other"
                        : `$${displayAmount(selectedAmountDetail?.amount)}`}
                    </span>
                    <span className="sub-title">
                      {selectedAmountDetail === ""
                        ? ""
                        : selectedAmountDetail?.description}
                    </span>
                  </div>
                  <div className="payment-option__caret"></div>
                </div>
              </Dropdown.Toggle>
              {selectedWalletPayment && (
                <Dropdown.Menu>
                  {selectedWalletPayment?.amountDetails?.map(
                    (amountDetail, i) => (
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedAmountDetail(amountDetail)
                        }}
                        key={i}
                      >
                        <div className="payment-option ps-0">
                          <div className="payment-option__title">
                            <span className="font-medium">
                              {amountDetail?.description ===
                              APPCONFIG.api.amountOtherSpecify
                                ? "Other"
                                : `$${displayAmount(amountDetail?.amount)}`}
                            </span>
                            <span className="sub-title">
                              {amountDetail?.description}
                            </span>
                          </div>
                        </div>
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              )}
            </Dropdown>
          </div>
        </div>
        {selectedAmountDetail?.description ===
          APPCONFIG.api.amountOtherSpecify && (
          <div className="generic-form">
            <div className="form-field mt-4">
              <Form.Control
                className={
                  amountEntered && parseFloat(amountEntered) < minAmount
                    ? "is-invalid"
                    : ""
                }
                type="number"
                defaultValue={amountEntered}
                placeholder={`Type in amount`}
                onKeyUp={event => {
                  setAmountEntered(event.target.value)
                }}
              />
              <div className="invalid-feedback">
                {amountEntered && parseFloat(amountEntered) < minAmount
                  ? `Payment must be atleast $${displayAmount(minAmount)}`
                  : ""}
              </div>
            </div>
          </div>
        )}
      </div>
      {!!allPaymentMethods && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Method
            <HelpIconWithTooltip
              tooltipClass={`make-a-payment-method-tooltip`}
              isOverModal={true}
              placement={`bottom-end`}
              TooltipComponent={() => (
                <>
                  <h2 className="h1">Payment Settings</h2>
                  <h3>Autopay</h3>
                  <p>
                    With autopay, Circa will withdraw your scheduled payments
                    automatically from your account. Circa always sends a
                    reminder according to your notification preferences before
                    withdrawing any funds, giving you the opportunity to make
                    last minute changes.
                  </p>
                  <h3>Manual</h3>
                  <p>
                    To pay manually, sign in and navigate to your Circa Wallet
                    each time you need to make a payment. You will still receive
                    payment reminders according to your notification
                    preferences.
                  </p>
                </>
              )}
            />
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              <Dropdown>
                <Dropdown.Toggle>
                  <div className="payment-option">
                    <div
                      className={`payment-option__icon icon-${selectedPaymentMethod?.type}`}
                    ></div>
                    <div className="payment-option__title">
                      {selectedPaymentMethod?.title}
                    </div>
                    <div className="payment-option__caret"></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {allPaymentMethods?.map((paymentMethod, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        if (paymentMethod?.type === ADDMETHODSLUG_BANK) {
                          showModalBankInformation({
                            onSubmitCallback: () => {
                              showModalMakeAPayment({
                                refetchDashboard,
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility,
                                walletInformation
                              })
                            },
                            updateInProgress,
                            setUpdateInProgress,
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility
                          })
                        } else if (paymentMethod?.type === ADDMETHODSLUG_CARD) {
                          showModalCardInformation({
                            onSubmitCallback: () => {
                              showModalMakeAPayment({
                                refetchDashboard,
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility,
                                walletInformation
                              })
                            },
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility,
                            setMessageBubbleComponent,
                            setMessageBubbleVisibility
                          })
                        } else {
                          setSelectedPaymentMethod(paymentMethod)
                        }
                      }}
                      key={i}
                    >
                      <div className="payment-option">
                        <div
                          className={`payment-option__icon icon-${paymentMethod?.type}`}
                        ></div>
                        <div className="payment-option__title">
                          {paymentMethod?.title}
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-primary flex-grow-1"
          disabled={!selectionIsValid}
          onClick={onSubmit}
        >
          {selectedAmountDetail?.description ===
          APPCONFIG.api.amountOtherSpecify
            ? `Continue`
            : `Schedule Payment`}
        </button>
      </div>
      <div className="btns-wrap d-flex flex-column widget">
        <button
          className="btn btn-bordered flex-grow-1"
          onClick={() => {
            showModalPaymentDetails({
              breadcrumbItems: [
                {
                  label: "Make A Payment",
                  onClick: () => {
                    showModalMakeAPayment({
                      refetchDashboard,
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      walletInformation
                    })
                  }
                }
              ],
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              formattedPaymentDate:
                selectedWalletPayment?.paymentMethodDescription
                  ?.replace("Payment", "")
                  ?.trim(),
              paymentId: selectedWalletPayment?.customerPaymentId,
              arrearsPaymentId: selectedWalletPayment?.customerPaymentArrearsId
            })
          }}
        >
          View Details
        </button>
        {!!amountLeftToPay && amountLeftToPay > 0 && (
          <p className="smaller-text mt-4 mb-0 text-center">
            There is ${amountLeftToPay} left to pay.
          </p>
        )}
        {!!arrears && arrears > 0 && (
          <p className="smaller-text mt-4 mb-0 text-center">
            ${arrears} will be applied to your balance.
          </p>
        )}
        {selectedPaymentMethod?.type ===
          APPCONFIG.api.globalPaymentMethods.CASH.slug && (
          <p className="smaller-text mt-4 mb-0 text-center">
            Agents will accept the scheduled amount as early as 2 days before
            your payment date.
          </p>
        )}
        {rentAssistEnabled && (
          <p className="smaller-text mt-4 mb-0 text-center">
            Your RentAssist plan will update once this payment is processed.
          </p>
        )}
      </div>
    </>
  )
}

export default OrgnWizardPayment
