import React from "react"

const TileRow = ({
  hasErrors,
  title,
  Icon,
  onTitleClick,
  actionButton1Text,
  onActionButton1Click,
  actionButton2Text,
  onActionButton2Click
}) => {
  return (
    <div
      className={`tile-row${
        hasErrors ? " has-errors" : ""
      } align-items-center d-flex justify-content-between block low-elevation br-medium`}
    >
      <div
        className="title clickable align-items-center d-flex"
        onClick={onTitleClick}
      >
        <Icon />
        <h4 className="mb-0">{title}</h4>
      </div>
      <div className="actions d-flex">
        {actionButton1Text && (
          <a className="clickable btn-edit" onClick={onActionButton1Click}>
            {actionButton1Text}
          </a>
        )}
        {actionButton2Text && !!onActionButton2Click && (
          <a className="clickable btn-delete" onClick={onActionButton2Click}>
            {actionButton2Text}
          </a>
        )}
      </div>
    </div>
  )
}
export default TileRow
