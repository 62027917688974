import axios from "axios"

import { APPCONFIG } from "../config"
import { manageMsalAuthJwtToken, manualMsalLogoutAndRedirect } from "."

axios.defaults.baseURL = APPCONFIG.usePackageJsonProxyForApis
  ? ""
  : APPCONFIG.baseWebApiUrl

export const Request = axios.create()

const tokenInterceptor = Request.interceptors.request.use(
  req => {
    const msalAuthJwtToken = manageMsalAuthJwtToken.get()
    if (!!msalAuthJwtToken) {
      req.headers["Authorization"] = "Bearer " + msalAuthJwtToken
    }
    return req
  },
  null,
  { synchronous: true }
)

const catchErrors = err => {
  // if ([400, 500, 403].find(e => e === err?.response?.status)) {
  //   window.customErrorCallback(err.response.data.message)
  // }
  if ([401, 403].find(e => e === err?.response?.status)) {
    manualMsalLogoutAndRedirect()
  }
  throw err
}

window.addEventListener("storage", () => {
  const msalAuthJwtToken = manageMsalAuthJwtToken.get()
  if (!!msalAuthJwtToken) {
    Request.interceptors.request.eject(tokenInterceptor)
    Request.interceptors.request.use(tokenInterceptor)
  }
})

export const post = (url, data, config) => {
  return Request.post(url, data, config).catch(catchErrors)
}

export const patch = (url, data, config) => {
  return Request.patch(url, data, config).catch(catchErrors)
}

export const put = (url, data, config) => {
  return Request.put(url, data, config).catch(catchErrors)
}

export const del = (url, data, config) => {
  return Request.delete(url, data, config).catch(catchErrors)
}

export const get = (url, config) => {
  return Request.get(url, config).catch(catchErrors)
}

export const customEvents = {
  API_ERROR: `${APPCONFIG.appNameSlug}-web-api-error`
}
