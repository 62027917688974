import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dropdown } from "react-bootstrap"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import {
  ADDMETHODSLUG_BANK,
  ADDMETHODSLUG_CARD,
  APPCONFIG
} from "../../../config"
import {
  displayAmount,
  formatDateToFormat,
  formatDateToHumanReadable,
  formatDateToString,
  handleApiError
} from "../../../utils"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"
import { logicPlan } from "../../../utils/logic-plan"

import { AnimationLoadingFullScreen } from "../../../assets"
import CalendarMonth from "../../elements/calendar/calendar-month"
import HelpIconWithTooltip from "../../elements/form/help-icon-with-tooltip"

import {
  useDashboardQueries,
  useWalletPaymentQueries
} from "../../../utils/queries"
import { usePaymentMutation } from "../../../utils/mutations"
import { showModalPaymentDetails } from "../payment/payment-details"
import { showModalMakeAPayment } from "../payment/make-a-payment"
import { showModalBankInformation } from "../bank-information/bank-information"
import { showModalCardInformation } from "../card-information/card-information"
import { showModalScheduleTheRest } from "./schedule-the-rest"

const OrgnWizardScheduleTheRest = ({
  breadcrumbItems = [],
  failedRequestParams,
  refetchDashboard,
  walletInformation,
  PostSubmitButtonComponent,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [allPaymentMethods, setAllPaymentMethods] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

  const [showDateSelectorDropdown, setShowDateSelectorDropdown] =
    useState(false)
  const [chosenDateForSelection, setChosenDateForSelection] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusResidentLeasePaymentMethods,
    data: dataResidentLeasePaymentMethods,
    isFetching: isFetchingResidentLeasePaymentMethods,
    isError: isErrorResidentLeasePaymentMethods
  } = useDashboardQueries.useResidentLeasePaymentMethodsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  const {
    status: statusPayOtherAmountInfo,
    data: dataPayOtherAmountInfo,
    isFetching: isFetchingPayOtherAmountInfo,
    isError: isErrorPayOtherAmountInfo,
    error: errorPayOtherAmountInfo,
    refetch: refetchPayOtherAmountInfo
  } = useWalletPaymentQueries.usePayOtherAmountInfoForAmountQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    customerPaymentId: failedRequestParams?.customerPaymentId,
    amount: failedRequestParams?.amount
  })

  useEffect(() => {
    if (!!dataResidentLeasePaymentMethods && !!dataPayOtherAmountInfo) {
      logicPlan.onUpdateResidentLeasePaymentMethods({
        dataResidentLeasePaymentMethods,
        setAllPaymentMethods,
        setSelectedPaymentMethod,
        suggestedDefault:
          dataPayOtherAmountInfo?.data?.pendingAmountDetails
            ?.defaultPaymentMethodSelected
      })
    }
  }, [dataResidentLeasePaymentMethods, dataPayOtherAmountInfo])

  const schedulePaymentAndLatePaymentMutation =
    usePaymentMutation.useSchedulePaymentAndLatePaymentMutation()
  const onSubmit = () => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      customerPaymentId: failedRequestParams?.customerPaymentId,
      paymentSettings: "A",
      paymentInformation: {
        amount: failedRequestParams?.amount,
        date: failedRequestParams?.date,
        defaultPaymentMethodSelected: {
          ...failedRequestParams?.paymentMethodInformation
        }
      },
      latePaymentInformation: {
        amount:
          dataPayOtherAmountInfo?.data?.pendingAmountDetails?.pendingAmount,
        date: formatDateToString(selectedDate),
        defaultPaymentMethodSelected: {
          ...([
            APPCONFIG.api.globalPaymentMethods.CASH.slug,
            APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
          ].indexOf(selectedPaymentMethod?.type) > -1
            ? {
                globalPaymentId:
                  selectedPaymentMethod?.type ===
                  APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
                    ? APPCONFIG.api.globalPaymentMethods.CASHAPP.id
                    : APPCONFIG.api.globalPaymentMethods.CASH.id
              }
            : false),
          ...(selectedPaymentMethod?.type === APPCONFIG.api.bankSlug
            ? { bankId: selectedPaymentMethod?.bankAccountId }
            : false),
          ...(selectedPaymentMethod?.type === APPCONFIG.api.cardSlug
            ? { cardId: selectedPaymentMethod?.cardId }
            : false)
        }
      },
      ...(failedRequestParams?.reason !== null
        ? {
            reason: failedRequestParams?.reason,
            message: failedRequestParams?.message
          }
        : {})
    }
    setUpdateInProgress(true)
    schedulePaymentAndLatePaymentMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200].indexOf(status) > -1) {
          setModalVisibility(false)
          refetchDashboard()
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const handleScreenApiError = ({
    customHeading = "Failed to schedule payments",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  if (updateInProgress || isFetchingResidents || isFetchingPayOtherAmountInfo) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="widget freeze-to-top">
        <h2 className="billboard text-center mb-4 mx-n2">Schedule The Rest</h2>
        <p className="you-are-modifying mb-4">
          There is remaining{" "}
          {`$${dataPayOtherAmountInfo?.data?.pendingAmountDetails?.pendingAmount}`}{" "}
          on{" "}
          {formatDateToFormat(
            new Date(
              failedRequestParams?.paymentActualDate ||
                failedRequestParams?.date
            ),
            "MMMM DD"
          )}
          .
          <br />
          Late payments cannot be changed.
        </p>
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Date
        </h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <Dropdown show={showDateSelectorDropdown}>
              <Dropdown.Toggle
                onClick={() => {
                  setShowDateSelectorDropdown(!showDateSelectorDropdown)
                }}
              >
                <div className="payment-option">
                  <div className="payment-option__icon"></div>
                  <div className="payment-option__title">
                    {!!selectedDate
                      ? formatDateToHumanReadable(selectedDate)
                      : `Select date`}
                  </div>
                  <div className="payment-option__caret"></div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <CalendarMonth
                  compact={true}
                  selectDateMode={true}
                  selectDateCallback={setChosenDateForSelection}
                  startDate={
                    dataPayOtherAmountInfo?.data?.pendingAmountDetails
                      ?.date?.[0]
                  }
                  selectDateModeDefaultSelectedDate={
                    dataPayOtherAmountInfo?.data?.pendingAmountDetails
                      ?.date?.[0] || null
                  }
                  selectDateModeAvailableDates={
                    dataPayOtherAmountInfo?.data?.pendingAmountDetails?.date ||
                    []
                  }
                />
                <div className="d-flex justify-content-center pt-4 pb-4 mb-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setSelectedDate(chosenDateForSelection || new Date())
                      setShowDateSelectorDropdown(false)
                    }}
                  >
                    Select Date
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {!!allPaymentMethods && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Method
            <HelpIconWithTooltip
              tooltipClass={`schedule-the-rest-method-tooltip`}
              isOverModal={true}
              placement={`bottom-end`}
              TooltipComponent={() => (
                <>
                  <h2 className="h1">Payment Settings</h2>
                  <h3>Autopay</h3>
                  <p>
                    With autopay, Circa will withdraw your scheduled payments
                    automatically from your account. Circa always sends a
                    reminder according to your notification preferences before
                    withdrawing any funds, giving you the opportunity to make
                    last minute changes.
                  </p>
                  <h3>Manual</h3>
                  <p>
                    To pay manually, sign in and navigate to your Circa Wallet
                    each time you need to make a payment. You will still receive
                    payment reminders according to your notification
                    preferences.
                  </p>
                </>
              )}
            />
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              <Dropdown>
                <Dropdown.Toggle>
                  <div className="payment-option">
                    <div
                      className={`payment-option__icon icon-${selectedPaymentMethod?.type}`}
                    ></div>
                    <div className="payment-option__title">
                      {selectedPaymentMethod?.title}
                    </div>
                    <div className="payment-option__caret"></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {allPaymentMethods?.map((paymentMethod, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        if (paymentMethod?.type === ADDMETHODSLUG_BANK) {
                          showModalBankInformation({
                            onSubmitCallback: () => {
                              showModalScheduleTheRest({
                                breadcrumbItems,
                                failedRequestParams,
                                refetchDashboard,
                                walletInformation,
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility
                              })
                            },
                            updateInProgress,
                            setUpdateInProgress,
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility
                          })
                        } else if (paymentMethod?.type === ADDMETHODSLUG_CARD) {
                          showModalCardInformation({
                            onSubmitCallback: () => {
                              showModalScheduleTheRest({
                                breadcrumbItems,
                                failedRequestParams,
                                refetchDashboard,
                                walletInformation,
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility
                              })
                            },
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility,
                            setMessageBubbleComponent,
                            setMessageBubbleVisibility
                          })
                        } else {
                          setSelectedPaymentMethod(paymentMethod)
                        }
                      }}
                      key={i}
                    >
                      <div className="payment-option">
                        <div
                          className={`payment-option__icon icon-${paymentMethod?.type}`}
                        ></div>
                        <div className="payment-option__title">
                          {paymentMethod?.title}
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-primary flex-grow-1"
          disabled={!selectedDate}
          onClick={onSubmit}
        >
          {`Schedule Payments`}
        </button>
      </div>
      {PostSubmitButtonComponent ? <PostSubmitButtonComponent /> : ""}
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-bordered flex-grow-1"
          onClick={() => {
            showModalPaymentDetails({
              breadcrumbItems: breadcrumbItems || [
                {
                  label: "Make A Payment",
                  onClick: () => {
                    showModalMakeAPayment({
                      refetchDashboard,
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      walletInformation
                    })
                  }
                }
              ],
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              formattedPaymentDate: formatDateToFormat(
                new Date(
                  failedRequestParams?.paymentActualDate ||
                    failedRequestParams?.date
                ),
                "MMMM DD"
              ),
              paymentId: failedRequestParams?.customerPaymentId
            })
          }}
        >
          View Details
        </button>
      </div>
      {!!dataPayOtherAmountInfo?.data?.pendingAmountDetails?.fees && (
        <p className="smaller-text text-center mb-0 mt-4">
          A{" "}
          {`$${displayAmount(
            dataPayOtherAmountInfo?.data?.pendingAmountDetails?.fees
          )}`}{" "}
          late fee will be added to this payment.
        </p>
      )}
    </>
  )
}

export default OrgnWizardScheduleTheRest
