import React, { useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { ALLROUTES } from "../../../routes"
import { AllIcons } from "../../../assets"
import { gotoLinkNewLease, reduceApiData } from "../../../utils"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"

import { useDashboardQueries } from "../../../utils/queries"

import "./no-current-lease.scss"

const ScreenDashNoCurrentLease = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc
  } = useOutletContext()

  const nav = useNavigate()

  const { data: dataResident } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })
  const reducedDataResident = reduceApiData(dataResident, "resident")

  useEffect(() => {
    document.body.classList.add("page__dash-no-current-lease")
    setContainerClass("screen__dash-no-current-lease")
    return () => {
      document.body.classList.remove("page__dash-no-current-lease")
      setContainerClass("")
    }
  }, [])

  const giantButtons = [
    {
      Icon: () => <AllIcons.LinkNewLease />,
      title: "Link New Lease",
      onClick: () => {
        gotoLinkNewLease({ nav })
      }
    },
    {
      Icon: () => <AllIcons.LeaseDetails />,
      title: "View Past Leases",
      onClick: () => {
        nav(ALLROUTES.dashboardChildren.settingsChildren.leases)
      }
    },
    {
      Icon: () => <AllIcons.ViewPayments />,
      title: "View Past Payments",
      onClick: () => {
        nav(ALLROUTES.dashboardChildren.settingsChildren.payments)
      }
    }
  ]

  return (
    <>
      <div className="d-flex">
        <div className="column-left flex-1 text-center">
          <FullScreenCentered className={`w-100`} vw100={false}>
            <h1>
              Welcome back, {reducedDataResident?.firstName || "Resident"}!
            </h1>
            <div className="graphic-wrap image"></div>
            <h2 className="h1">You have no active leases.</h2>
          </FullScreenCentered>
        </div>

        <div className="column-right flex-2">
          <FullScreenCentered className={`w-100`} vw100={false}>
            <div className="giant-buttons">
              {giantButtons.map(item => (
                <button
                  className="giant-button clickable"
                  onClick={item.onClick}
                >
                  <item.Icon />
                  <span className="h2">{item.title}</span>
                </button>
              ))}
            </div>
          </FullScreenCentered>
        </div>
      </div>
    </>
  )
}

export default ScreenDashNoCurrentLease
