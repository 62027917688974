import React from "react"
import { useNavigate } from "react-router-dom"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../state"

import { ALLROUTES } from "../../routes"
import { widgetGraphics } from "../../assets"

import OrgnInfoCardSecurity from "../organisms/card-information/card-security-info"
import OrgnInfoCreditReporting from "../organisms/credit-reporting/credit-reporting"
import OrgnInfoReferralCode from "../organisms/referral/referral-code-info"
import OrgnFormCashPayment from "../organisms/cash-payment/cash-payment"
import OrgnInfoCashApp from "../organisms/cash-app/cash-app"

import "./widgets.scss"

export const showModalInfoReferralCode = ({
  setModalBreadcrumb,
  setModalClass,
  setModalComponent,
  setModalVisibility
}) => {
  setModalBreadcrumb([{ label: "" }])
  setModalClass("modal--referral-code-info narrow-content")
  setModalComponent(() => (
    <div className="modal-content-height d-flex flex-column">
      <OrgnInfoReferralCode />
    </div>
  ))
  setModalVisibility(true)
}

const DashWidgetStaticWidgets = ({ selectedLease, walletInformation }) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const showModalCashPayment = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--cash-payment narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnFormCashPayment />
      </div>
    ))
    setModalVisibility(true)
  }

  const showModalCashAppPayment = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--cash-app narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnInfoCashApp />
      </div>
    ))
    setModalVisibility(true)
  }

  const showModalCardSecurity = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--card-security narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnInfoCardSecurity />
      </div>
    ))
    setModalVisibility(true)
  }

  const staticWidgets = [
    {
      slug: "credit-reporting",
      Graphic: () => <widgetGraphics.CreditReporting />,
      Heading: () => (
        <>
          The credit
          <br className="show-on-hover" /> you deserve
        </>
      ),
      description: "Using Circa boosts your credit.",
      ctaText: "Learn More",
      ctaOnClick: () => {
        setModalBreadcrumb([{ label: "" }])
        setModalClass("modal--credit-reporting narrow-content")
        setModalComponent(() => (
          <div className="modal-content-height d-flex flex-column">
            <OrgnInfoCreditReporting />
          </div>
        ))
        setModalVisibility(true)
      }
    },
    ...(!!walletInformation?.data?.rentAssistInformation?.leaseArrearsId ||
    !!selectedLease?.rentAssist?.isRentAssistRequired ||
    !!selectedLease?.leaseWidgetInformation?.displaySetupRentAssistWidget
      ? [
          {
            slug: "rent-assist",
            Graphic: () => <widgetGraphics.RentAssist />,
            Heading: () => (
              <>
                Missed rent?
                <br className="show-on-hover" /> It happens.
              </>
            ),
            description: "Make a plan to get caught up.",
            ctaText: `${
              !!walletInformation?.data?.rentAssistInformation
                ?.isRentAssistEnrolled
                ? "Modify"
                : "Setup"
            } RentAssist`,
            ctaOnClick: () => {
              nav(ALLROUTES.dashboardChildren.welcomeToRentAssist)
            }
          }
        ]
      : []),
    ...(!!selectedLease?.leaseWidgetInformation?.displayCardWidget
      ? [
          {
            slug: "payment-method-credit-card",
            Graphic: () => <widgetGraphics.PaymentMethodCreditCard />,
            heading: "Card Payments",
            description: "Know the basics",
            ctaText: "Learn More",
            ctaOnClick: showModalCardSecurity,
            graphicOnTheRight: true
          }
        ]
      : []),
    {
      slug: "referral",
      Graphic: () => <widgetGraphics.Referral />,
      Heading: () => (
        <>
          Share Circa
          <br className="show-on-hover" /> &amp; Earn!
        </>
      ),
      description: "Earn $10 per referral",
      ctaText: "Refer Your Neighbor",
      ctaOnClick: () => {
        showModalInfoReferralCode({
          setModalBreadcrumb,
          setModalClass,
          setModalComponent,
          setModalVisibility
        })
      },
      graphicOnTheRight: true
    },
    ...(!!selectedLease?.leaseWidgetInformation?.displayCashWidget
      ? [
          {
            slug: "payment-method-cash",
            Graphic: () => <widgetGraphics.PaymentMethodCash />,
            heading: "Cash Payments",
            description: "How it works",
            ctaText: "Learn More",
            ctaOnClick: showModalCashPayment,
            graphicOnTheRight: true
          }
        ]
      : []),
    ...(!!selectedLease?.leaseWidgetInformation?.displayCashAppWidget
      ? [
          {
            slug: "payment-method-cash-app",
            Graphic: () => <widgetGraphics.PaymentMethodCashApp />,
            heading: "Cash App",
            description: "How it works",
            ctaText: "Learn More",
            ctaOnClick: showModalCashAppPayment,
            graphicOnTheRight: true
          }
        ]
      : [])
  ]
  return (
    <SimpleBar
      style={{ maxHeight: "100%" }}
      autoHide={false}
      className="dash-widget static-widgets flex-grow-1 block high-elevation br-xlarge"
    >
      {staticWidgets.map((item, i) => (
        <div
          key={i}
          className={`static-widget clickable widget--${item.slug}${
            item.graphicOnTheRight ? " graphic-on-the-right" : ""
          } block high-elevation br-medium has-show-on-hover`}
          onClick={item.ctaOnClick}
        >
          <item.Graphic />
          <div className="details">
            {item.Heading && (
              <h4>
                <item.Heading />
              </h4>
            )}
            {item.heading && <h4>{item.heading}</h4>}
            <p>{item.description}</p>
            <button
              className="btn btn-text text-only"
              onClick={item.ctaOnClick}
            >
              {item.ctaText}
            </button>
          </div>
        </div>
      ))}
    </SimpleBar>
  )
}
export default DashWidgetStaticWidgets
