import React, { useState } from "react"

import { AnimationLoadingFullScreen } from "../../../assets"
import { APPCONFIG } from "../../../config"
import { displayAmount, numberWithOrdinal, pluralDayName } from "../../../utils"
import { logicPlan } from "../../../utils/logic-plan"

import YouPayOnThe from "../../widgets/you-pay-on-the"
import { showModifyPlanModal } from "./modify-plan"

import "./plan.scss"

export const showModalModifyPlanConfirm = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  planPaymentDates,
  planPaymentDay,
  selectedSchedule,
  ...props /* logicPlan params received from OrgnWizardPlan's `onSubmit` under showModifyPlanModal */
}) => {
  setModalClass("modal--modify-plan narrow-content")
  setModalBreadcrumb([
    {
      label: "Modify your plan",
      onClick: () => {
        showModifyPlanModal({
          setModalClass,
          setModalBreadcrumb,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          planPaymentDates,
          planPaymentDay,
          selectedSchedule,
          ...props
        })
      }
    },
    { label: "Save Changes" }
  ])
  setModalComponent(() => {
    const [updateInProgress, setUpdateInProgress] = useState(false)

    const onConfirmChanges = ({}) => {
      setUpdateInProgress(true)
      logicPlan.onSubmitModifyPlan({
        planPaymentDates,
        planPaymentDay,
        selectedSchedule,
        onSettled: () => {
          setUpdateInProgress(false)
        },
        ...props
        /*dataResidents,
        nav,
        planPaymentDates,
        planPaymentDay,
        selectedSchedule,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        updatePaymentPlanMutation*/
      })
    }

    if (updateInProgress) {
      return <AnimationLoadingFullScreen minVh100={false} />
    }

    return (
      <>
        <div className="widget freeze-to-top">
          <h2 className="billboard text-center mb-4">Modify your plan</h2>
          <YouPayOnThe
            accentuated={true}
            compact={selectedSchedule?.frequency * 1 > 1}
            leftLabel={`You pay`}
            leftInfo={
              !!selectedSchedule?.amountPerPayment
                ? `$${displayAmount(selectedSchedule?.amountPerPayment)}`
                : ""
            }
            rightLabel={`on${
              selectedSchedule?.frequency * 1 < 4 ? " the" : ""
            }${
              [
                APPCONFIG.api.planSlugs.FIRSTANDTHIRD,
                APPCONFIG.api.planSlugs.SECONDANDFOURTH
              ].indexOf(selectedSchedule?.plan) > -1
                ? ` ${selectedSchedule?.planName}`
                : ""
            }`}
            rightInfo={`${
              (!selectedSchedule?.paymentDates?.defaultDayofweek ||
                selectedSchedule?.plan ===
                  APPCONFIG.api.planSlugs.ALTERNATING) &&
              !!planPaymentDates?.[0]
                ? numberWithOrdinal(planPaymentDates[0])
                : ""
            }${
              (!selectedSchedule?.paymentDates?.defaultDayofweek ||
                selectedSchedule?.plan ===
                  APPCONFIG.api.planSlugs.ALTERNATING) &&
              !!planPaymentDates?.[1]
                ? ` & ${numberWithOrdinal(planPaymentDates[1])}`
                : ""
            }${
              !!selectedSchedule?.paymentDates?.defaultDayofweek &&
              selectedSchedule?.plan !== APPCONFIG.api.planSlugs.ALTERNATING
                ? pluralDayName(planPaymentDay)
                : ""
            }`}
          />
        </div>
        <div className="widget">
          <h2 className="h1 mb-0">Are you sure?</h2>
        </div>
        <div className="widget">
          <p>These changes will apply to all regularly scheduled payments.</p>
        </div>
        <div className="btns-wrap d-flex widget">
          <button
            className="btn btn-primary flex-grow-1"
            onClick={onConfirmChanges}
          >
            Confirm Changes
          </button>
        </div>
        <div className="btns-wrap d-flex widget">
          <button
            className="btn btn-bordered flex-grow-1"
            onClick={() => {
              setModalVisibility(false)
            }}
          >
            Cancel
          </button>
        </div>
      </>
    )
  })
  setModalVisibility(true)
}
