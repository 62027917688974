import React, { useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Carousel } from "react-bootstrap"

import { ALLROUTES } from "../../../routes"
import {
  AllIcons,
  CircaLoadingAnimationFS,
  GraphicRentAssist1,
  GraphicRentAssist2,
  GraphicRentAssist3
} from "../../../assets"
import { displayAmount } from "../../../utils"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"

import {
  useDashboardQueries,
  useRentAssistQueries
} from "../../../utils/queries"

import "./rent-assist.scss"

const ScreenDashRentAssist = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc
  } = useOutletContext()

  const nav = useNavigate()

  useEffect(() => {
    document.body.classList.add("page__dash-rent-assist")
    setContainerClass("screen__dash-rent-assist")
    return () => {
      document.body.classList.remove("page__dash-rent-assist")
      setContainerClass("")
    }
  }, [])

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusRentAssistInfo,
    data: dataRentAssistInfo,
    isFetching: isFetchingRentAssistInfo,
    isError: isErrorRentAssistInfo,
    error: errorRentAssistInfo,
    refetch: refetchRentAssistInfo
  } = useRentAssistQueries.useRentAssistInfoQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  if (isFetchingResidents || isFetchingRentAssistInfo) {
    return <CircaLoadingAnimationFS />
  }

  if (!dataRentAssistInfo?.data?.rentAssistInfoViewObj?.isRentAssistEligible) {
    nav(ALLROUTES.dashboardContainer)
    return <></>
  }

  return (
    <div className="d-flex flex-row-reverse flex-wrap">
      <div className="column-right flex-2">
        <button
          className="btn-close clickable"
          onClick={() => {
            nav(ALLROUTES.dashboardChildren.dashboard)
          }}
        ></button>
        <FullScreenCentered className={`w-100`} vw100={false}>
          <div className="generic-form-wrap narrow-centered">
            <div className="px-4">
              <h1 className="mb-3 pb-2">Welcome to RentAssist!</h1>
              <p className="mb-5">
                Make a payment today or set up a long-term plan to pay back
                missed rent.
              </p>
            </div>
            <div className="block danger high-elevation br-xlarge p-4">
              <p className="regular-text mb-0">Your Account Balance</p>
              <h2 className="billboard mb-0">
                -$
                {displayAmount(
                  dataRentAssistInfo?.data?.rentAssistInfoViewObj
                    ?.rentAssistCurrentPendingBalance
                )}
              </h2>
              <div
                className="block btn-tile horizontal clickable mt-4"
                onClick={() => {
                  nav(
                    `${ALLROUTES.dashboardChildren.dashboard}${ALLROUTES.hashUrls.makeAPayment}`
                  )
                }}
              >
                <AllIcons.PaymentOptionPayNow />
                <h4>Make a Payment</h4>
              </div>
              <div
                className="block btn-tile horizontal clickable mt-4"
                onClick={() => {
                  nav(
                    `${ALLROUTES.dashboardChildren.dashboard}${ALLROUTES.hashUrls.rentAssist}`
                  )
                }}
              >
                <AllIcons.SetupRentAssist />
                <h4>
                  {!!dataRentAssistInfo?.data?.rentAssistInfoViewObj
                    ?.isRentAssistEnrolled
                    ? `Modify`
                    : `Set up`}{" "}
                  RentAssist
                </h4>
              </div>
            </div>
          </div>
        </FullScreenCentered>
      </div>

      <div className="column-left flex-1 text-center">
        <FullScreenCentered className={`w-100`} vw100={false}>
          <Carousel
            className={`screen__signup--carousel d-flex flex-column-reverse`}
          >
            <Carousel.Item>
              <h2 className="h1">Choose what works for you</h2>
              <p className="regular-text mb-5">
                Select a plan within your property’s
                <br />
                pre-approved options. No extra fees!
              </p>
              <GraphicRentAssist1 />
            </Carousel.Item>

            <Carousel.Item>
              <h2 className="h1">Choose what works for you</h2>
              <p className="regular-text mb-5">
                Select a plan within your property’s
                <br />
                pre-approved options. No extra fees!
              </p>
              <GraphicRentAssist2 />
            </Carousel.Item>

            <Carousel.Item>
              <h2 className="h1">Choose what works for you</h2>
              <p className="regular-text mb-5">
                Select a plan within your property’s
                <br />
                pre-approved options. No extra fees!
              </p>
              <GraphicRentAssist3 />
            </Carousel.Item>
          </Carousel>
        </FullScreenCentered>
      </div>
    </div>
  )
}

export default ScreenDashRentAssist
