import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { AnimationLoadingFullScreen } from "../../../assets"
import {
  displayAmount,
  formatDateToFormat,
  handleApiError
} from "../../../utils"

import YouPayOnThe from "../../widgets/you-pay-on-the"
import HelpIconWithTooltip from "../../elements/form/help-icon-with-tooltip"
import { showChangePlanModal } from "../plan/change-plan"
import { showModalPayLate } from "../plan/pay-late"
import { showPayOtherAmountModal } from "../plan/pay-other-amount"
import { showModalPaymentDetails } from "./payment-details"

import {
  useDashboardQueries,
  useWalletPaymentQueries
} from "../../../utils/queries"
import { usePaymentMutation } from "../../../utils/mutations"

import "../plan/plan.scss"
import "./payment.scss"

export const showModalModifyPayment = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  refetchDashboard,
  allUpcomingPayments,
  ...modifyPaymentParams
  /**
   * `modifyPaymentParams` contains the following:
   * - amount,
   * - customerPaymentArrearsId,
   * - customerPaymentId,
   * - date,
   * - isLatePayment,
   * - planTypeName
   **/
}) => {
  setModalClass("modal--modify-plan narrow-content")
  setModalBreadcrumb([{ label: "" }])
  setModalComponent(() => {
    const nav = useNavigate()

    const [updateInProgress, setUpdateInProgress] = useState(false)

    const {
      status: statusResidents,
      data: dataResidents,
      isFetching: isFetchingResidents
    } = useDashboardQueries.useResidentsQuery({})

    const {
      status: statusPaymentModifyInfo,
      data: dataPaymentModifyInfo,
      isFetching: isFetchingPaymentModifyInfo,
      isError: isErrorPaymentModifyInfo,
      error: errorPaymentModifyInfo,
      refetch: refetchPaymentModifyInfo
    } = useWalletPaymentQueries.usePaymentModifyInfoQuery({
      config: { refetchOnMount: true },
      enabled: statusResidents === "success",
      residentId: dataResidents?.data?.residentData?.id,
      paymentId: modifyPaymentParams?.customerPaymentId,
      paymentArrearsId: modifyPaymentParams?.customerPaymentArrearsId
    })

    const [modifyPaymentOptions, setModifyPaymentOptions] = useState([])

    useEffect(() => {
      if (
        !!dataResidents &&
        !!dataPaymentModifyInfo &&
        !isFetchingPaymentModifyInfo
      ) {
        const paymentModifyInfoDetails =
          dataPaymentModifyInfo?.data?.paymentArrearDetails ||
          dataPaymentModifyInfo?.data?.paymentDetails
        setModifyPaymentOptions([
          ...(typeof paymentModifyInfoDetails?.isPayNow !== "undefined"
            ? [
                {
                  icon_class: "icon-pay-now",
                  title: "Pay Now",
                  onClick: onPayNow,
                  disabled: paymentModifyInfoDetails?.isPayNow === false
                }
              ]
            : []),
          ...(typeof paymentModifyInfoDetails?.isChangePlan !== "undefined"
            ? [
                {
                  icon_class: "icon-change-plan",
                  title: "Change Plan",
                  onClick: () => {
                    showChangePlanModal({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  },
                  disabled: paymentModifyInfoDetails?.isChangePlan === false
                }
              ]
            : []),
          ...(typeof paymentModifyInfoDetails?.isPayOtherAmount !== "undefined"
            ? [
                {
                  icon_class: "icon-pay-other-amount",
                  title: "Pay Other Amount",
                  onClick: () => {
                    showPayOtherAmountModal({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  },
                  disabled: paymentModifyInfoDetails?.isPayOtherAmount === false
                }
              ]
            : []),
          ...(typeof paymentModifyInfoDetails?.isPayLate !== "undefined"
            ? [
                {
                  icon_class: "icon-pay-late",
                  title: "Pay Late",
                  onClick: () => {
                    showModalPayLate({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  },
                  disabled: paymentModifyInfoDetails?.isPayLate === false
                }
              ]
            : [])
        ])
      }
    }, [dataResidents, dataPaymentModifyInfo, isFetchingPaymentModifyInfo])

    const mutations = {
      payNow: usePaymentMutation.usePayNowMutation(),
      payNowArrears: usePaymentMutation.usePayNowArrearsMutation()
    }
    const onPayNow = () => {
      const requestParams = {
        residentId: dataResidents?.data?.residentData?.id,
        ...(!!modifyPaymentParams?.customerPaymentId
          ? { paymentId: modifyPaymentParams?.customerPaymentId }
          : {}),
        ...(!!modifyPaymentParams?.customerPaymentArrearsId
          ? { paymentArrearsId: modifyPaymentParams?.customerPaymentArrearsId }
          : {})
      }
      const applicableMutation = !!modifyPaymentParams?.customerPaymentArrearsId
        ? mutations.payNowArrears
        : mutations.payNow
      setUpdateInProgress(true)
      applicableMutation.mutate(requestParams, {
        onSuccess: ({ data, status }) => {
          if ([200].indexOf(status) > -1) {
            setModalVisibility(false)
            if (!!refetchDashboard) refetchDashboard()
          }
        },
        onError: axiosError => {
          const errorData = axiosError?.response?.data || { apiError: true }
          handleScreenApiError({
            ...errorData
          })
        },
        onSettled: () => {
          setUpdateInProgress(false)
        }
      })
    }

    const handleScreenApiError = ({
      customHeading = "Failed to process payment",
      ...data
    }) => {
      handleApiError({
        customHeading,
        nav,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        ...data
      })
    }

    if (
      updateInProgress ||
      isFetchingResidents ||
      isFetchingPaymentModifyInfo
    ) {
      return <AnimationLoadingFullScreen minVh100={false} />
    }

    const navPrevpaymentItem =
      allUpcomingPayments?.[
        allUpcomingPayments?.findIndex(upcomingItem =>
          !!modifyPaymentParams?.customerPaymentArrearsId
            ? upcomingItem?.customerPaymentArrearsId ===
              modifyPaymentParams?.customerPaymentArrearsId
            : upcomingItem?.customerPaymentId ===
              modifyPaymentParams?.customerPaymentId
        ) - 1
      ]

    const navNextpaymentItem =
      allUpcomingPayments?.[
        allUpcomingPayments?.findIndex(upcomingItem =>
          !!modifyPaymentParams?.customerPaymentArrearsId
            ? upcomingItem?.customerPaymentArrearsId ===
              modifyPaymentParams?.customerPaymentArrearsId
            : upcomingItem?.customerPaymentId ===
              modifyPaymentParams?.customerPaymentId
        ) + 1
      ]

    return (
      <>
        <h2 className="billboard text-center mb-4">Modify payment</h2>
        <YouPayOnThe
          className={`clickable`}
          accentuated={true}
          leftLabel={
            !!modifyPaymentParams?.amount
              ? `You pay`
              : (
                  dataPaymentModifyInfo?.data?.paymentArrearDetails ||
                  dataPaymentModifyInfo?.data?.paymentDetails
                )?.headerDetails?.line1Display
          }
          leftInfo={
            !!modifyPaymentParams?.amount
              ? `$${displayAmount(modifyPaymentParams?.amount)}`
              : (
                  dataPaymentModifyInfo?.data?.paymentArrearDetails ||
                  dataPaymentModifyInfo?.data?.paymentDetails
                )?.headerDetails?.line2Display
          }
          rightLabel={!!modifyPaymentParams?.date ? `on` : ""}
          rightInfo={
            !!modifyPaymentParams?.date
              ? formatDateToFormat(
                  new Date(modifyPaymentParams?.date),
                  "MMM DD"
                )
              : ""
          }
          onClick={() => {
            showModalPaymentDetails({
              breadcrumbItems: [
                {
                  label: "Modify Your Plan",
                  onClick: () => {
                    showModalModifyPayment({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  }
                }
              ],
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              formattedPaymentDate: !!modifyPaymentParams?.date
                ? formatDateToFormat(
                    new Date(modifyPaymentParams?.date),
                    "MMM DD"
                  )
                : "",
              paymentId: modifyPaymentParams?.customerPaymentId,
              arrearsPaymentId: modifyPaymentParams?.customerPaymentArrearsId
            })
          }}
          {...(!!navPrevpaymentItem
            ? {
                arrowLeftCallback: () => {
                  showModalModifyPayment({
                    setModalClass,
                    setModalBreadcrumb,
                    setModalComponent,
                    setModalVisibility,
                    setMessageBubbleComponent,
                    setMessageBubbleVisibility,
                    refetchDashboard,
                    allUpcomingPayments,
                    ...navPrevpaymentItem
                  })
                }
              }
            : {})}
          {...(!!navNextpaymentItem
            ? {
                arrowRightCallback: () => {
                  showModalModifyPayment({
                    setModalClass,
                    setModalBreadcrumb,
                    setModalComponent,
                    setModalVisibility,
                    setMessageBubbleComponent,
                    setMessageBubbleVisibility,
                    refetchDashboard,
                    allUpcomingPayments,
                    ...navNextpaymentItem
                  })
                }
              }
            : {})}
        />
        <div className="widget need-to-modify-payment text-center">
          <h2 className="h1 my-0">Need to modify this payment?</h2>
          <p className="mt-3 mb-0">
            Your property and Circa have teamed up to offer you the below
            options.
          </p>
        </div>
        <div className="widget modify-plan-options">
          <h3 className="d-flex justify-content-end">
            <HelpIconWithTooltip
              tooltipClass={`modify-plan-settings-tooltip`}
              isOverModal={true}
              placement={`left`}
              TooltipComponent={() => (
                <>
                  <h2 className="h1">Available Options</h2>
                  <h3>Pay Now</h3>
                  <p>
                    Get “rent day” over with and pay anytime before your
                    scheduled payment date.
                  </p>
                  <h3>Change Plan</h3>
                  <p>Adjust this month’s plan and/or payment method.</p>
                  <h3>Pay Other Amount</h3>
                  <p>
                    <span className="color-primary-periwinkle">
                      Available 5 days before payment date
                    </span>
                    <br />
                    Pay a different amount, within your property’s pre-approved
                    range.
                    <br />
                    Pay more to build savings to cover rent in case of an
                    emergency.
                    <br />
                    Pay less if you are certain you cannot make full rent.
                    Reschedule the remaining amount before the end of the month
                    within 14 days. A $X late fee will apply.
                  </p>
                  <h3>Pay Late</h3>
                  <p>
                    <span className="color-primary-periwinkle">
                      Available 5 days before payment date
                    </span>
                    <br />
                    If you know for certain you cannot make rent, you can avoid
                    an overdraft fee or declined card transaction by
                    rescheduling your payment within 14 days of the original
                    payment date. A $X late fee will apply.
                  </p>
                </>
              )}
            />
          </h3>
          <div className={`option-selector`}>
            {modifyPaymentOptions?.map((modifyPlanOption, i) => (
              <div
                className={`option-wrap modify-plan-option block block--selectable btn-tile ${
                  modifyPlanOption.disabled === true ? "disabled" : "clickable"
                }`}
                onClick={modifyPlanOption.onClick}
                key={i}
              >
                <div className={modifyPlanOption.icon_class}></div>
                <h4>{modifyPlanOption.title}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className="widget d-flex justify-content-center">
          <button className="btn btn-text text-only">Learn More</button>
        </div>
      </>
    )
  })
  setModalVisibility(true)
}
