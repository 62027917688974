import React from "react"

import OrgnWizardScheduleTheRest from "./wizard-schedule-the-rest"

import "./plan.scss"

export const showModalScheduleTheRest = ({
  breadcrumbItems = [],
  failedRequestParams,
  refetchDashboard,
  walletInformation,
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  setModalClass("modal--schedule-the-rest narrow-content")
  setModalBreadcrumb([
    ...breadcrumbItems,
    {
      label: "Schedule The Rest"
    }
  ])
  setModalComponent(() => (
    <OrgnWizardScheduleTheRest
      breadcrumbItems={breadcrumbItems}
      failedRequestParams={failedRequestParams}
      refetchDashboard={refetchDashboard}
      walletInformation={walletInformation}
      setMessageBubbleComponent={setMessageBubbleComponent}
      setMessageBubbleVisibility={setMessageBubbleVisibility}
    />
  ))
  setModalVisibility(true)
}
