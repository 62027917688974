import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { handleApiError, reduceApiData } from "../../../utils"

import { CircaLoadingAnimationFS } from "../../../assets"
import { CheckboxButtonNoState } from "../../../components/elements/form/checkbox-button"

import {
  useDashboardQueries,
  useNotificationQueries
} from "../../../utils/queries"
import { useNotificationMutation } from "../../../utils/mutations"

import "./notification-preferences.scss"

const ScreenDashSettNotifPrefs = () => {
  const nav = useNavigate()

  const {
    setContainerClass,
    setModalClass,
    setModalBreadcrumb,
    setModalVisibility,
    setModalComponent,
    setMessageBubbleComponent,
    setMessageBubbleVisibility
  } = useOutletContext()

  useEffect(() => {
    document.body.classList.add("page__dash-sett-notifprefs")
    setContainerClass("screen__dash-sett-notifprefs")
    return () => {
      document.body.classList.remove("page__dash-sett-notifprefs")
      setContainerClass("")
    }
  }, [])

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusNotificationSettings,
    data: dataNotificationSettings,
    isFetching: isFetchingNotificationSettings,
    isError: isErrorNotificationSettings,
    error: errorNotificationSettings,
    refetch: refetchNotificationSettings
  } = useNotificationQueries.useNotificationSettingsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const [notificationSettings, setNotificationSettings] = useState(null)
  const [updateInProgress, setUpdateInProgress] = useState(false)
  useEffect(() => {
    if (
      !!dataNotificationSettings &&
      !isFetchingNotificationSettings &&
      !isErrorNotificationSettings
    ) {
      setNotificationSettings(
        reduceApiData(dataNotificationSettings, "notification_settings")
      )
    }
  }, [
    dataNotificationSettings,
    isFetchingNotificationSettings,
    isErrorNotificationSettings
  ])

  const notificationSettingMutation = useNotificationMutation.useNotificationSettingMutation()
  const handleNotificationSettingClick = (settingSlug, flag) => {
    const updatedNotificationSettings = { ...notificationSettings }
    updatedNotificationSettings[settingSlug][flag] =
      notificationSettings?.[settingSlug]?.[flag] === "Y" ? "N" : "Y"
    setNotificationSettings(updatedNotificationSettings)
    setUpdateInProgress(true)
    notificationSettingMutation.mutate(
      {
        residentId: dataResidents?.data?.residentData?.id,
        ...updatedNotificationSettings[settingSlug]
      },
      {
        onSuccess: ({ data, status }) => {
          if ([200].indexOf(status) > -1) {
          }
        },
        onError: axiosError => {
          const errorData = axiosError?.response?.data || { apiError: true }
          handleScreenApiError({
            ...errorData
          })
        },
        onSettled: () => {
          setUpdateInProgress(false)
        }
      }
    )
  }

  const handleScreenApiError = ({
    customHeading = "Failed to update setting",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  if (
    updateInProgress ||
    isFetchingResidents ||
    isFetchingNotificationSettings
  ) {
    return <CircaLoadingAnimationFS />
  }

  return (
    <div className="leases-wrap d-flex flex-column generic-form">
      <div className="d-flex align-items-center justify-content-between">
        <div className="col-notif-options">
          <h2 className="mb-3 pb-1">Notifications</h2>
        </div>
        <div className="col-notif-switch">
          <h3 className="mb-3 pb-1">Email</h3>
        </div>
        <div className="col-notif-switch">
          <h3 className="mb-3 pb-1">SMS</h3>
        </div>
        <div className="col-notif-switch">
          <h3 className="mb-3 pb-1">Push</h3>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="col-notif-options">
          <h3 className="ms-4 ps-2 mb-2">Account Essentials (Required)</h3>
          <p className="ms-4 ps-2 mb-0 regular-text color-grayscale-graphite">
            These messages are required because we can’t properly service your
            account without them.
          </p>
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`account-essentials-email`}
            noLabel={true}
            checked={
              notificationSettings?.["accountEssentials"]?.emailFlag === "Y"
            }
            readOnly={true}
            onClick={() => {
              handleNotificationSettingClick("accountEssentials", "emailFlag")
            }}
          />
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`account-essentials-sms`}
            noLabel={true}
            checked={
              notificationSettings?.["accountEssentials"]?.smsFlag === "Y"
            }
            onClick={() => {
              handleNotificationSettingClick("accountEssentials", "smsFlag")
            }}
          />
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`account-essentials-push`}
            noLabel={true}
            checked={
              notificationSettings?.["accountEssentials"]?.pushFlag === "Y"
            }
            onClick={() => {
              handleNotificationSettingClick("accountEssentials", "pushFlag")
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="col-notif-options">
          <h3 className="ms-4 ps-2 mb-2">Account Updates</h3>
          <p className="ms-4 ps-2 mb-0 regular-text color-grayscale-graphite">
            These updates notify you of any changes made to your account,
            including plan settings.
          </p>
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`account-updates-email`}
            noLabel={true}
            checked={
              notificationSettings?.["accountUpdates"]?.emailFlag === "Y"
            }
            onClick={() => {
              handleNotificationSettingClick("accountUpdates", "emailFlag")
            }}
          />
        </div>
        <div className="col-notif-switch"> </div>
        <div className="col-notif-switch"> </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="col-notif-options">
          <h3 className="ms-4 ps-2 mb-2">Payment Notifications</h3>
          <p className="ms-4 ps-2 mb-0 regular-text color-grayscale-graphite">
            These reminders help you stay on top of your payment schedule.
          </p>
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`payment-notif-email`}
            noLabel={true}
            checked={notificationSettings?.["payment"]?.emailFlag === "Y"}
            onClick={() => {
              handleNotificationSettingClick("payment", "emailFlag")
            }}
          />
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`payment-notif-sms`}
            noLabel={true}
            checked={notificationSettings?.["payment"]?.smsFlag === "Y"}
            onClick={() => {
              handleNotificationSettingClick("payment", "smsFlag")
            }}
          />
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`payment-notif-push`}
            noLabel={true}
            checked={notificationSettings?.["payment"]?.pushFlag === "Y"}
            onClick={() => {
              handleNotificationSettingClick("payment", "pushFlag")
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="col-notif-options">
          <h3 className="ms-4 ps-2 mb-2">Circa Notifications</h3>
          <p className="ms-4 ps-2 mb-0 regular-text color-grayscale-graphite">
            These communications keep you in the know about what’s new at Circa.
          </p>
        </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`circa-notif-email`}
            noLabel={true}
            checked={notificationSettings?.["circa"]?.emailFlag === "Y"}
            onClick={() => {
              handleNotificationSettingClick("circa", "emailFlag")
            }}
          />
        </div>
        <div className="col-notif-switch"> </div>
        <div className="col-notif-switch">
          <CheckboxButtonNoState
            name={`circa-notif-push`}
            noLabel={true}
            checked={notificationSettings?.["circa"]?.pushFlag === "Y"}
            onClick={() => {
              handleNotificationSettingClick("circa", "pushFlag")
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ScreenDashSettNotifPrefs
