import { APPCONFIG } from "../config"

export const validation = {
  //** do not name the method for matching against regex as pattern as it seems to create name conflict with useform library **
  name: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,}$/i.test(name) ||
      (name.length < 3 ? "Too short" : "Invalid name")
  },

  nameMax26: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,26}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 26
        ? "Too long"
        : "Invalid name")
  },

  alphaNumericMax26: {
    match: name =>
      !name ||
      /^([a-zA-Z0-9, .'-]){3,26}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 26
        ? "Too long"
        : "Invalid name")
  },

  nameMax30: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,30}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 30
        ? "Too long"
        : "Invalid name")
  },

  nameMax40: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,40}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 40
        ? "Too long"
        : "Invalid name")
  },

  email: {
    match: email =>
      !email ||
      (APPCONFIG.validateEmailRegex.test(email) &&
        (email.indexOf("+") < 0 ||
          (email.indexOf("+") > -1 &&
            (email.indexOf("@wearecirca.com") > -1 ||
              email.indexOf("@eppopay.com") > -1 ||
              email.indexOf("@d3minds.com") > -1 ||
              email.indexOf("@bicycle.io") > -1 ||
              email.indexOf("@ventragate.com") > -1)))) ||
      "Enter a valid email address"
  },

  password: {
    match: pass =>
      !pass ||
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
        pass
      ) ||
      (pass.length < 8
        ? "Too short"
        : pass.length > 16
        ? "Too long"
        : "Password should be between 8 and 16 characters and include at least one lower case letter (abc), upper case letter (ABC), number (0-9), and special character (!@#$%)")
  },

  number: {
    match: number => !number || /^\d+(\.\d+)?$/.test(number) || "Invalid number"
  },

  phone: {
    us: phoneNumber =>
      !phoneNumber ||
      (/^\d{3}\-\d{3}\-\d{4}$/.test(phoneNumber) && phoneNumber.length === 12) ||
      "Please enter a valid phone number"
  },

  dob: {
    notToday: dateInput => {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      return (
        !dateInput ||
        new Date(dateInput).getTime() < today.getTime() ||
        "Please enter your date of birth"
      )
    }
  },

  amount: {
    match: amount =>
      !amount ||
      /^\d+(\.\d{1,2})?$/.test(amount) ||
      "Please enter a valid amount"
  },

  zipcode: {
    match: zipcode =>
      !zipcode || /^\d{5}$/.test(zipcode) || "Please enter a valid zipcode"
  },

  bankroutingnumber: {
    match: bankroutingnumber =>
      !bankroutingnumber ||
      /^\d{9}$/.test(bankroutingnumber) ||
      "Please enter a valid routing number"
  },

  bankaccountnumber: {
    match: bankaccountnumber =>
      !bankaccountnumber ||
      /^\d{4,20}$/.test(bankaccountnumber) ||
      "Please enter a valid bank account number"
  },

  delete: {
    match: deleteText =>
      !deleteText ||
      /^DELETE$/.test(deleteText) ||
      "Please enter the word DELETE in caps"
  }
}
