import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import { zPad } from "../../../utils"

import { DashboardWrapperNoAuth } from "../../../components/wrappers/dashboard"
import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import GenericForm from "../../../components/elements/generic-form"
import MessageBubble from "../../../components/elements/message-bubble/message-bubble"

import { useResetPasswordMutation } from "../../../utils/mutations"

import "./reset-password-verify.scss"

const ScreenResetPasswordVerify = () => {
  const nav = useNavigate()
  const resetPasswordMutation = useResetPasswordMutation()

  const [messageBubbleVisible, setMessageBubbleVisible] = useState(false)
  const [messageBubbleHeadingText, setMessageBubbleHeadingText] = useState("")
  const [messageBubbleContentText, setMessageBubbleContentText] = useState("")

  const [resendCodeInSec, setResendCodeInSec] = useState(
    APPCONFIG.resendCodeInSec
  )
  let resendCodeInSecInterval

  const formFields = [
    {
      label: "Verification Code",
      showLabel: false,
      name: "verification_code",
      compType: "n-digit-code",
      digitCount: 4,
      rules: {
        required: true,
        minLength: 4,
        maxLength: 4
      }
    }
  ]

  useEffect(() => {
    resendCodeInSecInterval = setTimeout(() => {
      setResendCodeInSec(resendCodeInSec - 1)
    }, 1000)
    return () => {
      clearInterval(resendCodeInSecInterval)
    }
  })

  const handleError = ({ status, httpStatusCode, message, apiError }) => {
    if (apiError) {
      nav(ALLROUTES.serverDown)
      return
    }
    setMessageBubbleHeadingText("Signup Failed")
    setMessageBubbleContentText(message)
    setMessageBubbleVisible(true)
  }

  const onSubmit = formValues => {
    resetPasswordMutation.mutate(formValues, {
      onSuccess: ({ response }) => {
        if (response.status === true && response.httpStatusCode === 200) {
          nav(ALLROUTES.emailVerification, {
            state: { email: formValues.email, message: response.message }
          })
        } else {
          handleError(response)
        }
      },
      onError: axiosError => {
        handleError(axiosError?.response?.data || { apiError: true })
      }
    })
  }

  return (
    <>
      <MessageBubble
        onHide={() => {
          setMessageBubbleVisible(false)
        }}
        show={messageBubbleVisible}
        contentClassName={`global-message-bubble`}
        headingText={messageBubbleHeadingText}
        messageText={messageBubbleContentText}
      >
        <p>
          If you don't see a message in your inbox, make sure the email address
          you provided is correct. Also check your spam or junk mail folder.
          This email was sent from admin@wearecirca.com.
        </p>
        <p>If you want us to resend the email, click "Send New Code."</p>
        <p>
          For further questions, please{" "}
          <a href={ALLROUTES.supportEmailLink}>contact us</a>.
        </p>
      </MessageBubble>

      <DashboardWrapperNoAuth
        bodyClass={`screen__reset-password d-flex flex-row-reverse flex-wrap`}
      >
        <FullScreenCentered className={`w-100`} vw100={false}>
          <div className="generic-form-wrap narrow-centered">
            <h1 className="h-with-p d-flex align-items-center justify-content-between">
              Verify your email
              <span
                className="icon-help"
                onClick={() => {
                  setMessageBubbleHeadingText("Email Verification")
                  setMessageBubbleVisible(true)
                }}
              ></span>
            </h1>
            <p>Verification code has been sent, please type in the code.</p>
            <GenericForm
              formClass={"mb-3"}
              submitButtonText={"Verify Code"}
              onSubmit={onSubmit}
              formFields={formFields}
            />
            <button
              className="btn btn-text d-block mx-auto"
              type={"button"}
              onClick={() => {}}
              disabled={resendCodeInSec > 0}
            >
              Send New Code
              {resendCodeInSec > 0 ? ` In 0:${zPad(resendCodeInSec, 2)}` : ""}
            </button>
          </div>
        </FullScreenCentered>
      </DashboardWrapperNoAuth>
    </>
  )
}

export default ScreenResetPasswordVerify
