import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dropdown, Form } from "react-bootstrap"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { AnimationLoadingFullScreen } from "../../../assets"
import {
  ADDMETHODSLUG_BANK,
  ADDMETHODSLUG_CARD,
  APPCONFIG
} from "../../../config"
import {
  displayAmount,
  formatDateToHumanReadable,
  formatDateToString,
  handleApiError
} from "../../../utils"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"
import { logicPlan } from "../../../utils/logic-plan"

import CalendarMonth from "../../elements/calendar/calendar-month"
import { showModalModifyPayment } from "../payment/modify-payment"
import { showModalPayLate } from "./pay-late"
import { showModalPaymentDetails } from "../payment/payment-details"
import { showModalBankInformation } from "../bank-information/bank-information"
import { showModalCardInformation } from "../card-information/card-information"

import {
  useDashboardQueries,
  useWalletPaymentQueries
} from "../../../utils/queries"
import { usePaymentMutation } from "../../../utils/mutations"

const OrgnWizardPayLate = ({
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  refetchDashboard,
  allUpcomingPayments,
  modifyPaymentParams
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [allPaymentMethods, setAllPaymentMethods] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

  const [selectedReason, setSelectedReason] = useState(null)

  const messageFieldRef = useRef()

  const [showDateSelectorDropdown, setShowDateSelectorDropdown] = useState(
    false
  )
  const [chosenDateForSelection, setChosenDateForSelection] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusResidentLeasePaymentMethods,
    data: dataResidentLeasePaymentMethods,
    isFetching: isFetchingResidentLeasePaymentMethods,
    isError: isErrorResidentLeasePaymentMethods
  } = useDashboardQueries.useResidentLeasePaymentMethodsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  const {
    status: statusLateOrFailedPaymentModifyInfo,
    data: dataLateOrFailedPaymentModifyInfo,
    isFetching: isFetchingLateOrFailedPaymentModifyInfo,
    isError: isErrorLateOrFailedPaymentModifyInfo
  } = useWalletPaymentQueries.useLateOrFailedPaymentModifyInfoQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    paymentId: modifyPaymentParams?.customerPaymentId,
    paymentArrearsId: modifyPaymentParams?.customerPaymentArrearsId
  })

  useEffect(() => {
    if (
      !!dataResidentLeasePaymentMethods &&
      !!dataLateOrFailedPaymentModifyInfo
    ) {
      logicPlan.onUpdateResidentLeasePaymentMethods({
        dataResidentLeasePaymentMethods,
        setAllPaymentMethods,
        setSelectedPaymentMethod,
        suggestedDefault:
          dataLateOrFailedPaymentModifyInfo?.data?.lateFailedpaymentDetails
            ?.defaultPaymentMethodSelected
      })
    }
  }, [dataResidentLeasePaymentMethods, dataLateOrFailedPaymentModifyInfo])

  const modifyLatePaymentMutation = usePaymentMutation.useModifyLatePaymentMutation()
  const modifyLatePaymentArrearsMutation = usePaymentMutation.useModifyLatePaymentArrearsMutation()
  const onSubmit = () => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      ...(!!modifyPaymentParams?.customerPaymentId
        ? { paymentId: modifyPaymentParams?.customerPaymentId }
        : {}),
      ...(!!modifyPaymentParams?.customerPaymentArrearsId
        ? { paymentArrearsId: modifyPaymentParams?.customerPaymentArrearsId }
        : {}),
      ...(!!modifyPaymentParams?.customerPaymentArrearsId
        ? { date: formatDateToString(selectedDate) }
        : { paymentDate: formatDateToString(selectedDate) }), // Mar 27, '23 - changed to paymentDate
      reason: selectedReason,
      message: messageFieldRef?.current?.value || "",
      ...([
        APPCONFIG.api.globalPaymentMethods.CASH.slug,
        APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
      ].indexOf(selectedPaymentMethod?.type) > -1
        ? {
            globalPaymentId:
              selectedPaymentMethod?.type ===
              APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
                ? APPCONFIG.api.globalPaymentMethods.CASHAPP.id
                : APPCONFIG.api.globalPaymentMethods.CASH.id
          }
        : false),
      ...(selectedPaymentMethod?.type === APPCONFIG.api.bankSlug
        ? { bankId: selectedPaymentMethod?.bankAccountId }
        : false),
      ...(selectedPaymentMethod?.type === APPCONFIG.api.cardSlug
        ? { cardId: selectedPaymentMethod?.cardId }
        : false)
    }
    setUpdateInProgress(true)
    const applicableMutation = !!modifyPaymentParams?.customerPaymentArrearsId
      ? modifyLatePaymentMutation
      : modifyLatePaymentArrearsMutation
    applicableMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200].indexOf(status) > -1) {
          setModalVisibility(false)
          refetchDashboard()
        } else {
          handleScreenApiError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const handleScreenApiError = ({
    customHeading = "Failed to schedule payment",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  if (
    updateInProgress ||
    isFetchingResidents ||
    isFetchingLateOrFailedPaymentModifyInfo
  ) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="widget freeze-to-top">
        <h2 className="billboard text-center mb-4">Pay Late</h2>
        <p className="you-are-modifying mb-4">
          {
            dataLateOrFailedPaymentModifyInfo?.data?.lateFailedpaymentDetails
              ?.line1Display
          }
          <br />
          Late payment cannot be modified later.
        </p>
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Dates
        </h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <Dropdown show={showDateSelectorDropdown}>
              <Dropdown.Toggle
                onClick={() => {
                  setShowDateSelectorDropdown(!showDateSelectorDropdown)
                }}
              >
                <div className="payment-option">
                  <div className="payment-option__icon"></div>
                  <div className="payment-option__title">
                    {!!selectedDate
                      ? formatDateToHumanReadable(selectedDate)
                      : `Select date`}
                  </div>
                  <div className="payment-option__caret"></div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <CalendarMonth
                  compact={true}
                  selectDateMode={true}
                  selectDateCallback={setChosenDateForSelection}
                  startDate={
                    dataLateOrFailedPaymentModifyInfo?.data
                      ?.lateFailedpaymentDetails?.paymentDatesDetails
                      ?.startDate ||
                    dataLateOrFailedPaymentModifyInfo?.data
                      ?.lateFailedpaymentDetails?.paymentDatesDetails
                      ?.availableDates?.[0]
                  }
                  selectDateModeDefaultSelectedDate={
                    dataLateOrFailedPaymentModifyInfo?.data
                      ?.lateFailedpaymentDetails?.paymentDatesDetails
                      ?.defaultSeletedDate || null
                  }
                  selectDateModeAvailableDates={
                    dataLateOrFailedPaymentModifyInfo?.data
                      ?.lateFailedpaymentDetails?.paymentDatesDetails
                      ?.availableDates || []
                  }
                />
                <div className="d-flex justify-content-center pt-4 pb-4 mb-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setSelectedDate(chosenDateForSelection || new Date())
                      setShowDateSelectorDropdown(false)
                    }}
                  >
                    Select Date
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {!!allPaymentMethods && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Method
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              <Dropdown>
                <Dropdown.Toggle>
                  <div className="payment-option">
                    <div
                      className={`payment-option__icon icon-${selectedPaymentMethod?.type}`}
                    ></div>
                    <div className="payment-option__title">
                      {selectedPaymentMethod?.title}
                    </div>
                    <div className="payment-option__caret"></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {allPaymentMethods?.map((paymentMethod, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        if (paymentMethod?.type === ADDMETHODSLUG_BANK) {
                          showModalBankInformation({
                            onSubmitCallback: () => {
                              showModalPayLate({
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility,
                                refetchDashboard,
                                allUpcomingPayments,
                                ...modifyPaymentParams
                              })
                            },
                            updateInProgress,
                            setUpdateInProgress,
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility
                          })
                        } else if (paymentMethod?.type === ADDMETHODSLUG_CARD) {
                          showModalCardInformation({
                            onSubmitCallback: () => {
                              showModalPayLate({
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility,
                                refetchDashboard,
                                allUpcomingPayments,
                                ...modifyPaymentParams
                              })
                            },
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility,
                            setMessageBubbleComponent,
                            setMessageBubbleVisibility
                          })
                        } else {
                          setSelectedPaymentMethod(paymentMethod)
                        }
                      }}
                      key={i}
                    >
                      <div className="payment-option">
                        <div
                          className={`payment-option__icon icon-${paymentMethod?.type}`}
                        ></div>
                        <div className="payment-option__title">
                          {paymentMethod?.title}
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Reason
        </h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <Dropdown>
              <Dropdown.Toggle>
                <div className="payment-option">
                  <div className="payment-option__icon icon-question"></div>
                  <div className="payment-option__title">
                    {dataLateOrFailedPaymentModifyInfo?.data?.lateFailedpaymentDetails?.reasons?.find(
                      (reason, i) => i === selectedReason
                    ) || `Select reason`}
                  </div>
                  <div className="payment-option__caret"></div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dataLateOrFailedPaymentModifyInfo?.data?.lateFailedpaymentDetails?.reasons?.map(
                  (reasonText, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedReason(i)
                      }}
                      key={i}
                    >
                      <div className="payment-option ps-0">
                        <div className="payment-option__title">
                          {reasonText}
                        </div>
                      </div>
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="widget generic-form">
        <h3 className="mb-3">Message to Property (Optional)</h3>
        <div className="form-field">
          <Form.Control
            as="textarea"
            ref={messageFieldRef}
            rows={5}
            placeholder={`Share details with your property`}
          />
        </div>
      </div>
      <div className="btns-wrap d-flex widget">
        <button className="btn btn-primary flex-grow-1" onClick={onSubmit}>
          Schedule Payment
        </button>
      </div>
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-bordered flex-grow-1"
          onClick={() => {
            showModalPaymentDetails({
              breadcrumbItems: [
                {
                  label: "Payment Options",
                  onClick: () => {
                    showModalModifyPayment({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  }
                },
                {
                  label: "Pay Late",
                  onClick: () => {
                    showModalPayLate({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  }
                }
              ],
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              formattedPaymentDate: "",
              paymentId: modifyPaymentParams?.customerPaymentId,
              arrearsPaymentId: modifyPaymentParams?.customerPaymentArrearsId
            })
          }}
        >
          View Details
        </button>
      </div>
      {!!dataLateOrFailedPaymentModifyInfo?.data?.lateFailedpaymentDetails
        ?.fees && (
        <p className="smaller-text text-center mb-0 mt-4">
          A $
          {displayAmount(
            dataLateOrFailedPaymentModifyInfo?.data?.lateFailedpaymentDetails
              ?.fees
          )}{" "}
          late fee will be added to this payment.
        </p>
      )}
    </>
  )
}

export default OrgnWizardPayLate
