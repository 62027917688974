import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import { APPCONFIG } from "../../../config"
import {
  calculateAccountSetupPercentage,
  displayAmount,
  handleize,
  numberOrdinal,
  numberWithOrdinal,
  pluralDayName,
  consoleLog,
  formatDateToString
} from "../../../utils"
import {
  leaseIdInSetup,
  leaseIdOnDash,
  redirectPerLeaseSetupProgress
} from "../../../utils/logic"
import { computePlanJson, logicPlan } from "../../../utils/logic-plan"
import { CircaLoadingAnimationFS } from "../../../assets"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import CalendarMonth from "../../../components/elements/calendar/calendar-month"

import {
  useAccountSetupQueries,
  useDashboardQueries
} from "../../../utils/queries"
import { useAccountSetupMutation } from "../../../utils/mutations"

import "../../../components/organisms/plan/plan.scss"
import "./select-plan.scss"
import { logicCalendar } from "../../../utils/logic-calendar"
import { APIhandler } from "../../../utils/api"

const ScreenSetupSelectPlan = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup
  } = useOutletContext()

  const { pathname, state: fwdState } = useLocation()
  const nav = useNavigate()

  const [selectedPlan, setSelectedPlan] = useState(null)
  const [showPlanDescription, setShowPlanDescription] = useState(false)

  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [showScheduleDescription, setShowScheduleDescription] = useState(false)

  const [paymentStartDate, setPaymentStartDate] = useState(null)
  const [planPaymentDates, setPlanPaymentDates] = useState([])
  const [planPaymentDay, setPlanPaymentDay] = useState(null)

  const [previewCalStartDate, setPreviewCalStartDate] = useState(
    `${formatDateToString(new Date()).substring(0, 8)}01`
  )
  const [previewCalPaymentDates, setPreviewCalPaymentDates] = useState([])
  const [previewCalHolidayDates, setPreviewCalHolidayDates] = useState([])

  useEffect(() => {
    document.body.classList.add("page__select-plan")
    setContainerClass("screen__select-plan")
    setDashboardProgressPerc(calculateAccountSetupPercentage(pathname))
    if (fwdState?.showWelcomeBackPopup) {
      showDashboardPopup(dashboardPopups.WelcomeBack)
    }
    if (APPCONFIG.debugGlobal && APPCONFIG.debugRedirection)
      consoleLog(`[SCREEN] select-plan`)
    return () => {
      document.body.classList.remove("page__select-plan")
      setContainerClass("")
      setDashboardProgressPerc(0)
    }
  }, [])

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  useEffect(() => {
    if ((dataResidents || errorResidents) && !isFetchingResidents) {
      const redirectApplicable = redirectPerLeaseSetupProgress({
        nav,
        pathname,
        dataResidents,
        errorResidents,
        leaseId:
          leaseIdInSetup.get() ||
          dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
      })
      if (!!redirectApplicable) redirectApplicable()
    }
  }, [dataResidents, isFetchingResidents, isErrorResidents])

  const {
    status: statusAvailablePaymentPlans,
    data: dataAvailablePaymentPlans,
    isFetching: isFetchingAvailablePaymentPlans,
    isError: isErrorAvailablePaymentPlans
  } = useAccountSetupQueries.useAvailablePaymentPlansQuery({
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  useEffect(() => {
    logicPlan.onUpdateAvailablePaymentPlans({
      isAccountSetup: true,
      dataResidents,
      nav,
      dataAvailablePaymentPlans,
      isErrorAvailablePaymentPlans,
      isFetchingAvailablePaymentPlans,
      setSelectedPlan
    })
  }, [
    dataAvailablePaymentPlans,
    isFetchingAvailablePaymentPlans,
    isErrorAvailablePaymentPlans
  ])

  useEffect(() => {
    logicPlan.onUpdateSelectedPlan({ selectedPlan, setSelectedSchedule })
  }, [selectedPlan])

  useEffect(() => {
    logicPlan.onUpdateSelectedSchedule({
      startDate: paymentStartDate,
      selectedSchedule,
      setPaymentStartDate,
      setPlanPaymentDates,
      setPlanPaymentDay
    })
  }, [selectedSchedule])

  useEffect(() => {
    if (APPCONFIG.debugGlobal) {
      console.log("paymentStartDate", paymentStartDate)
    }
  }, [paymentStartDate])

  useEffect(() => {
    if (APPCONFIG.debugGlobal) {
      console.log("planPaymentDates", planPaymentDates)
    }
    updateCalendarPaymentDates()
  }, [planPaymentDates])

  const updatePaymentPlanMutation =
    useAccountSetupMutation.useUpdatePaymentPlanMutation()

  const updateCalendarPaymentDates = () => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId,
      paymentId: 0,
      planId: selectedSchedule?.planId,
      adjustPaymenPlan: true
    }
    if (
      !!requestParams?.residentId &&
      !!requestParams?.leaseId &&
      !!requestParams?.planId
    ) {
      requestParams.planJson = JSON.stringify(
        computePlanJson({
          planPaymentDates,
          planPaymentDay,
          selectedSchedule
        })
      )
      if (APPCONFIG.debugGlobal) {
        console.log("%c[updateCalendarPaymentDates]", "background-color:#FFC")
        console.log("requestParams", requestParams)
      }
      APIhandler.account_setup
        .get_calendar_preview_dates(requestParams)
        .then(response => {
          if (response?.status === 200) {
            if (APPCONFIG.debugGlobal) console.log("success", response)
            const paymentDates = response?.data?.data?.paymentDates || []
            if (!!response?.data?.data?.paymentDates) {
              setPreviewCalStartDate(`${paymentDates?.[0]?.substring(0, 8)}01`)
            }
            setPreviewCalPaymentDates(paymentDates || [])
            setPreviewCalHolidayDates(response?.data?.data?.holidays || [])
          } else {
            if (APPCONFIG.debugGlobal) console.log("error", response)
          }
        })
        .catch(axiosError => {
          if (APPCONFIG.debugGlobal) console.log(axiosError)
        })
    }
  }

  return (
    <>
      {isFetchingResidents || isFetchingAvailablePaymentPlans ? (
        <CircaLoadingAnimationFS />
      ) : (
        <Container fluid>
          <Row>
            <Col lg={7} className="column-left">
              <FullScreenCentered
                className={`content-wrap`}
                vw100={false}
                valign={"start vert-offset"}
              >
                <div className="d-flex align-items-center justify-content-between block high-elevation br-xlarge you-pay-on-the">
                  <div className="info-block ps-4">
                    <span className="label">You pay</span>
                    <h2 className="billboard">
                      <span className="plan-payment-date">
                        ${displayAmount(selectedSchedule?.amountPerPayment)}
                      </span>
                    </h2>
                  </div>
                  <div className="info-block pe-4">
                    <span className="label">
                      on
                      {selectedSchedule?.plan ===
                      APPCONFIG.api.planSlugs.ALTERNATING
                        ? " alternating"
                        : selectedSchedule?.frequency * 1 < 4
                        ? " the"
                        : ""}
                      {[
                        APPCONFIG.api.planSlugs.FIRSTANDTHIRD,
                        APPCONFIG.api.planSlugs.SECONDANDFOURTH
                      ].indexOf(selectedSchedule?.plan) > -1
                        ? ` ${selectedSchedule?.planName}`
                        : ""}
                    </span>
                    <h2 className="billboard">
                      {!selectedSchedule?.paymentDates?.defaultDayofweek &&
                      !!planPaymentDates?.[0] ? (
                        <span className="plan-payment-date">
                          {planPaymentDates[0]}
                          <sub className="h1">
                            {numberOrdinal(planPaymentDates[0])}
                          </sub>
                        </span>
                      ) : (
                        ""
                      )}
                      {!selectedSchedule?.paymentDates?.defaultDayofweek &&
                      !!planPaymentDates?.[1] ? (
                        <>
                          <span className="amp"> &amp; </span>
                          <span className="plan-payment-date">
                            {planPaymentDates[1]}
                            <sub className="h1">
                              {numberOrdinal(planPaymentDates[1])}
                            </sub>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      {!!selectedSchedule?.paymentDates?.defaultDayofweek ? (
                        <span className="plan-payment-date is-day-name">
                          {pluralDayName(planPaymentDay)}
                        </span>
                      ) : (
                        ""
                      )}
                    </h2>
                  </div>
                </div>
                <div className="calendar-month-wrap">
                  <CalendarMonth
                    startDate={previewCalStartDate}
                    paymentDates={previewCalPaymentDates}
                    holidayDates={previewCalHolidayDates}
                    showHelpIcon={true}
                    refDateCallback={({ refDate }) => {
                      if (APPCONFIG.debugGlobal) {
                        console.log("refDateCallback", refDate)
                      }
                      // upon month change, the dates (esp 4 pay) need update here
                      const computedPaymentDates =
                        logicCalendar.computedPaymentDates({
                          planPaymentDay:
                            planPaymentDay - APPCONFIG.api.dayIndexAdjust,
                          selectedSchedule,
                          startDate: refDate
                        })
                      setPaymentStartDate(
                        computedPaymentDates?.paymentStartDate
                      )
                      switch (selectedSchedule?.plan) {
                        case APPCONFIG.api.planSlugs.FIRSTANDTHIRD:
                        case APPCONFIG.api.planSlugs.SECONDANDFOURTH:
                        case APPCONFIG.api.planSlugs.FOURPAY:
                          setPlanPaymentDates(
                            computedPaymentDates?.paymentDates
                          )
                          break
                      }
                    }}
                  />
                </div>
              </FullScreenCentered>
            </Col>
            <Col lg={5} className="column-right">
              <FullScreenCentered
                className={`content-wrap`}
                vw100={false}
                valign={"start vert-offset"}
              >
                <h1 className="billboard text-center mb-4">Select Your Plan</h1>
                <div className="widget">
                  <h3 className="d-flex align-items-center justify-content-between">
                    Plan
                    <span
                      className="icon-help"
                      onClick={() => {
                        setShowPlanDescription(!showPlanDescription)
                      }}
                    ></span>
                  </h3>
                  <div
                    className={`option-selector${
                      showPlanDescription ? " show-description" : ""
                    }`}
                  >
                    {dataAvailablePaymentPlans?.data?.data?.map((plan, i) => (
                      <div
                        className={`option-wrap block block--selectable low-elevation br-medium${
                          plan.planTypeId === selectedPlan?.planTypeId
                            ? " selected"
                            : ""
                        }${plan.isAvailable === false ? " disabled" : ""}`}
                        data-plan={plan.planTypeId}
                        onClick={() => {
                          setSelectedPlan(plan)
                        }}
                        key={i}
                      >
                        <div
                          className={`plan plan--${handleize(
                            plan.planTypeName,
                            ""
                          )}`}
                        >
                          <div className="plan__icon"></div>
                          <div className="plan__label">
                            <div className="plan__title">
                              {plan.planTypeDisplayName}
                            </div>
                            <div className="plan__subtitle">
                              {plan.feesDescription}
                            </div>
                          </div>
                        </div>
                        <div className="option-description">
                          {plan.planTypeDescription}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {selectedPlan?.paymentPlans?.length > 1 && (
                  <div className="widget">
                    <h3 className="d-flex align-items-center justify-content-between">
                      Schedule
                      <span
                        className="icon-help"
                        onClick={() => {
                          setShowScheduleDescription(!showScheduleDescription)
                        }}
                      ></span>
                    </h3>
                    <div
                      className={`option-selector${
                        showScheduleDescription ? " show-description" : ""
                      }`}
                    >
                      {selectedPlan?.paymentPlans
                        ?.sort((a, b) => a.plansSortOrder - b.plansSortOrder)
                        .map((schedule, i) => (
                          <div
                            className={`option-wrap schedule--${handleize(
                              schedule.planName.replace("&", "and")
                            )} block block--selectable low-elevation br-medium${
                              schedule.planId === selectedSchedule?.planId
                                ? " selected"
                                : ""
                            }`}
                            data-schdeule={schedule.planId}
                            onClick={() => {
                              setSelectedSchedule(schedule)
                            }}
                            key={i}
                          >
                            <div className="plan">
                              <div className="plan__icon"></div>
                              <div className="plan__label">
                                <div className="plan__title">
                                  {schedule.planName}
                                </div>
                              </div>
                            </div>
                            <div className="option-description">
                              {schedule.planDescription}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {!!selectedSchedule?.paymentDates?.dayoftheweek && (
                  <div className="widget">
                    <h3 className="d-flex align-items-center justify-content-between">
                      Day
                    </h3>
                    <div className="plan-payment-options-wrap">
                      <div className="plan-payment-options block low-elevation br-medium">
                        <Dropdown>
                          <Dropdown.Toggle>
                            <div className="payment-option">
                              <div className="payment-option__icon"></div>
                              <div className="payment-option__title">
                                {pluralDayName(planPaymentDay)}
                              </div>
                              <div className="payment-option__caret"></div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <SimpleBar
                              style={{ maxHeight: "300px" }}
                              autoHide={false}
                            >
                              {selectedSchedule?.paymentDates?.dayoftheweek?.map(
                                (selectablePaymentDay, i) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      logicPlan.onSelectPlanPaymentDay({
                                        startDate: paymentStartDate,
                                        newPlanPaymentDay: selectablePaymentDay,
                                        selectedSchedule,
                                        setPlanPaymentDay,
                                        setPlanPaymentDates
                                      })
                                    }}
                                    key={i}
                                  >
                                    {pluralDayName(
                                      selectablePaymentDay +
                                        APPCONFIG.api.dayIndexAdjust
                                    )}
                                  </Dropdown.Item>
                                )
                              )}
                            </SimpleBar>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                )}
                {[
                  APPCONFIG.api.planSlugs.FIRSTANDTHIRD,
                  APPCONFIG.api.planSlugs.SECONDANDFOURTH,
                  APPCONFIG.api.planSlugs.FOURPAY
                ].indexOf(selectedSchedule?.plan) < 0 && (
                  <div className="widget">
                    <h3 className="d-flex align-items-center justify-content-between">
                      {selectedSchedule?.paymentDates?.paymentStartDate
                        ? `Start Date`
                        : `Date${
                            (selectedSchedule?.frequency || 1) * 1 > 1
                              ? "s"
                              : ""
                          }`}
                    </h3>
                    <div className="plan-payment-options-wrap">
                      <div className="plan-payment-options block low-elevation br-medium">
                        {planPaymentDates
                          .filter((item, i) =>
                            selectedSchedule?.plan ===
                              APPCONFIG.api.planSlugs.ALTERNATING && i === 1
                              ? false
                              : !!item
                          )
                          .map((planPaymentDate, iDate) => (
                            <Dropdown key={iDate}>
                              <Dropdown.Toggle>
                                <div className="payment-option">
                                  <div className="payment-option__icon"></div>
                                  <div className="payment-option__title">
                                    {`${numberWithOrdinal(planPaymentDate)}`}
                                  </div>
                                  <div className="payment-option__caret"></div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <SimpleBar
                                  style={{ maxHeight: "300px" }}
                                  autoHide={false}
                                >
                                  {(
                                    selectedSchedule?.paymentDates
                                      ?.paymentStartDate ||
                                    selectedSchedule?.paymentDates?.[
                                      `date${iDate + 1}`
                                    ]
                                  )?.map((selectablePaymentDate, i) => (
                                    <Dropdown.Item
                                      onClick={() => {
                                        logicPlan.onSelectPlanPaymentDate({
                                          newPaymentDate: selectablePaymentDate,
                                          index: iDate,
                                          planPaymentDates,
                                          selectedSchedule,
                                          setPlanPaymentDates,
                                          setPlanPaymentDay
                                        })
                                      }}
                                      key={i}
                                    >
                                      {numberWithOrdinal(selectablePaymentDate)}
                                    </Dropdown.Item>
                                  ))}
                                </SimpleBar>
                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="btns-wrap d-flex widget">
                  <button
                    className="btn btn-primary flex-grow-1"
                    onClick={() => {
                      logicPlan.onSubmitSelectPlan({
                        dataResidents,
                        nav,
                        planPaymentDates,
                        planPaymentDay,
                        selectedSchedule,
                        setMessageBubbleComponent,
                        setMessageBubbleVisibility,
                        updatePaymentPlanMutation
                      })
                    }}
                  >
                    Continue
                  </button>
                </div>
              </FullScreenCentered>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default ScreenSetupSelectPlan
