import React, { useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { ALLROUTES } from "../../../routes"
import { AllIcons, GraphicPausedLease } from "../../../assets"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"

import "./paused-lease.scss"

const ScreenDashPausedLease = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc
  } = useOutletContext()

  const nav = useNavigate()

  useEffect(() => {
    document.body.classList.add("page__dash-paused-lease")
    setContainerClass("screen__dash-paused-lease")
    return () => {
      document.body.classList.remove("page__dash-paused-lease")
      setContainerClass("")
    }
  }, [])

  const giantButtons = [
    {
      Icon: () => <AllIcons.LeaseDetails />,
      title: "View Past Leases",
      onClick: () => {
        nav(ALLROUTES.dashboardChildren.settingsChildren.leases)
      }
    },
    {
      Icon: () => <AllIcons.ViewPayments />,
      title: "View Past Payments",
      onClick: () => {
        nav(ALLROUTES.dashboardChildren.settingsChildren.payments)
      }
    }
  ]

  return (
    <>
      <div className="d-flex">
        <div className="column-left flex-1 text-center">
          <FullScreenCentered className={`w-100`} vw100={false}>
            <h1>No payments can be made at this time.</h1>
            <p>Contact Circa or your property for more options.</p>
            <GraphicPausedLease />
            <a
              className="btn btn-primary w-auto"
              href={ALLROUTES.supportEmailLink}
            >
              Contact Circa
            </a>
          </FullScreenCentered>
        </div>

        <div className="column-right flex-2">
          <FullScreenCentered className={`w-100`} vw100={false}>
            <div className="giant-buttons">
              <button className="giant-button danger text-left">
                <span className="d-flex flex-column">
                  <p className="mb-0">Lease activity is</p>
                  <span className="billboard">Paused</span>
                </span>
              </button>
              {giantButtons.map(item => (
                <button
                  className="giant-button clickable"
                  onClick={item.onClick}
                >
                  <item.Icon />
                  <span className="h2">{item.title}</span>
                </button>
              ))}
            </div>
          </FullScreenCentered>
        </div>
      </div>
    </>
  )
}

export default ScreenDashPausedLease
