import React from "react"

import {
  GraphicCreditReporting,
  GraphicPartnerTransUnion
} from "../../../assets"

const OrgnInfoCreditReporting = () => {
  return (
    <>
      <div className="freeze-to-top">
        <h2 className="billboard text-center mx-n2 mb-3 pb-2">
          Pay rent, build credit
        </h2>
      </div>
      <div className="color-grayscale-iron">
        <p className="mb-3 pb-2">
          Circa reports on-time payments, so you can build credit history by
          paying rent.
        </p>
        <div className="d-flex justify-content-center my-4 pb-2">
          <GraphicCreditReporting />
        </div>
        <p className="mb-2 mx-2">
          Every on-time payment according to your schedule is a chance to boost
          your credit — even when you’re using a flexible rent plan!
        </p>
        <p className="mx-2">
          Good credit scores are for more than just getting a credit card or a
          loan. They can help support job applications, lower interest rates,
          and unlock resources in an emergency.
        </p>
        <div className="d-flex justify-content-center mt-4 pt-3">
          <GraphicPartnerTransUnion />
        </div>
      </div>
    </>
  )
}
export default OrgnInfoCreditReporting
