import React, { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"

import { APPCONFIG } from "./config"
import { checkLastAccessedAndLogout, telephoneLink } from "./utils"

import { useViewport } from "./contexts/viewport"
import DashboardWrapper from "./components/wrappers/dashboard"
import SettingsWrapper from "./components/wrappers/settings"

import ScreenServerDown from "./pages/server-down/server-down"

import ScreenMobileApp from "./pages/onboarding/welcome/mobile-app"
import ScreenWelcome from "./pages/onboarding/welcome"
import ScreenSignup from "./pages/onboarding/signup"
import ScreenLogin from "./pages/onboarding/login"
import ScreenLogout from "./pages/onboarding/logout"
import ScreenEmailVerification from "./pages/onboarding/email-verification"
import ScreenForgotPasswordADB2C from "./pages/onboarding/forgot-password/forgot-password-adb2c"
import ScreenResetPasswordVerify from "./pages/onboarding/reset-password-verify"
import ScreenResetPassword from "./pages/onboarding/reset-password"

import ScreenSetupContactInformation from "./pages/setup/contact-information"
import ScreenSetupLeaseId from "./pages/setup/lease-id"
import ScreenSetupLeaseDetails from "./pages/setup/lease-details"
import ScreenSetupSelectPlan from "./pages/setup/select-plan"
import ScreenSetupSelectPaymentMethod from "./pages/setup/select-payment-method"
import ScreenSetupSelectAutopay from "./pages/setup/select-autopay"

import ScreenDashOverview from "./pages/dashboard/overview"
import ScreenDashNoCurrentLease from "./pages/dashboard/no-current-lease"
import ScreenDashPausedLease from "./pages/dashboard/paused-lease"
import ScreenDashRentAssist from "./pages/dashboard/rent-assist"

import ScreenDashSettProfile from "./pages/settings/profile"
import ScreenDashSettLeases from "./pages/settings/leases"
import ScreenDashSettNotifPrefs from "./pages/settings/notification-preferences"
import ScreenDashSettPaymentMethods from "./pages/settings/payment-methods"
import ScreenDashSettPayments from "./pages/settings/payments"
import ScreenDashSettLegal from "./pages/settings/legal"

import ScreenTermsConditions from "./pages/static/terms-conditions"

import { breakpoints } from "./assets"

export const ALLROUTES = {
  home: "/",
  serverDown: "/maintenance",

  login: "/login",
  logout: "/logout",
  signup: "/signup",

  emailVerification: "/verification",

  forgotPassword: "/forgot-password",
  resetPasswordVerifyCode: "/reset-password-verify",
  resetPassword: "/reset-password",

  accountSetupContainer: "/setup",
  accountSetupChildren: {
    accountSetup: "/setup",
    contactInformation: "/setup/contact-information",
    leaseId: "/setup/lease-id",
    leaseDetails: "/setup/lease-details",
    selectPlan: "/setup/select-plan",
    selectPaymentMethod: "/setup/select-payment-method",
    selectAutopay: "/setup/select-autopay"
  },

  dashboardContainer: "/dashboard",
  dashboardChildren: {
    dashboard: "/dashboard",
    noCurrentLease: "/dashboard/no-current-lease",
    pausedLease: "/dashboard/paused-lease",
    welcomeToRentAssist: "/dashboard/welcome-to-rentassist",
    settingsContainer: "/dashboard/settings",
    settingsChildren: {
      help: "/dashboard/settings/help",
      leases: "/dashboard/settings/leases",
      legal: "/dashboard/settings/legal",
      notifications: "/dashboard/settings/notifications",
      paymentMethods: "/dashboard/settings/payment-methods",
      payments: "/dashboard/settings/payments",
      settings: "/dashboard/settings/profile"
    }
  },

  hashUrls: {
    help: "#help",
    makeAPayment: "#make-a-payment",
    rentAssist: "#rent-assist"
  },

  staticPages: {
    termsAndConditions: "/terms",
    privacyPolicy: "/privacy-policy"
  },

  mobileAppAndroid: "",
  mobileAppIos: "",

  supportEmailLink: `mailto:${APPCONFIG.supportEmail}`,
  supportTelephoneLink: telephoneLink(APPCONFIG.supportTelephone)
}

export const WEBSITELINKS = {
  individualResidents: "https://www.wearecirca.com/individual-residents"
}

const SectionUnderDevelopment = () => {
  return <p>This section is under development.</p>
}

const RouteNotFound = () => {
  return <p>The page that you're looking for was not found.</p>
}

export const RouteElements = () => {
  const { width } = useViewport()
  const location = useLocation()

  useEffect(() => {
    if (
      location?.pathname.indexOf(ALLROUTES.accountSetupContainer) > -1 ||
      location?.pathname.indexOf(ALLROUTES.dashboardContainer) > -1
    ) {
      checkLastAccessedAndLogout({ markAccessed: true })
    }
  }, [location])

  const routeConfig = [
    { path: ALLROUTES.home, index: true, element: <ScreenWelcome /> },
    { path: ALLROUTES.serverDown, element: <ScreenServerDown /> },
    { path: ALLROUTES.login, element: <ScreenLogin /> },
    { path: ALLROUTES.logout, element: <ScreenLogout /> },
    { path: ALLROUTES.signup, element: <ScreenSignup /> },
    {
      path: ALLROUTES.emailVerification,
      element: <ScreenEmailVerification />
    },
    {
      path: ALLROUTES.forgotPassword,
      element: <ScreenForgotPasswordADB2C />
    },
    {
      path: ALLROUTES.resetPasswordVerifyCode,
      element: <ScreenResetPasswordVerify />
    },
    { path: ALLROUTES.resetPassword, element: <ScreenResetPassword /> },

    {
      path: ALLROUTES.accountSetupContainer,
      element: <DashboardWrapper />,
      children: [
        {
          path: ALLROUTES.accountSetupChildren.accountSetup,
          index: true,
          element: <SectionUnderDevelopment />
        },
        {
          path: ALLROUTES.accountSetupChildren.contactInformation,
          element: <ScreenSetupContactInformation />
        },
        {
          path: ALLROUTES.accountSetupChildren.leaseId,
          element: <ScreenSetupLeaseId />
        },
        {
          path: ALLROUTES.accountSetupChildren.leaseDetails,
          element: <ScreenSetupLeaseDetails />
        },
        {
          path: ALLROUTES.accountSetupChildren.selectPlan,
          element: <ScreenSetupSelectPlan />
        },
        {
          path: ALLROUTES.accountSetupChildren.selectPaymentMethod,
          element: <ScreenSetupSelectPaymentMethod />
        },
        {
          path: ALLROUTES.accountSetupChildren.selectAutopay,
          element: <ScreenSetupSelectAutopay />
        }
      ]
    },

    {
      path: ALLROUTES.dashboardContainer,
      element: <DashboardWrapper />,
      children: [
        {
          path: ALLROUTES.dashboardChildren.dashboard,
          index: true,
          element: <ScreenDashOverview />
        },
        {
          path: ALLROUTES.dashboardChildren.noCurrentLease,
          element: <ScreenDashNoCurrentLease />
        },
        {
          path: ALLROUTES.dashboardChildren.pausedLease,
          element: <ScreenDashPausedLease />
        },
        {
          path: ALLROUTES.dashboardChildren.welcomeToRentAssist,
          element: <ScreenDashRentAssist />
        },
        {
          path: ALLROUTES.dashboardChildren.settingsContainer,
          element: <SettingsWrapper />,
          children: [
            {
              path: ALLROUTES.dashboardChildren.settingsChildren.settings,
              index: true,
              element: <ScreenDashSettProfile />
            },
            {
              path: ALLROUTES.dashboardChildren.settingsChildren.help,
              element: <SectionUnderDevelopment />
            },
            {
              path: ALLROUTES.dashboardChildren.settingsChildren.leases,
              element: <ScreenDashSettLeases />
            },
            {
              path: ALLROUTES.dashboardChildren.settingsChildren.notifications,
              element: <ScreenDashSettNotifPrefs />
            },
            {
              path: ALLROUTES.dashboardChildren.settingsChildren.paymentMethods,
              element: <ScreenDashSettPaymentMethods />
            },
            {
              path: ALLROUTES.dashboardChildren.settingsChildren.payments,
              element: <ScreenDashSettPayments />
            },
            {
              path: ALLROUTES.dashboardChildren.settingsChildren.legal,
              element: <ScreenDashSettLegal />
            }
          ]
        }
      ]
    },

    {
      path: ALLROUTES.staticPages.termsAndConditions,
      element: <ScreenTermsConditions />
    },
    {
      path: ALLROUTES.staticPages.privacyPolicy,
      element: <SectionUnderDevelopment />
    },
    { path: "*", element: <RouteNotFound /> }
  ]

  return (
    <Routes>
      {width < breakpoints.md && !!APPCONFIG.showMobileScreenOnSmallerRes ? (
        <Route path={"*"} element={<ScreenMobileApp />} />
      ) : (
        <>
          {routeConfig?.map((rcItem, i) => (
            <Route
              path={rcItem?.path}
              element={rcItem?.element}
              index={rcItem?.index}
              key={i}
            >
              {rcItem?.children?.map((rcItemChild, j) => (
                <Route
                  path={rcItemChild?.path}
                  element={rcItemChild?.element}
                  index={rcItemChild?.index}
                  key={j}
                >
                  {rcItemChild?.children?.map((rcItemSubChild, k) => (
                    <Route
                      path={rcItemSubChild?.path}
                      element={rcItemSubChild?.element}
                      index={rcItemSubChild?.index}
                      key={k}
                    ></Route>
                  ))}
                </Route>
              ))}
            </Route>
          ))}
        </>
      )}
    </Routes>
  )
}

export default RouteElements

export const ALLMENUS = {
  loggedIn: [
    {
      name: "Profile",
      Icon: () => <div className="icon-wrap icon--profile"></div>,
      route: ALLROUTES.dashboardChildren.settingsChildren.settings
    },
    {
      name: "Payment Methods",
      Icon: () => <div className="icon-wrap icon--payment-methods"></div>,
      route: ALLROUTES.dashboardChildren.settingsChildren.paymentMethods
    },
    {
      name: "Payment Statements",
      Icon: () => <div className="icon-wrap icon--payment-statements"></div>,
      route: ALLROUTES.dashboardChildren.settingsChildren.payments
    },
    {
      name: "Leases",
      Icon: () => <div className="icon-wrap icon--leases"></div>,
      route: ALLROUTES.dashboardChildren.settingsChildren.leases
      /*,
      disabled: true*/
    },
    {
      name: "Notifications",
      Icon: () => <div className="icon-wrap icon--notification-prefs"></div>,
      route: ALLROUTES.dashboardChildren.settingsChildren.notifications
    },
    {
      name: "Change Password",
      Icon: () => <div className="icon-wrap icon--change-password"></div>,
      onClick: () => {
        window.location = APPCONFIG.msal.changePasswordUrl
      }
    },
    {
      name: "Sign Out",
      Icon: () => <div className="icon-wrap icon--signout"></div>,
      route: "[msalLogoutFunc]"
    }
  ],
  settings: [
    {
      name: "Profile",
      route: ALLROUTES.dashboardChildren.settingsChildren.settings
    },
    {
      name: "Payment Methods",
      route: ALLROUTES.dashboardChildren.settingsChildren.paymentMethods
    },
    {
      name: "Payments",
      route: ALLROUTES.dashboardChildren.settingsChildren.payments
    },
    {
      name: "Leases",
      route: ALLROUTES.dashboardChildren.settingsChildren.leases
    },
    {
      name: "Notifications",
      route: ALLROUTES.dashboardChildren.settingsChildren.notifications
    },
    {
      name: "Change Password",
      onClick: () => {
        window.location = APPCONFIG.msal.changePasswordUrl
      }
    },
    {
      name: "Legal",
      route: ALLROUTES.dashboardChildren.settingsChildren.legal
    },
    {
      name: "Help",
      route: "[helpPopup]"
    }
  ]
}
