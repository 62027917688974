import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { APPCONFIG } from "../../../config"
import {
  AnimationLoadingFullScreen,
  GraphicBankCheckImage
} from "../../../assets"
import { validation } from "../../../utils/validation"

import GenericForm from "../../elements/generic-form"

import {
  useDashboardQueries,
  usePaymentMethodQueries
} from "../../../utils/queries"
import { usePaymentMethodMutation } from "../../../utils/mutations"
import { handleApiError } from "../../../utils"

export const showModalBankInformation = ({
  bankAccountId,
  onSubmitCallback,
  onRemoveCallback,
  updateInProgress,
  setUpdateInProgress,
  setModalBreadcrumb,
  setModalClass,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  setModalBreadcrumb([{ label: "" }])
  setModalClass("modal--bank-info narrow-content")
  setModalComponent(() => (
    <div className="modal-content-height d-flex flex-column">
      <OrgnFormBankInformation
        bankAccountId={bankAccountId}
        onSubmit={onSubmitCallback}
        onRemove={!!bankAccountId ? onRemoveCallback : () => {}}
        updateInProgress={updateInProgress}
        setUpdateInProgress={setUpdateInProgress}
        setMessageBubbleComponent={setMessageBubbleComponent}
        setMessageBubbleVisibility={setMessageBubbleVisibility}
      />
    </div>
  ))
  setModalVisibility(true)
}

const OrgnFormBankInformation = ({
  bankAccountId,
  submitButtonText,
  onSubmit, // should rename to onSubmitCallback
  onRemove,
  updateInProgress,
  setUpdateInProgress,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const nav = useNavigate()

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    enabled: !!bankAccountId
  })

  const {
    status: statusBankAccount,
    data: dataBankAccount,
    isFetching: isFetchingBankAccount,
    isError: isErrorBankAccount,
    error: errorBankAccount,
    refetch: refetchBankAccount
  } = usePaymentMethodQueries.useBankAccountQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success" && !!bankAccountId,
    residentId: dataResidents?.data?.residentData?.id,
    bankAccountId
  })

  const [bankAccountNumber, setBankAccountNumber] = useState("")
  const [bankAccountNumberConfirm, setBankAccountNumberConfirm] = useState("")

  const addBankAccountMutation =
    usePaymentMethodMutation.useAddBankAccountMutation()
  const updateBankAccountMutation =
    usePaymentMethodMutation.useUpdateBankAccountMutation()

  const onSubmitBankAccount = formValues => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      ...formValues,
      accountType: formValues?.accountType?.substring(0, 1).toUpperCase(),
      accountClassification: formValues?.accountClassification
        ?.substring(0, 1)
        .toUpperCase()
    }
    setUpdateInProgress?.(true)
    addBankAccountMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          onSubmit?.(data)
        } else {
          handleScreenApiError({
            customHeading: "Failed to add bank account",
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          customHeading: "Failed to add bank account",
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress?.(false)
      }
    })
  }

  const onUpdateBankAccount = formValues => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      ...formValues
    }
    setUpdateInProgress(true)
    updateBankAccountMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200].indexOf(status) > -1) {
          onSubmit?.()
        } else {
          handleScreenApiError({
            customHeading: "Failed to update bank account",
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          customHeading: "Failed to update bank account",
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const handleScreenApiError = ({
    customHeading = "Failed to update payment method",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const LocatingYourAccountInfoTooltip = () => (
    <>
      <h2 className="h1">Locating Your Account Info</h2>
      <p className="mb-3">
        Find your account number and 9-digit routing number at the bottom of
        your check or by visiting your online banking portal.
      </p>
      <GraphicBankCheckImage />
    </>
  )

  let formFields = [
    {
      label: "Account Nickname (Optional)",
      name: "bankAccountNickName",
      rules: {
        validate: { ...validation.alphaNumericMax26 }
      },
      defaultValue:
        dataBankAccount?.data?.bankDetails?.bankAccountNickName || ""
    },
    {
      label: "Account Holder Name",
      name: "bankAccountHolderName",
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: {
        required: true
      },
      defaultValue:
        dataBankAccount?.data?.bankDetails?.bankAccountHolderName || ""
    },
    {
      label: "9-Digit Routing Number",
      name: "routingNumber",
      helpTooltipOverModal: true,
      HelpTooltip: !!bankAccountId
        ? null
        : () => <LocatingYourAccountInfoTooltip />,
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: {
        required: true,
        validate: { ...validation.bankroutingnumber }
      },
      defaultValue: dataBankAccount?.data?.bankDetails?.routingNumber || "",
      maxLength: 9
    },
    {
      label: "Account Number",
      name: "bankAccountNumber",
      helpTooltipOverModal: true,
      HelpTooltip: !!bankAccountId
        ? null
        : () => <LocatingYourAccountInfoTooltip />,
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: {
        required: true,
        validate: !!bankAccountId ? null : { ...validation.bankaccountnumber }
      },
      onChange: bankAcc => {
        setBankAccountNumber(bankAcc)
        setBankAccountNumberConfirm("")
      },
      defaultValue: dataBankAccount?.data?.bankDetails?.bankAccountNumber
        ? `****${dataBankAccount?.data?.bankDetails?.bankAccountNumber?.slice(
            -4
          )}`
        : ""
    },
    {
      label: "Confirm Account Number",
      name: "confirmBankAccountNumber",
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: {
        required: true,
        validate: !!bankAccountId
          ? null
          : {
              match: confirmBankAcc => {
                return (
                  confirmBankAcc === bankAccountNumber ||
                  "Bank account numbers should match"
                )
              }
            }
      },
      value: dataBankAccount?.data?.bankDetails?.bankAccountNumber
        ? `****${dataBankAccount?.data?.bankDetails?.bankAccountNumber?.slice(
            -4
          )}`
        : bankAccountNumberConfirm,
      onChange: confirmBankAcc => {
        setBankAccountNumberConfirm(confirmBankAcc)
      },
      defaultValue: dataBankAccount?.data?.bankDetails?.bankAccountNumber
        ? `****${dataBankAccount?.data?.bankDetails?.bankAccountNumber?.slice(
            -4
          )}`
        : ""
    },
    {
      label: "What type of account is this?",
      name: "accountType",
      compType: "radio-group",
      items: [
        {
          label: "Checking",
          value: "checking"
        },
        {
          label: "Savings",
          value: "savings"
        }
      ],
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: {
        required: true
      },
      defaultValue: dataBankAccount?.data?.bankDetails?.accountType
        ? dataBankAccount?.data?.bankDetails?.accountType === "C"
          ? "checking"
          : "savings"
        : "",
      selectedValue: dataBankAccount?.data?.bankDetails?.accountType
        ? dataBankAccount?.data?.bankDetails?.accountType === "C"
          ? "checking"
          : "savings"
        : ""
    },
    {
      label: "How is this account classified?",
      name: "accountClassification",
      compType: "radio-group",
      items: [
        {
          label: "Business",
          value: "business"
        },
        {
          label: "Personal",
          value: "personal"
        }
      ],
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: {
        required: true
      },
      defaultValue: dataBankAccount?.data?.bankDetails?.accountClassification
        ? dataBankAccount?.data?.bankDetails?.accountClassification === "B"
          ? "business"
          : "personal"
        : "",
      selectedValue: dataBankAccount?.data?.bankDetails?.accountClassification
        ? dataBankAccount?.data?.bankDetails?.accountClassification === "B"
          ? "business"
          : "personal"
        : ""
    },
    {
      label:
        "I am authorized to make electronic transactions from this account.",
      name: "accountAuth",
      compType: "checkbox-switch",
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: !!bankAccountId
        ? null
        : {
            required: true
          },
      isChecked: !!bankAccountId ? true : null
    },
    {
      LabelComponent: () => (
        <>
          I authorize Circa to debit funds from this account according to the{" "}
          <a href={APPCONFIG.staticLinks.directDebitAuth} target="_blank">
            direct debit authorization
          </a>
          .
        </>
      ),
      name: "authorizedCirca",
      compType: "checkbox-switch",
      disabled: !!bankAccountId,
      readOnly: !!bankAccountId,
      rules: !!bankAccountId
        ? null
        : {
            required: true
          },
      isChecked: !!bankAccountId ? true : null
    }
  ]

  if (isFetchingResidents || isFetchingBankAccount || updateInProgress) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="freeze-to-top mb-2">
        <h2 className="billboard text-center">Bank Information</h2>
      </div>
      <GenericForm
        formClass={""}
        submitButtonText={
          submitButtonText ||
          (bankAccountId ? "Save Changes" : "Add Bank Account")
        }
        onSubmit={formValues => {
          if (!!bankAccountId) {
            const { bankAccountNickName, ...rest } = formValues
            onUpdateBankAccount({ bankAccountNickName, bankAccountId })
          } else {
            onSubmitBankAccount({ ...formValues })
          }
        }}
        formFields={formFields}
        hasSecondaryButton={!!bankAccountId}
        secondaryButtonClass={`btn-danger btn-bordered`}
        secondaryButtonAction={onRemove}
        secondaryButtonText={"Remove"}
      />
    </>
  )
}
export default OrgnFormBankInformation
