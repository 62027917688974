import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Dropdown } from "react-bootstrap"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import {
  ADDMETHODSLUG_BANK_LABEL,
  ADDMETHODSLUG_CARD_LABEL,
  APPCONFIG
} from "../../../config"
import { handleApiError } from "../../../utils"
import { AllIcons } from "../../../assets"
import { displayTpl } from "../../../utils/templates"

import HelpIconWithTooltip from "../../../components/elements/form/help-icon-with-tooltip"
import TileRow from "../../../components/elements/tile-row"
import { showModalBankInformation } from "../../../components/organisms/bank-information/bank-information"
import { showModalCardInformation } from "../../../components/organisms/card-information/card-information"
import OrgnFormCashPayment from "../../../components/organisms/cash-payment/cash-payment"
import { showReplacePaymentMethodModal } from "../../../components/organisms/payment-method/replace-payment-method"
import OrgnInfoCashApp from "../../../components/organisms/cash-app/cash-app"
import { TooltipContentPaymentMethods } from "../../../components/organisms/tooltip-content/payment-methods"

import {
  useDashboardQueries,
  usePaymentMethodQueries
} from "../../../utils/queries"
import { usePaymentMethodMutation } from "../../../utils/mutations"

import "./payment-methods.scss"

const ScreenDashSettPaymentMethods = () => {
  const {
    setContainerClass,
    setMessageBubbleComponent,
    setMessageBubbleVisibility
  } = useOutletContext()

  const nav = useNavigate()

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusBankAccounts,
    data: dataBankAccounts,
    isFetching: isFetchingBankAccounts,
    isError: isErrorBankAccounts,
    error: errorBankAccounts,
    refetch: refetchBankAccounts
  } = usePaymentMethodQueries.useBankAccountsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const {
    status: statusCards,
    data: dataCards,
    isFetching: isFetchingCards,
    isError: isErrorCards,
    error: errorCards,
    refetch: refetchCards
  } = usePaymentMethodQueries.useCardsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  useEffect(() => {
    document.body.classList.add("page__dash-sett-payment-methods")
    setContainerClass("screen__dash-sett-payment-methods")
    return () => {
      document.body.classList.remove("page__dash-sett-payment-methods")
      setContainerClass("")
    }
  }, [])

  const deleteBankAccountMutation =
    usePaymentMethodMutation.useDeleteBankAccountMutation()
  const deleteCardMutation = usePaymentMethodMutation.useDeleteCardMutation()

  const handleScreenApiError = ({
    customHeading = "Failed to update payment method",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const showModalCashPayment = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--cash-payment narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnFormCashPayment />
      </div>
    ))
    setModalVisibility(true)
  }

  const showModalCashApp = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--cash-app narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnInfoCashApp />
      </div>
    ))
    setModalVisibility(true)
  }

  const handleDeletePaymentMethod = ({ bankAccountId, cardId }) => {
    if (!!bankAccountId || !!cardId) {
      const requestParams = {
        residentId: dataResidents?.data?.residentData?.id,
        ...(!!bankAccountId ? { bankAccountId } : {}),
        ...(!!cardId ? { cardId } : {})
      }
      setUpdateInProgress(true)
      const applicableMutation = !!cardId
        ? deleteCardMutation
        : deleteBankAccountMutation
      const applicableErrorMessage = `Failed to remove ${
        !!cardId ? "card" : "bank account"
      }`
      applicableMutation.mutate(requestParams, {
        onSuccess: ({ data, status }) => {
          if ([200, 201].indexOf(status) > -1) {
            refetchBankAccounts()
            refetchCards()
          } else {
            handleScreenApiError({
              customHeading: applicableErrorMessage,
              ...data
            })
          }
          setModalVisibility(false)
        },
        onError: axiosError => {
          const errorData = axiosError?.response?.data || { apiError: true }
          if (
            [521].indexOf(axiosError?.response?.data?.code) >
              -1 /* Delete Bank API */ ||
            [400].indexOf(axiosError?.response?.data?.status) >
              -1 /* Delete Card API */
          ) {
            showReplacePaymentMethodModal({
              // bankAccountId,
              ...requestParams,
              updateInProgress,
              setUpdateInProgress,
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              setMessageBubbleComponent,
              setMessageBubbleVisibility
            })
            return
          }
          handleScreenApiError({
            customHeading: applicableErrorMessage,
            ...errorData
          })
        },
        onSettled: () => {
          setUpdateInProgress(false)
        }
      })
    }
  }

  return (
    <div className="payment-methods-wrap d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between mb-4 pb-2">
        <h2 className="d-flex align-items-center mb-0">
          Payment Methods
          <HelpIconWithTooltip
            className={`ms-3`}
            tooltipClass={`payment-methods-tooltip`}
            placement={`right-start`}
            TooltipComponent={() => <TooltipContentPaymentMethods />}
          />
        </h2>
        <Dropdown className="add-payment-method-dropdown" align={`end`}>
          <Dropdown.Toggle>
            <a className="btn btn-primary add-payment-method-btn"></a>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="d-flex"
              onClick={() =>
                showModalBankInformation({
                  onSubmitCallback: () => {
                    setModalVisibility(false)
                    refetchBankAccounts()
                  },
                  updateInProgress,
                  setUpdateInProgress,
                  setModalBreadcrumb,
                  setModalClass,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility
                })
              }
            >
              <AllIcons.PaymentMethodBank className={`me-3`} />
              {ADDMETHODSLUG_BANK_LABEL}
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex"
              onClick={() =>
                showModalCardInformation({
                  onSubmitCallback: () => {
                    setModalVisibility(false)
                    refetchCards()
                  },
                  setModalBreadcrumb,
                  setModalClass,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility
                })
              }
            >
              <AllIcons.PaymentMethodCard className={`me-3`} />
              {ADDMETHODSLUG_CARD_LABEL}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {dataBankAccounts?.data?.data?.length > 0 && (
        <>
          <h3 className="mb-4">Bank Accounts</h3>
          {dataBankAccounts?.data?.data?.map((bankAccount, i) => (
            <TileRow
              key={i}
              title={displayTpl(bankAccount, "bank_account_display_name")}
              Icon={() => <AllIcons.PaymentMethodBank className={`me-3`} />}
              onTitleClick={() => {
                showModalBankInformation({
                  bankAccountId: bankAccount?.bankAccountid,
                  onSubmitCallback: () => {
                    setModalVisibility(false)
                    refetchBankAccounts()
                  },
                  onRemoveCallback: () => {
                    handleDeletePaymentMethod({
                      bankAccountId: bankAccount?.bankAccountid
                    })
                  },
                  updateInProgress,
                  setUpdateInProgress,
                  setModalBreadcrumb,
                  setModalClass,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility
                })
              }}
              actionButton1Text={`Edit`}
              onActionButton1Click={() => {
                showModalBankInformation({
                  bankAccountId: bankAccount?.bankAccountid,
                  onSubmitCallback: () => {
                    setModalVisibility(false)
                    refetchBankAccounts()
                  },
                  onRemoveCallback: () => {
                    handleDeletePaymentMethod({
                      bankAccountId: bankAccount?.bankAccountid
                    })
                  },
                  updateInProgress,
                  setUpdateInProgress,
                  setModalBreadcrumb,
                  setModalClass,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility
                })
              }}
              actionButton2Text={`Delete`}
              onActionButton2Click={() => {
                handleDeletePaymentMethod({
                  bankAccountId: bankAccount?.bankAccountid
                })
              }}
            />
          ))}
        </>
      )}
      {dataCards?.data?.data?.length > 0 && (
        <>
          <h3 className="mt-4 mb-4 pt-2">Credit / Debit Cards</h3>
          {dataCards?.data?.data?.map((card, i) => (
            <TileRow
              key={i}
              title={
                card?.cardNickName || `Card ending ****${card?.cardLast4Digits}`
              }
              Icon={() => <AllIcons.PaymentMethodCard className={`me-3`} />}
              onTitleClick={() => {
                showModalCardInformation({
                  cardId: card?.cardId,
                  onSubmitCallback: () => {
                    setModalVisibility(false)
                    refetchCards()
                  },
                  onRemoveCallback: () => {
                    handleDeletePaymentMethod({
                      cardId: card?.cardId
                    })
                  },
                  setModalBreadcrumb,
                  setModalClass,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility
                })
              }}
              actionButton1Text={`Edit`}
              onActionButton1Click={() => {
                showModalCardInformation({
                  cardId: card?.cardId,
                  onSubmitCallback: () => {
                    setModalVisibility(false)
                    refetchCards()
                  },
                  onRemoveCallback: () => {
                    handleDeletePaymentMethod({
                      cardId: card?.cardId
                    })
                  },
                  setModalBreadcrumb,
                  setModalClass,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility
                })
              }}
              actionButton2Text={`Delete`}
              onActionButton2Click={() => {
                handleDeletePaymentMethod({
                  cardId: card?.cardId
                })
              }}
            />
          ))}
        </>
      )}
      <h3 className="mt-4 mb-4 pt-2">Other</h3>
      <TileRow
        title={APPCONFIG.api.globalPaymentMethods.CASH.label}
        Icon={() => <AllIcons.PaymentMethodCash className={`me-3`} />}
        onTitleClick={showModalCashPayment}
      />
      <TileRow
        title={APPCONFIG.api.globalPaymentMethods.CASHAPP.label}
        Icon={() => <AllIcons.PaymentMethodCashApp className={`me-3`} />}
        onTitleClick={showModalCashApp}
      />
    </div>
  )
}

export default ScreenDashSettPaymentMethods
