import React from "react"

import { telephoneLink } from "../../../utils"

import "./contact-tile.scss"

const ContactTile = ({
  className,
  profileImage,
  fullName,
  department,
  phone,
  email
}) => {
  return (
    <div
      className={`contact-tile align-items-center d-flex flex-column justify-content-center block low-elevation br-medium ${className}`}
    >
      <div className="profile-image">
        {profileImage ? <img src="" /> : <span className="default"></span>}
      </div>
      <h4 className="fullname">{fullName}</h4>
      <p className="department">{department}</p>
      <div className="buttons">
        <a className="btn btn-bordered btn-short" href={telephoneLink(phone)}>
          Call
        </a>
        <a className="btn btn-bordered btn-short" href={`mailto:${email}`}>
          Email
        </a>
      </div>
    </div>
  )
}
export default ContactTile
