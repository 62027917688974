import React, { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate, Outlet } from "react-router-dom"
import { Modal } from "react-bootstrap"

import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../state"

import { basicReduxStore } from "../../reduxStore"
import { AzureAD, AuthenticationState } from "react-aad-msal"
import { signInAuthProvider } from "../../authProvider"

import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import { APPCONFIG } from "../../config"
import { ALLROUTES, ALLMENUS } from "../../routes"
import { CircaLoadingAnimationFS } from "../../assets"
import { consoleLog, manageMsalAuth, manageMsalAuthJwtToken } from "../../utils"

import MessageBubble from "../elements/message-bubble/message-bubble"
import DashboardHeader from "./dashboard-header"
import DashboardProgress from "../elements/dashboard-progress/dashboard-progress"
import DashboardRedBanner from "../elements/dashboard-red-banner/dashboard-red-banner"
import { dashboardPopups } from "./dashboard-popups"

import "./dashboard.scss"

export const DashboardWrapperNoAuth = ({
  children,
  bodyClass,
  progressPerc,
  msalAccountInfo,
  msalLogoutFunc,
  setLogoutRequested
}) => {
  const nav = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // redirect to dashboard if session is active
    if (
      (location.pathname === ALLROUTES.home ||
        location.pathname.indexOf(ALLROUTES.login) > -1) &&
      !!manageMsalAuthJwtToken?.get() &&
      !!manageMsalAuthJwtToken?.isValid()
    ) {
      consoleLog("Session is active, redirecting to dashboard...")
      nav(ALLROUTES.dashboardContainer)
    }
  }, [])

  const [containerClass, setContainerClass] = useState("")

  const { pathname } = useLocation()
  const [navSettingsActiveItem, setNavSettingsActiveItem] = useState(
    ALLMENUS.settings[0]
  )
  useEffect(() => {
    setNavSettingsActiveItem(
      ALLMENUS.settings.find(item => item?.route === pathname)
    )
  }, [navSettingsActiveItem, pathname])

  const {
    modalBreadcrumb,
    modalClass,
    ModalComponent,
    modalVisible
  } = useSelector(state => state.modal)
  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)
  const [modalIsScrollable, setModalIsScrollable] = useState(false)
  const [modalScrollBottomReached, setModalScrollBottomReached] = useState(
    false
  )

  const [redBannerClassName, setRedBannerClassName] = useState("")
  const [redBannerVisible, setRedBannerVisibility] = useState(false)
  const [redBannerDays, setRedBannerDays] = useState({})
  const [RedBannerLeftComponent, updateRedBannerLeftComponent] = useState("")
  const setRedBannerLeftComponent = comp => {
    updateRedBannerLeftComponent(() => comp)
  }
  const [RedBannerCenterComponent, updateRedBannerCenterComponent] = useState(
    ""
  )
  const setRedBannerCenterComponent = comp => {
    updateRedBannerCenterComponent(() => comp)
  }
  const [redBannerCta, setRedBannerCta] = useState({})
  const [RedBannerPostCtaComponent, updateRedBannerPostCtaComponent] = useState(
    ""
  )
  const setRedBannerPostCtaComponent = comp => {
    updateRedBannerPostCtaComponent(() => comp)
  }
  const redBannerOnOpenCallback = () => {
    setRedBannerClassName("")
    setRedBannerDays({})
    setRedBannerLeftComponent(() => <></>)
    setRedBannerCenterComponent(() => <></>)
    setRedBannerCta({})
    setRedBannerPostCtaComponent(() => <></>)
  }

  const [messageBubbleVisible, setMessageBubbleVisibility] = useState(false)
  const [MessageBubbleComponent, updateMessageBubbleComponent] = useState("div")
  const [messageBubbleClass, setMessageBubbleClass] = useState("")
  const [messageBubbleErrorMessage, setMessageBubbleErrorMessage] = useState("")
  const setMessageBubbleComponent = comp => {
    updateMessageBubbleComponent(() => comp)
  }
  const [messageBubbleOnClose, setMessageBubbleOnClose] = useState(null)

  const showDashboardPopup = PopupComponent => {
    setMessageBubbleComponent(PopupComponent)
    setMessageBubbleVisibility(true)
  }

  const [dashboardProgressPerc, setDashboardProgressPerc] = useState(0)

  const [unreadNotifRedDot, setUnreadNotifRedDot] = useState(false)

  const refSimpleBar = useRef()
  const scrollableNodeRef = useRef()

  useEffect(() => {
    setModalIsScrollable(
      refSimpleBar?.current?.contentWrapperEl?.style?.overflow?.indexOf(
        "scroll"
      ) > -1
    )
    scrollableNodeRef?.current?.addEventListener("scroll", event => {
      const { scrollHeight, scrollTop, clientHeight } = event.target
      const isBottomReached =
        scrollHeight - Math.round(scrollTop) === clientHeight
      setModalScrollBottomReached(isBottomReached)
    })
  })

  return (
    <>
      <Modal
        className={`modal--dashboard right-drawer${
          modalBreadcrumb.length > 0 ? " has-breadcrumb" : ""
        } ${modalClass}`}
        onHide={() => {
          setModalClass("")
          setModalBreadcrumb([])
          setModalVisibility(false)
          window.history.replaceState(
            "",
            document.title,
            window.location.pathname + window.location.search
          )
        }}
        show={modalVisible}
        contentClassName={``}
        centered
      >
        <Modal.Header closeButton>
          {modalBreadcrumb.length > 0 ? (
            <div className="modal-breadcrumb">
              {modalBreadcrumb.map((item, i) => (
                <div
                  key={i}
                  className={`${!!item.onClick ? "clickable" : ""}${
                    i === modalBreadcrumb.length - 1 ? " active" : ""
                  }`}
                  onClick={item.onClick}
                >
                  {item.label}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          <SimpleBar
            className={`${modalIsScrollable ? "scrollable" : ""}${
              modalScrollBottomReached ? " bottom-reached" : ""
            }`}
            ref={refSimpleBar}
            scrollableNodeProps={{ ref: scrollableNodeRef }}
            style={{ maxHeight: "100%" }}
            autoHide={false}
          >
            <div className="modal-content-inner">
              {<ModalComponent {...{ setModalClass, setModalVisibility }} />}
            </div>
          </SimpleBar>
          <div className="bottom-shadow"></div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <MessageBubble
        onHide={() => {
          setMessageBubbleVisibility(false)
          setMessageBubbleErrorMessage("")
          if (!!messageBubbleOnClose) {
            messageBubbleOnClose?.()
          }
          setMessageBubbleOnClose(null)
        }}
        show={messageBubbleVisible}
        contentClassName={`dashboard-message-bubble ${messageBubbleClass}`}
      >
        {<MessageBubbleComponent />}
        {messageBubbleErrorMessage ? (
          <p className="message-bubble-error">{messageBubbleErrorMessage}</p>
        ) : (
          ""
        )}
      </MessageBubble>

      <div
        className={`dashboard-wrapper min-vh-100 w-100 d-flex flex-column ${containerClass}`}
      >
        <DashboardHeader
          navSettingsActiveItem={navSettingsActiveItem}
          setNavSettingsActiveItem={setNavSettingsActiveItem}
          msalLogoutFunc={msalLogoutFunc}
          setLogoutRequested={setLogoutRequested}
          unreadNotifRedDot={unreadNotifRedDot}
        />

        <DashboardProgress perc={progressPerc || dashboardProgressPerc} />

        <div
          className={`dashboard-body-wrapper flex-grow-1 ${bodyClass || ""}`}
        >
          <DashboardRedBanner
            className={redBannerClassName}
            LeftComponent={RedBannerLeftComponent}
            show={redBannerVisible}
            days={redBannerDays}
            cta={redBannerCta}
            PostCtaComponent={RedBannerPostCtaComponent}
            onOpenCallback={redBannerOnOpenCallback}
          >
            {RedBannerCenterComponent ? <RedBannerCenterComponent /> : ""}
          </DashboardRedBanner>
          {children}
          <Outlet
            context={{
              setContainerClass,
              navSettingsActiveItem,
              setNavSettingsActiveItem,
              modalVisible,
              setModalClass,
              setModalBreadcrumb,
              setModalVisibility,
              setModalComponent,
              messageBubbleVisible,
              setMessageBubbleClass,
              setMessageBubbleVisibility,
              setMessageBubbleComponent,
              setMessageBubbleErrorMessage,
              setMessageBubbleOnClose,
              setDashboardProgressPerc,
              dashboardPopups,
              showDashboardPopup,
              setRedBannerClassName,
              setRedBannerVisibility,
              setRedBannerDays,
              setRedBannerLeftComponent,
              setRedBannerCenterComponent,
              setRedBannerCta,
              setRedBannerPostCtaComponent,
              setUnreadNotifRedDot
            }}
          ></Outlet>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = ({ children, bodyClass, progressPerc }) => {
  const nav = useNavigate()

  let msalLoginFunc
  const [authState, setAuthState] = useState(true)
  useEffect(() => {
    if (authState === false) {
      if (typeof msalLoginFunc === "function") {
        msalLoginFunc()
      } else {
        nav(ALLROUTES.home)
      }
    }
  }, [authState, msalLoginFunc, nav])

  const [logoutRequested, setLogoutRequested] = useState(false)

  const AuthenticatedDashboard = ({ msalLogoutFunc }) => (
    <DashboardWrapperNoAuth
      bodyClass={bodyClass}
      progressPerc={progressPerc}
      msalLogoutFunc={msalLogoutFunc}
      setLogoutRequested={setLogoutRequested}
    >
      {children}
    </DashboardWrapperNoAuth>
  )

  if (logoutRequested === true) {
    return <CircaLoadingAnimationFS />
  }

  if (!!manageMsalAuthJwtToken?.get()) {
    return <AuthenticatedDashboard />
  }

  return (
    <AzureAD provider={signInAuthProvider} reduxStore={basicReduxStore}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        // accountInfo object { jwtIdToken, account.userName, jwtAccessToken, account.name }
        if (
          !!error &&
          error.errorMessage.search(APPCONFIG.msal.forgotPasswordHashCheck) !==
            -1
        ) {
          window.location = APPCONFIG.msal.forgotPasswordUrl
          return <CircaLoadingAnimationFS />
        }
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            if (logoutRequested === false) {
              manageMsalAuth.save(accountInfo)
            }
            return <AuthenticatedDashboard msalLogoutFunc={logout} />
          case AuthenticationState.Unauthenticated:
            msalLoginFunc = login
            setAuthState(false)
            return <CircaLoadingAnimationFS />
          case AuthenticationState.InProgress:
          default:
            return <CircaLoadingAnimationFS />
        }
      }}
    </AzureAD>
  )
}

export default DashboardWrapper
