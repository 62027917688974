import React from "react"

import { AnimationLoadingFullScreen } from "../../../assets"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"
import { computePlanJson } from "../../../utils/logic-plan"

import { showPlanModal } from "./plan"
import { showModifyPlanModal } from "./modify-plan"
import Calendar from "../../elements/calendar/calendar"
import { WrappedCalendarMonthDays } from "../../elements/calendar/calendar-month"

import {
  useAccountSetupQueries,
  useDashboardQueries
} from "../../../utils/queries"

import "./plan.scss"

export const showPreviewCalendarModal = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  selectedPlan,
  selectedSchedule,
  planPaymentDates,
  planPaymentDay,
  ...props
}) => {
  setModalClass("modal--preview-calendar narrow-content")
  setModalBreadcrumb([
    {
      label: "Plan Summary",
      onClick: () => {
        showPlanModal({
          setModalClass,
          setModalBreadcrumb,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          ...props
        })
      }
    },
    {
      label: "Modify Plan",
      onClick: () => {
        showModifyPlanModal({
          setModalClass,
          setModalBreadcrumb,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          selections: {
            plan: selectedPlan,
            schedule: selectedSchedule,
            paymentDates: planPaymentDates,
            paymentDay: planPaymentDay
          },
          ...props
        })
      }
    },
    { label: "Preview Calendar" }
  ])
  setModalComponent(() => {
    const {
      status: statusResidents,
      data: dataResidents
    } = useDashboardQueries.useResidentsQuery({})

    const {
      status: statusCalendarPreviewDates,
      data: dataCalendarPreviewDates,
      isFetching: isFetchingCalendarPreviewDates,
      isError: isErrorCalendarPreviewDates
    } = useAccountSetupQueries.useCalendarPreviewDatesQuery({
      config: { refetchOnMount: true },
      enabled: statusResidents === "success",
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId,
      planId: selectedSchedule?.planId,
      planJson: JSON.stringify(
        computePlanJson({
          planPaymentDates,
          planPaymentDay,
          selectedSchedule
        })
      )
    })

    return (
      <>
        {dataCalendarPreviewDates && !isFetchingCalendarPreviewDates ? (
          <>
            <div className="widget freeze-to-top">
              <h2 className="billboard text-center mb-0">Preview Calendar</h2>
              <WrappedCalendarMonthDays compact={true} />
            </div>
            <div className="widget">
              <Calendar previewData={dataCalendarPreviewDates} />
            </div>
          </>
        ) : (
          <AnimationLoadingFullScreen minVh100={false} />
        )}
      </>
    )
  })
  setModalVisibility(true)
}
