import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"

import { CircaLoadingAnimationFS } from "../../../assets"
import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import {
  calculateAccountSetupPercentage,
  consoleLog,
  handleApiError,
  reduceApiData,
  SessionStorage
} from "../../../utils"
import { leaseIdInSetup } from "../../../utils/logic"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import OrgnSetupLeaseDetails from "../../../components/organisms/lease-details/setup-lease-details"

import { useDashboardQueries } from "../../../utils/queries"
import { useAccountSetupMutation } from "../../../utils/mutations"

import "./lease-details.scss"

const ScreenSetupLeaseDetails = () => {
  const {
    setContainerClass,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleOnClose,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup
  } = useOutletContext()

  const { pathname, state: fwdState } = useLocation()
  const nav = useNavigate()

  useEffect(() => {
    document.body.classList.add("page__lease-details")
    setContainerClass("screen__lease-details")
    if (fwdState?.showWelcomeBackPopup) {
      showDashboardPopup(dashboardPopups.WelcomeBack)
    }
    if (APPCONFIG.debugGlobal && APPCONFIG.debugRedirection)
      consoleLog(`[SCREEN] lease-details`)
    return () => {
      document.body.classList.remove("page__lease-details")
      setContainerClass("")
      setDashboardProgressPerc(0)
    }
  }, [])

  const {
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [leaseDetails, setLeaseDetails] = useState(false)

  useEffect(() => {
    if (!!dataResidents && !isFetchingResidents) {
      if (!!fwdState?.leaseId && !!fwdState?.pinCode) {
        if (
          leaseDetails === false &&
          dataResidents?.data?.code === 200 &&
          updateInProgress === false
        ) {
          consoleLog(`leaseDetails = ${JSON.stringify(leaseDetails)}`, "danger")
          consoleLog(`TRIGGERING handleSubmitLeaseDetails`, "danger")
          consoleLog(`[ENSURE THIS TRIGGERS ONLY ONCE]`, "danger")
          setLeaseDetails("fetching")
          handleSubmitLeaseDetails()
        }
      } else {
        nav(ALLROUTES.accountSetupChildren.leaseId)
      }
    }
  }, [dataResidents, isFetchingResidents])

  const submitLeaseDetailsMutation = useAccountSetupMutation.useSubmitLeaseDetailsMutation()
  const acceptLeaseTermsMutation = useAccountSetupMutation.useAcceptLeaseTermsMutation()

  const handleSubmitLeaseDetails = () => {
    if (!!updateInProgress) return
    setUpdateInProgress(true)
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      referralResidentid:
        fwdState?.referralResidentId ||
        SessionStorage.read(APPCONFIG.variables.referralResidentId) ||
        0,
      uniqueAccountCode: fwdState?.leaseId,
      pinCode: fwdState?.pinCode
    }
    submitLeaseDetailsMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          setDashboardProgressPerc(calculateAccountSetupPercentage(pathname))
          const reducedDataLeaseDetails = reduceApiData(data, "lease_details")
          setLeaseDetails(reducedDataLeaseDetails)
          leaseIdInSetup.set(reducedDataLeaseDetails?.leaseId)
        } else {
          setLeaseDetails("error")
          handleApiError({
            customHeading: "Failed to retrieve lease details",
            nav,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        setMessageBubbleOnClose(() => () => {
          nav(ALLROUTES.accountSetupChildren.leaseId)
        })
        handleApiError({
          nav,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const handleAcceptLeaseTerms = () => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseId: leaseDetails?.leaseId
    }
    acceptLeaseTermsMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          nav(ALLROUTES.accountSetupChildren.selectPlan)
        } else {
          handleApiError({
            customHeading: "Failed to accept lease terms",
            nav,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleApiError({
          nav,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          ...errorData
        })
      }
    })
  }

  return (
    <>
      {isFetchingResidents || typeof leaseDetails !== "object" ? (
        <CircaLoadingAnimationFS />
      ) : (
        <FullScreenCentered className={`w-100`} vw100={false}>
          <OrgnSetupLeaseDetails
            leaseDetails={leaseDetails}
            submitButtonText={`Confirm Information`}
            onSubmit={handleAcceptLeaseTerms}
            setMessageBubbleComponent={setMessageBubbleComponent}
            setMessageBubbleVisibility={setMessageBubbleVisibility}
          />
        </FullScreenCentered>
      )}
    </>
  )
}

export default ScreenSetupLeaseDetails
