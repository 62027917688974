import React from "react"

const YouPayOnThe = ({
  accentuated = false,
  compact = false,
  className,
  fullSpaceBetween = false,
  leftLabel,
  leftInfo,
  onClick,
  rightLabel,
  rightInfo,
  RightComponent,
  arrowLeftCallback = null,
  arrowRightCallback = null
}) => {
  return (
    <div
      className={`widget you-pay-on-the d-flex align-items-center justify-content-between block high-elevation br-xlarge${
        accentuated ? " accentuated" : ""
      }${compact ? " compact" : ""}${
        fullSpaceBetween ? " full-space-between" : ""
      }${!!onClick ? " clickable" : ""}${!!className ? ` ${className}` : ""}${
        !!arrowLeftCallback || !!arrowRightCallback ? " has-nav-buttons" : ""
      }`}
    >
      {!!arrowLeftCallback && (
        <button className="prev-item" onClick={arrowLeftCallback}></button>
      )}
      <div className="info-block left" onClick={onClick}>
        <span className="label">{leftLabel}</span>
        <h2 className="billboard">{leftInfo}</h2>
      </div>
      <div className="info-block right" onClick={onClick}>
        {!!rightLabel ? <span className="label">{rightLabel}</span> : ""}
        {!!rightInfo ? <h2 className="billboard">{rightInfo}</h2> : ""}
        {!!RightComponent ? <RightComponent /> : ""}
      </div>
      {!!arrowRightCallback && (
        <button className="next-item" onClick={arrowRightCallback}></button>
      )}
    </div>
  )
}
export default YouPayOnThe
