import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import {
  handleApiError,
  sanitizeFormValuesForSubmit,
  SessionStorage
} from "../../../utils"

import { CircaLoadingAnimationFS } from "../../../assets"
import OrgnFormProfile from "../../../components/organisms/profile/profile"
import OrgnFormDeleteAccount from "../../../components/organisms/account/delete-account"

import { useAccountSetupMutation } from "../../../utils/mutations"

import "./profile.scss"

const ScreenDashSettProfile = () => {
  const {
    setContainerClass,
    setMessageBubbleComponent,
    setMessageBubbleVisibility
  } = useOutletContext()

  const dispatch = useDispatch()
  const {
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const nav = useNavigate()

  useEffect(() => {
    document.body.classList.add("page__dash-sett-profile")
    setContainerClass("screen__dash-sett-profile")
    return () => {
      document.body.classList.remove("page__dash-sett-profile")
      setContainerClass("")
    }
  }, [])

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const showModalDeleteAccountForm = () => {
    setModalClass("modal--delete-account")
    setModalComponent(() => (
      <div>
        <div className="generic-form-wrap narrow-centered mx-auto">
          <OrgnFormDeleteAccount
            setMessageBubbleComponent={setMessageBubbleComponent}
            setMessageBubbleVisibility={setMessageBubbleVisibility}
          />
        </div>
      </div>
    ))
    setModalVisibility(true)
  }

  const modifyContactInfoMutation = useAccountSetupMutation.useModifyContactInfoMutation()

  const onSubmitModifyProfile = ({
    dataResidents,
    refetchResidents,
    ...formValues
  }) => {
    let { accept, ...filteredFormValues } = formValues
    filteredFormValues = sanitizeFormValuesForSubmit(filteredFormValues)
    filteredFormValues["id"] = 0
    filteredFormValues["residentId"] = dataResidents?.data?.residentData?.id
    setUpdateInProgress(true)
    modifyContactInfoMutation.mutate(filteredFormValues, {
      onSuccess: ({ data, status }) => {
        if ([200, 204].indexOf(status) > -1) {
          SessionStorage.write("residents", data)
          refetchResidents()
        } else {
          handleApiError({
            nav,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleApiError({
          nav,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  if (updateInProgress) {
    return <CircaLoadingAnimationFS />
  }

  return (
    <>
      <h2 className="mb-4">Profile</h2>
      <div className="profile-form-wrap d-flex flex-column">
        <OrgnFormProfile
          formClass={``}
          hideFields={["accept"]}
          submitButtonText={"Save"}
          onSubmit={onSubmitModifyProfile}
        />
        <div className="delete-account-btn d-flex justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-text text-only mx-auto"
            onClick={showModalDeleteAccountForm}
          >
            Delete Account
          </button>
        </div>
      </div>
    </>
  )
}

export default ScreenDashSettProfile
