import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { APPCONFIG } from "../../config"
import { ALLROUTES } from "../../routes"
import {
  manageMsalAuthJwtToken,
  msalAuthenticated,
  toggleResidentNav
} from "../../utils"

import { GraphicCircaLogo } from "../../assets"
import LoggedInNav from "../elements/logged-in-nav/logged-in-nav"
import { leaseIdInSetup, leaseIdOnDash } from "../../utils/logic"

const DashboardHeader = ({
  navSettingsActiveItem,
  setNavSettingsActiveItem,
  msalLogoutFunc,
  setLogoutRequested,
  unreadNotifRedDot
}) => {
  const { pathname } = useLocation()
  const nav = useNavigate()

  const msalAuthJwtToken = manageMsalAuthJwtToken.get()

  return (
    <>
      <div
        className="nav__resident--backdrop"
        onClick={() => {
          toggleResidentNav()
        }}
      ></div>

      <div className="dashboard-header d-flex align-items-center">
        <div
          className="has-logo"
          onClick={() => {
            if (
              !!msalAuthJwtToken &&
              pathname?.indexOf(ALLROUTES.accountSetupContainer) > -1
            ) {
              leaseIdInSetup.unset()
              leaseIdOnDash.unset()
            }
            nav(
              !!msalAuthJwtToken ? ALLROUTES.dashboardContainer : ALLROUTES.home
            )
          }}
        >
          <GraphicCircaLogo />
        </div>
        <div className="has-spacer flex-grow-1"></div>
        <div className="has-resident-nav">
          {!!msalAuthJwtToken &&
          pathname?.indexOf(ALLROUTES.accountSetupContainer) < 0 ? (
            <LoggedInNav
              navSettingsActiveItem={navSettingsActiveItem}
              setNavSettingsActiveItem={setNavSettingsActiveItem}
              msalLogoutFunc={msalLogoutFunc}
              setLogoutRequested={setLogoutRequested}
              unreadNotifRedDot={unreadNotifRedDot}
            />
          ) : (
            <a
              href={APPCONFIG.staticLinks.knowledgebase}
              className="btn btn-primary"
              target="_blank"
            >
              Help
            </a>
          )}
        </div>
      </div>
    </>
  )
}

export default DashboardHeader
