import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form } from "react-bootstrap"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { AllIcons, AnimationLoadingFullScreen } from "../../../assets"
import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import { displayAmount, handleApiError, handleize } from "../../../utils"
import { APIhandler } from "../../../utils/api"

import HelpIconWithTooltip from "../../elements/form/help-icon-with-tooltip"
import YouPayOnThe from "../../widgets/you-pay-on-the"

import {
  useDashboardQueries,
  useRentAssistQueries
} from "../../../utils/queries"
import { useRentAssistMutation } from "../../../utils/mutations"

const OrgnWizardRentAssist = ({
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  leaseArrearId,
  refetchDashboard,
  refetchWalletInformation,
  refetchLeasePlanDetails
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusRentAssistPlan,
    data: dataRentAssistPlan,
    isFetching: isFetchingRentAssistPlan,
    isError: isErrorRentAssistPlan,
    error: errorRentAssistPlan,
    refetch: refetchRentAssistPlan
  } = useRentAssistQueries.useRentAssistPlanQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseArrearId
  })

  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [rentAssistPlans, setRentAssistPlans] = useState(null)
  const [selectedRentAssistPlan, setSelectedRentAssistPlan] = useState(null)
  const [amountDetails, setAmountDetails] = useState(null)
  const [selectedAmountDetail, setSelectedAmountDetail] = useState(null)
  const enterAmountField = useRef()
  const [amountEntered, setAmountEntered] = useState("")
  const [amountError, setAmountError] = useState("")
  const minAmount = 1
  const [calculatingRentAssist, setCalculatingRentAssist] = useState(false)
  const [calculatedRentAssist, setCalculatedRentAssist] = useState(null)

  useEffect(() => {
    if (!!dataRentAssistPlan && !isFetchingRentAssistPlan) {
      setRentAssistPlans(
        dataRentAssistPlan?.data?.rentAssistPlanDetailObj
          ?.rentAssistPlanInformation
      )
      setSelectedRentAssistPlan(
        dataRentAssistPlan?.data?.rentAssistPlanDetailObj?.rentAssistPlanInformation?.find(
          planInfo => !!planInfo?.isSelected
        )
      )
    }
  }, [dataRentAssistPlan, isFetchingRentAssistPlan])

  useEffect(() => {
    if (!!selectedRentAssistPlan) {
      setAmountDetails([
        ...selectedRentAssistPlan?.rentAssistAmountDetails,
        {
          amount: "other",
          description: "Other",
          subtitle: APPCONFIG.api.amountOtherSpecify
        }
      ])
      setSelectedAmountDetail(
        selectedRentAssistPlan?.rentAssistAmountDetails?.find(
          amtDetail => !!amtDetail?.isSelected
        ) || selectedRentAssistPlan?.rentAssistAmountDetails?.[0]
      )
      if (!!enterAmountField.current) enterAmountField.current.value = ""
      setAmountEntered("")
      setCalculatedRentAssist(null)
    }
  }, [selectedRentAssistPlan])

  useEffect(() => {
    setCalculatedRentAssist(null)
    if (
      selectedAmountDetail?.subtitle === APPCONFIG.api.amountOtherSpecify &&
      !!amountEntered
    ) {
      calculateRentAssist(true)
    }
  }, [selectedAmountDetail])

  const calculateRentAssist = forceCalculate => {
    const amountErrorMessage = "Please check the entered value"
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      contractArrearsId: leaseArrearId,
      rentAssistType: selectedRentAssistPlan?.planName,
      value: !!enterAmountField.current.value
        ? enterAmountField.current.value * 1
        : 0
    }
    setAmountEntered(requestParams?.value)
    if (requestParams?.value === amountEntered && forceCalculate !== true)
      return
    if (requestParams?.value === 0) {
      setAmountError(amountErrorMessage)
      return
    }
    setAmountError("")
    setCalculatingRentAssist(true)
    APIhandler.rentassist
      .calculate_rentassist(requestParams)
      .then(response => {
        setCalculatedRentAssist(response?.data?.rentAssistAmountDetails)
      })
      .catch(axiosError => {
        setAmountError(
          axiosError?.response?.data?.errordescription || amountErrorMessage
        )
      })
      .finally(() => {
        setCalculatingRentAssist(false)
      })
  }

  const handleScreenApiError = ({
    customHeading = "Failed to setup RentAssist",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const applicableAmountValue = () => {
    return selectedAmountDetail?.subtitle === APPCONFIG.api.amountOtherSpecify
      ? !!enterAmountField?.current?.value
        ? parseFloat(enterAmountField?.current?.value)
        : 0
      : selectedAmountDetail?.amount
  }

  const saveRentAssistMutation = useRentAssistMutation.useSaveRentAssistMutation()
  const onSubmit = () => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      leaseArrearsId: leaseArrearId,
      plan: selectedRentAssistPlan?.planName,
      value: applicableAmountValue()
    }
    setUpdateInProgress(true)
    saveRentAssistMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200].indexOf(status) > -1) {
          setModalVisibility(false)
          refetchDashboard()
          refetchWalletInformation()
          refetchLeasePlanDetails()
          nav(ALLROUTES.dashboardContainer)
        } else {
          handleScreenApiError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  if (updateInProgress || isFetchingResidents || isFetchingRentAssistPlan) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="widget freeze-to-top">
        <h2 className="billboard text-center mb-4">RentAssist</h2>
        {!!selectedAmountDetail && selectedAmountDetail?.amount !== "other" && (
          <YouPayOnThe
            accentuated={true}
            leftLabel={`You pay an extra`}
            leftInfo={`$${displayAmount(
              selectedAmountDetail?.amountEachMonth
            )}`}
            rightLabel={`each month for`}
            rightInfo={`${selectedAmountDetail?.totalMonths} month${
              selectedAmountDetail?.totalMonths == 1 ? "" : "s"
            }`}
          />
        )}
        {!!calculatedRentAssist && (
          <YouPayOnThe
            accentuated={true}
            compact={
              `${displayAmount(
                calculatedRentAssist?.amountEachMonth
              )}${displayAmount(calculatedRentAssist?.totalMonths)}`.length > 5
            }
            leftLabel={`You pay an extra`}
            leftInfo={`$${displayAmount(
              calculatedRentAssist?.amountEachMonth
            )}`}
            rightLabel={`each month for`}
            rightInfo={`${displayAmount(
              calculatedRentAssist?.totalMonths
            )} month${calculatedRentAssist?.totalMonths == 1 ? "" : "s"}`}
          />
        )}
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Plan
          <HelpIconWithTooltip
            tooltipClass={`rent-assist-plan-tooltip`}
            isOverModal={true}
            placement={`left-start`}
            TooltipComponent={() => (
              <>
                <h2 className="h1 mb-3 pb-2">Payment Plan Options</h2>
                <h3 className="mt-0 mb-3">Percentage (%)</h3>
                <p className="mb-3">
                  A percentage of your total balance will be added to your
                  regular rent payment(s) each month, until your balance is paid
                  off.
                </p>
                <h3 className="mt-0 mb-3">Fixed Amount ($)</h3>
                <p className="mb-0">
                  A fixed dollar amount will be added to your regular rent
                  payment(s) each month, until your balance is paid off.
                </p>
              </>
            )}
          />
        </h3>
        <div className={`option-selector large-icon`}>
          {rentAssistPlans?.map((plan, i) => (
            <div
              className={`option-wrap block block--selectable low-elevation br-medium${
                plan?.planName === selectedRentAssistPlan?.planName
                  ? " selected"
                  : ""
              }`}
              data-plan={plan?.planName}
              onClick={() => {
                setSelectedRentAssistPlan(plan)
              }}
              key={i}
            >
              <div
                className={`plan plan--${
                  plan?.planName === APPCONFIG.api.rentAssist.percentage
                    ? "percentage"
                    : "dollar-amount"
                }`}
              >
                <div className="plan__icon">
                  {plan?.planName === APPCONFIG.api.rentAssist.percentage && (
                    <AllIcons.RentAssistPercentage />
                  )}
                  {plan?.planName === APPCONFIG.api.rentAssist.dollarAmount && (
                    <AllIcons.RentAssistDollarAmount />
                  )}
                </div>
                <div className="plan__label">
                  <h4 className="plan__title mb-0">
                    {plan?.planName === APPCONFIG.api.rentAssist.percentage
                      ? "Percentage"
                      : "Dollar Amount"}
                  </h4>
                  {plan.subtitle && (
                    <div className="plan__subtitle">{plan.subtitle}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Amount
        </h3>
        <div className={`option-selector`}>
          {amountDetails?.map((amtDetail, i) => (
            <div
              className={`option-wrap block block--selectable low-elevation br-medium${
                amtDetail?.amount === selectedAmountDetail?.amount
                  ? " selected"
                  : ""
              }`}
              data-plan={handleize(`${amtDetail?.amount}` || "other")}
              onClick={() => {
                setSelectedAmountDetail(amtDetail)
              }}
              key={i}
            >
              <div
                className={`plan plan--${handleize(
                  `${amtDetail?.amount}` || "other"
                )}`}
              >
                <div
                  className={`plan__icon ${
                    selectedRentAssistPlan?.planName ===
                    APPCONFIG.api.rentAssist.percentage
                      ? "icon-perc"
                      : "icon-dollar"
                  }`}
                ></div>
                <div className="plan__label">
                  <div className="plan__title">{amtDetail?.description}</div>
                  {amtDetail?.subtitle && (
                    <div className="plan__subtitle">{amtDetail?.subtitle}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {selectedAmountDetail?.subtitle ===
          APPCONFIG.api.amountOtherSpecify && (
          <div className="generic-form">
            <div className="form-field mt-4">
              <Form.Control
                ref={enterAmountField}
                className={
                  (amountEntered && parseFloat(amountEntered) < minAmount) ||
                  !!amountError
                    ? "is-invalid"
                    : ""
                }
                type="number"
                defaultValue={amountEntered}
                placeholder={`Type in the value`}
                onKeyUp={calculateRentAssist}
              />
              <div className="invalid-feedback">
                {amountEntered && parseFloat(amountEntered) < minAmount
                  ? `Payment must be atleast $${displayAmount(minAmount)}`
                  : ""}
                {amountError}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-primary flex-grow-1"
          disabled={
            !!calculatingRentAssist || applicableAmountValue() < minAmount
          }
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
      <div className="widget">
        <p className="smaller-text text-center">
          Amounts subject to change. Review your upcoming payment details for
          the most up-to-date information.
        </p>
      </div>
    </>
  )
}

export default OrgnWizardRentAssist
