import React from "react"

const reducer = (
  state = {
    modalBreadcrumb: [],
    modalClass: "",
    ModalComponent: () => <></>,
    modalVisible: false
  },
  action
) => {
  switch (action.type) {
    case "set_breadcrumb":
      return { ...state, modalBreadcrumb: action.payload }

    case "set_class":
      return { ...state, modalClass: action.payload }

    case "set_component":
      return { ...state, ModalComponent: action.payload }

    case "set_visibility":
      return { ...state, modalVisible: action.payload }

    default:
      return state
  }
}
export default reducer
