import React, { useRef, useState } from "react"
import { Form } from "react-bootstrap"

export const CheckboxButtonNoState = props => {
  const checkboxId = `checkbox-${props.name || Math.random() * 10e10}`

  return (
    <div
      className={`form-field form-field--checkbox-button d-flex flex-row${
        props?.noLabel ? " no-label" : ""
      }`}
    >
      <div className="checkbox-wrap">
        <Form.Check
          type="checkbox"
          label={props.noLabel ? "" : props.label}
          checked={props?.checked}
          readOnly={props?.readOnly || false}
          id={checkboxId}
          onClick={props?.readOnly ? () => {} : props?.onClick}
          onChange={e => {
            props?.onChange?.(e.target.checked)
          }}
        />
        {props.subLabel && <span className="sub-label">{props.subLabel}</span>}
      </div>
    </div>
  )
}

const CheckboxButton = props => {
  const checkboxId = `checkbox-${props.name || Math.random() * 10e10}`

  const [checked, setChecked] = useState(props?.checked || false)

  return (
    <div
      className={`form-field form-field--checkbox-button d-flex flex-row${
        props?.noLabel ? " no-label" : ""
      }`}
    >
      <div className="checkbox-wrap">
        <Form.Check
          type="checkbox"
          label={props.noLabel ? "" : props.label}
          checked={checked}
          readOnly={props?.readOnly || false}
          id={checkboxId}
          onClick={() => {
            setChecked(!checked)
          }}
          onChange={e => {
            props?.onChange?.(e.target.checked)
            props?.trigger?.()
          }}
        />
        {props.subLabel && <span className="sub-label">{props.subLabel}</span>}
      </div>
    </div>
  )
}
export default CheckboxButton
