import React from "react"
import { Form } from "react-bootstrap"

export default React.forwardRef(function (props, ref) {
  return (
    <>
      <Form.Label
        className="field-label"
        htmlFor={`checkbox-switch-${props.name}`}
      >
        {props.label || ""}
        {props.LabelComponent ? <props.LabelComponent /> : ""}
      </Form.Label>
      <Form.Check
        type="switch"
        className="checkbox-switch"
        ref={ref}
        id={`checkbox-switch-${props.name}`}
        name={props.name}
        onChange={props.onChange}
        checked={props.isChecked}
        defaultChecked={props.isChecked}
        disabled={props.readOnly}
      />
    </>
  )
})
