import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Dropdown, Table, Tabs, Tab } from "react-bootstrap"
import DateRangePicker from "react-bootstrap-daterangepicker"

import {
  AllIcons,
  AnimationLoadingFullScreen,
  GraphicNoPayments
} from "../../../assets"
import {
  displayAmount,
  formatDateToFormat,
  reduceApiData
} from "../../../utils"
import { pickALeaseForDisplay, leaseIdOnDash } from "../../../utils/logic"
import { displayTpl } from "../../../utils/templates"

import StatementTable from "./statements-table"
import { showModalPaymentDetails } from "../../../components/organisms/payment/payment-details"

import {
  useDashboardQueries,
  useStatementQueries
} from "../../../utils/queries"

import "bootstrap-daterangepicker/daterangepicker.css"
import "./payments.scss"

const ScreenDashSettPayments = () => {
  const {
    setContainerClass,
    setModalClass,
    setModalBreadcrumb,
    setModalVisibility,
    setModalComponent,
    setMessageBubbleComponent,
    setMessageBubbleVisibility
  } = useOutletContext()

  const nav = useNavigate()

  const [allLeases, setAllLeases] = useState(null)
  const [selectedLease, setSelectedLease] = useState(null)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusDashboard,
    data: dataDashboard,
    isFetching: isFetchingDashboard
  } = useDashboardQueries.useDashboardQuery({
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const [paymentsDateRange, setPaymentsDateRange] = useState(null)

  useEffect(() => {
    let reducedDataDashboard = !!dataDashboard
      ? reduceApiData(dataDashboard, "dashboard")
      : {}
    if (!!dataDashboard && !isFetchingDashboard) {
      const { onDisplay: leaseOnDisplay } = pickALeaseForDisplay({
        nav,
        reducedDataDashboard
      })
      setAllLeases(reducedDataDashboard?.allLeases)
      setSelectedLease(leaseOnDisplay.lease)
    }
  }, [dataResidents, isFetchingResidents, dataDashboard, isFetchingDashboard])

  const {
    status: statusUpcomingPayments,
    data: dataUpcomingPayments,
    isFetching: isFetchingUpcomingPayments,
    isError: isErrorUpcomingPayments,
    error: errorUpcomingPayments,
    refetch: refetchUpcomingPayments
  } = useStatementQueries.useUpcomingPaymentsQuery({
    enabled: statusResidents === "success" && !!selectedLease,
    residentId: dataResidents?.data?.residentData?.id,
    leaseId: selectedLease?.leaseId
  })

  const {
    status: statusPastPayments,
    data: dataPastPayments,
    isFetching: isFetchingPastPayments,
    isError: isErrorPastPayments,
    error: errorPastPayments,
    refetch: refetchPastPayments
  } = useStatementQueries.usePastPaymentsQuery({
    enabled: statusResidents === "success" && !!selectedLease,
    residentId: dataResidents?.data?.residentData?.id,
    leaseId: selectedLease?.leaseId
  })

  const tableColumns = React.useMemo(
    () => [
      {
        header: "Payment Date",
        accessorFn: row => row.formattedPaymentDate,
        footer: props => props.column.id
      },
      {
        header: "Payment Amount",
        accessorFn: row =>
          `$${displayAmount(row.totalPaymentAmount || row.amount)}`,
        footer: props => props.column.id
      },
      {
        header: "Status",
        accessorFn: row => row.statusText,
        footer: props => props.column.id
      },
      {
        header: " ",
        accessorFn: row => row.paymentId,
        cell: props => (
          <a
            className="clickable icon-wrap caret-right"
            onClick={() => {
              handlePaymentClick(props?.cell?.row?.original)
            }}
          ></a>
        ),
        footer: props => props.column.id
      }
    ],
    []
  )

  const [upcomingTableData, setUpcomingTableData] = React.useState([])

  useEffect(() => {
    if (!!dataUpcomingPayments && !isFetchingUpcomingPayments) {
      setUpcomingTableData(
        [...dataUpcomingPayments?.data?.upcomingPaymentDTOs]
          .reduce((total, item) => [...total, ...item.paymentInformation], [])
          .filter(statementItem => {
            return !!paymentsDateRange && !!statementItem?.paymentDate
              ? new Date(statementItem?.paymentDate) >=
                  paymentsDateRange?.startDate &&
                  new Date(statementItem?.paymentDate) <=
                    paymentsDateRange?.endDate
              : true
          })
          .sort(
            (a, b) =>
              new Date(a.paymentDate).getTime() -
              new Date(b.paymentDate).getTime()
          )
      )
    }
  }, [dataUpcomingPayments, isFetchingUpcomingPayments, paymentsDateRange])

  const [pastTableData, setPastTableData] = React.useState([])

  useEffect(() => {
    if (!!dataPastPayments && !isFetchingPastPayments) {
      setPastTableData(
        [...dataPastPayments?.data?.paymentHistory]
          .reduce((total, item) => [...total, ...item.payments], [])
          .filter(statementItem => {
            return !!paymentsDateRange && !!statementItem?.paymentDate
              ? new Date(statementItem?.paymentDate) >=
                  paymentsDateRange?.startDate &&
                  new Date(statementItem?.paymentDate) <=
                    paymentsDateRange?.endDate
              : true
          })
          .sort(
            (a, b) =>
              new Date(a.paymentDate).getTime() -
              new Date(b.paymentDate).getTime()
          )
      )
    }
  }, [dataPastPayments, isFetchingPastPayments, paymentsDateRange])

  const rowsPerPage = 7

  const [activeTab, setActiveTab] = useState("upcoming")

  useEffect(() => {
    document.body.classList.add("page__dash-sett-payments")
    setContainerClass("screen__dash-sett-payments")
    return () => {
      document.body.classList.remove("page__dash-sett-payments")
      setContainerClass("")
    }
  }, [])

  const handlePaymentClick = ({
    formattedPaymentDate,
    paymentId,
    arrearsPaymentId
  }) => {
    showModalPaymentDetails({
      breadcrumbItems: [
        {
          label: "Payments",
          onClick: () => {
            setModalVisibility(false)
          }
        }
      ],
      setModalClass,
      setModalBreadcrumb,
      setModalComponent,
      setModalVisibility,
      formattedPaymentDate,
      paymentId,
      arrearsPaymentId
    })
  }

  return (
    <>
      <h1 className="billboard mb-4">Payments</h1>
      <div className="payments-wrap d-flex flex-column">
        <div className="payments-wrap__filters align-items-center d-flex">
          {selectedLease && (
            <Dropdown className={allLeases?.length < 2 ? "only-one-lease" : ""}>
              <Dropdown.Toggle id={`leased-property-selector`}>
                {displayTpl(selectedLease?.address, "lease_display_name")}
              </Dropdown.Toggle>
              {allLeases?.length > 1 && (
                <Dropdown.Menu>
                  {allLeases
                    .filter(lease => lease?.leaseId !== selectedLease?.leaseId)
                    .map((lease, i) => (
                      <Dropdown.Item
                        onClick={() => {
                          setPaymentsDateRange(null)
                          if (!!lease?.leaseId) {
                            leaseIdOnDash.set(lease?.leaseId)
                            setSelectedLease(lease)
                          }
                        }}
                        key={i}
                      >
                        {displayTpl(lease?.address, "lease_display_name")}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              )}
            </Dropdown>
          )}
          <DateRangePicker
            initialSettings={paymentsDateRange}
            onApply={(event, picker) => {
              const startDate = !!picker?.startDate?.valueOf()
                ? new Date(picker?.startDate?.valueOf())
                : null
              const endDate = !!picker?.endDate?.valueOf()
                ? new Date(picker?.endDate?.valueOf())
                : null
              if (!!startDate && !!endDate) {
                setPaymentsDateRange({
                  startDate: startDate,
                  endDate: endDate
                })
              }
            }}
          >
            <button className="daterange-picker ms-4">
              {!!paymentsDateRange
                ? ` ${formatDateToFormat(
                    paymentsDateRange?.startDate
                  )} - ${formatDateToFormat(paymentsDateRange?.endDate)}`
                : "Date Range"}
              <AllIcons.Calendar />
            </button>
          </DateRangePicker>
          {paymentsDateRange && (
            <button
              type="button"
              className="btn btn-text text-only ms-3"
              onClick={() => {
                setPaymentsDateRange(null)
              }}
            >
              Clear
            </button>
          )}
        </div>
        <div className="payments-wrap__tabs mt-5">
          <Tabs
            id="payment-statements-tabs"
            activeKey={activeTab}
            onSelect={tabId => setActiveTab(tabId)}
          >
            <Tab eventKey="upcoming" title="Upcoming" on>
              {isFetchingResidents ||
              isFetchingUpcomingPayments ||
              !dataUpcomingPayments ? (
                <div className="no-data-wrap d-flex flex-column align-items-center justify-content-center block high-elevation br-large">
                  <AnimationLoadingFullScreen minVh100={false} />
                </div>
              ) : (
                <StatementTable
                  tableData={upcomingTableData}
                  columns={tableColumns}
                  defaultPageSize={rowsPerPage}
                  noRecordsMessage={`No upcoming payments`}
                />
              )}
            </Tab>
            <Tab eventKey="past" title="Past">
              {isFetchingResidents ||
              isFetchingPastPayments ||
              !dataPastPayments ? (
                <div className="no-data-wrap d-flex flex-column align-items-center justify-content-center block high-elevation br-large">
                  <AnimationLoadingFullScreen minVh100={false} />
                </div>
              ) : (
                <StatementTable
                  tableData={pastTableData}
                  columns={tableColumns}
                  defaultPageSize={rowsPerPage}
                  noRecordsMessage={`No past payments`}
                />
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default ScreenDashSettPayments
