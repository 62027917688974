import React, { useEffect, useState } from "react"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { AnimationLoadingFullScreen } from "../../../assets"

import { showModalBankInformation } from "../bank-information/bank-information"
import { showModalCardInformation } from "../card-information/card-information"

import {
  useDashboardQueries,
  usePaymentMethodQueries
} from "../../../utils/queries"
import { usePaymentMethodMutation } from "../../../utils/mutations"
import {
  ADDMETHODSLUG_BANK_LABEL,
  ADDMETHODSLUG_CARD_LABEL,
  APPCONFIG
} from "../../../config"
import { handleApiError } from "../../../utils"
import { useNavigate } from "react-router-dom"

export const showReplacePaymentMethodModal = ({
  bankAccountId,
  cardId,
  updateInProgress,
  setUpdateInProgress,
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  setModalBreadcrumb([{ label: "" }])
  setModalClass("modal--modify-plan narrow-content")
  setModalComponent(() => (
    <div className="modal-content-height d-flex flex-column">
      <div className="widget freeze-to-top">
        <h2 className="billboard mb-4">Replace Payment Method</h2>
        <p className="regular-text color-grayscale-graphite mb-0">
          There are scheduled payments using this payment method. Replace with
          another before removing this method from your account.
        </p>
      </div>
      <OrgnWizardReplacePaymentMethod
        bankAccountId={bankAccountId}
        cardId={cardId}
        updateInProgress={updateInProgress}
        setUpdateInProgress={setUpdateInProgress}
        setMessageBubbleComponent={setMessageBubbleComponent}
        setMessageBubbleVisibility={setMessageBubbleVisibility}
      />
    </div>
  ))
  setModalVisibility(true)
}

const OrgnWizardReplacePaymentMethod = ({
  bankAccountId,
  cardId,
  updateInProgress,
  setUpdateInProgress,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusBankAccounts,
    data: dataBankAccounts,
    isFetching: isFetchingBankAccounts,
    isError: isErrorBankAccounts,
    error: errorBankAccounts,
    refetch: refetchBankAccounts
  } = usePaymentMethodQueries.useBankAccountsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const {
    status: statusCards,
    data: dataCards,
    isFetching: isFetchingCards,
    isError: isErrorCards,
    error: errorCards,
    refetch: refetchCards
  } = usePaymentMethodQueries.useCardsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const [paymentMethodsList, setPaymentMethodsList] = useState([])

  const morePaymentMethods = [
    {
      slug: APPCONFIG.api.globalPaymentMethods.CASH.slug,
      icon_class: `icon-${APPCONFIG.api.globalPaymentMethods.CASH.slug}`,
      title: APPCONFIG.api.globalPaymentMethods.CASH.label,
      globalPaymentId: APPCONFIG.api.globalPaymentMethods.CASH.id
    },
    {
      slug: APPCONFIG.api.globalPaymentMethods.CASHAPP.slug,
      icon_class: `icon-${APPCONFIG.api.globalPaymentMethods.CASHAPP.slug}`,
      title: APPCONFIG.api.globalPaymentMethods.CASHAPP.label,
      globalPaymentId: APPCONFIG.api.globalPaymentMethods.CASHAPP.id
    }
  ]

  useEffect(() => {
    if (
      !isFetchingBankAccounts &&
      !isFetchingCards &&
      !!dataBankAccounts &&
      !!dataCards
    ) {
      setPaymentMethodsList([
        ...dataBankAccounts?.data?.data
          ?.filter(bankAc => bankAc?.bankAccountid !== bankAccountId)
          ?.map(bankAc => {
            bankAc.slug = `${APPCONFIG.api.bankSlug}--${bankAc?.bankAccountid}`
            bankAc.type = APPCONFIG.api.bankSlug
            bankAc.icon_class = `icon-${APPCONFIG.api.bankSlug}`
            bankAc.title = bankAc?.bankAccountNickName
            return bankAc
          }),
        ...dataCards?.data?.data
          ?.filter(card => card?.cardId !== cardId)
          ?.map(card => {
            card.slug = `${APPCONFIG.api.cardSlug}--${card?.cardId}`
            card.type = APPCONFIG.api.cardSlug
            card.icon_class = `icon-${APPCONFIG.api.cardSlug}`
            card.title = card?.cardNickName
            return card
          }),
        ...morePaymentMethods
      ])
    }
  }, [
    dataBankAccounts,
    isFetchingBankAccounts,
    errorBankAccounts,
    dataCards,
    isFetchingCards,
    errorCards
  ])

  const replacePaymentMethodMutation =
    usePaymentMethodMutation.useReplacePaymentMethodMutation()
  const replacePaymentMethodWithNewMutation =
    usePaymentMethodMutation.useReplacePaymentMethodWithNewMutation()

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const ADDNEWMETHODOPTIONS = [
    {
      icon_class: "icon-add",
      title: ADDMETHODSLUG_BANK_LABEL,
      onClick: () =>
        showModalBankInformation({
          onSubmitCallback: () => {
            showReplacePaymentMethodModal({
              bankAccountId,
              cardId,
              updateInProgress,
              setUpdateInProgress,
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              setMessageBubbleComponent,
              setMessageBubbleVisibility
            })
          },
          updateInProgress,
          setUpdateInProgress,
          setModalBreadcrumb,
          setModalClass,
          setModalComponent,
          setModalVisibility
        })
    },
    {
      icon_class: "icon-add",
      title: ADDMETHODSLUG_CARD_LABEL,
      onClick: () =>
        showModalCardInformation({
          onSubmitCallback: () => {
            showReplacePaymentMethodModal({
              bankAccountId,
              cardId,
              updateInProgress,
              setUpdateInProgress,
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              setMessageBubbleComponent,
              setMessageBubbleVisibility
            })
          },
          setModalBreadcrumb,
          setModalClass,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility
        })
    }
  ]

  const handleScreenApiError = ({
    customHeading = "Failed to replace payment method",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const onSubmitReplacePaymentMethod = () => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      ...(!!bankAccountId ? { oldBankAccountId: bankAccountId } : {}),
      ...(!!cardId ? { oldCardId: cardId } : {}),
      ...(!!selectedPaymentMethod?.globalPaymentId
        ? { globalPaymentId: selectedPaymentMethod?.globalPaymentId }
        : !!selectedPaymentMethod
        ? {
            [selectedPaymentMethod?.type === APPCONFIG.api.cardSlug
              ? "newCardId"
              : "newBankAccountId"]:
              selectedPaymentMethod?.type === APPCONFIG.api.cardSlug
                ? selectedPaymentMethod?.cardId
                : selectedPaymentMethod?.bankAccountid
          }
        : {})
    }
    setUpdateInProgress(true)
    replacePaymentMethodMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201, 204].indexOf(status) > -1) {
          refetchBankAccounts()
          refetchCards()
        } else {
          handleScreenApiError({
            ...data
          })
        }
        setModalVisibility(false)
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const onSubmitReplacePaymentMethodWithNew = formValues => {
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      ...(!!bankAccountId ? { oldBankAccountId: bankAccountId } : {}),
      ...(!!cardId ? { oldCardId: cardId } : {}),
      bankAccountDetailViewObject: {
        ...formValues,
        accountType: formValues?.accountType?.substring(0, 1).toUpperCase(),
        accountClassification: formValues?.accountClassification
          ?.substring(0, 1)
          .toUpperCase()
      }
    }
    setUpdateInProgress(true)
    replacePaymentMethodWithNewMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200, 201, 204].indexOf(status) > -1) {
          refetchBankAccounts()
          refetchCards()
        } else {
          handleScreenApiError({
            ...data
          })
        }
        setModalVisibility(false)
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  if (
    isFetchingResidents ||
    isFetchingBankAccounts ||
    isFetchingCards ||
    updateInProgress
  ) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Use Existing Method
        </h3>
        <div className="plan-payment-options-wrap">
          {paymentMethodsList.map((paymentMethod, i) => (
            <div
              className={`plan-payment-options block block--selectable ${
                paymentMethod.slug === selectedPaymentMethod?.slug
                  ? " selected"
                  : ""
              } low-elevation br-medium mt-3`}
              key={i}
              onClick={() => {
                setSelectedPaymentMethod(paymentMethod)
              }}
            >
              <div className="payment-option">
                <div
                  className={`payment-option__icon ${paymentMethod?.icon_class}`}
                ></div>
                <div className="payment-option__title">
                  {paymentMethod?.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Add New Method
        </h3>
        <div className={`option-selector`}>
          {ADDNEWMETHODOPTIONS.map((addNewMethodOption, i) => (
            <div
              className={`option-wrap modify-plan-option block block--selectable btn-tile ${
                addNewMethodOption.disabled ? "disabled" : "clickable"
              }`}
              onClick={addNewMethodOption.onClick}
              key={i}
            >
              <div className={addNewMethodOption.icon_class}></div>
              <h4>{addNewMethodOption.title}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-primary flex-grow-1"
          onClick={onSubmitReplacePaymentMethod}
        >
          Continue
        </button>
      </div>
    </>
  )
}

export default OrgnWizardReplacePaymentMethod
