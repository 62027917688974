import { useMutation } from "react-query"
import { APIhandler } from "./api"

// SIGNUP

export const useOnboardingMutation = {
  useValidateEmailMutation: () => {
    return useMutation(payload => APIhandler.onboarding.validate_email(payload))
  },
  useSignupMutation: () => {
    return useMutation(payload => APIhandler.onboarding.user_signup(payload))
  },
  useSignupEmailVerificationMutation: () => {
    return useMutation(payload =>
      APIhandler.onboarding.user_signup_email_verification(payload)
    )
  }
}

// ACCOUNT SETUP

export const useAccountSetupMutation = {
  useSubmitContactInfoMutation: () => {
    return useMutation(payload =>
      APIhandler.account_setup.submit_contact_info(payload)
    )
  },
  useModifyContactInfoMutation: () => {
    return useMutation(payload =>
      APIhandler.account_setup.modify_contact_info(payload)
    )
  },
  useSubmitLeaseDetailsMutation: () => {
    return useMutation(payload =>
      APIhandler.account_setup.submit_lease_details(payload)
    )
  },
  useAcceptLeaseTermsMutation: () => {
    return useMutation(payload =>
      APIhandler.account_setup.accept_lease_terms(payload)
    )
  },
  useUpdatePaymentPlanMutation: () => {
    return useMutation(payload =>
      APIhandler.account_setup.update_payment_plan(payload)
    )
  },
  useUpdatePaymentMethodsMutation: () => {
    return useMutation(payload =>
      APIhandler.account_setup.update_payment_methods(payload)
    )
  },
  useUpdatePaymentSetupMutation: () => {
    return useMutation(payload =>
      APIhandler.account_setup.update_payment_setup(payload)
    )
  }
}

// DASHBOARD

export const useDashboardMutation = {
  useMarkBannerAsReadMutation: () => {
    return useMutation(payload =>
      APIhandler.dashboard.mark_banner_as_read(payload)
    )
  }
}

// PLAN

export const usePlanMutation = {
  useModifyPlanMutation: () => {
    return useMutation(payload => APIhandler.plan.modify_plan(payload))
  },
  useChangePlanMutation: () => {
    return useMutation(payload => APIhandler.plan.change_plan(payload))
  },
  useScheduleAnExceptionMutation: () => {
    return useMutation(payload =>
      APIhandler.plan.schedule_an_exception(payload)
    )
  },
  useDeletePlanMutation: () => {
    return useMutation(payload => APIhandler.plan.delete_plan(payload))
  }
}

// PAYMENT METHOD

export const usePaymentMethodMutation = {
  useAddBankAccountMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.add_bank_account(payload)
    )
  },
  useUpdateBankAccountMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.update_bank_account(payload)
    )
  },
  useDeleteBankAccountMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.delete_bank_account(payload)
    )
  },
  useReplacePaymentMethodMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.replace_payment_method(payload)
    )
  },
  useReplacePaymentMethodWithNewMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.replace_payment_method_with_new(payload)
    )
  },
  useCreateStripeSetupIntentMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.create_stripe_setupintent(payload)
    )
  },
  useUpdateCardMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.update_card(payload)
    )
  },
  useDeleteCardMutation: () => {
    return useMutation(payload =>
      APIhandler.payment_methods.delete_card(payload)
    )
  }
}

// WALLET / PAYMENT

export const usePaymentMutation = {
  useSchedulePaymentMutation: () => {
    return useMutation(payload => APIhandler.payment.schedule_payment(payload))
  },
  useScheduleArrearPaymentMutation: () => {
    return useMutation(payload =>
      APIhandler.payment.schedule_arrear_payment(payload)
    )
  },
  useSchedulePaymentAndLatePaymentMutation: () => {
    return useMutation(payload =>
      APIhandler.payment.schedule_payment_and_late_payment(payload)
    )
  },
  usePayNowMutation: () => {
    return useMutation(payload => APIhandler.payment.pay_now(payload))
  },
  usePayNowArrearsMutation: () => {
    return useMutation(payload => APIhandler.payment.pay_now_arrears(payload))
  },
  useModifyLatePaymentMutation: () => {
    return useMutation(payload =>
      APIhandler.payment.modify_late_payment(payload)
    )
  },
  useModifyLatePaymentArrearsMutation: () => {
    return useMutation(payload =>
      APIhandler.payment.modify_late_payment_arrears(payload)
    )
  }
}

// RENTASSIST

export const useRentAssistMutation = {
  useSaveRentAssistMutation: () => {
    return useMutation(payload =>
      APIhandler.rentassist.save_rentassist(payload)
    )
  }
}

// NOTIFICATIONS

export const useNotificationMutation = {
  useNotificationSettingMutation: () => {
    return useMutation(payload =>
      APIhandler.notifications.update_notification_setting(payload)
    )
  }
}

// the mutations below this line are not in use with ADB2C

export const useLoginMutation = () => {
  return useMutation(payload => APIhandler.user_login(payload))
}

export const useForgotPasswordMutation = () => {
  return useMutation(payload => APIhandler.user_forgotpassword(payload))
}

export const useResetPasswordMutation = () => {
  return useMutation(payload => APIhandler.user_resetpassword(payload))
}
