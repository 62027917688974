import React, { useState } from "react"
import { Button } from "react-bootstrap"

import "./elements.scss"

function SubmitButton({
  buttonType = "button",
  verticalAlign = "center",
  wrapperClassName = "",
  className = "",
  width = "100",
  ...props
}) {
  return (
    <>
      <div
        className={`form-field form-field__submit d-flex align-items-${verticalAlign} justify-content-center${
          !!props.note ? " has-notes" : ""
        } ${wrapperClassName}`}
      >
        {!!props.note ? <p className="note">{props.note}</p> : ""}
        <Button
          variant={`${className.indexOf("danger") > -1 ? "danger" : "primary"}`}
          type={buttonType}
          disabled={props.disabled}
          className={`submit-btn mx-auto w-${width}${
            className ? ` ${className}` : ""
          }`}
          onClick={props.onClick}
        >
          <span>{props.text || "Submit"}</span>
        </Button>
      </div>
    </>
  )
}

export default SubmitButton
