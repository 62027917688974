import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import "./elements.scss"

const CheckboxSelect = ({ items, onChange, selectedItem = {}, formField }) => {
  const getLegendStyles = () => {
    return formField.width < 75
      ? { position: "absolute", left: "-6px", top: "-16px" }
      : {}
  }

  return (
    <div
      style={{ width: `${formField.width || 100}%` }}
      className="checkbox-radio-container position-relative d-inline-flex"
    >
      <div className={`checkbox-like-radios d-flex align-items-center flex-wrap`}>
        <label style={getLegendStyles()} className="field-label checkbox-legend ps-3">
          {formField.label}
        </label>
        {items.map((item, index) => {
          return (
            <Form.Check
              key={index}
              id={item.id || false}
              label={item.label}
              className="mx-2 "
              onChange={e => (e.target.checked ? onChange(item) : "")}
              checked={selectedItem?.label === item.label}
            />
          )
        })}
      </div>
    </div>
  )
}

export default CheckboxSelect
