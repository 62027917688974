import { del, get, patch, post, put } from "./web-api-handler.js"

export const APIhandler = {
  // ONBOARDING
  onboarding: {
    validate_email: payload =>
      post("/api/v5/registration/email/validate", payload),
    user_signup: payload =>
      post("/api/v7/registration/userDetail/validate", payload),
    user_signup_email_verification: payload =>
      post("/api/v7/token/verify", payload)
  },

  // ACCOUNT SETUP
  account_setup: {
    submit_contact_info: payload => post("/api/v5.2/residents", payload),
    modify_contact_info: ({ residentId, ...payload }) =>
      put(`/api/v5.1/residents/${residentId}`, payload),
    get_referral_amount: payload =>
      get(
        `/api/v5/uniquelease/${payload?.leaseId}/pincode/${payload?.pinCode}/referralamount`
      ),
    get_referral_resident_id: payload =>
      get(
        `/api/v5/residents/${payload?.residentId}/referracode/${payload?.referralCode}/residentreferralcode`
      ),
    submit_lease_details: ({ residentId, ...payload }) =>
      post(`/api/v7/leasedetails?residentId=${residentId}`, payload),
    accept_lease_terms: ({ residentId, leaseId, ...payload }) =>
      post(
        `/api/v7/residents/${residentId}/leases/${leaseId}/leaseId/acceptleaseterms`,
        payload
      ),
    get_available_payment_plans: payload =>
      get(
        `/api/v7/residents/${payload?.residentId}/leases/${payload?.leaseId}/availablepaymentplans`
      ),
    update_payment_plan: ({ residentId, ...payload }) =>
      post(`/api/v5/updatepaymentplan/residents/${residentId}`, payload),
    get_payment_methods: ({ residentId }) =>
      get(`/api/v5/paymentmethods?residentId=${residentId}`),
    get_calendar_preview_dates: params =>
      post(
        `/api/v5/residents/${params?.residentId}/leases/${params?.leaseId}/getcalendarpreviewdates`,
        params
      ),
    update_payment_methods: ({ residentId, ...payload }) =>
      put(`/api/v5/residents/${residentId}/paymentmethods`, payload),
    update_payment_setup: ({ residentId, leaseId, setupMode, ...payload }) =>
      post(
        `api/v5/resident/${residentId}/leases/${leaseId}/paymentsetup?paymentSettings=${setupMode}`
      ),

    delete_account: ({ password }) =>
      del(`/api/v7/deleteAccount/password/${password}`)
  },

  // DASHBOARD
  dashboard: {
    get_residents: () => get("/api/v7/residents"),
    get_single_resident: payload =>
      get(`/api/v7/residents/${payload?.residentId}`),
    get_dashboard: ({ residentId }) =>
      get(`/api/v7.1/residents/${residentId}/dashboarddetail`),
    get_leases: ({ residentId }) =>
      get(`api/v7/residents/${residentId}/leases`),
    get_lease_details: ({ residentId, leaseId }) =>
      get(`/api/v7/residents/${residentId}/leases/${leaseId}`),
    get_lease_plan_details: ({ residentId, leaseId }) =>
      get(`/api/v5/resident/${residentId}/lease/${leaseId}/leaseplandetails`),
    get_resident_lease_payment_methods: ({ residentId, leaseId }) =>
      get(`/api/v5/residents/${residentId}/lease/${leaseId}/paymentmethods`),
    get_payment_methods_for_payment_id: ({ residentId, paymentId }) =>
      get(
        `/api/v5/residents/${residentId}/payments/${paymentId}/paymentmethods`
      ),
    mark_banner_as_read: ({ residentId, bannerId }) =>
      put(`/api/v5/resident/${residentId}/banners/${bannerId}/markasread`)
  },

  // PLAN
  plan: {
    /* should move `get_available_payment_plans` here */

    modify_plan: ({ residentId, leaseId, paymentSettings, ...payload }) =>
      post(
        `/api/v7/resident/${residentId}/leases/${leaseId}/settings/${paymentSettings}/paymentplan`,
        payload
      ),
    get_exception_months_list: ({ residentId, leaseId }) =>
      get(
        `/api/v5/residents/${residentId}/lease/${leaseId}/exceptionmonthslist`
      ),
    get_available_payment_plans_for_lease_payment: ({
      residentId,
      leasepaymentId
    }) =>
      get(
        `/api/v7/residents/${residentId}/leasepaymentid/${leasepaymentId}/availablepaymentplans`
      ),
    change_plan_get_available_payment_plans_for_payment: ({
      residentId,
      paymentId
    }) =>
      get(
        `/api/v7/residents/${residentId}/paymentid/${paymentId}/availablepaymentplans`
      ),
    change_plan: ({
      residentId,
      leaseId,
      paymentId,
      paymentSettings,
      ...payload
    }) =>
      post(
        `/api/v7.1/resident/${residentId}/leases/${leaseId}/payments/${paymentId}/settings/${paymentSettings}/paymentplan`,
        payload
      ),
    schedule_an_exception: ({
      residentId,
      leaseId,
      paymentId,
      paymentSettings,
      ...payload
    }) =>
      post(
        `/api/v7/resident/${residentId}/leases/${leaseId}/payments/${paymentId}/settings/${paymentSettings}/paymentplan`,
        payload
      ),
    delete_plan: ({ residentId, paymentId }) =>
      del(`/api/v7/residents/${residentId}/payments/${paymentId}/deleteplan`)
  },

  // PAYMENT METHODS
  payment_methods: {
    get_bank_accounts: ({ residentId }) =>
      get(`/api/v5/residents/${residentId}/bankaccounts`),
    add_bank_account: ({ residentId, ...payload }) =>
      post(`/api/v5/residents/${residentId}/bankaccounts`, payload),
    get_bank_account: ({ residentId, bankAccountId }) =>
      get(`api/v5/residents/${residentId}/bankaccounts/${bankAccountId}`),
    update_bank_account: ({ residentId, ...payload }) =>
      put(`/api/v7/residents/${residentId}/UpdateBankNickName`, payload),
    delete_bank_account: ({ residentId, bankAccountId }) =>
      del(`api/v5/residents/${residentId}/bankaccounts/${bankAccountId}`),

    get_cards: ({ residentId }) => get(`api/v5/residents/${residentId}/cards`),
    get_stripe_publickey: () => get(`/api/v5/stripe/publickey`),
    create_stripe_setupintent: () => post(`/api/v5/stripe/createintent`),
    get_card: ({ residentId, cardId }) =>
      get(`api/v5/residents/${residentId}/cards/${cardId}`),
    update_card: ({ residentId, cardId, ...payload }) =>
      put(`/api/v5/residents/${residentId}/cards/${cardId}`, payload),
    delete_card: ({ residentId, cardId }) =>
      del(`api/v5/residents/${residentId}/cards/${cardId}`),

    replace_payment_method: ({ residentId, ...payload }) =>
      put(`/api/v6/residents/${residentId}/bankaccounts/replace`, payload),
    replace_payment_method_with_new: ({ residentId, ...payload }) =>
      put(
        `/api/v7/residents/${residentId}/bankaccounts/replacewithnewbankpayment`,
        payload
      )
  },

  // WALLET / PAYMENT
  payment: {
    get_wallet_information: ({ residentId, leaseId }) =>
      get(
        `/api/v7/residents/${residentId}/leases/${leaseId}/walletinformation`
      ),
    get_wallet_payment_details: ({ residentId, leaseId }) =>
      get(
        `/api/v7/residents/${residentId}/leases/${leaseId}/walletPaymentDetails`
      ),
    schedule_payment: ({ residentId, ...payload }) =>
      post(`/api/v7/residents/${residentId}/SchedulePayment`, payload),
    schedule_arrear_payment: ({ residentId, ...payload }) =>
      post(`/api/v7/residents/${residentId}/ScheduleArrearPayment`, payload),
    modify_payment: ({ residentId, paymentId }) =>
      get(`/api/v5/resident/${residentId}/payments/${paymentId}/paymentmodify`),
    get_pay_other_amount_info_for_amount: ({ residentId, ...payload }) =>
      post(
        `/api/v7/residents/${residentId}/GetPayOtherAmountInformation`,
        payload
      ),
    schedule_payment_and_late_payment: ({ residentId, ...payload }) =>
      post(`/api/v7/residents/${residentId}/schedulelatepayment`, payload),
    get_payment_modify_info: ({ residentId, paymentId }) =>
      get(`/api/v5/resident/${residentId}/payments/${paymentId}/paymentmodify`),
    get_payment_arrears_modify_info: ({ residentId, paymentArrearsId }) =>
      get(
        `/api/v5/resident/${residentId}/paymentarrear/${paymentArrearsId}/paymentarrearsmodify`
      ),
    pay_now: ({ residentId, paymentId, ...payload }) =>
      put(
        `/api/v5/resident/${residentId}/payments/${paymentId}/paynow`,
        payload
      ),
    pay_now_arrears: ({ residentId, paymentArrearsId, ...payload }) =>
      put(
        `/api/v5/resident/${residentId}/arraerpayments/${paymentArrearsId}/paynowarrears`,
        payload
      ),
    // Pay Late
    get_lateorfailed_payment_modify_info: ({ residentId, paymentId }) =>
      get(
        `/api/v7/resident/${residentId}/payments/${paymentId}/lateorfailedpayments`
      ),
    get_lateorfailed_payment_arrears_modify_info: ({
      residentId,
      paymentArrearsId
    }) =>
      get(
        `/api/v7/resident/${residentId}/arrearpayments/${paymentArrearsId}/lateorfailedpaymentsarrears`
      ),
    modify_late_payment: ({ residentId, ...payload }) =>
      put(`/api/v5/resident/${residentId}/modifylatepayment`, payload),
    modify_late_payment_arrears: ({ residentId, ...payload }) =>
      post(
        `/api/v7/residents/${residentId}/submitlateorfailedpayments`,
        payload
      ),
    // Pay Other Amount
    get_payotheramount_info: ({ residentId, paymentId }) =>
      get(
        `/api/v5/resident/${residentId}/payments/${paymentId}/otheramountinformation`
      ),
    submit_payotheramount: ({ residentId, ...payload }) =>
      post(
        `/api/v7/residents/${residentId}/GetPayOtherAmountInformation`,
        payload
      )
  },

  rentassist: {
    get_rentassist_info: ({ residentId, leaseId }) =>
      get(`/api/v5/residents/${residentId}/lease/${leaseId}/rentassist`),
    get_rentassist_plan: ({ residentId, leaseArrearId }) =>
      get(
        `/api/v5/residents/${residentId}/leasearrear/${leaseArrearId}/rentassistplan`
      ),
    calculate_rentassist: ({ residentId, ...payload }) =>
      post(`/api/v5/residents/${residentId}/calculaterentassist`, payload),
    save_rentassist: ({ residentId, leaseArrearsId, ...payload }) =>
      post(
        `/api/v5/residents/${residentId}/leasearrears/${leaseArrearsId}/saverentassist`,
        payload
      )
  },

  // STATEMENTS
  statements: {
    get_upcoming_payments: ({ residentId, leaseId }) =>
      get(`/api/v7/resident/${residentId}/leases/${leaseId}/upcomingpayments`),
    get_past_payments: ({ residentId, leaseId }) =>
      get(`/api/v5/leases/${leaseId}/paymentshistory`),
    get_payment_details: ({ residentId, paymentId }) =>
      get(`/api/v7/resident/${residentId}/payments/${paymentId}`),
    get_arrear_payment_details: ({ residentId, arrearsPaymentId }) =>
      get(`/api/v7/resident/${residentId}/arrearpayment/${arrearsPaymentId}`)
  },

  // NOTIFICATIONS
  notifications: {
    get_notifications: ({ residentId }) =>
      get(`/api/v7/GetInAppNotifications/resident/${residentId}`),
    get_notification_settings: ({ residentId }) =>
      get(`/api/v7/GetNotificationSettings/resident/${residentId}`),
    update_notification_setting: ({ residentId, ...payload }) =>
      put(`/api/v7/UpdateNotificationSettings/resident/${residentId}`, payload)
  },

  // MORE
  more_apis: {
    get_resident_referral_code: ({ residentId }) =>
      get(`/api/v5/residents/${residentId}/referralcode`)
  },

  // STATIC PAGE
  get_terms: payload => post("/api/v5/xxxxxx", payload),

  // LOGIN [NOT IN USE]
  user_login: payload => post("/api/v5/xxxxxx", payload),
  user_forgotpassword: payload => post("/api/v5/xxxxxx", payload),
  user_resetpassword: payload => post("/api/v5/xxxxxx", payload)
}
