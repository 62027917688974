import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ALLROUTES } from "../../../routes"
import { GraphicGetStarted } from "../../../assets"
import { clearOldMsalTokenIfFound } from "../../../utils"

import { DashboardWrapperNoAuth } from "../../../components/wrappers/dashboard"
import FullScreenCentered from "../../../components/wrappers/full-screen-centered"

import "./welcome.scss"

const ScreenWelcome = () => {
  const nav = useNavigate()

  useEffect(() => {
    const clearedToken = clearOldMsalTokenIfFound()
    if (clearedToken === true) {
      window.location.reload()
    }
  }, [])

  return (
    <DashboardWrapperNoAuth>
      <FullScreenCentered className={`screen__welcome-splash text-center`}>
        <h1 className="billboard">rent, reinvented</h1>

        <p>with you and your community in mind</p>

        <GraphicGetStarted />

        <div className="button-wrap mb-4">
          <button
            className="btn btn-primary"
            onClick={() => {
              nav(ALLROUTES.signup)
            }}
          >
            Get Started
          </button>
          <button
            className="btn btn-text d-block mx-auto my-3"
            onClick={() => {
              nav(ALLROUTES.dashboardContainer)
            }}
          >
            Sign In
          </button>
        </div>
      </FullScreenCentered>
    </DashboardWrapperNoAuth>
  )
}

export default ScreenWelcome
