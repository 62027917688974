import React, { useEffect, useState } from "react"
import { Form, FormGroup } from "react-bootstrap"

import {
  AnimationLoadingFullScreen,
  GraphicShareCircaAndEarn
} from "../../../assets"

import { useDashboardQueries, useMoreApiQueries } from "../../../utils/queries"

const OrgnInfoReferralCode = () => {
  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusResidentReferralCode,
    data: dataResidentReferralCode,
    isFetching: isFetchingResidentReferralCode,
    isError: isErrorResidentReferralCode
  } = useMoreApiQueries.useResidentReferralCodeQuery({
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const [referraCode, setReferraCode] = useState("------")

  useEffect(() => {
    if (
      !!dataResidentReferralCode &&
      !!dataResidentReferralCode?.data?.referralCode
    ) {
      setReferraCode(dataResidentReferralCode?.data?.referralCode)
    }
  }, [dataResidentReferralCode])

  if (isFetchingResidents || isFetchingResidentReferralCode) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="freeze-to-top">
        <h2 className="billboard text-center mx-n2 mb-3 pb-2">
          Share Circa &amp; Earn
        </h2>
      </div>
      <div className="color-grayscale-iron">
        <p className="mb-4 pb-2">
          Invite your neighbors and get $10 towards rent when they make their
          first payment. (They’ll get $10 too!)
        </p>
        <div className="d-flex justify-content-center my-4 pb-2">
          <GraphicShareCircaAndEarn />
        </div>
        <p className="mt-4 pt-2 mb-2 mx-2 text-center">
          Your referral code is:
        </p>
        <div className="d-flex justify-content-center mt-4 pt-2">
          <div className="generic-form d-flex flex-column">
            <FormGroup
              className={`form-field form-field--n-digit-code col-sm-12`}
            >
              <Form.Control
                className={`form-control--n-digit-code char`}
                type={"text"}
                minLength={6}
                maxLength={6}
                disabled={""}
                autoComplete="off"
              />
              <div className="field-display--n-digit-code">
                {"x"
                  .repeat(6)
                  .split("")
                  .map((_, i) => (
                    <span key={i} className={``}>
                      {String(referraCode || "").charAt(i)}
                    </span>
                  ))}
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4 pt-2">
          <a
            className="btn btn-text text-only"
            href={`mailto:?subject=Hey! Enjoy $10 off your 1st payment on the Circa Rent App!&body=I'd like to invite you to the Circa app that offers flexible online payments and provides complete residential rental payment assistance. Join with my referral code ${referraCode} and get $10 off your first payment.`}
          >
            Share Your Code
          </a>
        </div>
      </div>
    </>
  )
}
export default OrgnInfoReferralCode
