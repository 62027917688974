import React from "react"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"

import { firebaseApp } from "./firebase"
import { getMessaging, getToken } from "firebase/messaging"

import { APPCONFIG } from "./config"

import RouteElements from "./routes"
import { ViewportProvider } from "./contexts/viewport"
import ScrollToTop from "./utils/scrollToTop"

import "./App.scss"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false }
  }
})

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // Initialize Firebase Cloud Messaging
    const messaging = getMessaging(firebaseApp)
    if (
      "serviceWorker" in navigator &&
      APPCONFIG.firebase.pushNotificationsEnabled
    ) {
      navigator.serviceWorker
        .register("../firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope)
          getToken(messaging, {
            vapidKey: APPCONFIG.firebase.vapidKey,
            serviceWorkerRegistration: registration
          })
            .then(currentToken => {
              if (currentToken) {
                if (APPCONFIG.debugGlobal)
                  console.log("[firebase] client token:", currentToken)
              } else {
                if (APPCONFIG.debugGlobal)
                  console.log(
                    "[firebase] No registration token available. Request permission to generate one."
                  )
              }
            })
            .catch(err => {
              if (APPCONFIG.debugGlobal)
                console.log(
                  "[firebase] An error occurred while retrieving token. ",
                  err
                )
            })
        })
        .catch(function (err) {
          if (APPCONFIG.debugGlobal)
            console.log(
              "[firebase] Service worker registration failed, error:",
              err
            )
        })
    }
  }

  render() {
    return (
      <>
        {!!APPCONFIG.showMobileScreenOnSmallerRes ? (
          <QueryClientProvider client={queryClient}>
            <ViewportProvider>
              <React.StrictMode>
                <BrowserRouter>
                  <ScrollToTop />
                  <RouteElements />
                </BrowserRouter>
              </React.StrictMode>
            </ViewportProvider>
          </QueryClientProvider>
        ) : (
          <QueryClientProvider client={queryClient}>
            <React.StrictMode>
              <BrowserRouter>
                <ScrollToTop />
                <RouteElements />
              </BrowserRouter>
            </React.StrictMode>
          </QueryClientProvider>
        )}
      </>
    )
  }
}
