import React, { useEffect, useState } from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"

import { APPCONFIG, dayNames } from "../../config"
import { handleize, numberWithOrdinal } from "../../utils"
import { showPlanModal } from "../organisms/plan/plan"

import "./widgets.scss"

const DashWidgetPlanSummary = ({
  className,
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  leasePlanDetails,
  refetchLeasePlanDetails,
  refetchDashboard
}) => {
  const [selectedIcon, setSelectedIcon] = useState(null)

  const leasePlanInfo = leasePlanDetails?.leasePlanInformation

  return (
    <>
      {leasePlanDetails && (
        <div
          className={`dash-widget plan-summary block high-elevation br-xlarge p-4 ${className}`}
        >
          <div className="d-flex align-items-center">
            <h2 className="h1 flex-grow-1 m-0">
              You pay on{" "}
              {!!leasePlanDetails?.paymentDateForDisplay?.dayofweek && (
                <span className="pay-date">
                  {
                    dayNames[
                      leasePlanDetails?.paymentDateForDisplay?.dayofweek +
                        APPCONFIG.api.dayIndexAdjust
                    ]
                  }
                  s
                </span>
              )}
              {!!leasePlanDetails?.paymentDateForDisplay?.dayofweek ? (
                ""
              ) : (
                <>
                  the{" "}
                  {!!leasePlanDetails?.paymentDateForDisplay?.date1 && (
                    <span className="pay-date">
                      {numberWithOrdinal(
                        leasePlanDetails?.paymentDateForDisplay?.date1
                      )}
                    </span>
                  )}
                  {!!leasePlanDetails?.paymentDateForDisplay?.date2 && (
                    <>
                      {" "}
                      and{" "}
                      <span className="pay-date">
                        {numberWithOrdinal(
                          leasePlanDetails?.paymentDateForDisplay?.date2
                        )}
                      </span>
                    </>
                  )}
                </>
              )}
            </h2>
            <div
              className="icon-dots-vertical clickable"
              onClick={() => {
                showPlanModal({
                  setModalClass,
                  setModalBreadcrumb,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility,
                  leasePlanDetails,
                  refetchLeasePlanDetails,
                  refetchDashboard
                })
              }}
            ></div>
          </div>
          <div className="action-icons togglable-icons d-flex">
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={props => (
                <Tooltip
                  id="dash-plan-summary-tooltip-plan"
                  className="generic-tooltip"
                  {...props}
                >
                  Your plan is {[leasePlanInfo?.planTypeDisplayName, leasePlanInfo?.planTypeName === APPCONFIG.api.planTypeNames.TWOPAY ? leasePlanInfo?.planName : ""].filter(item => !!item).join(', ')}
                </Tooltip>
              )}
              placement="bottom"
            >
              <Button className="btn-nostyle">
                <leasePlanDetails.IconPlanType
                  className={`clickable selectable ${
                    selectedIcon === "plan" ? " selected" : ""
                  }`}
                  onMouseEnter={() => {
                    setSelectedIcon("plan")
                  }}
                  onMouseLeave={() => {
                    setSelectedIcon(null)
                  }}
                />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={props => (
                <Tooltip
                  id="dash-plan-summary-tooltip-method"
                  className="generic-tooltip"
                  {...props}
                >
                  You pay with{" "}
                  {["Bank", "Card"].indexOf(leasePlanInfo?.paymentMethodType) >
                    -1 && <>{leasePlanInfo?.paymentMethodName}</>}
                  {leasePlanInfo?.paymentMethodType === "Cash" && (
                    <>{APPCONFIG.api.globalPaymentMethods.CASH.label}</>
                  )}
                  {leasePlanInfo?.paymentMethodType === "Cash App" && (
                    <>{APPCONFIG.api.globalPaymentMethods.CASHAPP.label}</>
                  )}
                </Tooltip>
              )}
              placement="bottom"
            >
              <Button className="btn-nostyle">
                <leasePlanDetails.IconPaymentMethod
                  className={`clickable selectable icon-${handleize(
                    leasePlanInfo?.paymentMethodType
                  )}${selectedIcon === "payment-mode" ? " selected" : ""}`}
                  onMouseEnter={() => {
                    setSelectedIcon("payment-mode")
                  }}
                  onMouseLeave={() => {
                    setSelectedIcon(null)
                  }}
                />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={props => (
                <Tooltip
                  id="dash-plan-summary-tooltip-setup"
                  className="generic-tooltip"
                  {...props}
                >
                  Autopay is {leasePlanInfo?.isAutoPay ? "on" : "off"}.
                </Tooltip>
              )}
              placement="bottom"
            >
              <Button className="btn-nostyle">
                <leasePlanDetails.IconPaymentSetting
                  className={`clickable selectable ${
                    selectedIcon === "payment-method" ? " selected" : ""
                  }`}
                  onMouseEnter={() => {
                    setSelectedIcon("payment-method")
                  }}
                  onMouseLeave={() => {
                    setSelectedIcon(null)
                  }}
                />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </>
  )
}
export default DashWidgetPlanSummary
