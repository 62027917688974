import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"

import "./dashboard-red-banner.scss"

const DashboardRedBanner = ({
  children,
  className,
  show,
  LeftComponent,
  days: { daysCount, daysSuffix },
  cta: { ctaClass = `btn-danger`, ctaText, ctaOnClick },
  PostCtaComponent,
  onOpenCallback
}) => {
  useEffect(onOpenCallback, [])
  return (
    <>
      <div
        className={`dashboard-red-banner${className ? ` ${className}` : ""}${
          show ? " show-banner" : ""
        }`}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={3} className="column-left">
              {LeftComponent ? (
                <LeftComponent />
              ) : (
                daysCount && (
                  <div className="days-display d-flex align-items-center">
                    <h2 className="days-display__count billboard">
                      {daysCount}
                    </h2>
                    <h3 className="days-display__suffix h1">{daysSuffix}</h3>
                  </div>
                )
              )}
            </Col>
            <Col
              lg={6}
              className="column-center d-flex flex-column align-items-center"
            >
              {children}
            </Col>
            <Col
              lg={3}
              className="column-right d-flex align-items-center justify-content-end"
            >
              {ctaText ? (
                <button className={`btn ${ctaClass}`} onClick={ctaOnClick}>
                  {ctaText}
                </button>
              ) : (
                ""
              )}
              {PostCtaComponent ? <PostCtaComponent /> : ""}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default DashboardRedBanner
