import React from "react"

import { AllIcons } from "../../../assets"
import { displayTpl } from "../../../utils/templates"

import TileRow from "../../elements/tile-row"

import "./leases-list.scss"

const LeaseListItem = ({ title, incomplete, onLeaseDetails }) => {
  return (
    <TileRow
      hasErrors={incomplete}
      title={title}
      Icon={() => <AllIcons.LeaseAddress className={`me-3`} />}
      onTitleClick={onLeaseDetails}
      actionButton1Text={incomplete ? `Finish` : `View`}
      onActionButton1Click={onLeaseDetails}
    />
  )
}

const OrgnLeasesList = ({ className, leaseListData, onLeaseDetails }) => {
  return (
    <div className={`leases-list ${className}`}>
      {leaseListData.map((lease, i) => (
        <LeaseListItem
          title={displayTpl(lease, "lease_display_name")}
          incomplete={lease?.incomplete}
          onLeaseDetails={() => onLeaseDetails(lease)}
          key={i}
        />
      ))}
    </div>
  )
}

export default OrgnLeasesList
