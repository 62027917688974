import React, { useEffect } from "react"

import { ALLROUTES } from "../../../routes"
import { APPCONFIG } from "../../../config"
import { CircaLoadingAnimationFS } from "../../../assets"
import {
  dashifyPhoneNumber,
  reduceApiData,
  formatDateToStringUS,
  SessionStorage
} from "../../../utils"
import { validation } from "../../../utils/validation"

import GenericForm from "../../elements/generic-form"

import { useDashboardQueries } from "../../../utils/queries"

const OrgnFormProfile = ({
  formClass,
  hideFields,
  noPreload,
  submitButtonText,
  onSubmit
}) => {
  const accountInfo = SessionStorage.read(
    APPCONFIG.sessionVariables.msalAuthIdToken
  )

  const {
    data: dataResidents,
    isFetching: isFetchingResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  let formFields = [
    {
      label: "First Name",
      name: "firstName",
      rules: {
        required: true,
        validate: { ...validation.nameMax26 }
      },
      defaultValue: dataResidents?.data?.residentData?.firstName || ""
    },
    {
      label: "Last Name",
      name: "lastName",
      rules: {
        required: true,
        validate: { ...validation.nameMax26 }
      },
      defaultValue: dataResidents?.data?.residentData?.lastName || ""
    },
    {
      label: "Date of Birth",
      name: "dob",
      compType: "dob",
      rules: {
        required: true,
        minLength: 10,
        maxLength: 10
      },
      defaultValue: dataResidents?.data?.residentData?.dob
        ? formatDateToStringUS(new Date(dataResidents?.data?.residentData?.dob))
        : ""
    },
    {
      label: "Email Address",
      name: "email",
      compType: "email",
      rules: {
        required: true
      },
      disabled: true,
      defaultValue:
        dataResidents?.data?.residentData?.emailId ||
        accountInfo?.account?.idToken?.email ||
        ""
    },
    {
      label: "Mobile Phone Number",
      name: "primaryPhoneNumber",
      compType: "phone",
      type: "phone",
      rules: {
        required: true,
        validate: { ...validation.phone }
      },
      defaultValue: dataResidents?.data?.residentData?.primaryPhoneNumber
        ? dashifyPhoneNumber(
            dataResidents?.data?.residentData?.primaryPhoneNumber
          )
        : ""
    },
    {
      label: "Secondary Phone Number (Optional)",
      name: "secondaryPhoneNumber",
      compType: "phone",
      type: "phone",
      rules: {
        validate: { ...validation.phone }
      },
      defaultValue: dataResidents?.data?.residentData?.secondaryPhoneNumber
        ? dashifyPhoneNumber(
            dataResidents?.data?.residentData?.secondaryPhoneNumber
          )
        : ""
    },
    {
      label: "I have read and agree to the",
      name: "accept",
      rules: {
        validate: {
          shouldBeTrue: val =>
            val === true || "Please accept the terms and conditions"
        }
      },
      compType: "checkboxButton",
      subLabel: (
        <span>
          {" "}
          <a href={APPCONFIG.staticLinks.termsOfUse} target="_blank">
            Terms and Conditions
          </a>
          .
        </span>
      )
    }
  ]

  hideFields?.forEach((hideField, i) => {
    const foundIndex = formFields.findIndex(
      formField => formField.name === hideField
    )
    if (!!foundIndex) {
      formFields.splice(foundIndex, 1)
    }
  })

  if (isFetchingResidents) {
    return <CircaLoadingAnimationFS />
  }

  return (
    <>
      {dataResidents || noPreload ? (
        <GenericForm
          formClass={formClass}
          submitButtonText={submitButtonText}
          onSubmit={formValues => {
            onSubmit({ dataResidents, refetchResidents, ...formValues })
          }}
          formFields={formFields}
        />
      ) : (
        <CircaLoadingAnimationFS />
      )}
    </>
  )
}
export default OrgnFormProfile
