import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"

import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import {
  calculateAccountSetupPercentage,
  consoleLog,
  handleApiError,
  SessionStorage
} from "../../../utils"
import { redirectPerLeaseSetupProgress } from "../../../utils/logic"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import GenericForm from "../../../components/elements/generic-form"
import { GraphicLeaseInformation } from "../../../assets"

import { APIhandler } from "../../../utils/api"
import { useDashboardQueries } from "../../../utils/queries"

import "./lease-information.scss"
import { showModalHelp } from "../../../components/modals/modal-help"

const ScreenSetupLeaseId = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalBreadcrumb,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup
  } = useOutletContext()

  const { hash, pathname, state: fwdState } = useLocation()
  const nav = useNavigate()

  const [leaseId, setLeaseId] = useState("")
  const [pinCode, setPinCode] = useState("")
  const [formValid, setFormValid] = useState(false)
  const [referralCodeAdded, setReferralCodeAdded] = useState(false)

  useEffect(() => {
    if (hash === ALLROUTES.hashUrls.help) {
      showModalHelp({
        setModalBreadcrumb,
        setModalComponent,
        setModalVisibility
      })
    }
  }, [hash])

  useEffect(() => {
    document.body.classList.add("page__lease-information")
    setContainerClass("screen__lease-information")
    setDashboardProgressPerc(calculateAccountSetupPercentage(pathname))
    if (APPCONFIG.debugGlobal && APPCONFIG.debugRedirection)
      consoleLog(`[SCREEN] lease-information`)
    return () => {
      document.body.classList.remove("page__lease-information")
      setContainerClass("")
      setDashboardProgressPerc(0)
    }
  }, [])

  useEffect(() => {
    if (fwdState?.showWelcomeBackPopup) {
      showDashboardPopup(dashboardPopups.WelcomeBack)
    }
  }, [])

  const formFields = [
    {
      label: "Enter Lease ID",
      hLikeLabel: true,
      helpTooltipPlacement: "right-start",
      HelpTooltip: () => (
        <>
          <h2 className="h1">Lease ID</h2>
          <h3>Participating Communities</h3>
          <p>
            If your community uses Circa, you should have received a letter with
            your Lease ID.
          </p>
          <p>
            If you did not receive a letter, cannot find your Lease ID, or are
            unsure whether your community uses Circa, email us anytime at{" "}
            <a href={ALLROUTES.supportEmailLink}>{APPCONFIG.supportEmail}</a> or
            call us during business hours at{" "}
            <a href={ALLROUTES.supportTelephoneLink}>
              {APPCONFIG.supportTelephone}
            </a>
            .
          </p>
          <h3>Individual Residents</h3>
          <p>
            Don&#8217;t live in a community currently using Circa? Recommend
            Circa to your property. If they sign up, we&#8217;ll cover your rent
            for a month!
            <br />
            <a href={APPCONFIG.staticLinks.individualResidents} target="_blank">
              Click here to learn more.
            </a>
          </p>
        </>
      ),
      name: "leaseId",
      compType: "n-char-code",
      charCount: 7,
      dashPos: 3,
      rules: {
        required: true,
        minLength: 7,
        maxLength: 7
      },
      onChange: fieldValue => {
        setLeaseId(fieldValue)
      }
    },
    {
      label: "Enter Zip Code",
      hLikeLabel: true,
      name: "pinCode",
      compType: "n-digit-code",
      digitCount: 5,
      rules: {
        required: true,
        minLength: 5,
        maxLength: 5
      },
      onChange: fieldValue => {
        setPinCode(fieldValue)
      }
    }
  ]

  const referralCodeFormFields = [
    {
      label: "Referral Code",
      showLabel: false,
      name: "referralCode",
      compType: "n-char-code",
      charCount: 6,
      rules: {
        required: true,
        minLength: 6,
        maxLength: 6
      }
    }
  ]

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  useEffect(() => {
    if ((dataResidents || errorResidents) && !isFetchingResidents) {
      const redirectApplicable = redirectPerLeaseSetupProgress({
        nav,
        pathname,
        dataResidents,
        errorResidents
      })
      if (!!redirectApplicable) redirectApplicable()
    }
  }, [dataResidents, isFetchingResidents, isErrorResidents])

  const onReferralCodeSubmit = formValues => {
    setMessageBubbleErrorMessage("")
    APIhandler.account_setup
      .get_referral_resident_id({
        residentId: dataResidents?.data?.residentData?.id,
        referralCode: formValues?.referralCode
      })
      .then(response => {
        if (response?.status === 200) {
          SessionStorage.write(
            APPCONFIG.variables.referralResidentId,
            response?.data?.residentId
          )
          setReferralCodeAdded(true)
          setMessageBubbleVisibility(false)
        } else {
          setMessageBubbleErrorMessage(
            response?.data?.errordescription ||
              "An error has occurred while attempting to validate the referral code"
          )
        }
      })
      .catch(axiosError => {
        setMessageBubbleErrorMessage(
          axiosError?.response?.data?.errordescription ||
            "This code is invalid. Please try again."
        )
        setTimeout(() => {
          setMessageBubbleErrorMessage("")
        }, 5000)
      })
    return
  }

  const handleClickAddReferralCode = () => {
    APIhandler.account_setup
      .get_referral_amount({ leaseId, pinCode })
      .then(response => {
        showAddReferralCodePopup({
          referralAmount: response?.data?.referralAmount
        })
      })
      .catch(axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        if (errorData?.errordescription) {
          errorData.errordescription = errorData?.errordescription.replace(
            "Please try again",
            `Please try again or <a href="${ALLROUTES.hashUrls.help}">contact us</a>.`
          )
        }
        handleApiError({
          customHeading: "Unable to recognize Lease ID",
          nav,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          ...errorData
        })
      })
  }

  const showAddReferralCodePopup = ({ referralAmount }) => {
    setMessageBubbleComponent(() => {
      return (
        <>
          <h2 className="h1">Get ${referralAmount} with Referral Code!</h2>
          <p>
            Enter your referral code below to get ${referralAmount} off your
            first rent payment.
          </p>
          <GenericForm
            formClass={""}
            submitButtonText={"Apply Code"}
            submitButtonW={"auto"}
            onSubmit={onReferralCodeSubmit}
            formFields={referralCodeFormFields}
          />
        </>
      )
    })
    setMessageBubbleVisibility(true)
  }

  const onSubmit = formValues => {
    nav(ALLROUTES.accountSetupChildren.leaseDetails, {
      state: { leaseId, pinCode }
    })
  }

  return (
    <FullScreenCentered className={`w-100`} vw100={false}>
      <div className="generic-form-wrap narrow-centered text-center mb-4">
        <h1 className="billboard">Lease Information</h1>
        <GraphicLeaseInformation />
        <GenericForm
          formClass={""}
          submitButtonText={"Next"}
          onSubmit={onSubmit}
          formFields={formFields}
          setFormValid={setFormValid}
        />
        {referralCodeAdded ? (
          <p className="referral-code-added pt-4">
            Referral code successfully added
          </p>
        ) : (
          <button
            className="btn btn-bordered d-block mx-auto w-100"
            onClick={handleClickAddReferralCode}
            disabled={!formValid}
          >
            Add Referral Code
          </button>
        )}
      </div>
    </FullScreenCentered>
  )
}

export default ScreenSetupLeaseId
