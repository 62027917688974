import React, { useEffect, useRef, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Form,
  FormGroup,
  FormControl,
  InputGroup,
  Overlay,
  Tooltip
} from "react-bootstrap"

import { APPCONFIG } from "../../config"
import SubmitButton from "./form/submit-button"
import CheckboxSwitch from "./form/checkbox-switch"
import CheckboxButton from "./form/checkbox-button"
import CheckboxSelect from "./form/checkbox-select"
import RadioGroup from "./form/radio-group"
import TextField from "./form/text-field"
import SubmitButtonPair from "./form/submit-button-pair"
import HelpIconWithTooltip from "./form/help-icon-with-tooltip"

import "./generic-form.scss"
import "./form/elements.scss"
import PasswordField from "./form/password-field"

const GenericForm = ({
  formFields,
  formClass,

  StripeCardElement,

  submitButtonClass,
  submitButtonText,
  submitButtonW,
  onSubmit,

  hasSecondaryButton = false,
  secondaryButtonClass = "",
  secondaryButtonAction,
  secondaryButtonText,

  defaultValues,
  setFormValid
}) => {
  const forms = formFields

  const {
    control,
    watch,
    setError,
    formState: { errors },
    clearErrors,
    trigger,
    setValue,
    getValues,
    handleSubmit
  } = useForm()

  const formValues = watch()

  const maskDob = event => {
    const dob = getValues(event.target.name)
    if (dob.length === 2 || dob.length === 5) {
      setValue(event.target.name, `${dob}/`)
    }
  }

  const maskEmail = event => {
    const email = getValues(event.target.name)
    setValue(event.target.name, email.toLowerCase())
  }

  const maskNCharCode = event => {
    const element = event.target
    const elementValue = getValues(element.name)
    const sanitizedElementValue = elementValue
      .replace(/[^a-zA-Z\d]/g, "")
      .toUpperCase()
    event.target.value = sanitizedElementValue
    setValue(event.target.name, sanitizedElementValue)
  }

  const maskNDigitCode = event => {
    const element = event.target
    const elementValue = getValues(element.name)
    const sanitizedElementValue = elementValue.replace(/[^\d]/g, "")
    event.target.value = sanitizedElementValue
    setValue(event.target.name, sanitizedElementValue)
  }

  const maskPhone = event => {
    const phone = getValues(event.target.name)
    if (phone.length === 3 || phone.length === 7) {
      setValue(event.target.name, `${phone}-`)
    }
  }

  const onKeyDownHandle = event => {
    if (event.target.classList.contains("form-control--n-digit-code")) {
      if (event.target.classList.contains("char")) maskNCharCode(event)
      else maskNDigitCode(event)
    }
    if (
      event.target.classList.contains("form-control--dob") ||
      event.target.classList.contains("form-control--card-expiry-date")
    ) {
      maskDob(event)
    }
  }

  const onKeyUpHandle = event => {
    if (event.target.classList.contains("form-control--n-digit-code")) {
      if (event.target.classList.contains("char")) maskNCharCode(event)
      else maskNDigitCode(event)
    }
    if (
      event.target.classList.contains("form-control--dob") ||
      event.target.classList.contains("form-control--card-expiry-date")
    ) {
      maskDob(event)
    }
    if (event.target.classList.contains("form-control--email")) {
      maskEmail(event)
    }
    if (
      event.keyCode !== 8 &&
      ["phone", "tel"].indexOf(event.target.getAttribute("type")) > -1
    ) {
      maskPhone(event)
    }
  }

  useEffect(() => {
    if (APPCONFIG.debugForms) {
      console.log("VALUES", formValues)
      console.log("ERRORS", errors)
    }
    if (typeof setFormValid !== "undefined") {
      setFormValid(Object.keys(errors).length === 0)
    }
  }, [formValues])

  useEffect(() => {
    for (let key in defaultValues) {
      setValue(key, defaultValues[key])
      trigger(key)
    }
  }, [defaultValues])

  useEffect(() => {
    trigger()
  }, [])

  const getRender = formField => {
    let render
    let {
      showLabel,
      compType,
      digitCount,
      HelpTooltip,
      helpTooltipPlacement,
      helpTooltipOverModal,
      charCount,
      dashPos,
      hLikeLabel,
      ...formFieldProps
    } = formField
    switch (formField.compType) {
      case "checkboxButton":
        render = ({ field }) => (
          <CheckboxButton
            {...field}
            label={formField.label}
            subLabel={formField.subLabel}
            trigger={() => trigger(formField.name)}
          />
        )
        break

      case "checkbox-select":
        render = ({ field }) => (
          <CheckboxSelect
            items={formField.items}
            formField={formField}
            {...field}
            onChange={event => {
              field.onChange?.(event.target.value)
              formField?.onChange?.(event.target.value)
              trigger(formField.name)
            }}
            selectedItem={field.value}
          />
        )
        break

      case "checkbox-switch":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <FormGroup
            className={`form-field form-field--${
              formField.compType
            }${` ${formField?.formFieldClassName} `}d-flex flex-row${
              invalid && isDirty ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}`}
          >
            <CheckboxSwitch
              {...formFieldProps}
              label={formField.label}
              LabelComponent={formField.LabelComponent}
              onChange={event => {
                field.onChange?.(event?.target?.checked)
                formField?.onChange?.(event?.target?.checked)
                trigger(formField.name)
              }}
            />
          </FormGroup>
        )
        break

      case "custom":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <formField.component
            {...{ field, invalid, isDirty, error }}
            trigger={() => trigger(formField.name)}
          />
        )
        break

      case "card-expiry-date":
      case "dob":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <FormGroup
            className={`form-field form-field--${formField.compType}${` ${formField?.formFieldClassName} `}col-sm-12${
              invalid && isDirty ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}`}
          >
            {formField.label ? (
              <Form.Label className="field-label">{formField.label}</Form.Label>
            ) : (
              ""
            )}
            <FormControl
              className={`form-control--${formField.compType} ${
                invalid && isDirty ? "is-invalid" : ""
              }`}
              {...field}
              {...formFieldProps}
              onChange={event => {
                if (compType === "card-expiry-date") {
                  const cardExpiryDate = new Date(event.target.value).getTime()
                  if (
                    (event.target.value.length === 10 &&
                      cardExpiryDate > new Date().getTime()) ||
                    event.target.value.length < 10
                  ) {
                    field.onChange?.(event.target.value)
                    formField?.onChange?.(event.target.value)
                    trigger(formField.name)
                  }
                } else {
                  const ageYears =
                    new Date().getTime() -
                    new Date(event.target.value).getTime()
                  if (
                    (event.target.value.length === 10 &&
                      ageYears / 1000 / 60 / 60 / 24 / 365 >
                        APPCONFIG.minimumAge &&
                      ageYears / 1000 / 60 / 60 / 24 / 365 <
                        APPCONFIG.maximumAge) ||
                    event.target.value.length < 10
                  ) {
                    field.onChange?.(event.target.value)
                    formField?.onChange?.(event.target.value)
                    trigger(formField.name)
                  }
                }
              }}
              onKeyUp={e => {
                onKeyUpHandle(e)
              }}
              type={formField.type || "text"}
              placeholder={formField?.placeholder || "MM/DD/YYYY"}
            />
            {error && (
              <Form.Control.Feedback type="invalid">
                {error?.message}
              </Form.Control.Feedback>
            )}
          </FormGroup>
        )
        break

      case "dropdown":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <Form.Group
            className={`form-field${` ${formField?.formFieldClassName} `}col-sm-12${
              error ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}`}
          >
            <Form.Label className="field-label">{formField.label}</Form.Label>
            <Form.Select
              onChange={e => {
                field.onChange(formField.items[e.target.value])
                trigger(formField.name)
              }}
              value={formField.items?.findIndex(
                e => e.label === field.value.label
              )}
              placeholder={formField.placeholder}
            >
              <option>{formField.placeholder || formField.label}</option>
              {formField.items.map((item, i) => (
                <option value={i} key={i}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )
        break

      case "radio-group":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <FormGroup
            className={`form-field form-field--${
              formField.compType
            }${` ${formField?.formFieldClassName} `}d-flex flex-column${
              invalid && isDirty ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}`}
          >
            <RadioGroup
              items={formField.items}
              formField={formField}
              {...formFieldProps}
              onChange={event => {
                field.onChange?.(event?.target?.value)
                formField?.onChange?.(event?.target?.value)
                trigger(formField.name)
              }}
            />
          </FormGroup>
        )
        break

      case "phone":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <FormGroup
            className={`form-field form-field--${formField.compType}${` ${formField?.formFieldClassName} `}col-sm-12${
              invalid && isDirty ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}`}
          >
            {formField.label ? (
              <Form.Label className="field-label">{formField.label}</Form.Label>
            ) : (
              ""
            )}
            <InputGroup>
              <InputGroup.Text>
                <span className="flag flag-us"></span>
                +1
              </InputGroup.Text>
              <FormControl
                className={`${invalid && isDirty ? "is-invalid" : ""}`}
                {...field}
                {...formFieldProps}
                onChange={event => {
                  field.onChange?.(event.target.value)
                  formField?.onChange?.(event.target.value)
                  trigger(formField.name)
                }}
                onKeyDown={e => {
                  onKeyUpHandle(e)
                }}
                type={"tel"}
                placeholder={formField?.placeholder || ""}
                maxLength={formFieldProps?.rules?.maxLength || 12}
              />
            </InputGroup>
            {error && (
              <Form.Control.Feedback type="invalid">
                {error?.message}
              </Form.Control.Feedback>
            )}
          </FormGroup>
        )
        break

      case "textarea":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <div
            className={`form-field${` ${formField?.formFieldClassName} `}textarea position-relative${
              error ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}`}
          >
            <label className="field-label">{formField.label}</label>
            <textarea
              className="mx-auto d-block"
              type={formField.type || "text"}
              value={field.value}
              onChange={event => {
                field?.onChange?.(event.target.value)
                formField?.onChange?.(event.target.value)
                trigger(formField.name)
              }}
              placeholder={formField.placeholder || "placeholder"}
            />
          </div>
        )
        break

      case "n-char-code":
      case "n-digit-code":
        charCount = formField.charCount || formField.digitCount || 4
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <FormGroup
            className={`form-field form-field--n-digit-code${` ${formField?.formFieldClassName} `}col-sm-12${
              invalid && isDirty ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}${
              formField.showLabel !== false ? "" : " no-label"
            }${formField.hLikeLabel === true ? " h-like-label" : ""}`}
          >
            {formField.showLabel !== false ? (
              <Form.Label className="field-label">
                {formField.label}
                {formField.HelpTooltip ? (
                  <HelpIconWithTooltip
                    isOverModal={formField.helpTooltipOverModal}
                    placement={formField.helpTooltipPlacement}
                    TooltipComponent={formField.HelpTooltip}
                    formField={formField}
                  />
                ) : (
                  ""
                )}
              </Form.Label>
            ) : (
              ""
            )}
            <Form.Control
              className={`form-control--n-digit-code char ${
                invalid && isDirty ? "is-invalid" : ""
              }`}
              {...field}
              {...formFieldProps}
              onChange={event => {
                const lastChar =
                  event.target.value[event.target.value.length - 1]
                const charCheck =
                  formField.compType.indexOf("char") > -1
                    ? new RegExp(/^[a-zA-Z0-9]+$/).test(lastChar)
                    : new RegExp(/^[0-9]+$/).test(lastChar)
                if (
                  (event.target.value.length <= charCount && charCheck) ||
                  event.target.value === ""
                ) {
                  field.onChange(event.target.value)
                  formField?.onChange?.(event.target.value)
                  trigger(formField.name)
                  clearErrors("eitherPhoneOrEmail")
                }
              }}
              onKeyDown={e => {
                onKeyDownHandle(e)
              }}
              onKeyUp={e => {
                onKeyUpHandle(e)
              }}
              type={"text"}
              pattern={`[${
                formField.compType.indexOf("char") > -1 ? "a-zA-Z" : ""
              }0-9]{${charCount}}`}
              minLength={charCount}
              maxLength={charCount}
              disabled={formField?.disabled || ""}
              autoComplete="off"
            />
            <div className="field-display--n-digit-code">
              {"x"
                .repeat(charCount)
                .split("")
                .map((_, i) => (
                  <span
                    key={i}
                    className={`${
                      (formValues[formField.name] || "").length === i
                        ? "active"
                        : ""
                    }${
                      formField?.dashPos && formField?.dashPos === i
                        ? " dash-before"
                        : ""
                    }`}
                  >
                    {String(formValues[formField.name] || "").charAt(i)}
                  </span>
                ))}
            </div>
            {error && (
              <Form.Control.Feedback type="invalid">
                {error?.message}
              </Form.Control.Feedback>
            )}
          </FormGroup>
        )
        break

      case "stripe-card-element":
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <FormGroup
            className={`form-field form-field--${formField.compType}${` ${formField?.formFieldClassName} `}col-sm-12`}
          >
            <Form.Label
              className={`field-label${
                formField.HelpTooltip ? " has-tooltip" : ""
              }`}
            >
              {formField.label}
              {formField.HelpTooltip ? (
                <HelpIconWithTooltip
                  isOverModal={formField.helpTooltipOverModal}
                  placement={formField.helpTooltipPlacement}
                  TooltipComponent={formField.HelpTooltip}
                  formField={formField}
                />
              ) : (
                ""
              )}
            </Form.Label>
            <StripeCardElement />
          </FormGroup>
        )
        break

      default:
        render = ({ field, fieldState: { invalid, isDirty, error } }) => (
          <FormGroup
            className={`form-field form-field--${formField.type}${` ${formField?.formFieldClassName} `}col-sm-12${
              invalid && isDirty ? " form-field--invalid" : ""
            }${formField?.readOnly ? " form-field--readonly" : ""}`}
          >
            <Form.Label
              className={`field-label${
                formField.HelpTooltip ? " has-tooltip" : ""
              }`}
            >
              {formField.label}
              {formField.HelpTooltip ? (
                <HelpIconWithTooltip
                  isOverModal={formField.helpTooltipOverModal}
                  placement={formField.helpTooltipPlacement}
                  TooltipComponent={formField.HelpTooltip}
                  formField={formField}
                />
              ) : (
                ""
              )}
            </Form.Label>
            {formField.type === "password" ? (
              <PasswordField
                field={field}
                formField={formField}
                invalid={invalid}
                isDirty={isDirty}
                error={error}
                onChange={event => {
                  field.onChange(event.target.value)
                  formField?.onChange?.(event.target.value)
                  trigger(formField.name)
                  clearErrors("eitherPhoneOrEmail")
                }}
                onKeyUpHandle={onKeyUpHandle}
              />
            ) : (
              <Form.Control
                className={`form-control--${formField.type}${
                  invalid && isDirty ? " is-invalid" : ""
                }`}
                {...field}
                {...formFieldProps}
                onChange={event => {
                  field.onChange(event.target.value)
                  formField?.onChange?.(event.target.value)
                  trigger(formField.name)
                  clearErrors("eitherPhoneOrEmail")
                }}
                onKeyUp={onKeyUpHandle}
                type={formField.type || "text"}
                placeholder={formField?.placeholder || ""}
                disabled={formField?.disabled || ""}
              />
            )}

            {errors?.eitherPhoneOrEmail && (
              <p type="invalid" className="either-phone-email-error">
                {errors?.eitherPhoneOrEmail?.message}
              </p>
            )}
            {error && (
              <Form.Control.Feedback type="invalid">
                {error?.message}
              </Form.Control.Feedback>
            )}
          </FormGroup>
        )
        break
    }
    return render
  }

  return (
    <div className="generic-form d-flex flex-column">
      <form className={formClass}>
        {forms.map((e, i) => (
          <Controller
            key={i}
            defaultValue={e.defaultValue || ""}
            disabled={e.disabled || ""}
            name={e.name}
            control={control}
            rules={e.rules}
            render={getRender(e)}
            placeholder={e.placeholder || ""}
          />
        ))}

        <SubmitButton
          className={submitButtonClass}
          text={submitButtonText}
          width={submitButtonW || "100"}
          disabled={!!Object.keys(errors).length}
          onClick={() => onSubmit(formValues)}
        />

        {hasSecondaryButton && (
          <SubmitButton
            className={secondaryButtonClass}
            text={secondaryButtonText}
            width={submitButtonW || "100"}
            disabled={!!Object.keys(errors).length}
            onClick={() => secondaryButtonAction(formValues)}
          />
        )}
      </form>
    </div>
  )
}

export default GenericForm
