import React from "react"

import { AllIcons, GraphicWearecircaCashApp } from "../../../assets"

const OrgnInfoCashApp = ({ cashAppPaymentCallback }) => {
  return (
    <>
      <div className="freeze-to-top mb-2">
        <h2 className="billboard text-center">Cash App</h2>
      </div>
      <div className="color-grayscale-graphite">
        <p className="mb-3 pb-2">
          Pay your rent with Cash App using a QR code or $wearecirca.
        </p>
        <h3>Step 1: Download Cash App</h3>
        <p className="mb-3 pb-2">
          Download Cash App on the iOS App Store or Google Play Store.
        </p>
        <h3>Step 2: Pay on Cash App</h3>
        <p className="mb-2">
          Scan the QR code below using your mobile device or search $wearecirca.
        </p>
        <p className="mb-3 pb-2">A 2.75% processing fee will apply.</p>
        <div className="d-flex justify-content-center my-4 pb-2">
          <GraphicWearecircaCashApp />
        </div>
        <div className="color-grayscale-iron text-center">
          <h1 className="color-grayscale-iron d-flex justify-content-center mb-0">
            Circa
            <AllIcons.BadgeBuilding className={`ms-2`} />
          </h1>
          <p className="mb-0">$wearecirca</p>
          <p className="mt-3">Payment Note: XXX-XXXX</p>
        </div>
        {cashAppPaymentCallback && (
          <>
            <div className="btns-wrap d-flex widget">
              <button
                className="btn btn-primary flex-grow-1"
                onClick={() => {
                  cashAppPaymentCallback()
                }}
              >
                Continue with Cash App
              </button>
            </div>
            <p className="smaller-text mt-4 mb-0 text-center">
              Access this info anytime in
              <br />
              Settings &gt; Payment Methods.
            </p>
          </>
        )}
      </div>
    </>
  )
}
export default OrgnInfoCashApp
