import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"

import { ALLMENUS } from "../../../routes"

import { showModalHelp } from "../../modals/modal-help"

import "./settings-nav.scss"

const SettingsNav = () => {
  const {
    navSettingsActiveItem,
    setNavSettingsActiveItem,
    setModalBreadcrumb,
    setModalComponent,
    setModalVisibility
  } = useOutletContext()

  const { pathname } = useLocation()
  const nav = useNavigate()

  const handleOnClick = navItem => {
    if (navItem?.route === "[helpPopup]") {
      showModalHelp({
        setModalBreadcrumb,
        setModalComponent,
        setModalVisibility
      })
      return
    }
    setNavSettingsActiveItem(navItem)
    if (!!navItem.onClick) {
      navItem.onClick()
    } else if (!!navItem.route) {
      nav(navItem.route)
    }
  }

  return (
    <nav className="settings-nav">
      <ul className="d-flex flex-column">
        {ALLMENUS.settings.map((navItem, i) => (
          <li
            key={i}
            className={
              navSettingsActiveItem?.route === navItem?.route ? "active" : ""
            }
          >
            <a
              className="h3 clickable"
              onClick={() => {
                handleOnClick(navItem)
              }}
            >
              {navItem.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}
export default SettingsNav
