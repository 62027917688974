import React from "react"

import OrgnWizardPaymentDetails from "./wizard-payment-details"

import "../plan/plan.scss"
import "./payment.scss"

export const showModalPaymentDetails = ({
  breadcrumbItems = [],
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  formattedPaymentDate,
  paymentId,
  arrearsPaymentId
}) => {
  setModalClass("modal--payment-details narrow-content")
  setModalBreadcrumb([
    ...breadcrumbItems,
    { label: `${formattedPaymentDate} Payment` }
  ])
  setModalComponent(() => (
    <OrgnWizardPaymentDetails
      paymentId={paymentId}
      arrearsPaymentId={arrearsPaymentId}
    />
  ))
  setModalVisibility(true)
}
