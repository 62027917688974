import React from "react"

import { APPCONFIG } from "../../../config"

import OrgnWizardPayment from "./wizard-payment"

import "../plan/plan.scss"
import "./payment.scss"

export const showModalMakeAPayment = ({
  refetchDashboard,
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  walletInformation
}) => {
  setModalBreadcrumb([{ label: "" }])
  setModalClass("modal--make-a-payment narrow-content")
  setModalComponent(() => (
    <div className="modal-content-height d-flex flex-column">
      <OrgnWizardPayment
        refetchDashboard={refetchDashboard}
        setMessageBubbleComponent={setMessageBubbleComponent}
        setMessageBubbleVisibility={setMessageBubbleVisibility}
        walletInformation={walletInformation}
      />
    </div>
  ))
  setModalVisibility(true)
}
