import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Dropdown } from "react-bootstrap"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import { AnimationLoadingFullScreen } from "../../../assets"
import {
  ADDMETHODSLUG,
  ADDMETHODSLUG_BANK,
  ADDMETHODSLUG_CARD,
  APPCONFIG,
  PLANPAYMENTSETTINGS
} from "../../../config"
import { handleize, numberWithOrdinal, pluralDayName } from "../../../utils"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"
import { logicPlan } from "../../../utils/logic-plan"

import HelpIconWithTooltip from "../../elements/form/help-icon-with-tooltip"
import { showPlanModal } from "./plan"
import { TooltipContentPaymentMethods } from "../tooltip-content/payment-methods"

import {
  useAccountSetupQueries,
  useDashboardQueries,
  usePlanQueries
} from "../../../utils/queries"
import { usePlanMutation } from "../../../utils/mutations"

import "./plan.scss"
import { ALLROUTES } from "../../../routes"
import { showModalBankInformation } from "../bank-information/bank-information"
import { showModalCardInformation } from "../card-information/card-information"

export const showScheduleExceptionModal = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  leasePlanDetails,
  refetchLeasePlanDetails,
  refetchDashboard,
  contractPaymentId = null
}) => {
  setModalClass("modal--plan-schedule-exception narrow-content")
  setModalBreadcrumb([
    {
      label: "Plan Summary",
      onClick: () => {
        showPlanModal({
          setModalClass,
          setModalBreadcrumb,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          leasePlanDetails
        })
      }
    },
    { label: "Schedule an Exception" }
  ])
  setModalComponent(() => {
    const nav = useNavigate()

    const [updateInProgress, setUpdateInProgress] = useState(false)

    const [selectedPlan, setSelectedPlan] = useState(null)
    const [showPlanDescription, setShowPlanDescription] = useState(false)

    const [selectedSchedule, setSelectedSchedule] = useState(null)
    const [showScheduleDescription, setShowScheduleDescription] =
      useState(false)

    const [paymentStartDate, setPaymentStartDate] = useState(null)
    const [planPaymentDates, setPlanPaymentDates] = useState([])
    const [planPaymentDay, setPlanPaymentDay] = useState(null)

    const [allPaymentMethods, setAllPaymentMethods] = useState(null)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
    const [selectedPlanPaymentSetting, setPlanPaymentSetting] = useState(
      PLANPAYMENTSETTINGS?.[0]?.slug
    )

    const [selectedExceptionMonth, setSelectedExceptionMonth] = useState(null)

    const { status: statusResidents, data: dataResidents } =
      useDashboardQueries.useResidentsQuery({})

    const {
      status: statusExceptionMonthsList,
      data: dataExceptionMonthsList,
      isFetching: isFetchingExceptionMonthsList,
      isError: isErrorExceptionMonthsList
    } = usePlanQueries.useExceptionMonthsListQuery({
      config: { refetchOnMount: true },
      enabled: statusResidents === "success",
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
    })

    const {
      status: statusAvailablePaymentPlans,
      data: dataAvailablePaymentPlans,
      isFetching: isFetchingAvailablePaymentPlans,
      isError: isErrorAvailablePaymentPlans
    } = usePlanQueries.useAvailablePaymentPlansForLeasePaymentQuery({
      config: { refetchOnMount: true },
      enabled:
        statusResidents === "success" &&
        statusExceptionMonthsList === "success" &&
        !!selectedExceptionMonth,
      residentId: dataResidents?.data?.residentData?.id,
      leasepaymentId: selectedExceptionMonth?.contractPaymentId
    })

    const {
      status: statusResidentLeasePaymentMethods,
      data: dataResidentLeasePaymentMethods,
      isFetching: isFetchingResidentLeasePaymentMethods,
      isError: isErrorResidentLeasePaymentMethods
    } = useDashboardQueries.useResidentLeasePaymentMethodsQuery({
      config: { refetchOnMount: true },
      enabled: statusResidents === "success",
      residentId: dataResidents?.data?.residentData?.id,
      leaseId:
        leaseIdInSetup.get() ||
        leaseIdOnDash.get() ||
        dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
    })

    useEffect(() => {
      logicPlan.onUpdateSelectedPlan({ selectedPlan, setSelectedSchedule })
    }, [selectedPlan])

    useEffect(() => {
      logicPlan.onUpdateSelectedSchedule({
        selectedSchedule,
        setPaymentStartDate,
        setPlanPaymentDates,
        setPlanPaymentDay
      })
    }, [selectedSchedule])

    useEffect(() => {
      if (APPCONFIG.debugGlobal)
        console.log("dataExceptionMonthsList", dataExceptionMonthsList)
      if (
        !!dataExceptionMonthsList &&
        dataExceptionMonthsList?.data?.exceptionMonthListviewObj?.monthList
          ?.length > 0 &&
        selectedExceptionMonth === null
      ) {
        setSelectedExceptionMonth(
          dataExceptionMonthsList?.data?.exceptionMonthListviewObj?.monthList?.find(
            item => item?.contractPaymentId === contractPaymentId
          ) ||
            dataExceptionMonthsList?.data?.exceptionMonthListviewObj
              ?.monthList?.[0]
        )
      }
    }, [dataExceptionMonthsList])

    useEffect(() => {
      logicPlan.onUpdateAvailablePaymentPlans({
        dataResidents,
        nav,
        dataAvailablePaymentPlans,
        isErrorAvailablePaymentPlans,
        isFetchingAvailablePaymentPlans,
        setSelectedPlan
      })
    }, [
      dataAvailablePaymentPlans,
      isFetchingAvailablePaymentPlans,
      isErrorAvailablePaymentPlans
    ])

    useEffect(() => {
      logicPlan.onUpdateResidentLeasePaymentMethods({
        dataResidentLeasePaymentMethods,
        setAllPaymentMethods,
        setSelectedPaymentMethod
      })
    }, [
      dataResidentLeasePaymentMethods,
      isFetchingResidentLeasePaymentMethods,
      isErrorResidentLeasePaymentMethods
    ])

    const scheduleAnExceptionMutation =
      usePlanMutation.useScheduleAnExceptionMutation()

    const onScheduleAnException = () => {
      setUpdateInProgress(true)
      logicPlan.onSubmitModifyPlan({
        dataResidents,
        nav,
        planPaymentDates,
        planPaymentDay,
        selectedSchedule,
        selectedPaymentMethod,
        selectedPlanPaymentSetting,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        modifyPlanDebugSlug: `Schedule An Exception`,
        modifyPlanMutation: scheduleAnExceptionMutation,
        modifyPlanMutationExtraParams: {
          paymentId: selectedExceptionMonth?.contractPaymentId,
          applyToFutureMonths: false,
          isChangeDefaultPlan: false
        },
        onSuccess: () => {
          showPlanModal({
            setModalClass,
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            leasePlanDetails,
            refetchLeasePlanDetails,
            refetchDashboard
          })
        },
        onSettled: () => {
          setUpdateInProgress(false)
        }
      })
    }

    const deletePlanMutation = usePlanMutation.useDeletePlanMutation()

    const onDeletePlan = () => {
      setUpdateInProgress(true)
      logicPlan.onDeletePlan({
        dataResidents,
        nav,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        paymentId: selectedExceptionMonth?.contractPaymentId,
        deletePlanMutation,
        onSuccess: () => {
          showPlanModal({
            setModalClass,
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            leasePlanDetails,
            refetchLeasePlanDetails,
            refetchDashboard
          })
        },
        onSettled: () => {
          setUpdateInProgress(false)
        }
      })
    }

    useEffect(() => {
      if (selectedPaymentMethod?.type === ADDMETHODSLUG_BANK) {
        showModalBankInformation({
          onSubmitCallback: () => {
            showScheduleExceptionModal({
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              setMessageBubbleComponent,
              setMessageBubbleVisibility,
              leasePlanDetails,
              refetchLeasePlanDetails,
              refetchDashboard
            })
          },
          updateInProgress,
          setUpdateInProgress,
          setModalBreadcrumb,
          setModalClass,
          setModalComponent,
          setModalVisibility
        })
      }
      if (selectedPaymentMethod?.type === ADDMETHODSLUG_CARD) {
        showModalCardInformation({
          onSubmitCallback: () => {
            showScheduleExceptionModal({
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              setMessageBubbleComponent,
              setMessageBubbleVisibility,
              leasePlanDetails,
              refetchLeasePlanDetails,
              refetchDashboard
            })
          },
          setModalBreadcrumb,
          setModalClass,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility
        })
      }
    }, [selectedPaymentMethod])

    if (
      updateInProgress ||
      isFetchingAvailablePaymentPlans ||
      isFetchingResidentLeasePaymentMethods
    ) {
      return <AnimationLoadingFullScreen minVh100={false} />
    }

    return (
      <>
        <div className="widget freeze-to-top">
          <h2 className="billboard text-center mb-4 mx-n2">
            Schedule an Exception
          </h2>
        </div>
        <div className="widget">
          {dataExceptionMonthsList?.data?.exceptionMonthListviewObj?.monthList
            ?.length > 0 && (
            <>
              <h3 className="d-flex align-items-center justify-content-between">
                Month
              </h3>
              <div className="plan-payment-options-wrap">
                <div className="plan-payment-options block low-elevation br-medium">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <div className="payment-option">
                        <div className="payment-option__icon"></div>
                        <div className="payment-option__title">
                          {selectedExceptionMonth?.month || "Select a month"}
                        </div>
                        <div className="payment-option__caret"></div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {dataExceptionMonthsList?.data?.exceptionMonthListviewObj?.monthList?.map(
                        (exceptionMonth, i) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedExceptionMonth(exceptionMonth)
                              }}
                              key={i}
                              className={
                                exceptionMonth?.isDisabled
                                  ? "appears-disabled"
                                  : ""
                              }
                            >
                              {exceptionMonth?.month}
                            </Dropdown.Item>
                          )
                        }
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </div>
        {!!selectedExceptionMonth && (
          <>
            <div className="widget">
              <h3 className="d-flex align-items-center justify-content-between">
                Plan
                <span
                  className="icon-help"
                  onClick={() => {
                    setShowPlanDescription(!showPlanDescription)
                  }}
                ></span>
              </h3>
              <div
                className={`option-selector${
                  showPlanDescription ? " show-description" : ""
                }`}
              >
                {dataAvailablePaymentPlans?.data?.data?.map((plan, i) => (
                  <div
                    className={`option-wrap block block--selectable low-elevation br-medium${
                      plan.planTypeId === selectedPlan?.planTypeId
                        ? " selected"
                        : ""
                    }${plan.isAvailable === false ? " disabled" : ""}`}
                    data-plan={plan.planTypeId}
                    onClick={() => {
                      setSelectedPlan(plan)
                    }}
                    key={i}
                  >
                    <div
                      className={`plan plan--${handleize(
                        plan.planTypeName,
                        ""
                      )}`}
                    >
                      <div className="plan__icon"></div>
                      <div className="plan__label">
                        <div className="plan__title">
                          {plan.planTypeDisplayName}
                        </div>
                        <div className="plan__subtitle">
                          {plan.feesDescription}
                        </div>
                      </div>
                    </div>
                    <div className="option-description">
                      {plan.planTypeDescription}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {selectedPlan?.paymentPlans?.length > 1 && (
              <div className="widget">
                <h3 className="d-flex align-items-center justify-content-between">
                  Schedule
                  <span
                    className="icon-help"
                    onClick={() => {
                      setShowScheduleDescription(!showScheduleDescription)
                    }}
                  ></span>
                </h3>
                <div
                  className={`option-selector${
                    showScheduleDescription ? " show-description" : ""
                  }`}
                >
                  {selectedPlan?.paymentPlans
                    ?.sort((a, b) => a.plansSortOrder - b.plansSortOrder)
                    .map((schedule, i) => (
                      <div
                        className={`option-wrap schedule--${handleize(
                          schedule.planName.replace("&", "and")
                        )} block block--selectable low-elevation br-medium${
                          schedule.planId === selectedSchedule?.planId
                            ? " selected"
                            : ""
                        }`}
                        data-schdeule={schedule.planId}
                        onClick={() => {
                          setSelectedSchedule(schedule)
                        }}
                        key={i}
                      >
                        <div className="plan">
                          <div className="plan__icon"></div>
                          <div className="plan__label">
                            <div className="plan__title">
                              {schedule.planName}
                            </div>
                          </div>
                        </div>
                        <div className="option-description">
                          {schedule.planDescription}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!!selectedPlan &&
              !!selectedSchedule?.paymentDates?.dayoftheweek && (
                <div className="widget">
                  <h3 className="d-flex align-items-center justify-content-between">
                    Day
                  </h3>
                  <div className="plan-payment-options-wrap">
                    <div className="plan-payment-options block low-elevation br-medium">
                      <Dropdown>
                        <Dropdown.Toggle>
                          <div className="payment-option">
                            <div className="payment-option__icon"></div>
                            <div className="payment-option__title">
                              {pluralDayName(planPaymentDay)}
                            </div>
                            <div className="payment-option__caret"></div>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <SimpleBar
                            style={{ maxHeight: "300px" }}
                            autoHide={false}
                          >
                            {selectedSchedule?.paymentDates?.dayoftheweek?.map(
                              (selectablePaymentDay, i) => (
                                <Dropdown.Item
                                  onClick={() => {
                                    logicPlan.onSelectPlanPaymentDay({
                                      startDate: paymentStartDate,
                                      newPlanPaymentDay: selectablePaymentDay,
                                      selectedSchedule,
                                      setPlanPaymentDay,
                                      setPlanPaymentDates
                                    })
                                  }}
                                  key={i}
                                >
                                  {pluralDayName(
                                    selectablePaymentDay +
                                      APPCONFIG.api.dayIndexAdjust
                                  )}
                                </Dropdown.Item>
                              )
                            )}
                          </SimpleBar>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )}
            {!!selectedPlan &&
              [
                APPCONFIG.api.planSlugs.FIRSTANDTHIRD,
                APPCONFIG.api.planSlugs.SECONDANDFOURTH,
                APPCONFIG.api.planSlugs.FOURPAY
              ].indexOf(selectedSchedule?.plan) < 0 && (
                <div className="widget">
                  <h3 className="d-flex align-items-center justify-content-between">
                    {selectedSchedule?.paymentDates?.paymentStartDate
                      ? `Start Date`
                      : `Date${
                          (selectedSchedule?.frequency || 1) * 1 > 1 ? "s" : ""
                        }`}
                  </h3>
                  <div className="plan-payment-options-wrap">
                    <div className="plan-payment-options block low-elevation br-medium">
                      {planPaymentDates
                        .filter((item, i) =>
                          selectedSchedule?.plan ===
                            APPCONFIG.api.planSlugs.ALTERNATING && i === 1
                            ? false
                            : !!item
                        )
                        .map((planPaymentDate, iDate) => (
                          <Dropdown key={iDate}>
                            <Dropdown.Toggle>
                              <div className="payment-option">
                                <div className="payment-option__icon"></div>
                                <div className="payment-option__title">
                                  {`${numberWithOrdinal(planPaymentDate)}`}
                                </div>
                                <div className="payment-option__caret"></div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <SimpleBar
                                style={{ maxHeight: "300px" }}
                                autoHide={false}
                              >
                                {(
                                  selectedSchedule?.paymentDates
                                    ?.paymentStartDate ||
                                  selectedSchedule?.paymentDates?.[
                                    `date${iDate + 1}`
                                  ]
                                )?.map((selectablePaymentDate, i) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      logicPlan.onSelectPlanPaymentDate({
                                        newPaymentDate: selectablePaymentDate,
                                        index: iDate,
                                        planPaymentDates,
                                        selectedSchedule,
                                        setPlanPaymentDates,
                                        setPlanPaymentDay
                                      })
                                    }}
                                    key={i}
                                  >
                                    {numberWithOrdinal(selectablePaymentDate)}
                                  </Dropdown.Item>
                                ))}
                              </SimpleBar>
                            </Dropdown.Menu>
                          </Dropdown>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            {!!selectedPlan && allPaymentMethods && (
              <div className="widget">
                <h3 className="d-flex align-items-center justify-content-between">
                  Method
                  <HelpIconWithTooltip
                    className={`ms-3`}
                    tooltipClass={`payment-methods-tooltip`}
                    isOverModal={true}
                    placement={`left-start`}
                    TooltipComponent={() => <TooltipContentPaymentMethods />}
                  />
                </h3>
                <div className="plan-payment-options-wrap">
                  <div className="plan-payment-options block low-elevation br-medium">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <div className="payment-option">
                          <div
                            className={`payment-option__icon icon-${selectedPaymentMethod?.type}`}
                          ></div>
                          <div className="payment-option__title">
                            {selectedPaymentMethod?.title || "Select"}
                          </div>
                          <div className="payment-option__caret"></div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {allPaymentMethods?.map((paymentMethod, i) => (
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedPaymentMethod(paymentMethod)
                            }}
                            key={i}
                          >
                            <div className="payment-option">
                              <div
                                className={`payment-option__icon icon-${paymentMethod.type}`}
                              ></div>
                              <div className="payment-option__title">
                                {paymentMethod?.title}
                              </div>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}
            {!!selectedPlan &&
              selectedPaymentMethod?.type?.indexOf(
                APPCONFIG.api.globalPaymentMethods.CASH.slug
              ) < 0 && (
                <div className="widget">
                  <h3 className="d-flex align-items-center justify-content-between">
                    Settings
                    <HelpIconWithTooltip
                      tooltipClass={`schedule-exception-plan-settings-tooltip`}
                      isOverModal={true}
                      placement={`top-end`}
                      TooltipComponent={() => (
                        <>
                          <h2 className="h1">Payment Settings</h2>
                          <h3>Autopay</h3>
                          <p>
                            With autopay, Circa will withdraw your scheduled
                            payments automatically from your account. Circa
                            always sends a reminder according to your
                            notification preferences before withdrawing any
                            funds, giving you the opportunity to make last
                            minute changes.
                          </p>
                          <h3>Manual</h3>
                          <p>
                            To pay manually, sign in and navigate to your Circa
                            Wallet each time you need to make a payment. You
                            will still receive payment reminders according to
                            your notification preferences.
                          </p>
                        </>
                      )}
                    />
                  </h3>
                  <div className={`option-selector`}>
                    {PLANPAYMENTSETTINGS.map((planPaymentSetting, i) => (
                      <div
                        className={`option-wrap plan-payment-setting--${
                          planPaymentSetting.slug
                        } block block--selectable low-elevation br-medium${
                          planPaymentSetting.slug === selectedPlanPaymentSetting
                            ? " selected"
                            : ""
                        }`}
                        data-planpaymentsetting={planPaymentSetting.slug}
                        onClick={() =>
                          setPlanPaymentSetting(planPaymentSetting.slug)
                        }
                        key={i}
                      >
                        <div className="plan">
                          <div className="plan__icon"></div>
                          <div className="plan__label">
                            <div className="plan__title">
                              {planPaymentSetting.title}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {selectedPlan && (
              <>
                {selectedExceptionMonth?.isDisabled && (
                  <div className="btns-wrap d-flex widget">
                    <button
                      className="btn btn-bordered flex-grow-1"
                      onClick={onDeletePlan}
                    >
                      Delete
                    </button>
                  </div>
                )}
                <div className="btns-wrap d-flex widget">
                  <button
                    className="btn btn-primary flex-grow-1"
                    onClick={onScheduleAnException}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </>
    )
  })
  setModalVisibility(true)
}
