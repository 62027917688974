import React from "react"
import { Outlet, useOutletContext } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"

import SettingsNav from "../elements/settings-nav/settings-nav"

import "./settings.scss"

const SettingsWrapper = () => {
  const {
    setContainerClass,
    navSettingsActiveItem,
    setNavSettingsActiveItem,
    modalVisible,
    setModalClass,
    setModalBreadcrumb,
    setModalVisibility,
    setModalComponent,
    messageBubbleVisible,
    setMessageBubbleVisibility,
    setMessageBubbleComponent,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup,
    setRedBannerVisibility,
    setRedBannerDays,
    setRedBannerCenterComponent,
    setRedBannerCta
  } = useOutletContext()

  return (
    <Container fluid className="settings-wrapper vert-offset">
      <Row className="settings-heading">
        <Col md="12">
          <h2 className="billboard">Settings</h2>
        </Col>
      </Row>
      <Row>
        <Col md="4" className="settings-wrapper__nav-col">
          <SettingsNav
            setModalComponent={setModalComponent}
            setModalVisibility={setModalVisibility}
          />
        </Col>
        <Col md="8" className="settings-wrapper__main-col">
          <Outlet
            context={{
              setContainerClass,
              navSettingsActiveItem,
              setNavSettingsActiveItem,
              modalVisible,
              setModalClass,
              setModalBreadcrumb,
              setModalVisibility,
              setModalComponent,
              messageBubbleVisible,
              setMessageBubbleVisibility,
              setMessageBubbleComponent,
              setMessageBubbleErrorMessage,
              setDashboardProgressPerc,
              dashboardPopups,
              showDashboardPopup,
              setRedBannerVisibility,
              setRedBannerDays,
              setRedBannerCenterComponent,
              setRedBannerCta
            }}
          ></Outlet>
        </Col>
      </Row>
    </Container>
  )
}
export default SettingsWrapper
