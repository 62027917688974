import React, { useEffect, useState } from "react"

import { ReactComponent as CircaLogo } from "./graphics/circa-logo.svg"
import { ReactComponent as LoadingAnimation } from "./icons/loading-animation.svg"
import { ReactComponent as BeRightBack } from "./graphics/be-right-back.svg"
import { ReactComponent as GetStarted } from "./graphics/get-started.svg"
import { ReactComponent as SignupCarousel1 } from "./graphics/signup-carousel-1.svg"
import { ReactComponent as SignupCarousel2 } from "./graphics/signup-carousel-2.svg"
import { ReactComponent as SignupCarousel3 } from "./graphics/signup-carousel-3.svg"
import { ReactComponent as YouveGotMail } from "./graphics/youve-got-mail.svg"
import { ReactComponent as LeaseInformation } from "./graphics/lease-information.svg"
import { ReactComponent as BankCheckImage } from "./graphics/bank-check-image.svg"
import { ReactComponent as NeverMissAPayment } from "./graphics/never-miss-a-payment.svg"
import { ReactComponent as PausedLease } from "./graphics/paused-lease.svg"
import { ReactComponent as NoPayments } from "./graphics/no-payments.svg"
import { ReactComponent as CreditReporting } from "./graphics/credit-reporting.svg"
import { ReactComponent as PartnerTransUnion } from "./graphics/partner-transunion.svg"
import { ReactComponent as PoweredByStripe } from "./graphics/powered-by-stripe.svg"
import { ReactComponent as CardSecurityInfo } from "./graphics/card-security-info.svg"
import { ReactComponent as WearecircaCashApp } from "./graphics/wearecirca-cash-app.svg"
import { ReactComponent as RentAssist1 } from "./graphics/rent-assist-1.svg"
import { ReactComponent as RentAssist2 } from "./graphics/rent-assist-2.svg"
import { ReactComponent as RentAssist3 } from "./graphics/rent-assist-3.svg"
import { ReactComponent as ShareCircaAndEarn } from "./graphics/share-circa-and-earn.svg"
import { ReactComponent as Notifications } from "./graphics/notifications.svg"

import { ReactComponent as WCreditReporting } from "./graphics/widget-credit-reporting.svg"
import { ReactComponent as WPaymentMethodCashApp } from "./graphics/widget-payment-method-cash-app.svg"
import { ReactComponent as WPaymentMethodCash } from "./graphics/widget-payment-method-cash.svg"
import { ReactComponent as WPaymentMethodCreditCard } from "./graphics/widget-payment-method-credit-card.svg"
import { ReactComponent as WReferral } from "./graphics/widget-referral.svg"
import { ReactComponent as WRentAssist } from "./graphics/widget-rent-assist.svg"

import { ReactComponent as PlanOnePay } from "./icons/plan-one-pay.svg"
import { ReactComponent as PlanOnePayDeferred } from "./icons/plan-one-pay-deferred.svg"
import { ReactComponent as PlanTwoPay } from "./icons/plan-two-pay.svg"
import { ReactComponent as PlanFourPay } from "./icons/plan-four-pay.svg"
import { ReactComponent as PaymentSettingAutopay } from "./icons/payment-setting-autopay.svg"
import { ReactComponent as PaymentSettingManual } from "./icons/payment-setting-manual.svg"
import { ReactComponent as PaymentMethodBank } from "./icons/payment-method-bank-account.svg"
import { ReactComponent as PaymentMethodCashApp } from "./icons/payment-method-cash-app.svg"
import { ReactComponent as PaymentMethodCash } from "./icons/payment-method-cash.svg"
import { ReactComponent as PaymentMethodCard } from "./icons/payment-mode-card.svg"
import { ReactComponent as Calendar } from "./icons/calendar.svg"
import { ReactComponent as ExclamationPoint } from "./icons/exclamation-point.svg"
import { ReactComponent as LinkNewLease } from "./icons/link-new-lease.svg"
import { ReactComponent as LeaseDetails } from "./icons/lease-details.svg"
import { ReactComponent as ViewPayments } from "./icons/view-payments.svg"
import { ReactComponent as IconNotifications } from "./icons/notifications.svg"
import { ReactComponent as PaymentOptionPayNow } from "./icons/payment-option-pay-now.svg"
import { ReactComponent as SetupRentAssist } from "./icons/setup-rent-assist.svg"
import { ReactComponent as RentAssistPercentage } from "./icons/percentage.svg"
import { ReactComponent as RentAssistDollarAmount } from "./icons/dollar-sign.svg"
import { ReactComponent as BadgeBuilding } from "./icons/badge-building.svg"

import { ReactComponent as InappAlarmBell } from "./icons/inapp/alarmBell.svg"
import { ReactComponent as InappAlternating } from "./icons/inapp/alternating.svg"
import { ReactComponent as InappAutomatic } from "./icons/inapp/automatic.svg"
import { ReactComponent as InappAwardMedal } from "./icons/inapp/awardMedal.svg"
import { ReactComponent as InappAwardStar } from "./icons/inapp/awardStar.svg"
import { ReactComponent as InappBank } from "./icons/inapp/bank.svg"
import { ReactComponent as InappBusinessDeal } from "./icons/inapp/businessDeal.svg"
import { ReactComponent as InappCalendar } from "./icons/inapp/calendar.svg"
import { ReactComponent as InappCard } from "./icons/inapp/card.svg"
import { ReactComponent as InappCash } from "./icons/inapp/cash.svg"
import { ReactComponent as InappCashApp } from "./icons/inapp/cashApp.svg"
import { ReactComponent as InappDelete } from "./icons/inapp/delete.svg"
import { ReactComponent as InappExclamationPoint } from "./icons/inapp/exclamationPoint.svg"
import { ReactComponent as InappFirstAndThird } from "./icons/inapp/firstAndThird.svg"
import { ReactComponent as InappFourPay } from "./icons/inapp/fourPay.svg"
import { ReactComponent as InappHeartMoneyBag } from "./icons/inapp/heartMoneyBag.svg"
import { ReactComponent as InappHourGlass } from "./icons/inapp/hourGlass.svg"
import { ReactComponent as InappItem } from "./icons/inapp/item.svg"
import { ReactComponent as InappManual } from "./icons/inapp/manual.svg"
import { ReactComponent as InappNotebook } from "./icons/inapp/notebook.svg"
import { ReactComponent as InappOnePay } from "./icons/inapp/onePay.svg"
import { ReactComponent as InappOnePayDeferred } from "./icons/inapp/onePayDeferred.svg"
import { ReactComponent as InappPaymentMethod } from "./icons/inapp/paymentMethod.svg"
import { ReactComponent as InappPencil } from "./icons/inapp/pencil.svg"
import { ReactComponent as InappPercentage } from "./icons/inapp/percentage.svg"
import { ReactComponent as InappPiggyBank } from "./icons/inapp/piggyBank.svg"
import { ReactComponent as InappProperty } from "./icons/inapp/property.svg"
import { ReactComponent as InappPropertyCheck } from "./icons/inapp/propertyCheck.svg"
import { ReactComponent as InappPropertyWarning } from "./icons/inapp/propertyWarning.svg"
import { ReactComponent as InappSecondAndFourth } from "./icons/inapp/secondAndFourth.svg"
import { ReactComponent as InappSettings } from "./icons/inapp/settings.svg"
import { ReactComponent as InappStarburst } from "./icons/inapp/starburst.svg"
import { ReactComponent as InappSuccessful } from "./icons/inapp/successful.svg"
import { ReactComponent as InappSystemUpdate } from "./icons/inapp/systemUpdate.svg"
import { ReactComponent as InappSystemUpdates2 } from "./icons/inapp/systemUpdates2.svg"
import { ReactComponent as InappTimer } from "./icons/inapp/timer.svg"
import { ReactComponent as InappTwoDate } from "./icons/inapp/twoDate.svg"
import { ReactComponent as InappTwoPay } from "./icons/inapp/twoPay.svg"
import { ReactComponent as InappWallet } from "./icons/inapp/wallet.svg"

import { ReactComponent as LeaseAddress } from "./icons/lease-address.svg"

import { CircaLoadingAnimation } from "./animations"

export const GraphicCircaLogo = () => {
  return (
    <div className="graphic-wrap">
      <CircaLogo />
    </div>
  )
}

export const AnimationLoading = () => {
  return (
    <div className="animation-wrap">
      <LoadingAnimation />
    </div>
  )
}

export const CircaLoadingAnimationFS = ({ children }) => {
  useEffect(() => {
    window.reloadIfLoadingIsProlonged = true
    setTimeout(() => {
      if (window.reloadIfLoadingIsProlonged === true) window.location.reload()
    }, 5 * 60 * 1000)
    return () => {
      window.reloadIfLoadingIsProlonged = false
    }
  }, [])

  return (
    <div className="circa-animation-wrap pos-fixed loading-fs d-flex flex-column align-items-center justify-content-center w-100 min-vh-100">
      <CircaLoadingAnimation />
      {children}
    </div>
  )
}

export const AnimationLoadingFullScreen = ({ minVh100 = true, children }) => {
  return (
    <div
      className={`loading-fs d-flex flex-column align-items-center justify-content-center w-100 ${
        minVh100 ? "min-vh-100" : "h-100"
      }`}
    >
      <AnimationLoading />
      {children}
    </div>
  )
}

export const GraphicBeRightBack = () => {
  return (
    <div className="graphic-wrap">
      <BeRightBack />
    </div>
  )
}

export const GraphicGetStarted = () => {
  return (
    <div className="graphic-wrap">
      <GetStarted />
    </div>
  )
}

export const GraphicSignupCarousel1 = () => {
  return (
    <div className="graphic-wrap">
      <SignupCarousel1 />
    </div>
  )
}

export const GraphicSignupCarousel2 = () => {
  return (
    <div className="graphic-wrap">
      <SignupCarousel2 />
    </div>
  )
}

export const GraphicSignupCarousel3 = () => {
  return (
    <div className="graphic-wrap">
      <SignupCarousel3 />
    </div>
  )
}

export const GraphicYouveGotMail = () => {
  return (
    <div className="graphic-wrap">
      <YouveGotMail />
    </div>
  )
}

export const GraphicLeaseInformation = () => {
  return (
    <div className="graphic-wrap">
      <LeaseInformation />
    </div>
  )
}

export const GraphicBankCheckImage = () => {
  return (
    <div className="graphic-wrap">
      <BankCheckImage />
    </div>
  )
}

export const GraphicNeverMissAPayment = () => {
  return (
    <div className="graphic-wrap">
      <NeverMissAPayment />
    </div>
  )
}

export const GraphicPausedLease = () => {
  return (
    <div className="graphic-wrap">
      <PausedLease />
    </div>
  )
}

export const GraphicNoPayments = () => {
  return (
    <div className="graphic-wrap">
      <NoPayments />
    </div>
  )
}

export const GraphicCreditReporting = () => {
  return (
    <div className="graphic-wrap">
      <CreditReporting />
    </div>
  )
}

export const GraphicPartnerTransUnion = () => {
  return (
    <div className="graphic-wrap">
      <PartnerTransUnion />
    </div>
  )
}

export const GraphicPoweredByStripe = () => {
  return (
    <div className="graphic-wrap">
      <PoweredByStripe />
    </div>
  )
}

export const GraphicCardSecurityInfo = () => {
  return (
    <div className="graphic-wrap">
      <CardSecurityInfo />
    </div>
  )
}

export const GraphicWearecircaCashApp = () => {
  return (
    <div className="graphic-wrap">
      <WearecircaCashApp />
    </div>
  )
}

export const GraphicRentAssist1 = () => {
  return (
    <div className="graphic-wrap">
      <RentAssist1 />
    </div>
  )
}

export const GraphicRentAssist2 = () => {
  return (
    <div className="graphic-wrap">
      <RentAssist2 />
    </div>
  )
}

export const GraphicRentAssist3 = () => {
  return (
    <div className="graphic-wrap">
      <RentAssist3 />
    </div>
  )
}

export const GraphicShareCircaAndEarn = () => {
  return (
    <div className="graphic-wrap">
      <ShareCircaAndEarn />
    </div>
  )
}

export const GraphicNotifications = ({ className }) => {
  return (
    <div className={`graphic-wrap ${className}`}>
      <Notifications />
    </div>
  )
}

export const widgetGraphics = {
  CreditReporting: () => (
    <div className="graphic-wrap">
      <WCreditReporting />
    </div>
  ),
  PaymentMethodCashApp: () => (
    <div className="graphic-wrap">
      <WPaymentMethodCashApp />
    </div>
  ),
  PaymentMethodCash: () => (
    <div className="graphic-wrap">
      <WPaymentMethodCash />
    </div>
  ),
  PaymentMethodCreditCard: () => (
    <div className="graphic-wrap">
      <WPaymentMethodCreditCard />
    </div>
  ),
  Referral: () => (
    <div className="graphic-wrap">
      <WReferral />
    </div>
  ),
  RentAssist: () => (
    <div className="graphic-wrap">
      <WRentAssist />
    </div>
  )
}

export const AllIcons = {
  plans: {
    PlanOnePay: ({ className, onClick, onMouseEnter, onMouseLeave }) => (
      <div
        className={`icon-wrap${className ? ` ${className}` : ""}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PlanOnePay />
      </div>
    ),
    PlanOnePayDeferred: ({
      className,
      onClick,
      onMouseEnter,
      onMouseLeave
    }) => (
      <div
        className={`icon-wrap${className ? ` ${className}` : ""}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PlanOnePayDeferred />
      </div>
    ),
    PlanTwoPay: ({ className, onClick, onMouseEnter, onMouseLeave }) => (
      <div
        className={`icon-wrap${className ? ` ${className}` : ""}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PlanTwoPay />
      </div>
    ),
    PlanFourPay: ({ className, onClick, onMouseEnter, onMouseLeave }) => (
      <div
        className={`icon-wrap${className ? ` ${className}` : ""}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PlanFourPay />
      </div>
    )
  },
  PaymentSettingAutopay: ({
    className,
    onClick,
    onMouseEnter,
    onMouseLeave
  }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PaymentSettingAutopay />
    </div>
  ),
  PaymentSettingManual: ({
    className,
    onClick,
    onMouseEnter,
    onMouseLeave
  }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PaymentSettingManual />
    </div>
  ),
  PaymentMethodBank: ({ className, onClick, onMouseEnter, onMouseLeave }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PaymentMethodBank />
    </div>
  ),
  PaymentMethodCashApp: ({
    className,
    onClick,
    onMouseEnter,
    onMouseLeave
  }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PaymentMethodCashApp />
    </div>
  ),
  PaymentMethodCash: ({ className, onClick, onMouseEnter, onMouseLeave }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PaymentMethodCash />
    </div>
  ),
  PaymentMethodCard: ({ className, onClick, onMouseEnter, onMouseLeave }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PaymentMethodCard />
    </div>
  ),
  Calendar: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <Calendar />
    </div>
  ),
  ExclamationPoint: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <ExclamationPoint />
    </div>
  ),
  LinkNewLease: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <LinkNewLease />
    </div>
  ),
  LeaseDetails: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <LeaseDetails />
    </div>
  ),
  ViewPayments: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <ViewPayments />
    </div>
  ),
  Notifications: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <IconNotifications />
    </div>
  ),
  LeaseAddress: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <LeaseAddress />
    </div>
  ),
  PaymentOptionPayNow: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <PaymentOptionPayNow />
    </div>
  ),
  SetupRentAssist: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <SetupRentAssist />
    </div>
  ),
  RentAssistPercentage: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <RentAssistPercentage />
    </div>
  ),
  RentAssistDollarAmount: ({ className, onClick }) => (
    <div
      className={`icon-wrap${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <RentAssistDollarAmount />
    </div>
  ),
  BadgeBuilding: ({ className }) => (
    <div className={`icon-wrap${className ? ` ${className}` : ""}`}>
      <BadgeBuilding />
    </div>
  )
}

export const AllInappIcons = {
  InappAlarmBell: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappAlarmBell />
    </div>
  ),
  InappAlternating: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappAlternating />
    </div>
  ),
  InappAutomatic: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappAutomatic />
    </div>
  ),
  InappAwardMedal: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappAwardMedal />
    </div>
  ),
  InappAwardStar: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappAwardStar />
    </div>
  ),
  InappBank: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappBank />
    </div>
  ),
  InappBusinessDeal: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappBusinessDeal />
    </div>
  ),
  InappCalendar: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappCalendar />
    </div>
  ),
  InappCard: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappCard />
    </div>
  ),
  InappCash: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappCash />
    </div>
  ),
  InappCashApp: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappCashApp />
    </div>
  ),
  InappDelete: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappDelete />
    </div>
  ),
  InappExclamationPoint: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappExclamationPoint />
    </div>
  ),
  InappFirstAndThird: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappFirstAndThird />
    </div>
  ),
  InappFourPay: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappFourPay />
    </div>
  ),
  InappHeartMoneyBag: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappHeartMoneyBag />
    </div>
  ),
  InappHourglass: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappHourGlass />
    </div>
  ),
  InappItem: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappItem />
    </div>
  ),
  InappManual: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappManual />
    </div>
  ),
  InappNotebook: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappNotebook />
    </div>
  ),
  InappOnePay: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappOnePay />
    </div>
  ),
  InappOnePayDeferred: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappOnePayDeferred />
    </div>
  ),
  InappPaymentMethod: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappPaymentMethod />
    </div>
  ),
  InappPencil: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappPencil />
    </div>
  ),
  InappPercentage: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappPercentage />
    </div>
  ),
  InappPiggyBank: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappPiggyBank />
    </div>
  ),
  InappProperty: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappProperty />
    </div>
  ),
  InappPropertyCheck: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappPropertyCheck />
    </div>
  ),
  InappPropertyWarning: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappPropertyWarning />
    </div>
  ),
  InappSecondAndFourth: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappSecondAndFourth />
    </div>
  ),
  InappSettings: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappSettings />
    </div>
  ),
  InappStarburst: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappStarburst />
    </div>
  ),
  InappSuccessful: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappSuccessful />
    </div>
  ),
  InappSystemUpdate: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappSystemUpdate />
    </div>
  ),
  InappSystemUpdates2: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappSystemUpdates2 />
    </div>
  ),
  InappTimer: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappTimer />
    </div>
  ),
  InappTwoDate: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappTwoDate />
    </div>
  ),
  InappTwoPay: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappTwoPay />
    </div>
  ),
  InappWallet: ({ className }) => (
    <div className={`icon-wrap inapp${!!className ? " " + className : ""}`}>
      <InappWallet />
    </div>
  )
}

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}
