import React from "react"

const ItemsList = ({ items }) => {
  return (
    <div className={`items-list`}>
      {items.map((item, i) => (
        <div className="item" key={i}>
          <div className="name">{item.name}</div>
          <div className="value">{item.value}</div>
        </div>
      ))}
    </div>
  )
}
export default ItemsList
