import React from "react"

import { APPCONFIG } from "../../config"
import { ALLROUTES } from "../../routes"

export const dashboardPopups = {
  WelcomeBack: () => {
    return (
      <>
        <h2 className="h1">Welcome back!</h2>
        <p>
          You're almost done setting up your account! We saved your progress, so
          you can pick up where you left off.
        </p>
      </>
    )
  },
  DontForget: ({ paymentMonth, paymentYear }) => (
    <>
      <h2 className="h1">Don’t Forget!</h2>
      <p>
        Circa will process your rent starting {paymentMonth} {paymentYear}. Make
        sure you take care of any payments before then. If you’d like us to
        start processing payments sooner, please send us an email at{" "}
        <a href={ALLROUTES.supportEmailLink}>{APPCONFIG.supportEmail}</a>.
      </p>
      <p>
        If you currently use another service to pay rent, make sure you cancel
        it before {paymentMonth} to avoid paying twice!
      </p>
    </>
  ),
  HiDontMissOutNotifications: ({ nav, firstName }) => (
    <>
      <h2 className="h1">Hi, {firstName}! Don’t miss out.</h2>
      <p>Notifications are turned off.</p>
      <p>
        Turn on to get important updates about your payment and account
        activity.
      </p>
      <div className="action-buttons">
        <button className="btn btn-primary">Turn On</button>
        <button
          className="btn btn-text"
          onClick={() => {
            nav(ALLROUTES.dashboardChildren.settingsChildren.notifications)
          }}
        >
          Set Preferences
        </button>
      </div>
    </>
  ),
  WelcomeToTheNewCirca: () => (
    <>
      <h2 className="h1">Welcome to the new Circa!</h2>
      <p>
        Your experience means everything to us, and we’re thrilled to launch
        improvements to our service and look.
      </p>
      <div className="action-buttons">
        <button
          className="btn btn-bordered btn-short"
          onClick={() => {
            window.open(APPCONFIG.staticLinks.welcomeToTheNewCircaLearnMore)
          }}
        >
          Learn More
        </button>
      </div>
    </>
  ),
  HaveYouMadeYourCashPayment: () => (
    <>
      <h2 className="h1">Have you made your cash payment?</h2>
      <p>
        Let us know once you’ve paid your rent using cash at a retailer near
        you.
      </p>
      <div className="action-buttons">
        <button className="btn btn-primary">I Paid</button>
        <button className="btn btn-text">Learn More</button>
      </div>
    </>
  ),
  HaveYouMadeYourCashAppPayment: () => (
    <>
      <h2 className="h1">Have you paid using Cash App?</h2>
      <p>Let us know once you’ve paid your rent using Cash App.</p>
      <div className="action-buttons">
        <button className="btn btn-primary">I Paid</button>
        <button className="btn btn-text">Learn More</button>
      </div>
    </>
  ),
  CongratsYoureCaughtUp: () => (
    <>
      <h2 className="h1">Congrats! You’re caught up.</h2>
      <p>You have successfully paid off your balance using RentAssist.</p>
    </>
  )
}
