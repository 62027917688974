import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { APPCONFIG } from "../../../config"
import { ALLMENUS, ALLROUTES } from "../../../routes"
import { Dropdown } from "react-bootstrap"
import { AllIcons } from "../../../assets"
import {
  manageMsalAuth,
  reduceApiData,
  manualMsalLogoutAndRedirect
} from "../../../utils"

import OrgnInAppNotificationsList from "../../organisms/inapp-notifications/inapp-notifications-list"
import { showModalHelp } from "../../modals/modal-help"

import { useDashboardQueries } from "../../../utils/queries"

import "./logged-in-nav.scss"

const LoggedInNav = ({
  navSettingsActiveItem,
  setNavSettingsActiveItem,
  msalLogoutFunc,
  setLogoutRequested,
  unreadNotifRedDot
}) => {
  const { pathname } = useLocation()
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const { data: dataResident } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })
  const reducedDataResident = reduceApiData(dataResident, "resident")

  const showModalInAppNotifications = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--notifications narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <div className="freeze-to-top">
          <h2 className="billboard text-center">Notifications</h2>
        </div>
        <OrgnInAppNotificationsList />
        <div className="pt-4"></div>
      </div>
    ))
    setModalVisibility(true)
  }

  const handleOnClick = navItem => {
    if (navItem?.route === "[msalLogoutFunc]") {
      setLogoutRequested(true)
      manageMsalAuth.delete()
      manualMsalLogoutAndRedirect()
      if (!!msalLogoutFunc) {
        msalLogoutFunc()
      }
    } else if (!!navItem?.onClick) {
      navItem.onClick()
    } else if (!!navItem?.route) {
      if (navItem.route.indexOf("/settings") > -1) {
        setNavSettingsActiveItem(navItem)
      }
      nav(navItem.route)
    }
  }

  return (
    <div className="loggedin-nav d-flex align-items-center">
      <div
        className="icon-wrap icon--help clickable"
        onClick={() => {
          showModalHelp({
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility
          })
        }}
      ></div>
      {!!pathname && pathname.indexOf(ALLROUTES.dashboardContainer) > -1 && (
        <AllIcons.Notifications
          className={`icon--notification d-flex clickable${
            !!unreadNotifRedDot ? " unread" : ""
          }`}
          onClick={showModalInAppNotifications}
        />
      )}
      <Dropdown>
        <Dropdown.Toggle id={`logged-in-nav-dropdown`}>
          Hello, {reducedDataResident?.firstName || "Resident"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {ALLMENUS.loggedIn.map((navItem, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => {
                handleOnClick(navItem)
              }}
              disabled={navItem.disabled}
            >
              <navItem.Icon />
              {navItem.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default LoggedInNav
