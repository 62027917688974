import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import { CircaLoadingAnimationFS, GraphicYouveGotMail } from "../../../assets"
import { simulateMsalAuthOnSignup, zPad } from "../../../utils"

import { DashboardWrapperNoAuth } from "../../../components/wrappers/dashboard"
import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import MessageBubble from "../../../components/elements/message-bubble/message-bubble"
import GenericForm from "../../../components/elements/generic-form"

import { useOnboardingMutation } from "../../../utils/mutations"

import "./email-verification.scss"

const ScreenEmailVerification = () => {
  const nav = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (!!state?.email) {
      if (
        !!verificationCodeRef?.current &&
        !!verificationCodeRef?.current?.focus
      )
        verificationCodeRef?.current?.focus()
    } else {
      nav(ALLROUTES.signup)
    }
  }, [])

  const mutations = {
    signup: useOnboardingMutation.useSignupMutation(),
    emailVerification: useOnboardingMutation.useSignupEmailVerificationMutation()
  }

  const [messageBubbleVisible, setMessageBubbleVisible] = useState(false)
  const [messageBubbleHeadingText, setMessageBubbleHeadingText] = useState("")
  const [messageBubbleContentText, setMessageBubbleContentText] = useState("")

  const resendCodeDuration = APPCONFIG.resendCodeInSec

  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [verifiedAndRedirecting, setVerifiedAndRedirecting] = useState(false)

  const verificationCodeRef = useRef()

  const formFields = [
    {
      label: "Verification Code",
      showLabel: false,
      name: "verificationCode",
      compType: "n-digit-code",
      digitCount: 4,
      rules: {
        required: true,
        minLength: 4,
        maxLength: 4
      },
      ref: verificationCodeRef
    }
  ]

  const Ref = useRef(null)

  const [timer, setTimer] = useState(`00:00:${zPad(resendCodeDuration, 2)}`)

  const getTimeRemaining = e => {
    const total = Date.parse(e) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / 1000 / 60 / 60) % 24)
    return {
      total,
      hours,
      minutes,
      seconds
    }
  }

  const startTimer = e => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e)
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      )
    }
  }

  const clearTimer = e => {
    setTimer(`00:00:${zPad(resendCodeDuration, 2)}`)

    if (Ref.current) clearInterval(Ref.current)
    const id = setInterval(() => {
      startTimer(e)
    }, 1000)
    Ref.current = id
  }

  const getDeadTime = () => {
    let deadline = new Date()
    deadline.setSeconds(deadline.getSeconds() + resendCodeDuration)
    return deadline
  }

  useEffect(() => {
    clearTimer(getDeadTime())
  }, [])

  const resetResendTimer = () => {
    clearTimer(getDeadTime())
  }

  const getTimerValueInSec = () => {
    const timerChunks = timer.split(":")
    return timerChunks[0] * 60 * 60 + timerChunks[1] * 60 + timerChunks[2] * 1
  }

  const handleError = ({
    status,
    httpStatusCode,
    code,
    message,
    errordescription,
    apiError
  }) => {
    if (apiError) {
      nav(ALLROUTES.serverDown)
      return
    }
    setMessageBubbleHeadingText("Verification Failed")
    setMessageBubbleContentText(message || errordescription)
    setMessageBubbleVisible(true)
  }

  const onVerifySubmit = formValues => {
    const requestParams = {
      email: state?.email || "",
      ...formValues
    }
    setUpdateInProgress(true)
    mutations.emailVerification.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if (
          (status === 200 || data.httpStatusCode === 200) &&
          !!data.responseToken
        ) {
          simulateMsalAuthOnSignup(data, state?.email)
          setVerifiedAndRedirecting(true)
          setTimeout(() => {
            window.location = ALLROUTES.accountSetupChildren.contactInformation
          }, 2 * 1000)
        } else {
          handleError(data)
        }
      },
      onError: axiosError => {
        handleError(
          axiosError?.response?.data || axiosError?.data || { apiError: true }
        )
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const onResendCode = () => {
    const resendCodeParams = {
      email: state?.email || "",
      password: state?.password || ""
    }
    mutations.signup.mutate(resendCodeParams, {
      onSuccess: ({ data, status }) => {
        if (
          status === 200 &&
          data.status === true &&
          data.httpStatusCode === 200
        ) {
          setMessageBubbleHeadingText("Email sent")
          setMessageBubbleContentText(
            "A verification email has been sent successfully."
          )
          setMessageBubbleVisible(true)
          resetResendTimer()
        } else {
          handleError(data)
        }
      },
      onError: axiosError => {
        handleError(
          axiosError?.response?.data || axiosError?.data || { apiError: true }
        )
      }
    })
  }

  return (
    <>
      <MessageBubble
        onHide={() => {
          setMessageBubbleVisible(false)
        }}
        show={messageBubbleVisible}
        contentClassName={`global-message-bubble`}
        headingText={messageBubbleHeadingText}
        messageText={messageBubbleContentText}
      >
        {messageBubbleContentText ? (
          ""
        ) : (
          <>
            <p>
              If you don't see a message in your inbox, make sure the email
              address you provided is correct. Also check your spam or junk mail
              folder. This email was sent from admin@wearecirca.com.
            </p>
            <p>If you want us to resend the email, click "Send New Code."</p>
            <p>
              For further questions, please{" "}
              <a href={ALLROUTES.supportEmailLink}>contact us</a>.
            </p>
          </>
        )}
      </MessageBubble>

      {updateInProgress || verifiedAndRedirecting ? (
        <CircaLoadingAnimationFS />
      ) : (
        <DashboardWrapperNoAuth>
          <FullScreenCentered
            className={`screen__email-verification text-center`}
            vw100={false}
          >
            <h1 className="billboard">
              You&#8217;ve got mail!
              <span
                className="icon-help"
                onClick={() => {
                  setMessageBubbleHeadingText("Email Verification")
                  setMessageBubbleContentText("")
                  setMessageBubbleVisible(true)
                }}
              ></span>
            </h1>

            <GraphicYouveGotMail />

            <p>
              Please enter the verification code we sent to{" "}
              <span>{state?.email || "-"}</span>
            </p>

            <GenericForm
              submitButtonText={"Continue"}
              submitButtonW={"auto"}
              onSubmit={onVerifySubmit}
              formFields={formFields}
            />

            <div className="button-wrap mb-4">
              <button
                className="btn btn-text"
                type={"button"}
                onClick={onResendCode}
                disabled={getTimerValueInSec() > 0}
              >
                Send New Code
                {getTimerValueInSec() > 0 ? ` In ${timer.substring(3)}` : ""}
              </button>
            </div>
          </FullScreenCentered>
        </DashboardWrapperNoAuth>
      )}
    </>
  )
}

export default ScreenEmailVerification
