import React, { useEffect } from "react"

import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import { displayAmount, formatDateToFormat } from "../../../utils"

import ItemsList from "../../elements/items-list"

const OrgnLeaseDetails = ({
  leaseDetails,
  onViewLease,
  submitButtonText,
  onSubmit,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const itemList1 = [
    {
      name: "Lease ID",
      value: leaseDetails?.uniqueAccountNumber || "XXX-XXXX"
    },
    {
      name: "Monthly Rent",
      value: `$${displayAmount(leaseDetails?.monthlyRent)}`
    },
    ...(!!leaseDetails?.startDate
      ? [
          {
            name: "Start Date",
            value: formatDateToFormat(
              new Date(leaseDetails?.startDate),
              "MM/DD/YYYY"
            )
          }
        ]
      : []),
    ...(!!leaseDetails?.endDate
      ? [
          {
            name: "End Date",
            value: formatDateToFormat(
              new Date(leaseDetails?.endDate),
              "MM/DD/YYYY"
            )
          }
        ]
      : [])
  ]

  const itemList2 = [
    {
      name: "Community",
      value: leaseDetails?.communityName
    },
    {
      name: "Address",
      value: [
        leaseDetails?.addressInformation?.addressLine1,
        leaseDetails?.addressInformation?.addressLine2
      ]
        .filter(item => !!item)
        .join(", ")
    },
    {
      name: "Unit",
      value: leaseDetails?.addressInformation?.unit
    },
    {
      name: "City",
      value: leaseDetails?.addressInformation?.city
    },
    {
      name: "State",
      value: leaseDetails?.addressInformation?.state
    },
    {
      name: "Zip Code",
      value: leaseDetails?.addressInformation?.zipCode
    }
  ]

  const showReportAnErrorPopup = () => {
    setModalVisibility(false)
    setMessageBubbleComponent(() => {
      return (
        <>
          <h2 className="h1">Report an Error</h2>
          <p>
            If any of these lease details are incorrect, please contact us
            before continuing your account setup.
          </p>
          <p>
            Email us anytime at{" "}
            <a href={ALLROUTES.supportEmailLink}>{APPCONFIG.supportEmail}</a> or
            call us during business hours at{" "}
            <a href={ALLROUTES.supportTelephoneLink}>
              {APPCONFIG.supportTelephone}
            </a>
            .
          </p>
        </>
      )
    })
    setMessageBubbleVisibility(true)
  }

  return (
    <>
      <h3 className="align-items-center d-flex justify-content-between font-regular mt-2">
        Lease Information
        {!!onViewLease && (
          <button
            className="btn btn-text text-only"
            onClick={() => {
              setModalVisibility(false)
              onViewLease?.()
            }}
          >
            View Lease
          </button>
        )}
      </h3>
      <ItemsList items={itemList1} />
      <h3 className="font-regular">Address</h3>
      <ItemsList items={itemList2} />
      {submitButtonText && (
        <button
          className="btn btn-primary d-block mx-auto w-100"
          onClick={onSubmit}
        >
          {submitButtonText}
        </button>
      )}
      <button
        className="btn btn-text text-only d-block mx-auto mt-4 pt-2 w-100"
        onClick={showReportAnErrorPopup}
      >
        Report an Error
      </button>
    </>
  )
}

export default OrgnLeaseDetails
