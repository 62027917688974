import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"

import {
  AnimationLoadingFullScreen,
  CircaLoadingAnimationFS
} from "../../../assets"
import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import { gotoLinkNewLease, reduceApiData } from "../../../utils"
import {
  leaseIdInSetup,
  leaseIdOnDash,
  redirectPerLeaseSetupProgress
} from "../../../utils/logic"

import OrgnLeaseDetails from "../../../components/organisms/lease-details/lease-details"
import OrgnLeasesList from "../../../components/organisms/leases-list/leases-list"

import { useDashboardQueries } from "../../../utils/queries"

import "./leases.scss"

const ScreenDashSettLeases = () => {
  const {
    setContainerClass,
    setModalClass,
    setModalBreadcrumb,
    setModalVisibility,
    setModalComponent,
    setMessageBubbleComponent,
    setMessageBubbleVisibility
  } = useOutletContext()

  const nav = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    document.body.classList.add("page__dash-sett-leases")
    setContainerClass("screen__dash-sett-leases")
    return () => {
      document.body.classList.remove("page__dash-sett-leases")
      setContainerClass("")
    }
  }, [])

  const [presentLeases, setPresentLeases] = useState([])
  const [pastLeases, setPastLeases] = useState([])

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusLeases,
    data: dataLeases,
    isFetching: isFetchingLeases,
    isError: isErrorLeases,
    error: errorLeases,
    refetch: refetchLeases
  } = useDashboardQueries.useLeasesQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  useEffect(() => {
    if ((dataLeases || errorLeases) && !isFetchingLeases) {
      const reducedDataLeases = reduceApiData(dataLeases, "leases")
      if (APPCONFIG.debugGlobal) console.log(reducedDataLeases)
      setPresentLeases(reducedDataLeases?.presentLeases)
      setPastLeases(reducedDataLeases?.pastLeases)
    }
  }, [dataLeases, isFetchingLeases, isErrorLeases, errorLeases])

  const onLeaseDetails = leaseInfo => {
    if (leaseInfo?.type === APPCONFIG.api.leaseIncomplete) {
      const redirectApplicable = redirectPerLeaseSetupProgress({
        nav,
        pathname,
        dataResidents,
        errorResidents,
        leaseId: leaseInfo?.leaseId
      })
      if (!!redirectApplicable) {
        leaseIdInSetup.set(leaseInfo?.leaseId)
        redirectApplicable()
        return
      }
    }
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--lease-details narrow-content")
    setModalComponent(() => {
      const {
        status: statusLeaseDetails,
        data: dataLeaseDetails,
        isFetching: isFetchingLeaseDetails,
        isError: isErrorLeaseDetails,
        error: errorLeaseDetails,
        refetch: refetchLeaseDetails
      } = useDashboardQueries.useLeaseDetailsQuery({
        config: { refetchOnMount: true },
        enabled: statusResidents === "success",
        residentId: dataResidents?.data?.residentData?.id,
        leaseId: leaseInfo?.leaseId
      })

      if (isFetchingLeaseDetails) {
        return <AnimationLoadingFullScreen minVh100={false} />
      }

      return (
        <div className="modal-content-height d-flex flex-column">
          <div className="freeze-to-top">
            <h2 className="billboard text-center">Lease Details</h2>
          </div>
          <OrgnLeaseDetails
            leaseDetails={dataLeaseDetails?.data?.data}
            onViewLease={
              leaseInfo?.type === APPCONFIG.variables.typePastLease
                ? null
                : () => {
                    leaseIdOnDash.set(leaseInfo?.leaseId)
                    leaseIdInSetup.set(leaseInfo?.leaseId)
                    nav(ALLROUTES.dashboardChildren.dashboard)
                  }
            }
            setModalVisibility={setModalVisibility}
            setMessageBubbleComponent={setMessageBubbleComponent}
            setMessageBubbleVisibility={setMessageBubbleVisibility}
          />
        </div>
      )
    })
    setModalVisibility(true)
  }

  return (
    <>
      {isFetchingResidents ? (
        <CircaLoadingAnimationFS />
      ) : (
        <div className="leases-wrap d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="mb-0">Leases</h2>
            <button
              className="btn btn-primary link-lease-btn"
              onClick={() => {
                gotoLinkNewLease({ nav })
              }}
            ></button>
          </div>
          {presentLeases?.length > 0 && (
            <OrgnLeasesList
              className={`mt-4 mb-4`}
              leaseListData={presentLeases}
              onLeaseDetails={onLeaseDetails}
            />
          )}
          {pastLeases?.length > 0 && (
            <>
              <h3 className="mb-0 mt-4">Past Leases</h3>
              <OrgnLeasesList
                className={`mt-4 mb-4`}
                leaseListData={pastLeases}
                onLeaseDetails={onLeaseDetails}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

export default ScreenDashSettLeases
