import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { Dropdown } from "react-bootstrap"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import { AnimationLoadingFullScreen } from "../../../assets"
import {
  ADDMETHODSLUG,
  ADDMETHODSLUG_BANK,
  ADDMETHODSLUG_CARD,
  APPCONFIG,
  PLANPAYMENTSETTINGS
} from "../../../config"
import {
  displayAmount,
  handleize,
  numberWithOrdinal,
  pluralDayName
} from "../../../utils"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"
import { logicPlan } from "../../../utils/logic-plan"
import { ALLROUTES } from "../../../routes"

import HelpIconWithTooltip from "../../elements/form/help-icon-with-tooltip"
import YouPayOnThe from "../../widgets/you-pay-on-the"
import { TooltipContentPaymentMethods } from "../tooltip-content/payment-methods"

import {
  useAccountSetupQueries,
  useDashboardQueries
} from "../../../utils/queries"
import { usePlanMutation } from "../../../utils/mutations"
import { showModalBankInformation } from "../bank-information/bank-information"
import { showModalCardInformation } from "../card-information/card-information"
import { showModifyPlanModal } from "./modify-plan"

const OrgnWizardPlan = ({
  wizardTitle,
  showYouPayOnThe = false,
  PostTitleComponent,
  submitButtonText,
  leasePlanDetails,
  refetchLeasePlanDetails,
  refetchDashboard,
  selections,
  onSubmit,
  onSuccess,
  PostSubmitButtonComponent,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [selectedPlan, setSelectedPlan] = useState(null)
  const [showPlanDescription, setShowPlanDescription] = useState(false)

  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [showScheduleDescription, setShowScheduleDescription] = useState(false)

  const [paymentStartDate, setPaymentStartDate] = useState(null)
  const [planPaymentDates, setPlanPaymentDates] = useState([])
  const [planPaymentDay, setPlanPaymentDay] = useState(null)

  const [allPaymentMethods, setAllPaymentMethods] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [selectedPlanPaymentSetting, setPlanPaymentSetting] = useState(
    PLANPAYMENTSETTINGS?.[0]?.slug
  )

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusAvailablePaymentPlans,
    data: dataAvailablePaymentPlans,
    isFetching: isFetchingAvailablePaymentPlans,
    isError: isErrorAvailablePaymentPlans
  } = useAccountSetupQueries.useAvailablePaymentPlansQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  const {
    status: statusResidentLeasePaymentMethods,
    data: dataResidentLeasePaymentMethods,
    isFetching: isFetchingResidentLeasePaymentMethods,
    isError: isErrorResidentLeasePaymentMethods
  } = useDashboardQueries.useResidentLeasePaymentMethodsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  useEffect(() => {
    if (!!selections?.plan) {
      setTimeout(() => {
        setSelectedPlan(selections?.plan)
        setSelectedSchedule(selections?.schedule)
        if (!!selections?.paymentDates)
          setPlanPaymentDates(selections?.paymentDates)
        if (!!selections?.paymentDay) setPlanPaymentDay(selections?.paymentDay)
      }, 1000)
    }
  }, [])

  useEffect(() => {
    logicPlan.onUpdateSelectedPlan({ selectedPlan, setSelectedSchedule })
  }, [selectedPlan])

  useEffect(() => {
    logicPlan.onUpdateSelectedSchedule({
      selectedSchedule,
      setPaymentStartDate,
      setPlanPaymentDates,
      setPlanPaymentDay
    })
  }, [selectedSchedule])

  useEffect(() => {
    logicPlan.onUpdateAvailablePaymentPlans({
      dataResidents,
      nav,
      dataAvailablePaymentPlans,
      isErrorAvailablePaymentPlans,
      isFetchingAvailablePaymentPlans,
      setSelectedPlan
    })
  }, [
    dataAvailablePaymentPlans,
    isFetchingAvailablePaymentPlans,
    isErrorAvailablePaymentPlans
  ])

  useEffect(() => {
    logicPlan.onUpdateResidentLeasePaymentMethods({
      dataResidentLeasePaymentMethods,
      setAllPaymentMethods,
      setSelectedPaymentMethod
    })
  }, [
    dataResidentLeasePaymentMethods,
    isFetchingResidentLeasePaymentMethods,
    isErrorResidentLeasePaymentMethods
  ])

  const modifyPlanMutation = usePlanMutation.useModifyPlanMutation()

  useEffect(() => {
    if (selectedPaymentMethod?.type === ADDMETHODSLUG_BANK) {
      showModalBankInformation({
        onSubmitCallback: () => {
          showModifyPlanModal({
            setModalClass,
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            leasePlanDetails,
            refetchLeasePlanDetails,
            refetchDashboard
          })
        },
        updateInProgress,
        setUpdateInProgress,
        setModalBreadcrumb,
        setModalClass,
        setModalComponent,
        setModalVisibility
      })
    }
    if (selectedPaymentMethod?.type === ADDMETHODSLUG_CARD) {
      showModalCardInformation({
        onSubmitCallback: () => {
          showModifyPlanModal({
            setModalClass,
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            leasePlanDetails,
            refetchLeasePlanDetails,
            refetchDashboard
          })
        },
        setModalBreadcrumb,
        setModalClass,
        setModalComponent,
        setModalVisibility,
        setMessageBubbleComponent,
        setMessageBubbleVisibility
      })
    }
  }, [selectedPaymentMethod])

  if (
    isFetchingResidents ||
    isFetchingAvailablePaymentPlans ||
    isFetchingResidentLeasePaymentMethods ||
    updateInProgress
  ) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="widget freeze-to-top">
        <h2 className="billboard text-center mb-4">{wizardTitle}</h2>
        {showYouPayOnThe && (
          <YouPayOnThe
            accentuated={true}
            compact={selectedSchedule?.frequency * 1 > 1}
            leftLabel={`You pay`}
            leftInfo={
              !!selectedSchedule?.amountPerPayment
                ? `$${displayAmount(selectedSchedule?.amountPerPayment)}`
                : ""
            }
            rightLabel={`on${
              selectedSchedule?.plan === APPCONFIG.api.planSlugs.ALTERNATING
                ? " alternating"
                : selectedSchedule?.frequency * 1 < 4
                ? " the"
                : ""
            }${
              [
                APPCONFIG.api.planSlugs.FIRSTANDTHIRD,
                APPCONFIG.api.planSlugs.SECONDANDFOURTH
              ].indexOf(selectedSchedule?.plan) > -1
                ? ` ${selectedSchedule?.planName}`
                : ""
            }`}
            rightInfo={`${
              !selectedSchedule?.paymentDates?.defaultDayofweek &&
              !!planPaymentDates?.[0]
                ? numberWithOrdinal(planPaymentDates[0])
                : ""
            }${
              !selectedSchedule?.paymentDates?.defaultDayofweek &&
              !!planPaymentDates?.[1]
                ? ` & ${numberWithOrdinal(planPaymentDates[1])}`
                : ""
            }${
              !!selectedSchedule?.paymentDates?.defaultDayofweek
                ? pluralDayName(planPaymentDay)
                : ""
            }`}
          />
        )}
        {PostTitleComponent ? <PostTitleComponent /> : ""}
      </div>
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Plan
          <span
            className="icon-help"
            onClick={() => {
              setShowPlanDescription(!showPlanDescription)
            }}
          ></span>
        </h3>
        <div
          className={`option-selector${
            showPlanDescription ? " show-description" : ""
          }`}
        >
          {dataAvailablePaymentPlans?.data?.data?.map((plan, i) => (
            <div
              className={`option-wrap block block--selectable low-elevation br-medium${
                plan.planTypeId === selectedPlan?.planTypeId ? " selected" : ""
              }${plan.isAvailable === false ? " disabled" : ""}`}
              data-plan={plan.planTypeId}
              onClick={() => {
                setSelectedPlan(plan)
              }}
              key={i}
            >
              <div className={`plan plan--${handleize(plan.planTypeName, "")}`}>
                <div className="plan__icon"></div>
                <div className="plan__label">
                  <div className="plan__title">{plan.planTypeDisplayName}</div>
                  <div className="plan__subtitle">{plan.feesDescription}</div>
                </div>
              </div>
              <div className="option-description">
                {plan.planTypeDescription}
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedPlan?.paymentPlans?.length > 1 && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Schedule
            <span
              className="icon-help"
              onClick={() => {
                setShowScheduleDescription(!showScheduleDescription)
              }}
            ></span>
          </h3>
          <div
            className={`option-selector${
              showScheduleDescription ? " show-description" : ""
            }`}
          >
            {selectedPlan?.paymentPlans
              ?.sort((a, b) => a.plansSortOrder - b.plansSortOrder)
              .map((schedule, i) => (
                <div
                  className={`option-wrap schedule--${handleize(
                    schedule.planName.replace("&", "and")
                  )} block block--selectable low-elevation br-medium${
                    schedule.planId === selectedSchedule?.planId
                      ? " selected"
                      : ""
                  }`}
                  data-schdeule={schedule.planId}
                  onClick={() => {
                    setSelectedSchedule(schedule)
                  }}
                  key={i}
                >
                  <div className="plan">
                    <div className="plan__icon"></div>
                    <div className="plan__label">
                      <div className="plan__title">{schedule.planName}</div>
                    </div>
                  </div>
                  <div className="option-description">
                    {schedule.planDescription}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {!!selectedSchedule?.paymentDates?.dayoftheweek && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Day
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              <Dropdown>
                <Dropdown.Toggle>
                  <div className="payment-option">
                    <div className="payment-option__icon"></div>
                    <div className="payment-option__title">
                      {pluralDayName(planPaymentDay)}
                    </div>
                    <div className="payment-option__caret"></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <SimpleBar style={{ maxHeight: "300px" }} autoHide={false}>
                    {selectedSchedule?.paymentDates?.dayoftheweek?.map(
                      (selectablePaymentDay, i) => (
                        <Dropdown.Item
                          onClick={() => {
                            logicPlan.onSelectPlanPaymentDay({
                              startDate: paymentStartDate,
                              newPlanPaymentDay: selectablePaymentDay,
                              selectedSchedule,
                              setPlanPaymentDay,
                              setPlanPaymentDates
                            })
                          }}
                          key={i}
                        >
                          {pluralDayName(
                            selectablePaymentDay + APPCONFIG.api.dayIndexAdjust
                          )}
                        </Dropdown.Item>
                      )
                    )}
                  </SimpleBar>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      {[
        APPCONFIG.api.planSlugs.FIRSTANDTHIRD,
        APPCONFIG.api.planSlugs.SECONDANDFOURTH,
        APPCONFIG.api.planSlugs.FOURPAY
      ].indexOf(selectedSchedule?.plan) < 0 && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            {selectedSchedule?.paymentDates?.paymentStartDate
              ? `Start Date`
              : `Date${(selectedSchedule?.frequency || 1) * 1 > 1 ? "s" : ""}`}
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              {planPaymentDates
                .filter((item, i) =>
                  selectedSchedule?.plan ===
                    APPCONFIG.api.planSlugs.ALTERNATING && i === 1
                    ? false
                    : !!item
                )
                .map((planPaymentDate, iDate) => (
                  <Dropdown key={iDate}>
                    <Dropdown.Toggle>
                      <div className="payment-option">
                        <div className="payment-option__icon"></div>
                        <div className="payment-option__title">
                          {`${numberWithOrdinal(planPaymentDate)}`}
                        </div>
                        <div className="payment-option__caret"></div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <SimpleBar
                        style={{ maxHeight: "300px" }}
                        autoHide={false}
                      >
                        {(
                          selectedSchedule?.paymentDates?.paymentStartDate ||
                          selectedSchedule?.paymentDates?.[`date${iDate + 1}`]
                        )?.map((selectablePaymentDate, i) => (
                          <Dropdown.Item
                            onClick={() => {
                              logicPlan.onSelectPlanPaymentDate({
                                newPaymentDate: selectablePaymentDate,
                                index: iDate,
                                planPaymentDates,
                                selectedSchedule,
                                setPlanPaymentDates,
                                setPlanPaymentDay
                              })
                            }}
                            key={i}
                          >
                            {numberWithOrdinal(selectablePaymentDate)}
                          </Dropdown.Item>
                        ))}
                      </SimpleBar>
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
            </div>
          </div>
        </div>
      )}
      {allPaymentMethods && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Method
            <HelpIconWithTooltip
              className={`ms-3`}
              tooltipClass={`payment-methods-tooltip`}
              isOverModal={true}
              placement={`left-start`}
              TooltipComponent={() => <TooltipContentPaymentMethods />}
            />
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              <Dropdown>
                <Dropdown.Toggle>
                  <div className="payment-option">
                    <div
                      className={`payment-option__icon icon-${selectedPaymentMethod?.type}`}
                    ></div>
                    <div className="payment-option__title">
                      {selectedPaymentMethod?.title || "Select"}
                    </div>
                    <div className="payment-option__caret"></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {allPaymentMethods?.map((paymentMethod, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedPaymentMethod(paymentMethod)
                      }}
                      key={i}
                    >
                      <div className="payment-option">
                        <div
                          className={`payment-option__icon icon-${paymentMethod.type}`}
                        ></div>
                        <div className="payment-option__title">
                          {paymentMethod?.title}
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      {selectedPaymentMethod?.type?.indexOf(
        APPCONFIG.api.globalPaymentMethods.CASH.slug
      ) < 0 && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Settings
            <HelpIconWithTooltip
              tooltipClass={`${handleize(wizardTitle)}-plan-settings-tooltip`}
              isOverModal={true}
              placement={`top-end`}
              TooltipComponent={() => (
                <>
                  <h2 className="h1">Payment Settings</h2>
                  <h3>Autopay</h3>
                  <p>
                    With autopay, Circa will withdraw your scheduled payments
                    automatically from your account. Circa always sends a
                    reminder according to your notification preferences before
                    withdrawing any funds, giving you the opportunity to make
                    last minute changes.
                  </p>
                  <h3>Manual</h3>
                  <p>
                    To pay manually, sign in and navigate to your Circa Wallet
                    each time you need to make a payment. You will still receive
                    payment reminders according to your notification
                    preferences.
                  </p>
                </>
              )}
            />
          </h3>
          <div className={`option-selector`}>
            {PLANPAYMENTSETTINGS.map((planPaymentSetting, i) => (
              <div
                className={`option-wrap plan-payment-setting--${
                  planPaymentSetting.slug
                } block block--selectable low-elevation br-medium${
                  planPaymentSetting.slug === selectedPlanPaymentSetting
                    ? " selected"
                    : ""
                }`}
                data-planpaymentsetting={planPaymentSetting.slug}
                onClick={() => setPlanPaymentSetting(planPaymentSetting.slug)}
                key={i}
              >
                <div className="plan">
                  <div className="plan__icon"></div>
                  <div className="plan__label">
                    <div className="plan__title">
                      {planPaymentSetting.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-primary flex-grow-1"
          onClick={() => {
            onSubmit({
              dataResidents,
              nav,
              planPaymentDates,
              planPaymentDay,
              selectedSchedule,
              selectedPaymentMethod,
              selectedPlanPaymentSetting,
              setMessageBubbleComponent,
              setMessageBubbleVisibility,
              modifyPlanMutation,
              onSuccess
            })
            /* logicPlan.onSubmitModifyPlan to be triggered by `onConfirmChanges` under showModalModifyPlanConfirm */
          }}
        >
          {submitButtonText}
        </button>
      </div>
      {PostSubmitButtonComponent && (
        <PostSubmitButtonComponent
          selectedPlan={selectedPlan}
          selectedSchedule={selectedSchedule}
          planPaymentDates={planPaymentDates}
          planPaymentDay={planPaymentDay}
        />
      )}
    </>
  )
}

export default OrgnWizardPlan
