import React, { useEffect } from "react"

import {
  CircaLoadingAnimationFS
} from "../../../assets"
import { ALLROUTES } from "../../../routes"
import {
  clearOldMsalTokenIfFound,
  manageMsalAuthJwtToken,
  manualMsalLogout
} from "../../../utils"

const ScreenLogout = () => {

  useEffect(() => {
    const msalAuthJwtToken = manageMsalAuthJwtToken.get()
    if (!!msalAuthJwtToken) {
      clearOldMsalTokenIfFound()
    }
      manualMsalLogout()
    window.location = ALLROUTES.dashboardChildren.dashboard
  }, [])

  return <CircaLoadingAnimationFS />
}

export default ScreenLogout
