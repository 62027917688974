import React from "react"

import { showModalModifyPayment } from "../payment/modify-payment"
import OrgnWizardPayOtherAmount from "./wizard-pay-other-amount"

import "./plan.scss"

export const showPayOtherAmountModal = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  refetchDashboard,
  allUpcomingPayments,
  ...modifyPaymentParams
  /**
   * `modifyPaymentParams` contains the following:
   * - amount,
   * - customerPaymentArrearsId,
   * - customerPaymentId,
   * - date,
   * - isLatePayment,
   * - planTypeName
   **/
}) => {
  setModalClass("modal--pay-other-amount narrow-content")
  setModalBreadcrumb([
    {
      label: "Modify Your Plan",
      onClick: () => {
        showModalModifyPayment({
          setModalClass,
          setModalBreadcrumb,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          refetchDashboard,
          allUpcomingPayments,
          ...modifyPaymentParams
        })
      }
    },
    { label: "Pay Other Amount" }
  ])
  setModalComponent(() => (
    <OrgnWizardPayOtherAmount
      setMessageBubbleComponent={setMessageBubbleComponent}
      setMessageBubbleVisibility={setMessageBubbleVisibility}
      refetchDashboard={refetchDashboard}
      allUpcomingPayments={allUpcomingPayments}
      modifyPaymentParams={modifyPaymentParams}
    />
  ))
  setModalVisibility(true)
}
