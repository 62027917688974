import React, { useEffect, useState } from "react"

import { APPCONFIG } from "../../../config"
import { AllIcons, AnimationLoadingFullScreen } from "../../../assets"
import { reduceApiData } from "../../../utils"

import { showModifyPlanModal } from "./modify-plan"
import { showScheduleExceptionModal } from "./schedule-exception"

import { useDashboardQueries } from "../../../utils/queries"

import "./plan.scss"

export const showPlanModal = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  leasePlanDetails,
  refetchLeasePlanDetails,
  refetchDashboard
}) => {
  const exceptionClickable = true

  setModalClass("modal--plan-summary narrow-content")
  setModalBreadcrumb([
    {
      label: ""
    }
  ])
  setModalComponent(() => {
    const {
      data: dataResidents,
      isFetching: isFetchingResidents
    } = useDashboardQueries.useResidentsQuery({
      config: { refetchOnMount: true }
    })

    const {
      data: dataLeasePlanDetails,
      isFetching: isFetchingLeasePlanDetails
    } = useDashboardQueries.useLeasePlanDetailsQuery({
      config: { refetchOnMount: true },
      enabled: !!dataResidents,
      residentId: dataResidents?.data?.residentData?.id,
      leaseId: leasePlanDetails?.leaseId
    })

    const [planSummaryItems, setPlanSummaryItems] = useState([])
    useEffect(() => {
      let applicableLeasePlanDetails = !!dataLeasePlanDetails
        ? reduceApiData(dataLeasePlanDetails, "lease_plan_details")
        : leasePlanDetails

      setPlanSummaryItems([
        {
          label:
            applicableLeasePlanDetails?.leasePlanInformation
              ?.planTypeDisplayName,
          Icon: () => (
            <div
              className={`plan--${applicableLeasePlanDetails?.iconSlugPlanType}`}
            >
              <div className="plan__icon"></div>
            </div>
          )
        },
        {
          label: applicableLeasePlanDetails?.humanReadablePlanDateText,
          Icon: () => <AllIcons.Calendar className="plan__icon" />
        },
        {
          label:
            applicableLeasePlanDetails?.leasePlanInformation?.paymentMethodName,
          Icon: () =>
            !!applicableLeasePlanDetails?.IconPaymentMethod ? (
              <applicableLeasePlanDetails.IconPaymentMethod
                className={`plan__icon${
                  applicableLeasePlanDetails?.leasePlanInformation
                    ?.paymentMethodName ===
                  APPCONFIG.api.globalPaymentMethods.CASHAPP.label
                    ? ` has-${APPCONFIG.api.globalPaymentMethods.CASHAPP.slug}`
                    : ""
                }`}
              />
            ) : (
              <></>
            )
        },
        {
          label: applicableLeasePlanDetails?.leasePlanInformation?.isAutoPay
            ? "Autopay"
            : "Manual",
          Icon: () =>
            !!applicableLeasePlanDetails?.IconPaymentSetting ? (
              <applicableLeasePlanDetails.IconPaymentSetting className="plan__icon" />
            ) : (
              <></>
            )
        }
      ])
    }, [dataLeasePlanDetails, leasePlanDetails])

    if (isFetchingResidents || isFetchingLeasePlanDetails) {
      return <AnimationLoadingFullScreen minVh100={false} />
    }

    return (
      <>
        <h2 className="billboard text-center mb-4">Plan Summary</h2>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h3 className="mb-0">Monthly Plan</h3>
            <button
              className="btn btn-text text-only"
              onClick={() => {
                showModifyPlanModal({
                  setModalClass,
                  setModalBreadcrumb,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility,
                  leasePlanDetails,
                  refetchLeasePlanDetails,
                  refetchDashboard
                })
              }}
            >
              Edit
            </button>
          </div>
          <div className="plan-summary-items d-flex flex-column">
            {planSummaryItems.map((item, i) => (
              <div
                className="plan-summary-item d-flex align-items-center"
                key={i}
              >
                <item.Icon />
                {item.label}
              </div>
            ))}
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <h3 className="mb-0">Exceptions</h3>
            <button
              className="btn btn-text text-only"
              onClick={() => {
                showScheduleExceptionModal({
                  setModalClass,
                  setModalBreadcrumb,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility,
                  leasePlanDetails:
                    dataLeasePlanDetails?.data || leasePlanDetails,
                  refetchLeasePlanDetails,
                  refetchDashboard
                })
              }}
            >
              Add
            </button>
          </div>
          {(dataLeasePlanDetails?.data || leasePlanDetails)
            ?.exceptionPlanInformation?.length > 0 ? (
            <div className="plan-exceptions-list pt-3">
              {(
                dataLeasePlanDetails?.data || leasePlanDetails
              )?.exceptionPlanInformation?.map((exceptionItem, i) => (
                <div
                  className={`plan-exception-item mb-3${
                    exceptionClickable
                      ? " d-flex align-items-center clickable block low-elevation br-medium p-3"
                      : ""
                  }`}
                  key={i}
                  onClick={() => {
                    showScheduleExceptionModal({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      leasePlanDetails:
                        dataLeasePlanDetails?.data || leasePlanDetails,
                      refetchLeasePlanDetails,
                      refetchDashboard,
                      contractPaymentId: exceptionItem?.contractPaymentId
                    })
                  }}
                >
                  <div className="plan-exception-details">
                    <h4>{exceptionItem?.exceptionMonth}</h4>
                    <p className="m-0">
                      {exceptionItem?.planDetailsDecription}
                    </p>
                  </div>
                  {exceptionClickable ? (
                    <div className="caret-right"></div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="no-scheduled-exceptions text-center mt-4">
              No scheduled exceptions
            </p>
          )}
        </div>
      </>
    )
  })
  setModalVisibility(true)
}
