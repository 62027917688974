import React from "react"

import { APPCONFIG } from "../../../config"

const OrgnFormCashPayment = ({ cashPaymentCallback }) => {
  return (
    <>
      <div className="freeze-to-top">
        <h2 className="billboard text-center mb-3 pb-2">In-Person Cash</h2>
      </div>
      <div className="color-grayscale-graphite">
        <p className="mb-3 pb-2">
          Pay your rent with cash by visiting an agent retailer near you.
        </p>
        <h3 className="mb-3">Step 1: Find an Agent</h3>
        <p>
          Tap below to find a list of agents near you. Enter your zip code and
          filter results by “Bill Payment” or “Pay a Bill.”
        </p>
        <div className="d-flex flex-column">
          <button
            className="btn btn-bordered"
            onClick={() => {
              window.open(APPCONFIG.staticLinks.findMoneygramAgent)
            }}
          >
            Find Moneygram Agent
          </button>
          <button
            className="btn btn-bordered mt-3"
            onClick={() => {
              window.open(APPCONFIG.staticLinks.findFidelityExpressAgent)
            }}
          >
            Find Fidelity Express Agent
          </button>
        </div>
        <h3 className="mb-3 mt-3 pt-2">Step 2: Pay In-Person</h3>
        <p className="mb-2">Tell the agent you are paying a Circa bill.</p>
        <p className="mb-2">
          After a one-time account setup, you will provide your phone number and
          pay with cash. A small processing fee will apply.
        </p>
        <p className="mb-2">
          Visit our{" "}
          <a href={APPCONFIG.staticLinks.supportCenter} target="_blank">
            Support Center
          </a>{" "}
          for more details, as the specific process may vary by location.
        </p>
        {cashPaymentCallback && (
          <>
            <div className="btns-wrap d-flex widget mt-4">
              <button
                className="btn btn-primary flex-grow-1"
                onClick={() => {
                  cashPaymentCallback()
                }}
              >
                Continue with In-Person Cash
              </button>
            </div>
            <p className="smaller-text mt-4 mb-0 text-center">
              Access this info anytime in
              <br />
              Settings &gt; Payment Methods.
            </p>
          </>
        )}
      </div>
    </>
  )
}
export default OrgnFormCashPayment
