import React, { useState } from "react"
import { Form } from "react-bootstrap"

const PasswordField = ({
  field,
  formField,
  invalid,
  isDirty,
  error,
  onChange,
  onKeyUpHandle
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <Form.Control
        className={`${invalid && isDirty ? "is-invalid" : ""}`}
        {...field}
        {...formField}
        onChange={onChange}
        onKeyUp={onKeyUpHandle}
        type={showPassword ? "text" : formField.type}
        placeholder={formField?.placeholder || ""}
        disabled={formField?.disabled || ""}
      />
      {formField.type === "password" ? (
        <span
          className="visibility-toggle"
          onClick={() => {
            setShowPassword(!showPassword)
          }}
        ></span>
      ) : (
        ""
      )}
    </>
  )
}
export default PasswordField
