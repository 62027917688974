import React from "react"

import { ALLROUTES } from "../../../routes"
import { APPCONFIG } from "../../../config"
import { formatDateToFormat, formatDateToHumanReadable } from "../../../utils"

import ItemsList from "../../elements/items-list"

const OrgnSetupLeaseDetails = ({
  leaseDetails,
  submitButtonText,
  onSubmit,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const itemList1 = [
    {
      name: "Community",
      value: leaseDetails?.communityName
    },
    {
      name: "Address",
      value: [
        leaseDetails?.addressInformation?.addressLine1,
        leaseDetails?.addressInformation?.addressLine2
      ]
        .filter(item => !!item)
        .join(", ")
    },
    {
      name: "Unit",
      value: leaseDetails?.addressInformation?.unit
    },
    {
      name: "City",
      value: leaseDetails?.addressInformation?.city
    },
    {
      name: "State",
      value: leaseDetails?.addressInformation?.state
    },
    {
      name: "Zip Code",
      value: leaseDetails?.addressInformation?.zipCode
    }
  ]

  const itemList2 = [
    {
      name: "Monthly Rent",
      value: `$${leaseDetails?.monthlyRent}`
    },
    ...(!!leaseDetails?.startDate
      ? [
          {
            name: "Start Date",
            value: formatDateToFormat(
              new Date(leaseDetails?.startDate),
              "MM/DD/YYYY"
            )
          }
        ]
      : []),
    ...(!!leaseDetails?.endDate
      ? [
          {
            name: "End Date",
            value: formatDateToFormat(
              new Date(leaseDetails?.endDate),
              "MM/DD/YYYY"
            )
          }
        ]
      : [])
  ]

  const showReportAnErrorPopup = () => {
    setMessageBubbleComponent(() => {
      return (
        <>
          <h2 className="h1">Report an Error</h2>
          <p>
            If any of these lease details are incorrect, please contact us
            before continuing your account setup.
          </p>
          <p>
            Email us anytime at{" "}
            <a href={ALLROUTES.supportEmailLink}>{APPCONFIG.supportEmail}</a> or
            call us during business hours at{" "}
            <a href={ALLROUTES.supportTelephoneLink}>
              {APPCONFIG.supportTelephone}
            </a>
            .
          </p>
        </>
      )
    })
    setMessageBubbleVisibility(true)
  }

  return (
    <div className="organism__lease-details generic-form-wrap narrow-centered">
      <h1 className="billboard text-center">Lease Details</h1>
      <h3>Address</h3>
      <ItemsList items={itemList1} />
      <h3>Lease Information</h3>
      <ItemsList items={itemList2} />
      {submitButtonText ? (
        <button
          className="btn btn-primary d-block mx-auto w-100"
          onClick={onSubmit}
        >
          {submitButtonText}
        </button>
      ) : (
        ""
      )}
      <button
        className="btn btn-text text-only d-block mx-auto mt-4 w-100"
        onClick={showReportAnErrorPopup}
      >
        Report an Error
      </button>
    </div>
  )
}

export default OrgnSetupLeaseDetails
