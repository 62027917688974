import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { iOS } from "../../utils"
import { ALLROUTES } from "../../routes"
import { DashboardWrapperNoAuth } from "../../components/wrappers/dashboard"
import { GraphicBeRightBack } from "../../assets"
import FullScreenCentered from "../../components/wrappers/full-screen-centered"

import "./server-down.scss"

const ScreenServerDown = () => {
  const nav = useNavigate()

  return (
    <DashboardWrapperNoAuth
      bodyClass={`screen__login d-flex flex-row-reverse flex-wrap`}
    >
      <FullScreenCentered className={`screen__server-down text-center`}>
        <GraphicBeRightBack />

        <h1 className="billboard">We&#8217;ll be right back!</h1>

        <p>
          Circa is temporarily offline for routine, scheduled maintenance. For
          immediate support, please{" "}
          <a href={ALLROUTES.supportEmailLink}>contact us</a>.
        </p>
      </FullScreenCentered>
    </DashboardWrapperNoAuth>
  )
}

export default ScreenServerDown
