import React from "react"
import { ALLROUTES } from "../../../routes"

import "./message-bubble.scss"

const MessageBubble = ({
  children,
  onHide,
  show,
  contentClassName,
  headingText,
  messageText
}) => {
  return (
    <>
      <div
        className={`message-bubble-backdrop ${show ? "show-bubble" : ""}`}
        onClick={onHide}
      ></div>
      <div
        className={`message-bubble ${contentClassName} ${
          show ? "is-visible" : ""
        }`}
      >
        <button className="close" onClick={onHide}></button>
        {headingText ? <h3>{headingText}</h3> : ""}
        <div className="message-text">
          {messageText ? <p>{messageText}</p> : ""}
          {children}
        </div>
      </div>
    </>
  )
}
export default MessageBubble
