import React from "react"
import { useNavigate } from "react-router-dom"
import Slider from "react-slick"

import { ALLROUTES } from "../../routes"
import { displayAmount, formatDateToFormat, handleize } from "../../utils"

import { showModalModifyPayment } from "../organisms/payment/modify-payment"

import "./widgets.scss"

const DashWidgetUpcomingPaymentsCarousel = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  refetchDashboard,
  upcomingPaymentDetails
}) => {
  const nav = useNavigate()

  const slickSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.6,
    slidesToScroll: 1,
    //swipeToSlide: true,
    //variableWidth: true,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 7
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4.4
        }
      }
    ],
    afterChange: index => {}
  }

  return (
    <div className="dash-widget upcoming-pymt-carousel has-tile-carousel">
      <Slider {...slickSettings}>
        {upcomingPaymentDetails.map((upcomingPaymentItem, i) =>
          upcomingPaymentItem?.seeAll ? (
            <div key={i} className="tile-item-wrap">
              <div
                className={`tile-item see-all clickable`}
                onClick={() => {
                  nav(ALLROUTES.dashboardChildren.settingsChildren.payments)
                }}
              >
                <div className="up-pymt__label plan__label">
                  <div className="up-pymt__title plan__title">See all</div>
                </div>
                <div className="long-arrow-right"></div>
              </div>
            </div>
          ) : (
            <div key={i} className="tile-item-wrap">
              <div
                className={`tile-item plan--${handleize(
                  upcomingPaymentItem?.planType ||
                    upcomingPaymentItem?.planTypeName,
                  ""
                )} clickable`}
                onClick={() => {
                  showModalModifyPayment({
                    setModalClass,
                    setModalBreadcrumb,
                    setModalComponent,
                    setModalVisibility,
                    setMessageBubbleComponent,
                    setMessageBubbleVisibility,
                    refetchDashboard,
                    allUpcomingPayments: upcomingPaymentDetails.filter(
                      item => !item?.seeAll
                    ),
                    ...upcomingPaymentItem
                  })
                }}
              >
                <div className="up-pymt__icon plan__icon"></div>
                <div className="up-pymt__label plan__label">
                  <div className="up-pymt__subtitle plan__subtitle">
                    {!!upcomingPaymentItem?.amount
                      ? `$${displayAmount(upcomingPaymentItem?.amount)}`
                      : ""}
                  </div>
                  <div className="up-pymt__title plan__title">
                    {!!upcomingPaymentItem?.date
                      ? formatDateToFormat(new Date(upcomingPaymentItem?.date))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </Slider>
    </div>
  )
}
export default DashWidgetUpcomingPaymentsCarousel
