import React from "react"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import OrgnInfoCardSecurity from "../card-information/card-security-info"

export const TooltipContentPaymentMethods = () => {
  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  return (
    <>
      <h2 className="h1 mb-2">Payment Methods</h2>
      <h3 className="mb-3 mt-3">Bank Account</h3>
      <p>No additional fees</p>
      <p className="mt-2">
        Add your bank information to pay rent directly from your account. This
        is one of the quickest, safest, and cheapest ways to pay.
      </p>
      <h3 className="mb-3 mt-3">Debit/Credit Card</h3>
      <p>+ 3% processing fee</p>
      <p className="mt-2">
        Pay using your debit or credit card. Transactions are powered by Stripe.
        Circa accepts Visa, Mastercard, and Discover.
      </p>
      <p className="mt-2">
        <a
          className="clickable"
          onClick={() => {
            setModalBreadcrumb([{ label: "" }])
            setModalClass("modal--card-security narrow-content")
            setModalComponent(() => (
              <div className="modal-content-height d-flex flex-column">
                <OrgnInfoCardSecurity />
              </div>
            ))
            setModalVisibility(true)
          }}
        >
          Click here to learn more.
        </a>
      </p>
      <h3 className="mb-3 mt-3">Cash App</h3>
      <p>+ 2.75% processing fee</p>
      <p className="mt-2">Use Cash App to pay to $wearecirca.</p>
      <h3 className="mb-3 mt-3">In-Person Cash</h3>
      <p>+ agent fee</p>
      <p className="mt-2">
        Visit an agent at a nearby retailer to pay using cash. We’ll help you
        find an agent near you.
      </p>
      <h3 className="mb-3 mt-3">Don’t Forget!</h3>
      <p>
        You can always change your payment method up to 1 business day before
        your scheduled payment. You can even change mid-month if you have
        multiple payments scheduled.
      </p>
    </>
  )
}
