import { ALLROUTES } from "../../../routes"
import { displayAmount, formatDateToFormat } from "../../../utils"

export const showRBC = {
  almostDone: ({
    setRedBannerVisibility,
    setRedBannerDays,
    setRedBannerLeftComponent,
    setRedBannerCenterComponent,
    setRedBannerCta,
    nav
  }) => {
    setRedBannerDays({})
    setRedBannerCenterComponent(() => (
      <>
        <h3>Almost done!</h3>
        <p>Finish your lease setup to start paying rent.</p>
      </>
    ))
    setRedBannerCta({
      ctaText: "Finish",
      ctaOnClick: () => {
        setRedBannerVisibility(false)
        nav(ALLROUTES.accountSetupChildren.contactInformation)
      }
    })
    setRedBannerVisibility(true)
  },

  paymentFailed: ({
    setRedBannerVisibility,
    setRedBannerDays,
    setRedBannerLeftComponent,
    setRedBannerCenterComponent,
    setRedBannerCta,
    metaData: { daysToAct },
    text,
    urgent = false,
    ctaText = "Reschedule",
    ctaOnClick
  }) => {
    setRedBannerDays({ daysCount: daysToAct, daysSuffix: "days to act" })
    setRedBannerLeftComponent(null)
    setRedBannerCenterComponent(() => (
      <>
        {!!text && <h3>{text}</h3>}
        {urgent && <p>Urgent Action Required</p>}
        <p>If you do not reschedule, we have to pause your account.</p>
      </>
    ))
    setRedBannerCta({
      ctaClass: "btn-danger",
      ctaText,
      ctaOnClick
    })
    setRedBannerVisibility(true)
  },

  rentAssistBalanceActionRequired: ({
    setRedBannerVisibility,
    setRedBannerDays,
    setRedBannerLeftComponent,
    setRedBannerCenterComponent,
    setRedBannerCta,
    text,
    ctaOnClick
  }) => {
    const splitter = ". "
    const textHeading =
      text?.indexOf(splitter) > -1 ? text?.split(splitter)?.[0] : ""
    const textContent =
      text?.indexOf(splitter) > -1 ? text?.split(splitter)?.[1] : text
    setRedBannerDays({})
    setRedBannerLeftComponent(() => (
      <>
        <h2 className="h1 mb-0">Action Required</h2>
      </>
    ))
    setRedBannerCenterComponent(() => (
      <>
        {textHeading && <h3>{textHeading}</h3>}
        {textContent && <p>{textContent}</p>}
      </>
    ))
    setRedBannerCta({
      ctaClass: "btn-bordered",
      ctaText: "Make A Plan",
      ctaOnClick
    })
    setRedBannerVisibility(true)
  },

  referralEarned: ({
    setRedBannerClassName,
    setRedBannerVisibility,
    setRedBannerDays,
    setRedBannerLeftComponent,
    setRedBannerCenterComponent,
    setRedBannerCta,
    text,
    title,
    ctaOnClick,
    setRedBannerPostCtaComponent
  }) => {
    setRedBannerClassName("banner--info")
    setRedBannerDays({})
    setRedBannerLeftComponent(() => <></>)
    setRedBannerCenterComponent(() => (
      <>
        <h3>{title}</h3>
        <p className="mw-400">{text}</p>
      </>
    ))
    setRedBannerCta({
      ctaClass: "btn-bordered",
      ctaText: "Refer A Neighbor",
      ctaOnClick
    })
    setRedBannerPostCtaComponent(
      () => <></>
      /* future feature, disabled for now (
      <a className="btn btn-text text-only ms-3">View Stats</a>
      )*/
    )
    setRedBannerVisibility(true)
  }
}
