import { useEffect, useRef, useState } from "react"
import Cookies from "universal-cookie"

import { AuthenticationState } from "react-aad-msal"
import { signInAuthProvider } from "../authProvider"

import { APPCONFIG, dayNames, monthNames } from "../config"
import { ALLROUTES } from "../routes"
import { AllIcons } from "../assets"
import { leaseIdInSetup, leaseIdOnDash } from "./logic"

export const msalAuthenticated = msalAuthState => {
  return msalAuthState === AuthenticationState.Authenticated
}

export const simulateMsalAuthOnSignup = (signUpData, email) => {
  // save token to sessionStorage
  if (!!signUpData?.responseToken) {
    manageMsalAuthJwtToken.save(signUpData?.responseToken)
  }
  return
  // simulate sessionStorage variables that the `react-aad-msal` library sets
  if (APPCONFIG.debugGlobal) {
    console.log("%csignUpData", "background-color:red;color:white")
    console.log(signUpData)
  }
  const idToken = signUpData.responseToken
  const idTokenHeader = JSON.parse(atob(idToken?.split(".")?.[0] || {}))
  const idTokenPayload = JSON.parse(atob(idToken?.split(".")?.[1] || {}))
  const clientInfo = btoa(
    JSON.stringify({
      uid: idTokenPayload.oid,
      utid: idTokenPayload.tid
    })
  )
  SessionStorage.write(APPCONFIG.sessionVariables.msalAuthIdToken, idToken)
  SessionStorage.write(
    APPCONFIG.sessionVariables.msalAuthClientInfo,
    clientInfo
  )
  const thirdEntryKey = `{"authority":"${APPCONFIG.msal.signInAuthority.toLowerCase()}","clientId":"${
    APPCONFIG.msal.applicationID
  }","homeAccountIdentifier":"${clientInfo}"}`
  const thirdEntryValue = `{"accessToken":"${idToken}","idToken":"${idToken}","expiresIn":"${idTokenPayload.exp}","homeAccountIdentifier":"${clientInfo}"}`
  SessionStorage.write(thirdEntryKey, thirdEntryValue)

  const cookies = new Cookies()
  cookies.set(APPCONFIG.sessionVariables.msalAuthIdToken, idToken, {
    path: "/"
  })
  cookies.set(APPCONFIG.sessionVariables.msalAuthClientInfo, clientInfo, {
    path: "/"
  })
}

export const LocalStorage = {
  write: (key, val) => {
    if (val && typeof val === "object")
      localStorage.setItem(key, JSON.stringify(val))
    else localStorage.setItem(key, val)
  },
  read: key => {
    let str = localStorage.getItem(key)
    if (["{", "["].find(e => str?.charAt(0) === e) || str === "null") {
      return JSON.parse(str)
    }
    return str
  },
  remove: key => {
    localStorage.removeItem(key)
  }
}

export const SessionStorage = {
  write: (key, val) => {
    if (val && typeof val === "object")
      sessionStorage.setItem(key, JSON.stringify(val))
    else sessionStorage.setItem(key, val)
  },
  read: key => {
    let str = sessionStorage.getItem(key)
    if (["{", "["].find(e => str?.charAt(0) === e) || str === "null") {
      return JSON.parse(str)
    }
    return str
  },
  remove: key => {
    sessionStorage.removeItem(key)
  }
}

export const manageMsalAuth = {
  save: accountInfo => {
    SessionStorage.write(
      APPCONFIG.sessionVariables.msalAuthIdToken,
      accountInfo
    )
    manageMsalAuthJwtToken.save(accountInfo?.jwtIdToken)
  },
  delete: () => {
    SessionStorage.remove(APPCONFIG.sessionVariables.msalAuthIdToken)
    manageMsalAuthJwtToken.delete()
  },
  get: () => {
    return SessionStorage.read(APPCONFIG.sessionVariables.msalAuthIdToken)
  }
}

export const manageMsalAuthJwtToken = {
  save: msalJwtToken => {
    SessionStorage.write(APPCONFIG.sessionVariables.msalJwtToken, msalJwtToken)
  },
  delete: () => {
    SessionStorage.remove(APPCONFIG.sessionVariables.msalJwtToken)
  },
  get: () => {
    return SessionStorage.read(APPCONFIG.sessionVariables.msalJwtToken)
  },
  isValid: () => {
    const jwtToken = SessionStorage.read(
      APPCONFIG.sessionVariables.msalJwtToken
    )
    const jwtTokenPayload = jwtToken?.split(".")?.[1] || {}
    return !!jwtTokenPayload?.exp
      ? new Date(jwtTokenPayload?.exp * 1000).getTime() > new Date().getTime()
      : false
  }
}

export const refreshMsalTokens = {
  getIdToken: () => {
    consoleLog(`getIdToken`)
    signInAuthProvider.getIdToken().then(token => {})
  },
  getAccessToken: () => {
    consoleLog(`getAccessToken`)
    signInAuthProvider.getAccessToken().then(token => {})
  }
}

export const manualMsalLogout = () => {
  if (!!manageMsalAuthJwtToken?.get() && APPCONFIG.debugGlobal) {
    consoleLog("Clearing old session")
  }
  sessionStorage.clear()
  // localStorage.clear() - LocalStorage variables are meant to persist and used upon next access
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toLocaleString() + ";path=/")
  })
}
export const manualMsalLogoutAndRedirect = () => {
  manualMsalLogout()
  window.location = APPCONFIG.msal.logoutUrl
}
export const clearOldMsalTokenIfFound = () => {
  const msalAuthJwtToken = manageMsalAuthJwtToken.get()
  const msalAuthJwtTokenIsValid = manageMsalAuthJwtToken.isValid()
  manualMsalLogout()
  if (!!msalAuthJwtToken && !msalAuthJwtTokenIsValid) {
    consoleLog("Found old token", "urgent")
    return true
  }
}

export const checkLastAccessedAndLogout = props => {
  const msalAuthJwtToken = manageMsalAuthJwtToken.get()
  const timeNow = new Date().getTime()
  let dashLastAccessed =
    LocalStorage.read(APPCONFIG.sessionVariables.dashLastAccessed) || timeNow
  dashLastAccessed = dashLastAccessed * 1
  consoleLog(`Last accessed ${new Date(dashLastAccessed).toLocaleString()}`)
  consoleLog(`Time now  ${new Date(timeNow).toLocaleString()}`)
  if (!!props?.markAccessed && !!msalAuthJwtToken) {
    LocalStorage.write(APPCONFIG.sessionVariables.dashLastAccessed, timeNow)
    consoleLog(`Marked dashboard accessed`, "urgent")
  }
  if ((timeNow - dashLastAccessed) / 1000 > 60 * 60 * 2) {
    manualMsalLogoutAndRedirect()
  }
}

export const handleApiError = ({
  status,
  httpStatusCode,
  customHeading,
  message,
  title,
  errordescription,
  apiError,
  nav,
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  if (apiError) {
    nav(ALLROUTES.serverDown)
    return
  }
  setMessageBubbleComponent(() => (
    <>
      <h3>{customHeading || `Action Failed`}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: (
            message ||
            title ||
            errordescription ||
            "A fatal error has occurred"
          )?.replace(
            "Please contact us.",
            `<a href="${ALLROUTES.supportEmailLink}">Please contact us.</a>`
          )
        }}
      ></p>
    </>
  ))
  setMessageBubbleVisibility(true)
}

export const gotoLinkNewLease = ({ nav }) => {
  leaseIdInSetup.unset()
  leaseIdOnDash.unset()
  nav(ALLROUTES.accountSetupChildren.leaseId)
}

export const sanitizeFormValuesForSubmit = formValues => {
  const phoneNumberPrefix =
    localStorage.getItem("phoneNumberPrefix") || APPCONFIG.phoneNumberPrefix
  Object.entries(formValues).map(([key, value]) => {
    if (key.toLowerCase().indexOf("phone") > -1) {
      formValues[key] = !!value
        ? `${phoneNumberPrefix}${value.replace(/\-/g, "")}`
        : ""
    }
  })
  return formValues
}

export const reduceApiData = (apiData, objectName) => {
  switch (objectName) {
    case "available_payment_plans":
      return apiData?.data?.data
    case "dashboard":
      const dashData = apiData?.data?.dashboardResponseObject
      return {
        ...dashData,
        allLeasesCount:
          dashData?.currentLeases?.length +
          dashData?.failedLeases?.length +
          dashData?.pausedLeases?.length +
          dashData?.pendingLeases?.length,
        allLeases: [
          ...dashData?.currentLeases?.map(lease => {
            lease.type = "current"
            return lease
          }),
          ...dashData?.failedLeases?.map(lease => {
            lease.type = "failed"
            return lease
          }),
          ...dashData?.pausedLeases?.map(lease => {
            lease.type = "paused"
            return lease
          }),
          ...dashData?.pendingLeases?.map(lease => {
            lease.type = "pending"
            lease.setupRequired = true
            return lease
          })
        ]
      }
    case "leases":
      const presentLeases = [
        ...apiData?.data?.data?.incompleteLeases.map(lease => {
          lease.type = APPCONFIG.api.leaseIncomplete
          lease.incomplete = true
          return lease
        }),
        ...apiData?.data?.data?.currentLeases.map(lease => {
          lease.type = "current"
          return lease
        })
      ]
      const pastLeases = [
        ...apiData?.data?.data?.pastLeases.map(lease => {
          lease.type = APPCONFIG.variables.typePastLease
          return lease
        })
      ]
      return { ...apiData?.data?.data, presentLeases, pastLeases }
    case "lease_details":
      return apiData?.data
    case "lease_plan_details":
      let IconPlanType = () => <></>
      switch (apiData?.data?.leasePlanInformation?.planTypeName) {
        case APPCONFIG.api.planTypeNames.ONEPAY:
          IconPlanType = AllIcons.plans.PlanOnePay
          break
        case APPCONFIG.api.planTypeNames.ONEDEFERRED:
          IconPlanType = AllIcons.plans.PlanOnePayDeferred
          break
        case APPCONFIG.api.planTypeNames.TWOPAY:
          IconPlanType = AllIcons.plans.PlanTwoPay
          break
        case APPCONFIG.api.planTypeNames.FOURPAY:
          IconPlanType = AllIcons.plans.PlanFourPay
          break
      }
      let IconPaymentMethod = () => <></>
      switch (apiData?.data?.leasePlanInformation?.paymentMethodType) {
        case "Bank":
          IconPaymentMethod = AllIcons.PaymentMethodBank
          break
        case "Card":
          IconPaymentMethod = AllIcons.PaymentMethodCard
          break
        case "Cash":
          IconPaymentMethod = AllIcons.PaymentMethodCash
          break
        case "Cash App":
          IconPaymentMethod = AllIcons.PaymentMethodCashApp
          break
      }
      const paymentDateForDisplay = JSON.parse(
        apiData?.data?.leasePlanInformation?.paymentDateForDisplay || "{}"
      )
      let humanReadablePlanDateText = ""
      if (paymentDateForDisplay?.dayofweek) {
        humanReadablePlanDateText = `${
          dayNames[
            paymentDateForDisplay?.dayofweek + APPCONFIG.api.dayIndexAdjust
          ]
        }s`
      } else if (paymentDateForDisplay?.date1) {
        humanReadablePlanDateText = numberWithOrdinal(
          paymentDateForDisplay?.date1
        )
        if (paymentDateForDisplay?.date2) {
          humanReadablePlanDateText = `${humanReadablePlanDateText} & ${numberWithOrdinal(
            paymentDateForDisplay?.date2
          )}`
        }
      }
      return {
        ...apiData?.data,
        IconPlanType,
        iconSlugPlanType: handleize(
          apiData?.data?.leasePlanInformation?.planTypeName || "",
          ""
        ),
        IconPaymentMethod,
        IconPaymentSetting: apiData?.data?.leasePlanInformation?.isAutoPay
          ? AllIcons.PaymentSettingAutopay
          : AllIcons.PaymentSettingManual,
        paymentDateForDisplay,
        humanReadablePlanDateText
      }
    case "resident_lease_payment_methods":
      const bankAccountList = [
        ...apiData?.data?.paymentMethods?.bankAccountList
      ]
      bankAccountList.forEach(paymentMethod => {
        paymentMethod.type = APPCONFIG.api.bankSlug
        paymentMethod.title = paymentMethod.bankAccountNickName
        return paymentMethod
      })
      const cardList = [...apiData?.data?.paymentMethods?.cardList]
      cardList.forEach(paymentMethod => {
        paymentMethod.type = APPCONFIG.api.cardSlug
        paymentMethod.title = paymentMethod.cardNickName
        return paymentMethod
      })
      return {
        ...apiData?.data?.paymentMethods,
        bankAccountList,
        cardList
      }
    case "notification_settings":
      return {
        accountEssentials: {
          ...apiData?.data?.list.find(
            item => item?.settingsType === "accountEssentials"
          )
        },
        accountUpdates: {
          ...apiData?.data?.list.find(
            item => item?.settingsType === "accountUpdates"
          )
        },
        circa: {
          ...apiData?.data?.list.find(item => item?.settingsType === "circa")
        },
        payment: {
          ...apiData?.data?.list.find(item => item?.settingsType === "payment")
        }
      }
    case "resident":
      return apiData?.data?.residentData
    default:
      return apiData
  }
}

export const iOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

export function toggleResidentNav(toggle) {
  if (toggle === "open") {
    document.body.classList.add("show-nav--resident")
  } else {
    document.body.classList.remove("show-nav--resident")
  }
}

export const calculateAccountSetupPercentage = currentAccountSetupPage => {
  const pages = []
  let index = -1
  for (let page in ALLROUTES.accountSetupChildren) {
    index++
    if (index > 0) {
      pages.push({
        page: ALLROUTES.accountSetupChildren[page],
        index: index
      })
    }
  }
  const currentPage = pages.find(item => item.page === currentAccountSetupPage)
  return Math.floor((currentPage.index / (pages.length + 0.5)) * 100)
}

export const formatDateToString = dateObject => {
  return `${dateObject.getFullYear()}-${zPad(
    dateObject.getMonth() + 1,
    2
  )}-${zPad(dateObject.getDate(), 2)}`
}
export const formatDateToStringUS = dateObject => {
  let d = formatDateToString(dateObject)
  d = d.split("-")
  return `${d[1]}/${d[2]}/${d[0]}`
}
export const formatDateToHumanReadable = dateObject => {
  const d = formatDateToString(dateObject).split("-")
  return `${monthNames[d[1] - 1]} ${d[2] * 1}${numberOrdinal(d[2])}, ${d[0]}`
}
export const formatDateToFormat = (dateObject, format = "MMM DD") => {
  const d = formatDateToString(dateObject).split("-")
  return format
    .replace("DD", zPad(d[2], 2))
    .replace("D", d[2] * 1)
    .replace("o", numberOrdinal(d[2]))
    .replace("MMMM", monthNames[d[1] - 1])
    .replace("MMM", monthNames[d[1] - 1]?.substring(0, 3))
    .replace("MM", zPad(d[1], 2))
    .replace("YYYY", d[0])
}

export const dashifyPhoneNumber = phone => {
  const sanitizedPhone = phone.replace(/\D[^\.]/g, "").slice(-10)
  return `${sanitizedPhone.slice(0, 3)}-${sanitizedPhone.slice(
    3,
    6
  )}-${sanitizedPhone.slice(6)}`
}

export const telephoneLink = phone => {
  return `tel:${phone.replace(/\D/g, "")}`
}

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

export const numberOrdinal = num => {
  const ords = ["th", "st", "nd", "rd"]
  const v = num % 100
  return ords[(v - 20) % 10] || ords[v] || ords[0]
}

export const numberWithOrdinal = num => {
  return `${num}${numberOrdinal(num)}`
}

export const handleize = (text, replaceSpaceWith = "-") => {
  return text?.toLowerCase()?.replace(/\s+/g, replaceSpaceWith)
}

export const zPad = (n, width, z) => {
  z = z || "0"
  n = n + ""
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const displayAmount = (amount, template) => {
  template = template || "AMTDEC"
  const amountWithDecimal = (1 * amount).toFixed(2).replace(".00", "")
  const decimalAmountSplit = `${amountWithDecimal}`.split(".")
  return template
    .replace("AMT", decimalAmountSplit[0])
    .replace("DEC", decimalAmountSplit[1] ? `.${decimalAmountSplit[1]}` : "")
}

export const pluralDayName = dayOfWeek => {
  return `${dayNames[dayOfWeek]}s`
}

export const useEffectOnce = effect => {
  const destroyFunc = useRef()
  const effectCalled = useRef(false)
  const renderAfterCalled = useRef(false)
  const [val, setVal] = useState(0)

  if (effectCalled.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect()
      effectCalled.current = true
    }

    // this forces one render after the effect is run
    setVal(val => val + 1)

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return
      }
      if (destroyFunc.current) {
        destroyFunc.current()
      }
    }
  }, [])
}

export const capFirst = str => str.charAt(0).toUpperCase() + str.slice(1)

export const consoleLog = (message, type) => {
  let styling
  switch (type) {
    case "danger":
      styling = "background-color:red; color:white"
      break
    case "urgent":
      styling = "background-color:rgba(255,0,0,0.1); color:#A00"
      break
    case "info":
    default:
      styling = "background-color: rgba(102, 204, 153, 0.1); color: #2f7d56"
      break
  }
  if (APPCONFIG.debugGlobal) console.log(`%c${message}`, styling)
}
