import { APPCONFIG } from "../config"
import { ALLROUTES } from "../routes"

import { consoleLog, reduceApiData, SessionStorage } from "."

export const getRedirectInfoForWorkflowMetaData = ({ workflowMetaData }) => {
  const {
    leaseId,
    referralId,
    uniqueAccountNumber,
    workflowStageName,
    zipCode
  } = workflowMetaData || {}

  consoleLog(`[getRedirectInfoForWorkflowMetaData] Lease #${leaseId}`)

  let redirectToRoute = null,
    fwdState = {},
    showWelcomeBackPopup = true
  switch (workflowStageName) {
    case APPCONFIG.workflowStageNames.REGISTRATION:
      break

    case APPCONFIG.workflowStageNames.PROFILE_UPDATE:
      redirectToRoute = ALLROUTES.accountSetupChildren.leaseId
      break

    case APPCONFIG.workflowStageNames.LINK_LEASE:
      redirectToRoute = ALLROUTES.accountSetupChildren.leaseDetails
      fwdState = {
        leaseId: uniqueAccountNumber,
        pinCode: zipCode,
        referralResidentId: referralId
      }
      break

    case APPCONFIG.workflowStageNames.LINK_LEASE_CONFIRMED:
      redirectToRoute = ALLROUTES.accountSetupChildren.selectPlan
      break

    case APPCONFIG.workflowStageNames.PAYMENT_PLAN:
      redirectToRoute = ALLROUTES.accountSetupChildren.selectPaymentMethod
      break

    case APPCONFIG.workflowStageNames.PAYMENT_METHOD:
      redirectToRoute = ALLROUTES.accountSetupChildren.selectAutopay
      break

    // setup complete, move to dashboard
    case APPCONFIG.workflowStageNames.MANUAL_AUTO_PAY:
    case APPCONFIG.workflowStageNames.NOTIFICATIONS:
      if (!!leaseIdOnDash.get() || !!leaseIdInSetup.get()) {
        consoleLog(
          `CHECK EITHER leaseIdOnDash(${leaseIdOnDash.get()}) OR leaseIdInSetup(${leaseIdInSetup.get()}) IS AVAILABLE`
        )
        redirectToRoute = ALLROUTES.dashboardChildren.dashboard
        showWelcomeBackPopup = false
      }
      break
  }
  if (APPCONFIG.debugGlobal)
    consoleLog(`[WORKFLOW STAGE NAME] ${workflowStageName}`, "urgent")
  redirectToRoute = redirectToRoute?.replace(/\/$/, "")
  return {
    redirectToRoute,
    fwdState,
    showWelcomeBackPopup
  }
}

export const redirectPerLeaseSetupProgress = ({
  nav,
  pathname,
  dataResidents,
  errorResidents,
  leaseId = null
}) => {
  consoleLog(`[redirectPerLeaseSetupProgress] lease #${leaseId}`)

  if (APPCONFIG.api.forceRedirectOnFirstIncompleteLease === false) return

  if (
    !!errorResidents &&
    errorResidents?.response?.status === 404 &&
    pathname !== ALLROUTES.accountSetupChildren.contactInformation
  ) {
    nav(ALLROUTES.accountSetupChildren.contactInformation)
    return
  }

  if (!dataResidents) return

  const reducedDataResident = reduceApiData(dataResidents, "resident")

  const chosenWorkflowMetaData =
    reducedDataResident?.workflowMetaData?.find(
      wfMetaData => wfMetaData?.leaseId * 1 === leaseId * 1
    ) || reducedDataResident?.workflowMetaData?.[0]

  const {
    redirectToRoute,
    fwdState,
    showWelcomeBackPopup
  } = getRedirectInfoForWorkflowMetaData({
    workflowMetaData: chosenWorkflowMetaData
  })

  if (!!redirectToRoute && pathname !== redirectToRoute) {
    if (APPCONFIG.debugGlobal) {
      consoleLog(`[REDIRECTING] ${redirectToRoute}`, "danger")
      console.log("fwdState", fwdState)
    }
    return () => {
      nav(redirectToRoute, { state: { showWelcomeBackPopup, ...fwdState } })
    }
  }
  return false
}

export const leaseIdOnDash = {
  get: () => {
    return SessionStorage.read(APPCONFIG.sessionVariables.leaseIdOnDash)
  },
  set: leaseId => {
    SessionStorage.write(APPCONFIG.sessionVariables.leaseIdOnDash, leaseId)
  },
  unset: () => {
    SessionStorage.remove(APPCONFIG.sessionVariables.leaseIdOnDash)
  }
}

export const leaseIdInSetup = {
  get: () => {
    return SessionStorage.read(APPCONFIG.sessionVariables.leaseIdInSetup)
  },
  set: leaseId => {
    SessionStorage.write(APPCONFIG.sessionVariables.leaseIdInSetup, leaseId)
  },
  unset: () => {
    SessionStorage.remove(APPCONFIG.sessionVariables.leaseIdInSetup)
  }
}

export const pickALeaseForDisplay = ({
  nav,
  dataResidents,
  reducedDataDashboard
}) => {
  const response = { onDisplay: {} }
  let pickableLeases = [
    ...reducedDataDashboard?.currentLeases,
    ...reducedDataDashboard?.failedLeases
  ]
  if (!!dataResidents) {
    let completeLeaseIds = []
    dataResidents?.data?.residentData?.workflowMetaData?.forEach(workflow => {
      if (
        [
          APPCONFIG.workflowStageNames.MANUAL_AUTO_PAY,
          APPCONFIG.workflowStageNames.NOTIFICATIONS
        ].indexOf(workflow?.workflowStageName) > -1
      ) {
        completeLeaseIds.push(workflow?.leaseId)
      }
    })
    if (completeLeaseIds.length > 0) {
      pickableLeases = pickableLeases.filter(
        lease => completeLeaseIds.indexOf(lease?.leaseId) > -1
      )
    }
  }
  if (pickableLeases?.length > 0) {
    const lastSavedLeaseId = leaseIdOnDash.get()
    const savedLeaseIdOnDisplay = !!lastSavedLeaseId
      ? lastSavedLeaseId * 1
      : pickableLeases?.[0]?.leaseId
    response.onDisplay.leaseId = savedLeaseIdOnDisplay
    response.onDisplay.lease = reducedDataDashboard?.allLeases.find(
      lease => lease?.leaseId === savedLeaseIdOnDisplay
    )
  }
  return response
}
