import React from "react"

import YouPayOnThe from "./you-pay-on-the"

import "./widgets.scss"

const DashWidgetRentReminder = ({ headerDetails, onClick, onModifyClick }) => {
  return (
    <YouPayOnThe
      accentuated={true}
      className={`dash-widget rent-reminder p-4`}
      fullSpaceBetween={true}
      leftLabel={headerDetails?.line1Display}
      leftInfo={headerDetails?.line2Display}
      onClick={event => {
        onClick({ event, ...headerDetails })
      }}
      RightComponent={() => (
        <button
          className="btn btn-bordered"
          onClick={event => {
            onModifyClick({ event, ...headerDetails })
          }}
        >
          Modify
        </button>
      )}
    />
  )
}
export default DashWidgetRentReminder
