import React from "react"

import CalendarMonth from "./calendar-month"

import { formatDateToString } from "../../../utils"

import "./calendar.scss"

const Calendar = ({ previewData }) => {
  const monthsToShow = [
    ...new Set(
      previewData?.data?.data?.paymentDates?.map(date =>
        new Date(date).getMonth()
      )
    )
  ]

  return (
    <div className="preview-calendar">
      {monthsToShow.map((monthIndex, i) => {
        let paymentDates = previewData?.data?.data?.paymentDates.filter(
          date => new Date(date).getMonth() === monthIndex
        )
        let holidayDates = previewData?.data?.data?.holidays.filter(
          date => new Date(date).getMonth() === monthIndex
        )
        return (
          <CalendarMonth
            compact={true}
            hideDays={true}
            startDate={previewData?.data?.data?.paymentDates.find(
              date => new Date(date).getMonth() === monthIndex
            )}
            paymentDates={paymentDates}
            holidayDates={holidayDates}
            key={i}
          />
        )
      })}
    </div>
  )
}
export default Calendar
