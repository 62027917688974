import React, { useRef, useState } from "react"
import { Overlay, Tooltip } from "react-bootstrap"

const HelpIconWithTooltip = ({
  className,
  tooltipClass,
  TooltipComponent,
  formField,
  placement,
  isOverModal
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const tooltipTarget = useRef(null)

  return (
    <>
      <span
        className={`icon-help ${className}`}
        ref={tooltipTarget}
        onClick={() => setShowTooltip(!showTooltip)}
      ></span>
      <Overlay
        target={tooltipTarget.current}
        show={showTooltip}
        placement={placement || "bottom-end"}
      >
        {props => (
          <Tooltip
            id={`overlay-${formField?.name}`}
            className={`${tooltipClass} ${
              isOverModal ? " tooltip--over-modal" : ""
            }`}
            {...props}
          >
            <TooltipComponent />
            <span
              className="close"
              onClick={() => setShowTooltip(false)}
            ></span>
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}
export default HelpIconWithTooltip
