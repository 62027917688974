import React from "react"

import HelpPanel from "../elements/help-panel/help-panel"

export const showModalHelp = ({
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility
}) => {
  setModalBreadcrumb([{ label: "" }])
  setModalComponent(() => (
    <div className="modal-content-height d-flex flex-column">
      <div className="freeze-to-top">
        <h2 className="billboard text-center mx-n2 mb-3">How can we help?</h2>
      </div>
      <div className="pt-5"></div>
      <HelpPanel />
      <div className="pt-4"></div>
    </div>
  ))
  setModalVisibility(true)
}
