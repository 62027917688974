const webAppBaseUrl = `https://${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`

const tenant = "deveppopay.onmicrosoft.com"
const applicationID = "8e3bc58c-a371-4ccf-a04f-124ebc083c87" // "742dbeaa-009e-425b-875b-cb2dcc18fee4" // New Client ID a3736fe3-d262-43b6-a7a5-e391d9750c58
const reactRedirectUri = `${webAppBaseUrl}/dashboard` // /dashboard
const tenantSubdomain = tenant.split(".")[0]
const instance = `https://${tenantSubdomain}.b2clogin.com/`

const signInPolicy = "B2C_1A_EP_Renter_SignUp_SignIn"
const signInAuthority = `${instance}${tenant}/${signInPolicy}`

const forgotPasswordHashCheck = "AADB2C90118"
const forgotPasswordPolicy = "B2C_1A_EP_PasswordReset"

const changePasswordPolicy = "B2C_1A_EP_ChangePassword"

export const APPCONFIG = {
  appNameSlug: "circa",
  supportEmail: "support@wearecirca.com",
  supportTelephone: "(844) 274-7747",
  minimumAge: 16,
  maximumAge: 150,
  resendCodeInSec: 0.5 * 60,
  validateEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/,

  webAppBaseUrl: webAppBaseUrl,
  baseWebApiUrl: "https://azapp-circa-api-v2-qa-001.azurewebsites.net",
  usePackageJsonProxyForApis: false,

  msal: {
    applicationID: applicationID,
    reactRedirectUri: reactRedirectUri,
    authScopesPrefix: `https://${tenant}/api-7/`,
    signInAuthority: signInAuthority,
    forgotPasswordHashCheck: forgotPasswordHashCheck,
    forgotPasswordUrl: `https://${tenantSubdomain}.b2clogin.com/${tenantSubdomain}.onmicrosoft.com/oauth2/v2.0/authorize?p=${forgotPasswordPolicy}&client_id=${applicationID}&nonce=defaultNonce&redirect_uri=${reactRedirectUri}&scope=openid&response_type=id_token&prompt=login`,
    changePasswordUrl: `https://${tenantSubdomain}.b2clogin.com/${tenantSubdomain}.onmicrosoft.com/oauth2/v2.0/authorize?p=${changePasswordPolicy}&client_id=${applicationID}&nonce=defaultNonce&redirect_uri=${reactRedirectUri}&scope=openid&response_type=id_token`,
    loginUrl: `https://${tenantSubdomain}.b2clogin.com/${tenantSubdomain}.onmicrosoft.com/oauth2/v2.0/authorize?p=${signInPolicy}&client_id=${applicationID}&nonce=defaultNonce&redirect_uri=${reactRedirectUri}&scope=openid&response_type=id_token&prompt=login`,
    logoutUrl: `https://${tenantSubdomain}.b2clogin.com/${tenantSubdomain}.onmicrosoft.com/oauth2/v2.0/logout?p=${signInPolicy}&post_logout_redirect_uri=${reactRedirectUri}`
  },
  sessionVariables: {
    dashLastAccessed: `dashLastAccessed`,

    msalJwtToken: `msal.jwt.token`,
    msalAuthIdToken: `msal.${applicationID}.idtoken`,
    msalAuthClientInfo: `msal.${applicationID}.client.info`,

    leaseIdInSetup: `leaseIdInSetup`,
    leaseIdOnDash: `leaseIdOnDash`
  },

  workflowStageNames: {
    REGISTRATION: "registration",
    PROFILE_UPDATE: "profile_update",
    LINK_LEASE: "link_lease",
    LINK_LEASE_CONFIRMED: "link_lease_confirmed",
    PAYMENT_PLAN: "payment_plan",
    PAYMENT_METHOD: "payment_method",
    MANUAL_AUTO_PAY: "manual_auto_pay",
    NOTIFICATIONS: "notifications"
  },

  staticLinks: {
    eula: "https://www.wearecirca.com/eula",
    findMoneygramAgent: "https://www.moneygram.com/intl/en/en-locator",
    findFidelityExpressAgent: "https://www.fidelityexpress.co/find-a-location",
    individualResidents: "https://www.wearecirca.com/individual-residents",
    privacyPolicy: "https://www.wearecirca.com/privacy",
    supportCenter: "https://support.wearecirca.com",
    termsOfUse: "https://www.wearecirca.com/terms",
    directDebitAuth:
      "https://wearecirca.com/s/Circa-ACH-Direct-Debit-Authorization-25Jan23-Template.pdf",
    knowledgebase: "https://support.wearecirca.com/",
    welcomeToTheNewCircaLearnMore: ""
  },

  variables: {
    referralResidentId: "referralResidentId",
    typePastLease: "past"
  },

  api: {
    dayIndexAdjust: -1,
    forceRedirectOnFirstIncompleteLease: true,
    planTypeNames: {
      ONEPAY: "One Pay",
      ONEDEFERRED: "One Pay Deferred",
      TWOPAY: "Two Pay",
      FOURPAY: "Four Pay"
    },
    planSlugs: {
      ONEPAY: "onepay",
      ONEDEFERRED: "onepaydeferred",
      TWODATE: "twodate",
      ALTERNATING: "alternating",
      FIRSTANDTHIRD: "firstandthirdweek",
      SECONDANDFOURTH: "secondandfourthweek",
      FOURPAY: "fourpay"
    },
    bankSlug: "bank",
    cardSlug: "card",
    globalPaymentMethods: {
      CASH: {
        id: 1,
        label: "In-Person Cash",
        slug: "cash"
      },
      CASHAPP: {
        id: 2,
        label: "Cash App",
        slug: "cash-app"
      }
    },
    amountOtherSpecify: "Specify",
    insufficientAmount: "Insufficient amount",
    anyActionRequired: "ctionRequired",
    urgentActionRequired: "urgentActionRequired",
    rentAssistRequired: "rentAssistRequired",
    referralReward: "referral_reward",
    leaseIncomplete: "incomplete",
    rentAssistStatus: { enabled: 2 },
    rentAssist: {
      percentage: "percentage",
      dollarAmount: "dollarAmount"
    },
    bannerNameNewCirca: "New_circa_welcome",
    bannerFlagN: "N"
  },

  firebase: {
    pushNotificationsEnabled: false,
    firebaseConfig: {
      apiKey: "AIzaSyCdW9ERZRd6QtGpT6YFjDO-8DXOfx2TkTc",
      authDomain: "circa-dev-821d5.firebaseapp.com",
      projectId: "circa-dev-821d5",
      storageBucket: "circa-dev-821d5.appspot.com",
      messagingSenderId: "1030279153756",
      appId: "1:1030279153756:web:da0bac167abf42ec52fe99",
      measurementId: "G-9NYK8XK4L1"
    },
    vapidKey:
      "BF-lCjpeQXHPMwRBwCQPAy7K5ebvqyUXRjSZU1Rhox50_BNLiqi-DBoZI-I9r26VGpsPkj6DObgqfQ8CSALkmK0"
  },

  showMobileScreenOnSmallerRes: false,
  phoneNumberPrefix: "+1",
  debugGlobal: localStorage.getItem("debugGlobal") || false,
  debugForms: localStorage.getItem("debugForms") || false,
  debugCalendar: localStorage.getItem("debugCalendar") || false,
  debugRedirection: localStorage.getItem("debugRedirection") || false
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]
export const workdaysRange = [1, 5]

export const ADDMETHODSLUG = "add"
export const ADDMETHODSLUG_BANK = `${ADDMETHODSLUG}-bank`
export const ADDMETHODSLUG_BANK_LABEL = `Add Bank Account`
export const ADDMETHODSLUG_BANK_LABEL_SETUP = `Bank Account`
export const ADDMETHODSLUG_CARD = `${ADDMETHODSLUG}-card`
export const ADDMETHODSLUG_CARD_LABEL = `Add Card`
export const ADDMETHODSLUG_CARD_LABEL_SETUP = `Debit/Credit Card`
export const MORESELECTABLEPAYMENTMETHODS = [
  {
    type: APPCONFIG.api.globalPaymentMethods.CASH.slug,
    id: APPCONFIG.api.globalPaymentMethods.CASH.id,
    title: APPCONFIG.api.globalPaymentMethods.CASH.label
  },
  {
    type: APPCONFIG.api.globalPaymentMethods.CASHAPP.slug,
    id: APPCONFIG.api.globalPaymentMethods.CASHAPP.id,
    title: APPCONFIG.api.globalPaymentMethods.CASHAPP.label
  },
  {
    type: ADDMETHODSLUG_BANK,
    title: ADDMETHODSLUG_BANK_LABEL
  },
  {
    type: ADDMETHODSLUG_CARD,
    title: ADDMETHODSLUG_CARD_LABEL
  }
]

export const PLANPAYMENTSETTINGS = [
  {
    slug: "autopay",
    title: "Autopay"
  },
  {
    slug: "manual",
    title: "Manual"
  }
]
