import React from "react"
import { Container, Row } from "react-bootstrap"
import PropTypes from "prop-types"

const FullScreenCentered = props => {
  return (
    <Container
      className={`full-screen-centered d-flex align-items-center justify-content-center ${
        props.vw100 === false ? "w-auto" : "vw-100"
      } ${props.vh100 === false ? "" : "min-vh-100"} ${props.className || ""}`}
      fluid
    >
      <Row
        className={`flex-grow-1 d-flex flex-column align-items-center justify-content-${
          props.valign || "center"
        } ${props.vh100 === false ? "" : "min-vh-100"}`}
      >
        {props.children}
      </Row>
    </Container>
  )
}
FullScreenCentered.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string
}

export default FullScreenCentered
