import React, { useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { AllIcons } from "../../../assets"
import { APPCONFIG } from "../../../config"

import "./legal.scss"

const ScreenDashSettLegal = () => {
  const {
    setContainerClass,
    setModalClass,
    setModalBreadcrumb,
    setModalVisibility,
    setModalComponent,
    setMessageBubbleComponent,
    setMessageBubbleVisibility
  } = useOutletContext()

  const nav = useNavigate()

  useEffect(() => {
    document.body.classList.add("page__dash-sett-legal")
    setContainerClass("screen__dash-sett-legal")
    return () => {
      document.body.classList.remove("page__dash-sett-legal")
      setContainerClass("")
    }
  }, [])

  const legalListItems = [
    {
      title: "Terms of Use",
      route: APPCONFIG.staticLinks.termsOfUse
    },
    {
      title: "Privacy Policy",
      route: APPCONFIG.staticLinks.privacyPolicy
    },
    {
      title: "End User License Agreement",
      route: APPCONFIG.staticLinks.eula
    },
    {
      title: "Direct Debit Authorization",
      route: ""
    }
  ]

  return (
    <div className="leases-wrap d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-0">Legal</h2>
      </div>
      <div className={`legal-items-list d-flex flex-column mt-4 mb-4`}>
        {legalListItems.map((legalListItem, i) => (
          <div
            className={`tile-row align-items-center d-flex block low-elevation br-medium${
              !!legalListItem.route ? " clickable" : ""
            }`}
            onClick={() => {
              if (!!legalListItem.route) {
                window.open(legalListItem.route)
              }
            }}
          >
            <AllIcons.LeaseDetails />
            <h4 className="font-medium mb-0 ms-3">{legalListItem.title}</h4>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScreenDashSettLegal
