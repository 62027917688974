import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { Col, Container, Dropdown, Row } from "react-bootstrap"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { appDataActionCreators } from "../../../state"

import { APPCONFIG, monthNames } from "../../../config"
import { ALLROUTES } from "../../../routes"
import {
  consoleLog,
  LocalStorage,
  reduceApiData,
  SessionStorage
} from "../../../utils"
import {
  pickALeaseForDisplay,
  redirectPerLeaseSetupProgress,
  leaseIdOnDash,
  leaseIdInSetup
} from "../../../utils/logic"
import { displayTpl } from "../../../utils/templates"
import { CircaLoadingAnimationFS } from "../../../assets"

import DashWidgetRentReminder from "../../../components/widgets/dash-rent-reminder"
import DashWidgetUpcomingPaymentsCarousel from "../../../components/widgets/dash-upcoming-payments-carousel"
import DashWidgetPlanSummary from "../../../components/widgets/dash-plan-summary"
import DashWidgetWallet from "../../../components/widgets/dash-wallet"
import DashWidgetStaticWidgets from "../../../components/widgets/dash-static-widgets"
import { showModalPayLate } from "../../../components/organisms/plan/pay-late"
import { showModalMakeAPayment } from "../../../components/organisms/payment/make-a-payment"
import { showModalModifyPayment } from "../../../components/organisms/payment/modify-payment"
import { showRBC } from "../../../components/elements/dashboard-red-banner/dashboard-red-banner-content"
import OrgnWizardRentAssist from "../../../components/organisms/rent-assist/wizard-rent-assist"
import OrgnInfoReferralCode from "../../../components/organisms/referral/referral-code-info"

import {
  useDashboardQueries,
  useWalletPaymentQueries
} from "../../../utils/queries"
import { useDashboardMutation } from "../../../utils/mutations"

import "./overview.scss"
import "../../../components/organisms/plan/plan.scss"

const ScreenDashOverview = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalClass,
    setModalBreadcrumb,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setMessageBubbleOnClose,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup,
    setRedBannerClassName,
    setRedBannerVisibility,
    setRedBannerDays,
    setRedBannerLeftComponent,
    setRedBannerCenterComponent,
    setRedBannerCta,
    setRedBannerPostCtaComponent,
    setUnreadNotifRedDot
  } = useOutletContext()

  const rbcParams = {
    setRedBannerClassName,
    setRedBannerVisibility,
    setRedBannerDays,
    setRedBannerLeftComponent,
    setRedBannerCenterComponent,
    setRedBannerCta,
    setRedBannerPostCtaComponent
  }

  const nav = useNavigate()
  const location = useLocation()

  useEffect(() => {
    document.body.classList.add("page__dash-overview")
    setContainerClass("screen__dash-overview")
    if (APPCONFIG.debugGlobal && APPCONFIG.debugRedirection)
      consoleLog(`[SCREEN] overview (dashboard)`)
    return () => {
      document.body.classList.remove("page__dash-overview")
      setContainerClass("")
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (
        window.location.pathname.indexOf(ALLROUTES.accountSetupContainer) < 0
      ) {
        //showRBC.almostDone({ ...rbcParams, nav })
        /*showRBC.paymentFailed({
          ...rbcParams,
          daysToAct: 14,
          failedPayment: {
            amount: 960,
            date: "2022-08-06"
          },
          urgent: true,
          ctaOnClick: () => {
            setRedBannerVisibility(false)
            nav(ALLROUTES.dashboardChildren.welcomeToRentAssist)
          }
        })
        showRBC.rentAssistBalanceActionRequired({
          ...rbcParams,
          balance: -500,
          ctaOnClick: () => {
            setRedBannerVisibility(false)
            nav(ALLROUTES.dashboardChildren.welcomeToRentAssist)
          }
        })
        showRBC.referralEarned({
          ...rbcParams,
          referredTo: `Lauren Johnson`,
          referralAmount: 10,
          ctaOnClick: showModalInfoReferralCode
        })*/
      }
    }, 2000)
    // close all popups / banners on unmount
    return () => {
      setRedBannerVisibility(false)
      setMessageBubbleVisibility(false)
      setModalVisibility(false)
    }
  }, [])

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [allLeases, setAllLeases] = useState(null)
  const [selectedLease, setSelectedLease] = useState(null)
  const [selectedLeasePlanDetails, setSelectedLeasePlanDetails] = useState(null)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  const {
    status: statusDashboard,
    data: dataDashboard,
    isFetching: isFetchingDashboard,
    isError: isErrorDashboard,
    error: errorDashboard,
    refetch: refetchDashboard
  } = useDashboardQueries.useDashboardQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  const {
    status: statusWalletInformation,
    data: dataWalletInformation,
    isFetching: isFetchingWalletInformation,
    isError: isErrorWalletInformation,
    error: errorWalletInformation,
    refetch: refetchWalletInformation
  } = useWalletPaymentQueries.useWalletInformationQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success" && !!selectedLease,
    residentId: dataResidents?.data?.residentData?.id,
    leaseId: selectedLease?.leaseId
  })

  const dispatch = useDispatch()

  const markAsReadMutation = useDashboardMutation.useMarkBannerAsReadMutation()

  const setMessageBubbleOnCloseCallback = bannerId => {
    if (!!bannerId) {
      setMessageBubbleOnClose(() => () => {
        consoleLog(`Close banner API for #${bannerId}`)
        const requestParams = {
          residentId: dataResidents?.data?.residentData?.id,
          bannerId
        }
        markAsReadMutation.mutate(requestParams, {
          onSuccess: ({ data, status }) => {
            refetchDashboard()
          }
        })
      })
    }
  }

  const displayNextDashboardPopup = () => {
    // 1. Welcome to new circa
    const newCircaBanner = dataDashboard?.data?.dashboardResponseObject?.generalBanners?.find(
      banner =>
        banner?.name === APPCONFIG.api.bannerNameNewCirca &&
        banner?.readFlag === APPCONFIG.api.bannerFlagN
    )
    if (!!dataDashboard && !!newCircaBanner) {
      consoleLog(`Display banner - welcome (#${newCircaBanner?.bannerId})`)
      showDashboardPopup(() => <dashboardPopups.WelcomeToTheNewCirca />)
      setMessageBubbleOnCloseCallback(newCircaBanner?.bannerId)
    }

    // 2. Don't forget
    const popupDontForgetVariable = `resident-${dataResidents?.data?.residentData?.id}-popup-shown-dont-forget`
    const rentStartDate = dataResidents?.data?.residentData?.rentAssistStartDate
    if (
      !!dataResidents &&
      !!rentStartDate &&
      !LocalStorage.read(popupDontForgetVariable)
    ) {
      consoleLog("Display banner - dont forget")
      const rentStartDateObj = new Date(rentStartDate)
      LocalStorage.write(popupDontForgetVariable, "1")
      showDashboardPopup(() => (
        <dashboardPopups.DontForget
          paymentMonth={monthNames[rentStartDateObj.getMonth()]}
          paymentYear={rentStartDateObj.getFullYear()}
        />
      ))
    }
  }

  // message bubble banners
  useEffect(() => {
    if (
      window.location.pathname.indexOf(ALLROUTES.accountSetupContainer) < 0 &&
      !!dataResidents &&
      !!dataDashboard
    ) {
      setTimeout(() => {
        displayNextDashboardPopup()
      }, 5000)
    }
  }, [dataResidents, dataDashboard])

  useEffect(() => {
    // if resident data is missing, redirect to contact info
    if (isErrorResidents && errorResidents?.response?.status === 404) {
      nav(ALLROUTES.accountSetupChildren.contactInformation)
    }
    let reducedDataDashboard = !!dataDashboard
      ? reduceApiData(dataDashboard, "dashboard")
      : {}
    if (!!dataDashboard) {
      const { setDashboardDetails } = bindActionCreators(
        appDataActionCreators,
        dispatch
      )
      setDashboardDetails(reducedDataDashboard)
    }
    // check resident data and redirect if first account setup is incomplete
    consoleLog("redirect if first account setup is incomplete")
    if (
      (dataResidents || errorResidents) &&
      !isFetchingResidents &&
      (dataDashboard || errorDashboard) &&
      !isFetchingDashboard &&
      reducedDataDashboard?.allLeasesCount < 2
    ) {
      redirectPerLeaseSetupProgress({
        nav,
        ...location,
        dataResidents,
        errorResidents
      })
    }
    // redirect to leases page if onlyPastLeases = true
    if (!!dataDashboard && reducedDataDashboard?.onlyPastLeases === true) {
      if (APPCONFIG.debugGlobal) {
        consoleLog(`Only past leases. Redirecting to leases page...`)
      }
      nav(ALLROUTES.dashboardChildren.settingsChildren.leases)
    }
    // select a lease for display
    if (!!dataDashboard && !isFetchingDashboard) {
      if (reducedDataDashboard?.allLeases?.length === 0) {
        if (APPCONFIG.debugGlobal) {
          consoleLog(`No lease registered. Redirecting to account setup...`)
        }
        nav(ALLROUTES.accountSetupChildren.leaseId)
        return
      }
      setAllLeases(reducedDataDashboard?.allLeases)
      const prevSelectedLeaseForDisplay = leaseIdOnDash.get()
      if (!!prevSelectedLeaseForDisplay) {
        // set the state to the last selected lease
        setSelectedLease(
          reducedDataDashboard?.allLeases?.find(
            lease => lease?.leaseId * 1 === prevSelectedLeaseForDisplay * 1
          )
        )
        if (APPCONFIG.debugGlobal) {
          consoleLog(`Lease #${prevSelectedLeaseForDisplay} on display`)
        }
      } else {
        // or if no lease was selected, pick a lease automatically
        const { onDisplay: leasePickedForDisplay } = pickALeaseForDisplay({
          nav,
          dataResidents,
          reducedDataDashboard
        })
        leaseIdOnDash.set(leasePickedForDisplay.leaseId)
        setSelectedLease(leasePickedForDisplay.lease)
        if (APPCONFIG.debugGlobal) {
          consoleLog(
            `Auto-selected lease #${leasePickedForDisplay.leaseId} for display`
          )
        }
      }
    }
    // set unread notification red dot
    if (!!dataDashboard) {
      setUnreadNotifRedDot(
        dataDashboard?.data?.dashboardResponseObject?.unreadNotification ||
          false
      )
    }
  }, [
    dataResidents,
    isFetchingResidents,
    isErrorResidents,
    errorResidents,
    dataDashboard,
    isFetchingDashboard,
    isErrorDashboard,
    errorDashboard
  ])

  const {
    status: statusLeasePlanDetails,
    data: dataLeasePlanDetails,
    isFetching: isFetchingLeasePlanDetails,
    isError: isErrorLeasePlanDetails,
    error: errorLeasePlanDetails,
    refetch: refetchLeasePlanDetails
  } = useDashboardQueries.useLeasePlanDetailsQuery({
    config: { refetchOnMount: true },
    enabled: statusDashboard === "success" && !!selectedLease,
    residentId: dataResidents?.data?.residentData?.id,
    leaseId: selectedLease?.leaseId
  })

  useEffect(() => {
    let reducedDataLeasePlanDetails = !!dataLeasePlanDetails
      ? reduceApiData(dataLeasePlanDetails, "lease_plan_details")
      : {}
    if (!!dataLeasePlanDetails) {
      setSelectedLeasePlanDetails(reducedDataLeasePlanDetails)
      // open RentAssist modal
      if (location.hash === ALLROUTES.hashUrls.rentAssist) {
        showModalRentAssistWizard({
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          leaseArrearId:
            dataLeasePlanDetails?.data?.rentAssistInformation?.leaseArrearsId,
          refetchDashboard,
          refetchWalletInformation,
          refetchLeasePlanDetails
        })
      }
    }
  }, [
    dataLeasePlanDetails,
    isFetchingLeasePlanDetails,
    isErrorLeasePlanDetails,
    errorLeasePlanDetails
  ])

  useEffect(() => {
    if (location.hash === ALLROUTES.hashUrls.makeAPayment) {
      showModalMakeAPayment({
        refetchDashboard,
        setModalClass,
        setModalBreadcrumb,
        setModalComponent,
        setModalVisibility,
        setMessageBubbleComponent,
        setMessageBubbleVisibility,
        walletInformation: dataWalletInformation
      })
    }
  }, [])

  useEffect(() => {
    // Check setup status of selected lease
    // If no selectable (current/failed) lease is available,
    // check for the status of first lease
    if (!!selectedLease || !!allLeases) {
      // check if selected or first lease needs account setup
      const selectedOrFirstLeaseId =
        selectedLease?.leaseId || allLeases?.[0]?.leaseId
      const redirectApplicable = redirectPerLeaseSetupProgress({
        nav,
        ...location,
        dataResidents,
        errorResidents,
        leaseId: selectedOrFirstLeaseId
      })
      if (!!redirectApplicable) {
        if (APPCONFIG.debugGlobal) {
          consoleLog(
            `Redirect applicable for selected lease #${selectedOrFirstLeaseId}`
          )
        }
        leaseIdOnDash.set(selectedOrFirstLeaseId)
        leaseIdInSetup.set(selectedOrFirstLeaseId)
        setUpdateInProgress(true)
        redirectApplicable()
      }
      // display applicable banners
      if (
        !!selectedLease?.leaseBanners ||
        !!dataDashboard?.data?.dashboardResponseObject?.generalBanners
      ) {
        // action required
        const actionRequiredLeaseBanner = [
          ...(selectedLease?.leaseBanners || []),
          ...(dataDashboard?.data?.dashboardResponseObject?.generalBanners ||
            [])
        ]?.find(
          banner =>
            banner?.name?.indexOf(APPCONFIG.api.anyActionRequired) > -1 &&
            banner?.readFlag === "N"
        )
        if (!!actionRequiredLeaseBanner) {
          if (APPCONFIG.debugGlobal) {
            console.log("actionRequiredLeaseBanner")
            console.log(actionRequiredLeaseBanner)
          }
          showRBC.paymentFailed({
            ...rbcParams,
            ...actionRequiredLeaseBanner,
            urgent:
              actionRequiredLeaseBanner?.name ===
              APPCONFIG.api.urgentActionRequired,
            ctaOnClick: () => {
              // setRedBannerVisibility(false)
              // nav(ALLROUTES.dashboardChildren.welcomeToRentAssist)
              showModalPayLate({
                setModalClass,
                setModalBreadcrumb,
                setModalComponent,
                setModalVisibility,
                setMessageBubbleComponent,
                setMessageBubbleVisibility,
                refetchDashboard,
                ...actionRequiredLeaseBanner?.metaData
              })
            }
          })
        }
        // referral earned
        const referralEarnedLeaseBanner = [
          ...(selectedLease?.leaseBanners || []),
          ...(dataDashboard?.data?.dashboardResponseObject?.generalBanners ||
            [])
        ]?.find(
          banner =>
            banner?.name?.indexOf(APPCONFIG.api.referralReward) > -1 &&
            banner?.readFlag === "N"
        )
        if (!!referralEarnedLeaseBanner) {
          if (APPCONFIG.debugGlobal) {
            console.log("referralEarnedLeaseBanner")
            console.log(referralEarnedLeaseBanner)
          }
          showRBC.referralEarned({
            ...rbcParams,
            ...referralEarnedLeaseBanner,
            ctaOnClick: () => {
              showModalInfoReferralCode({
                setModalBreadcrumb,
                setModalClass,
                setModalComponent,
                setModalVisibility
              })
            }
          })
        }
        // rentAssist balance action required
        const rentAssistBalanceLeaseBanner = [
          ...(selectedLease?.leaseBanners || []),
          ...(dataDashboard?.data?.dashboardResponseObject?.generalBanners ||
            [])
        ]?.find(
          banner =>
            banner?.name?.indexOf(APPCONFIG.api.rentAssistRequired) > -1 &&
            banner?.readFlag === "N"
        )
        if (!!rentAssistBalanceLeaseBanner) {
          if (APPCONFIG.debugGlobal) {
            console.log("rentAssistBalanceLeaseBanner")
            console.log(rentAssistBalanceLeaseBanner)
          }
          showRBC.rentAssistBalanceActionRequired({
            ...rbcParams,
            ...rentAssistBalanceLeaseBanner,
            ctaOnClick: () => {
              setRedBannerVisibility(false)
              nav(ALLROUTES.dashboardChildren.welcomeToRentAssist)
            }
          })
        }
      }
    }
  }, [selectedLease, allLeases])

  const showModalRentAssistWizard = props => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--rent-assist narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnWizardRentAssist {...props} />
      </div>
    ))
    setModalVisibility(true)
  }

  const showModalInfoReferralCode = () => {
    setModalBreadcrumb([{ label: "" }])
    setModalClass("modal--referral-code-info narrow-content")
    setModalComponent(() => (
      <div className="modal-content-height d-flex flex-column">
        <OrgnInfoReferralCode />
      </div>
    ))
    setModalVisibility(true)
  }

  return (
    <>
      {isFetchingResidents ||
      updateInProgress ||
      !selectedLease ||
      !dataWalletInformation ? (
        <CircaLoadingAnimationFS />
      ) : (
        <Container className="vert-offset">
          <Row className="leased-property-selector mb-4">
            <Col>
              {selectedLease && (
                <Dropdown
                  className={allLeases?.length < 2 ? "only-one-lease" : ""}
                >
                  <Dropdown.Toggle id={`leased-property-selector`}>
                    {displayTpl(selectedLease?.address, "lease_display_name")}
                  </Dropdown.Toggle>
                  {allLeases?.length > 1 && (
                    <Dropdown.Menu>
                      {allLeases
                        .filter(
                          lease => lease?.leaseId !== selectedLease?.leaseId
                        )
                        .map((lease, i) => (
                          <Dropdown.Item
                            className={`d-flex align-items-center justify-content-between${
                              !!lease?.setupRequired ? " danger" : ""
                            }`}
                            onClick={() => {
                              if (!!lease?.leaseId) {
                                leaseIdOnDash.set(lease?.leaseId)
                                setSelectedLease(lease)
                                setRedBannerVisibility(false)
                                refetchDashboard()
                                refetchWalletInformation()
                                refetchLeasePlanDetails()
                              }
                            }}
                            key={i}
                          >
                            {displayTpl(lease?.address, "lease_display_name")}
                            {!!lease?.setupRequired && (
                              <span className="btn btn-text text-only danger no-action">
                                Setup
                              </span>
                            )}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              )}
            </Col>
          </Row>
          <Row>
            <Col xxl={4} xl={4} md={4} className="d-flex flex-column">
              {!!selectedLease &&
                (!!selectedLease?.headerDetails?.customerPaymentId ||
                  !!selectedLease?.headerDetails?.customerPaymentArrearsId) && (
                  <DashWidgetRentReminder
                    headerDetails={selectedLease?.headerDetails}
                    onClick={({ event, ...props }) => {
                      showModalModifyPayment({
                        setModalClass,
                        setModalBreadcrumb,
                        setModalComponent,
                        setModalVisibility,
                        setMessageBubbleComponent,
                        setMessageBubbleVisibility,
                        refetchDashboard,
                        ...props,
                        ...selectedLease?.upcomingPaymentDetails.find(
                          upPayment =>
                            upPayment?.customerPaymentId ===
                            selectedLease?.headerDetails?.customerPaymentId
                        )
                      })
                    }}
                    onModifyClick={({ event, ...props }) => {
                      event.stopPropagation()
                      showModalModifyPayment({
                        setModalClass,
                        setModalBreadcrumb,
                        setModalComponent,
                        setModalVisibility,
                        setMessageBubbleComponent,
                        setMessageBubbleVisibility,
                        refetchDashboard,
                        ...props,
                        ...selectedLease?.upcomingPaymentDetails.find(
                          upPayment =>
                            upPayment?.customerPaymentId ===
                            selectedLease?.headerDetails?.customerPaymentId
                        )
                      })
                    }}
                  />
                )}
              {!!selectedLease?.upcomingPaymentDetails &&
                selectedLease?.upcomingPaymentDetails?.length > 0 && (
                  <DashWidgetUpcomingPaymentsCarousel
                    setModalClass={setModalClass}
                    setModalBreadcrumb={setModalBreadcrumb}
                    setModalComponent={setModalComponent}
                    setModalVisibility={setModalVisibility}
                    setMessageBubbleComponent={setMessageBubbleComponent}
                    setMessageBubbleVisibility={setMessageBubbleVisibility}
                    refetchDashboard={refetchDashboard}
                    upcomingPaymentDetails={[
                      ...selectedLease?.upcomingPaymentDetails,
                      { seeAll: true }
                    ]}
                  />
                )}
              {selectedLeasePlanDetails && (
                <DashWidgetPlanSummary
                  className="flex-grow-1"
                  setModalClass={setModalClass}
                  setModalBreadcrumb={setModalBreadcrumb}
                  setModalComponent={setModalComponent}
                  setModalVisibility={setModalVisibility}
                  setMessageBubbleComponent={setMessageBubbleComponent}
                  setMessageBubbleVisibility={setMessageBubbleVisibility}
                  leasePlanDetails={selectedLeasePlanDetails || {}}
                  refetchLeasePlanDetails={refetchLeasePlanDetails}
                  refetchDashboard={refetchDashboard}
                />
              )}
            </Col>
            <Col xxl={4} xl={4} md={4} className="d-flex flex-column">
              {!!dataWalletInformation && (
                <DashWidgetWallet
                  refetchDashboard={refetchDashboard}
                  setModalClass={setModalClass}
                  setModalBreadcrumb={setModalBreadcrumb}
                  setModalComponent={setModalComponent}
                  setModalVisibility={setModalVisibility}
                  setMessageBubbleComponent={setMessageBubbleComponent}
                  setMessageBubbleVisibility={setMessageBubbleVisibility}
                  walletInformation={dataWalletInformation}
                />
              )}
              <div className="d-xxl-none d-xl-none d-md-none mb-4"></div>
            </Col>
            <Col xxl={4} xl={4} md={4} className="d-flex flex-column">
              {!!selectedLease && !!dataWalletInformation && (
                <DashWidgetStaticWidgets
                  selectedLease={selectedLease}
                  walletInformation={dataWalletInformation}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default ScreenDashOverview
