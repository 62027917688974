import React from "react"

import {
  AllInappIcons,
  AnimationLoadingFullScreen,
  GraphicNotifications
} from "../../../assets"
import { capFirst, formatDateToFormat } from "../../../utils"

import {
  useDashboardQueries,
  useNotificationQueries
} from "../../../utils/queries"

import "./inapp-notifications.scss"

const InAppNotificationItem = ({ notifData }) => {
  const iconSlugWColor =
    notifData?.icon?.indexOf("-") > -1
      ? notifData?.icon?.split("-")
      : [notifData?.icon]
  const iconColor = iconSlugWColor[1] || ""
  const Icon = AllInappIcons[`Inapp${capFirst(iconSlugWColor[0])}`] || "span"
  return (
    <div
      className={`inapp-notif-item block low-elevation br-medium d-flex p-3 mt-3${
        !!notifData?.shortDescription ? "" : " no-descr"
      }${notifData?.inAppReadFlag || iconColor === "RP" ? " red" : ""}`}
    >
      <div className="generic__icon">{notifData?.icon && <Icon />}</div>
      <div className="inapp-notif-item__details">
        <div className="inapp-notif-item__title">{notifData?.title}</div>
        {!!notifData?.shortDescription && (
          <div
            className="inapp-notif-item__content"
            dangerouslySetInnerHTML={{
              __html: notifData?.shortDescription?.replace(
                /((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d+|-?\d+\.?\d*)/g,
                '<span class="highlight">$1</span>'
              )
            }}
          ></div>
        )}
      </div>
      <div className="inapp-notif-item__date">
        {notifData?.AnimationLoadingFullScreennotificationDateFormat ||
          formatDateToFormat(new Date(notifData?.date))}
      </div>
    </div>
  )
}

const OrgnInAppNotificationsList = () => {
  let refTimeLabel = null

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusNotifications,
    data: dataNotifications,
    isFetching: isFetchingNotifications,
    isError: isErrorNotifications,
    error: errorNotifications,
    refetch: refetchNotifications
  } = useNotificationQueries.useNotificationsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id
  })

  if (isFetchingResidents || isFetchingNotifications) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      {[...dataNotifications?.data?.newInAppNotification]?.length > 0 && (
        <>
          <h4 className="font-medium mb-0 mt-4">New</h4>
          {[...dataNotifications?.data?.newInAppNotification]?.map(
            (notifItem, i) => (
              <InAppNotificationItem notifData={notifItem} key={i} />
            )
          )}
        </>
      )}
      {dataNotifications?.data?.lastMonthInAppNotification?.length > 0 && (
        <>
          <h4 className="font-medium mb-0 mt-4">Last Month</h4>
          {dataNotifications?.data?.lastMonthInAppNotification?.map(
            (notifItem, i) => (
              <InAppNotificationItem notifData={notifItem} key={i} />
            )
          )}
        </>
      )}
      {dataNotifications?.data?.oldInAppNotification?.length > 0 && (
        <>
          <h4 className="font-medium mb-0 mt-4">All Time</h4>
          {dataNotifications?.data?.oldInAppNotification?.map(
            (notifItem, i) => (
              <InAppNotificationItem notifData={notifItem} key={i} />
            )
          )}
        </>
      )}
      {[
        ...dataNotifications?.data?.newInAppNotification,
        ...dataNotifications?.data?.lastMonthInAppNotification,
        ...dataNotifications?.data?.oldInAppNotification
      ].length === 0 && (
        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <GraphicNotifications className={`d-flex justify-content-center`} />
          <h4 className="font-medium text-center mb-0 mt-4">
            No new notifications
          </h4>
        </div>
      )}
    </>
  )
}
export default OrgnInAppNotificationsList
