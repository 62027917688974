import React from "react"

import { CircaLoadingAnimationFS } from "../../../assets"

import "./forgot-password.scss"
import { APPCONFIG } from "../../../config"

const ScreenForgotPasswordADB2C = () => {
  window.location = APPCONFIG.msal.forgotPasswordUrl
  return (
    <CircaLoadingAnimationFS />
  )
}

export default ScreenForgotPasswordADB2C
