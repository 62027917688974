import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ALLROUTES } from "../../../routes"

import DashboardWrapper from "../../../components/wrappers/dashboard"
import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import GenericForm from "../../../components/elements/generic-form"
import { validation } from "../../../utils/validation"
import MessageBubble from "../../../components/elements/message-bubble/message-bubble"

import { useLoginMutation } from "../../../utils/mutations"

import "./login.scss"

const ScreenLogin = () => {
  const nav = useNavigate()
  const loginMutation = useLoginMutation()

  const [userPassword, setUserPassword] = useState("")

  const formFields = [
    {
      label: "Email",
      name: "email",
      type: "email",
      rules: {
        required: true,
        validate: { ...validation.email }
      }
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      rules: {
        required: true,
        validate: { ...validation.password }
      },
      onChange: pass => {
        setUserPassword(pass)
      }
    }
  ]

  const [messageBubbleVisible, setMessageBubbleVisible] = useState(false)
  const [messageBubbleHeadingText, setMessageBubbleHeadingText] = useState("")
  const [messageBubbleContentText, setMessageBubbleContentText] = useState("")

  const handleError = ({ status, httpStatusCode, message, apiError }) => {
    if (apiError) {
      nav(ALLROUTES.serverDown)
      return
    }
    setMessageBubbleHeadingText("Signup Failed")
    setMessageBubbleContentText(message)
    setMessageBubbleVisible(true)
  }

  const onSubmit = formValues => {
    loginMutation.mutate(formValues, {
      onSuccess: ({ response }) => {
        if (response.status === true && response.httpStatusCode === 200) {
          nav(ALLROUTES.emailVerification, {
            state: { email: formValues.email, message: response.message }
          })
        } else {
          handleError(response)
        }
      },
      onError: axiosError => {
        handleError(axiosError?.response?.data || { apiError: true })
      }
    })
  }

  return (
    <>
      <MessageBubble
        onHide={() => {
          setMessageBubbleVisible(false)
        }}
        show={messageBubbleVisible}
        contentClassName={`global-message-bubble`}
        headingText={messageBubbleHeadingText}
        messageText={messageBubbleContentText}
      ></MessageBubble>

      <DashboardWrapper
        bodyClass={`screen__login d-flex flex-row-reverse flex-wrap`}
      >
        <FullScreenCentered className={`w-100`} vw100={false}>
          <div className="generic-form-wrap narrow-centered">
            <h1>Sign in</h1>
            <GenericForm
              formClass={"mb-3"}
              submitButtonText={"Sign In"}
              onSubmit={onSubmit}
              formFields={formFields}
            />
            <button
              className="btn btn-text d-block mx-auto"
              onClick={() => {
                nav(ALLROUTES.forgotPassword)
              }}
            >
              Forgot Password
            </button>
            <div className="hr-with-text mt-3">
              <span>or</span>
            </div>
            <button
              className="btn btn-text d-block mx-auto"
              onClick={() => {
                nav(ALLROUTES.signup)
              }}
            >
              Create Account
            </button>
          </div>
        </FullScreenCentered>
      </DashboardWrapper>
    </>
  )
}

export default ScreenLogin
