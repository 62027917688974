import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { ALLROUTES } from "../../../routes"
import {
  handleApiError,
  manageMsalAuth,
  manualMsalLogout
} from "../../../utils"
import { APIhandler } from "../../../utils/api"
import { validation } from "../../../utils/validation"

import { AnimationLoadingFullScreen } from "../../../assets"
import GenericForm from "../../elements/generic-form"

const OrgnFormDeleteAccount = ({
  setMessageBubbleComponent,
  setMessageBubbleVisibility
}) => {
  const nav = useNavigate()

  const [processing, setProcessing] = useState(false)

  const formFields = [
    {
      label: "Type “DELETE”",
      name: "type_delete",
      rules: {
        required: true,
        validate: { ...validation.delete }
      }
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      rules: {
        required: true,
        validate: { ...validation.password }
      }
    }
  ]

  const handleScreenApiError = ({
    customHeading = "Failed to delete account",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  const onSubmit = formValues => {
    const requestParams = { password: formValues.password }
    setProcessing(true)
    APIhandler.account_setup
      .delete_account(requestParams)
      .then(response => {
        if (response?.data?.code === 200) {
          manageMsalAuth.delete()
          manualMsalLogout()
          window.location = ALLROUTES.home
        } else {
          setProcessing(false)
          handleScreenApiError({
            ...response?.data
          })
        }
      })
      .catch(axiosError => {
        setProcessing(false)
        const errorData = axiosError?.response?.data || { apiError: true }
        handleScreenApiError({
          ...errorData
        })
      })
  }

  if (processing) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <h2 className="billboard text-center mb-3">Are you sure?</h2>
      <p className="mb-1">
        This action deletes your account and erases your entire digital
        footprint from our system. You will lose your username, payment history,
        and access to current and future features.
      </p>
      <p className="mb-3">This action is permanent and cannot be undone.</p>
      <GenericForm
        formClass={""}
        submitButtonClass={`mt-3 mw-100`}
        submitButtonText={"Delete Everything"}
        onSubmit={onSubmit}
        formFields={formFields}
      />
    </>
  )
}
export default OrgnFormDeleteAccount
