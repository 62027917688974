import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"

import { APPCONFIG } from "../../../config"
import { ALLROUTES } from "../../../routes"
import {
  calculateAccountSetupPercentage,
  consoleLog,
  handleApiError,
  sanitizeFormValuesForSubmit,
  SessionStorage
} from "../../../utils"

import FullScreenCentered from "../../../components/wrappers/full-screen-centered"
import OrgnFormProfile from "../../../components/organisms/profile/profile"

import { useDashboardQueries } from "../../../utils/queries"
import { useAccountSetupMutation } from "../../../utils/mutations"

import "./contact-information.scss"

const ScreenSetupContactInformation = () => {
  const {
    setContainerClass,
    modalVisible,
    setModalComponent,
    setModalVisibility,
    setMessageBubbleComponent,
    setMessageBubbleVisibility,
    setMessageBubbleErrorMessage,
    setDashboardProgressPerc,
    dashboardPopups,
    showDashboardPopup
  } = useOutletContext()

  const { pathname, state: fwdState } = useLocation()
  const nav = useNavigate()

  useEffect(() => {
    document.body.classList.add("page__contact-information")
    setContainerClass("screen__contact-information")
    setDashboardProgressPerc(calculateAccountSetupPercentage(pathname))
    if (fwdState?.showWelcomeBackPopup) {
      showDashboardPopup(dashboardPopups.WelcomeBack)
    }
    if (APPCONFIG.debugGlobal && APPCONFIG.debugRedirection)
      consoleLog(`[SCREEN] contact-information`)
    return () => {
      document.body.classList.remove("page__contact-information")
      setContainerClass("")
      setDashboardProgressPerc(0)
    }
  }, [])

  const {
    data: dataResidents,
    isFetching: isFetchingResidents,
    isError: isErrorResidents,
    error: errorResidents,
    refetch: refetchResidents
  } = useDashboardQueries.useResidentsQuery({
    config: { refetchOnMount: true }
  })

  useEffect(() => {
    if (!!dataResidents && dataResidents?.data?.code === 200) {
      nav(ALLROUTES.accountSetupChildren.leaseId)
    }
  }, [dataResidents])

  const submitContactInfoMutation = useAccountSetupMutation.useSubmitContactInfoMutation()

  const onSubmit = formValues => {
    let {
      accept,
      dataResidents,
      refetchResidents,
      ...filteredFormValues
    } = formValues
    filteredFormValues = sanitizeFormValuesForSubmit(filteredFormValues)
    filteredFormValues["id"] = 0
    submitContactInfoMutation.mutate(filteredFormValues, {
      onSuccess: ({ data, status }) => {
        if ([200, 201].indexOf(status) > -1) {
          SessionStorage.write("residents", data)
          nav(ALLROUTES.accountSetupChildren.leaseId)
        } else {
          handleApiError({
            nav,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        if (
          errorData?.code === 409 &&
          errorData?.errordescription?.toLowerCase()?.indexOf("duplicate") > -1
        ) {
          nav(ALLROUTES.accountSetupChildren.leaseId)
        } else {
          handleApiError({
            nav,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            ...errorData
          })
        }
      }
    })
  }

  return (
    <>
      <FullScreenCentered className={`w-100`} vw100={false}>
        <div className="generic-form-wrap narrow-centered w400">
          <h1 className="billboard text-center">Contact Information</h1>
          <OrgnFormProfile
            noPreload={true}
            formClass={``}
            hideFields={["email"]}
            submitButtonText={"Continue"}
            onSubmit={onSubmit}
          />
        </div>
      </FullScreenCentered>
    </>
  )
}

export default ScreenSetupContactInformation
