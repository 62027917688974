import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dropdown, Form } from "react-bootstrap"

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { modalActionCreators } from "../../../state"

import { AnimationLoadingFullScreen } from "../../../assets"
import {
  ADDMETHODSLUG_BANK,
  ADDMETHODSLUG_CARD,
  APPCONFIG
} from "../../../config"
import {
  displayAmount,
  formatDateToString,
  handleApiError
} from "../../../utils"
import { leaseIdInSetup, leaseIdOnDash } from "../../../utils/logic"
import { logicPlan } from "../../../utils/logic-plan"

import { showModalModifyPayment } from "../payment/modify-payment"
import { showModalPaymentDetails } from "../payment/payment-details"
import { showModalScheduleTheRest } from "./schedule-the-rest"
import { showPayOtherAmountModal } from "./pay-other-amount"
import { showModalBankInformation } from "../bank-information/bank-information"
import { showModalCardInformation } from "../card-information/card-information"

import {
  useDashboardQueries,
  useWalletPaymentQueries
} from "../../../utils/queries"
import { usePaymentMutation } from "../../../utils/mutations"

const OrgnWizardPayOtherAmount = ({
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  refetchDashboard,
  allUpcomingPayments,
  modifyPaymentParams
}) => {
  const nav = useNavigate()

  const dispatch = useDispatch()
  const {
    setModalBreadcrumb,
    setModalClass,
    setModalComponent,
    setModalVisibility
  } = bindActionCreators(modalActionCreators, dispatch)

  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [allPaymentMethods, setAllPaymentMethods] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

  const [selectedReason, setSelectedReason] = useState(null)

  const amountFieldRef = useRef()
  const messageFieldRef = useRef()

  const [amountEntered, setAmountEntered] = useState("")
  const [minAmount, setMinAmount] = useState(1)
  const [paymentAmount, setPaymentAmount] = useState(1)

  const {
    status: statusResidents,
    data: dataResidents,
    isFetching: isFetchingResidents
  } = useDashboardQueries.useResidentsQuery({})

  const {
    status: statusResidentLeasePaymentMethods,
    data: dataResidentLeasePaymentMethods,
    isFetching: isFetchingResidentLeasePaymentMethods,
    isError: isErrorResidentLeasePaymentMethods
  } = useDashboardQueries.useResidentLeasePaymentMethodsQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    leaseId:
      leaseIdInSetup.get() ||
      leaseIdOnDash.get() ||
      dataResidents?.data?.residentData?.workflowMetaData?.[0]?.leaseId
  })

  const {
    status: statusLateOrFailedPaymentModifyInfo,
    data: dataPayOtherAmountInfo,
    isFetching: isFetchingPayOtherAmountInfo,
    isError: isErrorPayOtherAmountInfo
  } = useWalletPaymentQueries.usePayOtherAmountInfoQuery({
    config: { refetchOnMount: true },
    enabled: statusResidents === "success",
    residentId: dataResidents?.data?.residentData?.id,
    paymentId: modifyPaymentParams?.customerPaymentId
  })

  useEffect(() => {
    if (!!dataResidentLeasePaymentMethods && !!dataPayOtherAmountInfo) {
      setMinAmount(dataPayOtherAmountInfo?.data?.details?.minimumAmount || 1)
      const amountMatch =
        dataPayOtherAmountInfo?.data?.details?.line1Display?.match(
          /\$\d+.?\d+/
        ) || []
      if (!!amountMatch[0])
        setPaymentAmount(amountMatch[0]?.replace("$", "") * 1)
      logicPlan.onUpdateResidentLeasePaymentMethods({
        dataResidentLeasePaymentMethods,
        setAllPaymentMethods,
        setSelectedPaymentMethod,
        suggestedDefault:
          dataPayOtherAmountInfo?.data?.details?.defaultPaymentMethodSelected
      })
    }
  }, [dataResidentLeasePaymentMethods, dataPayOtherAmountInfo])

  const schedulePaymentMutation =
    usePaymentMutation.useSchedulePaymentMutation()
  const onSubmit = () => {
    let requestParamDate = new Date(
      dataPayOtherAmountInfo?.data?.details?.paymentDate
    )
    requestParamDate.setDate(requestParamDate.getDate() + 1)
    const requestParams = {
      residentId: dataResidents?.data?.residentData?.id,
      customerPaymentId: modifyPaymentParams?.customerPaymentId,
      amount: amountEntered * 1,
      date: formatDateToString(requestParamDate),
      paymentMethodInformation: {
        ...([
          APPCONFIG.api.globalPaymentMethods.CASH.slug,
          APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
        ].indexOf(selectedPaymentMethod?.type) > -1
          ? {
              globalPaymentId:
                selectedPaymentMethod?.type ===
                APPCONFIG.api.globalPaymentMethods.CASHAPP.slug
                  ? APPCONFIG.api.globalPaymentMethods.CASHAPP.id
                  : APPCONFIG.api.globalPaymentMethods.CASH.id
            }
          : false),
        ...(selectedPaymentMethod?.type === APPCONFIG.api.bankSlug
          ? { bankId: selectedPaymentMethod?.bankAccountId }
          : false),
        ...(selectedPaymentMethod?.type === APPCONFIG.api.cardSlug
          ? { cardId: selectedPaymentMethod?.cardId }
          : false)
      }
    }
    setUpdateInProgress(true)
    schedulePaymentMutation.mutate(requestParams, {
      onSuccess: ({ data, status }) => {
        if ([200].indexOf(status) > -1) {
          setModalVisibility(false)
          refetchDashboard()
        } else {
          handleScreenApiError({
            ...data
          })
        }
      },
      onError: axiosError => {
        const errorData = axiosError?.response?.data || { apiError: true }
        if (
          errorData?.code === 400 &&
          errorData?.errordescription.indexOf(
            APPCONFIG.api.insufficientAmount
          ) > -1
        ) {
          showModalScheduleTheRest({
            breadcrumbItems: [
              {
                label: "Payment Options",
                onClick: () => {
                  showModalModifyPayment({
                    setModalClass,
                    setModalBreadcrumb,
                    setModalComponent,
                    setModalVisibility,
                    setMessageBubbleComponent,
                    setMessageBubbleVisibility,
                    refetchDashboard,
                    allUpcomingPayments,
                    ...modifyPaymentParams
                  })
                }
              },
              {
                label: "Pay Other Amount",
                onClick: () => {
                  showPayOtherAmountModal({
                    setModalClass,
                    setModalBreadcrumb,
                    setModalComponent,
                    setModalVisibility,
                    setMessageBubbleComponent,
                    setMessageBubbleVisibility,
                    refetchDashboard,
                    allUpcomingPayments,
                    ...modifyPaymentParams
                  })
                }
              }
            ],
            failedRequestParams: {
              ...requestParams,
              paymentActualDate:
                dataPayOtherAmountInfo?.data?.details?.paymentDate,
              reason: selectedReason,
              message: messageFieldRef?.current?.value || ""
            },
            refetchDashboard,
            // walletInformation,
            setModalClass,
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility,
            setMessageBubbleComponent,
            setMessageBubbleVisibility
          })
          return
        }
        handleScreenApiError({
          ...errorData
        })
      },
      onSettled: () => {
        setUpdateInProgress(false)
      }
    })
  }

  const handleScreenApiError = ({
    customHeading = "Failed to schedule payment",
    ...data
  }) => {
    handleApiError({
      customHeading,
      nav,
      setMessageBubbleComponent,
      setMessageBubbleVisibility,
      ...data
    })
  }

  if (updateInProgress || isFetchingResidents || isFetchingPayOtherAmountInfo) {
    return <AnimationLoadingFullScreen minVh100={false} />
  }

  return (
    <>
      <div className="widget freeze-to-top">
        <h2 className="billboard text-center mb-4 mx-n2">Pay Other Amount</h2>
        <p className="you-are-modifying mb-4">
          {dataPayOtherAmountInfo?.data?.details?.line1Display}
          <br />
          Late payment cannot be modified later.
        </p>
      </div>
      <div className="widget generic-form">
        <h3>Amount</h3>
        <div className="form-field">
          <Form.Control
            className={
              amountEntered && parseFloat(amountEntered) < minAmount
                ? "is-invalid"
                : ""
            }
            ref={amountFieldRef}
            type="number"
            placeholder={`Type in amount`}
            onKeyUp={event => {
              setAmountEntered(event.target.value)
            }}
            onChange={event => {
              setAmountEntered(event.target.value)
            }}
          />
          <div className="invalid-feedback">
            {amountEntered && parseFloat(amountEntered) < minAmount
              ? `Payment must be atleast $${displayAmount(minAmount)}`
              : ""}
          </div>
        </div>
      </div>
      {!!allPaymentMethods && (
        <div className="widget">
          <h3 className="d-flex align-items-center justify-content-between">
            Method
          </h3>
          <div className="plan-payment-options-wrap">
            <div className="plan-payment-options block low-elevation br-medium">
              <Dropdown>
                <Dropdown.Toggle>
                  <div className="payment-option">
                    <div
                      className={`payment-option__icon icon-${selectedPaymentMethod?.type}`}
                    ></div>
                    <div className="payment-option__title">
                      {selectedPaymentMethod?.title}
                    </div>
                    <div className="payment-option__caret"></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {allPaymentMethods?.map((paymentMethod, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        if (paymentMethod?.type === ADDMETHODSLUG_BANK) {
                          showModalBankInformation({
                            onSubmitCallback: () => {
                              showPayOtherAmountModal({
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility,
                                refetchDashboard,
                                allUpcomingPayments,
                                ...modifyPaymentParams
                              })
                            },
                            updateInProgress,
                            setUpdateInProgress,
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility
                          })
                        } else if (paymentMethod?.type === ADDMETHODSLUG_CARD) {
                          showModalCardInformation({
                            onSubmitCallback: () => {
                              showPayOtherAmountModal({
                                setModalClass,
                                setModalBreadcrumb,
                                setModalComponent,
                                setModalVisibility,
                                setMessageBubbleComponent,
                                setMessageBubbleVisibility,
                                refetchDashboard,
                                allUpcomingPayments,
                                ...modifyPaymentParams
                              })
                            },
                            setModalBreadcrumb,
                            setModalClass,
                            setModalComponent,
                            setModalVisibility,
                            setMessageBubbleComponent,
                            setMessageBubbleVisibility
                          })
                        } else {
                          setSelectedPaymentMethod(paymentMethod)
                        }
                      }}
                      key={i}
                    >
                      <div className="payment-option">
                        <div
                          className={`payment-option__icon icon-${paymentMethod?.type}`}
                        ></div>
                        <div className="payment-option__title">
                          {paymentMethod?.title}
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      <div className="widget">
        <h3 className="d-flex align-items-center justify-content-between">
          Reason
        </h3>
        <div className="plan-payment-options-wrap">
          <div className="plan-payment-options block low-elevation br-medium">
            <Dropdown>
              <Dropdown.Toggle>
                <div className="payment-option">
                  <div className="payment-option__icon icon-question"></div>
                  <div className="payment-option__title">
                    {dataPayOtherAmountInfo?.data?.details?.reasons?.find(
                      (reason, i) => i === selectedReason
                    ) || `Select reason`}
                  </div>
                  <div className="payment-option__caret"></div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dataPayOtherAmountInfo?.data?.details?.reasons?.map(
                  (reasonText, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedReason(i)
                      }}
                      key={i}
                    >
                      <div className="payment-option ps-0">
                        <div className="payment-option__title">
                          {reasonText}
                        </div>
                      </div>
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="widget generic-form">
        <h3 className="mb-3">Message to Property (Optional)</h3>
        <div className="form-field">
          <Form.Control
            as="textarea"
            ref={messageFieldRef}
            rows={5}
            placeholder={`Share details with your property`}
          />
        </div>
      </div>
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-primary flex-grow-1"
          disabled={
            !amountEntered ||
            parseFloat(amountEntered) < minAmount ||
            selectedReason === null
          }
          onClick={onSubmit}
        >
          {`Continue`}
        </button>
      </div>
      <div className="btns-wrap d-flex widget">
        <button
          className="btn btn-bordered flex-grow-1"
          onClick={() => {
            showModalPaymentDetails({
              breadcrumbItems: [
                {
                  label: "Payment Options",
                  onClick: () => {
                    showModalModifyPayment({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  }
                },
                {
                  label: "Pay Other Amount",
                  onClick: () => {
                    showPayOtherAmountModal({
                      setModalClass,
                      setModalBreadcrumb,
                      setModalComponent,
                      setModalVisibility,
                      setMessageBubbleComponent,
                      setMessageBubbleVisibility,
                      refetchDashboard,
                      allUpcomingPayments,
                      ...modifyPaymentParams
                    })
                  }
                }
              ],
              setModalClass,
              setModalBreadcrumb,
              setModalComponent,
              setModalVisibility,
              formattedPaymentDate: "",
              paymentId: modifyPaymentParams?.customerPaymentId,
              arrearsPaymentId: modifyPaymentParams?.customerPaymentArrearsId
            })
          }}
        >
          View Details
        </button>
      </div>
      {!!dataPayOtherAmountInfo?.data?.lateFailedpaymentDetails?.fees && (
        <p className="smaller-text text-center mb-0 mt-4">
          A $
          {displayAmount(
            dataPayOtherAmountInfo?.data?.lateFailedpaymentDetails?.fees
          )}{" "}
          late fee will be added to this payment.
        </p>
      )}
      {amountEntered &&
        parseFloat(amountEntered) >= minAmount &&
        parseFloat(amountEntered) < paymentAmount && (
          <p className="amount-left-to-pay smaller-text text-center mt-4 mb-0">
            There is ${paymentAmount - parseFloat(amountEntered)} left to pay
          </p>
        )}
    </>
  )
}

export default OrgnWizardPayOtherAmount
