import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import "./elements.scss"

const RadioGroup = ({ items, onChange, formField }) => {
  const [selectedValue, setSelectedValue] = useState(
    formField?.selectedValue || null
  )

  return (
    <>
      <label className="field-label">{formField.label}</label>
      {items.map((item, index) => {
        return (
          <Form.Check
            key={index}
            type={"radio"}
            id={item.id || `radio-${formField.name}-${item.value}`}
            label={item.label}
            name={formField.name}
            value={item.value}
            onClick={() => setSelectedValue(item.value)}
            checked={selectedValue === item.value}
            onChange={onChange}
            disabled={formField?.readOnly}
          />
        )
      })}
    </>
  )
}
export default RadioGroup
