import { APPCONFIG } from "../config"
import { zPad } from "."

export const logicCalendar = {
  computedPaymentDates: ({ planPaymentDay, selectedSchedule, startDate }) => {
    if (APPCONFIG.debugGlobal) {
      console.log(
        `%c[logicCalendar.computedPaymentDates] planPaymentDay:${planPaymentDay} startDate:${startDate}`,
        "color: orangered"
      )
    }
    const paymentRefStartDate =
      startDate ||
      selectedSchedule?.paymentDates?.defaultPaymentStartDate ||
      `${new Date().getFullYear()}-${zPad(new Date().getMonth() + 1, 2)}-01`
    let firstNewPlanPaymentDay = new Date(
      `${new Date(paymentRefStartDate).getFullYear()}-${zPad(
        new Date(paymentRefStartDate).getMonth() + 1,
        2
      )}-01`
    )
    selectedSchedule?.paymentDates?.paymentStartDate?.some(
      (paymentStartDate, i) => {
        firstNewPlanPaymentDay.setDate(paymentStartDate)
        if (
          firstNewPlanPaymentDay.getDay() ===
          (planPaymentDay || selectedSchedule?.paymentDates?.defaultDayofweek) +
            APPCONFIG.api.dayIndexAdjust
        ) {
          return true
        }
        return false
      }
    )
    let paymentDates
    if (!!selectedSchedule?.paymentDates?.paymentStartDate) {
      switch ((selectedSchedule?.frequency || 1) * 1) {
        case 4:
          /*paymentDates = [
            firstNewPlanPaymentDay.getDate(),
            firstNewPlanPaymentDay.getDate() + 7 * 1,
            firstNewPlanPaymentDay.getDate() + 7 * 2,
            firstNewPlanPaymentDay.getDate() + 7 * 3
          ]*/
          break
        case 2:
          let refStartDate =
            selectedSchedule?.paymentDates?.paymentStartDate?.[0]
          /*switch (selectedSchedule?.plan) {
            case APPCONFIG.api.planSlugs.FIRSTANDTHIRD:
            case APPCONFIG.api.planSlugs.SECONDANDFOURTH:
              selectedSchedule?.paymentDates?.paymentStartDate?.forEach(
                (payDate, i) => {
                  const payDateObj = paymentRefStartDate
                    ? new Date(paymentRefStartDate)
                    : new Date()
                  payDateObj.setDate(payDate)
                  if (
                    payDateObj.getDay() ===
                    (selectedSchedule?.paymentDates?.defaultDayofweek ||
                      selectedSchedule?.paymentDates?.dayoftheweek?.[0]) +
                      APPCONFIG.api.dayIndexAdjust
                  ) {
                    refStartDate = payDate
                  }
                }
              )
              break
          }
          paymentDates = [refStartDate, refStartDate + 7 * 2]*/
          if (selectedSchedule?.plan === APPCONFIG.api.planSlugs.ALTERNATING) {
            paymentDates = [
              selectedSchedule?.paymentDates?.paymentStartDate?.find(
                paymentDate => {
                  let paymentDateObj = new Date(
                    `${new Date(paymentRefStartDate).getFullYear()}-${zPad(
                      new Date(paymentRefStartDate).getMonth() + 1,
                      2
                    )}-01`
                  )
                  paymentDateObj.setDate(paymentDate)
                  return (
                    paymentDateObj.getDay() ===
                    selectedSchedule?.paymentDates?.defaultDayofweek +
                      APPCONFIG.api.dayIndexAdjust
                  )
                }
              )
            ]
          }
          break
      }
    } else {
      paymentDates = [
        selectedSchedule?.paymentDates?.defaultDate1,
        selectedSchedule?.paymentDates?.defaultDate2
      ]
    }
    return {
      paymentStartDate: paymentRefStartDate,
      paymentDates: paymentDates?.filter(item => !!item) || []
    }
  }
}
