import { useQuery } from "react-query"
import { APIhandler } from "./api"

// ----- ACCOUNT SETUP -----

export const useAccountSetupQueries = {
  useAvailablePaymentPlansQuery: ({
    config = {},
    enabled,
    residentId,
    leaseId
  }) => {
    return useQuery(
      ["available-payment-plans", residentId, leaseId],
      () =>
        APIhandler.account_setup.get_available_payment_plans({
          residentId,
          leaseId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useCalendarPreviewDatesQuery: ({
    config = {},
    enabled,
    residentId,
    leaseId,
    paymentId = 0,
    planId,
    planJson,
    adjustPaymenPlan = true
  }) => {
    return useQuery(
      [
        "calendar-preview-dates",
        residentId,
        leaseId,
        paymentId,
        planId,
        planJson,
        adjustPaymenPlan
      ],
      () =>
        APIhandler.account_setup.get_calendar_preview_dates({
          residentId,
          leaseId,
          paymentId,
          planId,
          planJson,
          adjustPaymenPlan
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  usePaymentMethodsQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["payment-methods", residentId],
      () => APIhandler.account_setup.get_payment_methods({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- DASHBOARD -----

export const useDashboardQueries = {
  useResidentsQuery: ({ config = {}, enabled = true }) => {
    return useQuery(["residents"], () => APIhandler.dashboard.get_residents(), {
      retry: false,
      refetchOnMount: false,
      enabled,
      ...config
    })
  },

  // not in use
  useSingleResidentQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["resident", residentId],
      () => APIhandler.dashboard.get_single_resident({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useDashboardQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["dashboard", residentId],
      () => APIhandler.dashboard.get_dashboard({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useLeasesQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["leases", residentId],
      () => APIhandler.dashboard.get_leases({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useLeaseDetailsQuery: ({ config = {}, enabled, residentId, leaseId }) => {
    return useQuery(
      ["lease-details", residentId, leaseId],
      () => APIhandler.dashboard.get_lease_details({ residentId, leaseId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useLeasePlanDetailsQuery: ({ config = {}, enabled, residentId, leaseId }) => {
    return useQuery(
      ["lease-plan-details", residentId, leaseId],
      () =>
        APIhandler.dashboard.get_lease_plan_details({ residentId, leaseId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useResidentLeasePaymentMethodsQuery: ({
    config = {},
    enabled,
    residentId,
    leaseId
  }) => {
    return useQuery(
      ["resident-lease-payment-methods", residentId, leaseId],
      () =>
        APIhandler.dashboard.get_resident_lease_payment_methods({
          residentId,
          leaseId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  usePaymentMethodsForPaymentIdQuery: ({
    config = {},
    enabled,
    residentId,
    paymentId
  }) => {
    return useQuery(
      ["payment-methods-for-payment-id", residentId, paymentId],
      () =>
        APIhandler.dashboard.get_payment_methods_for_payment_id({
          residentId,
          paymentId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- PLAN -----

export const usePlanQueries = {
  useExceptionMonthsListQuery: ({
    config = {},
    enabled,
    residentId,
    leaseId
  }) => {
    return useQuery(
      ["exception-months-list", residentId, leaseId],
      () =>
        APIhandler.plan.get_exception_months_list({
          residentId,
          leaseId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  useAvailablePaymentPlansForLeasePaymentQuery: ({
    config = {},
    enabled,
    residentId,
    leasepaymentId
  }) => {
    return useQuery(
      ["available-payment-plans-for-lease-payment", residentId, leasepaymentId],
      () =>
        APIhandler.plan.get_available_payment_plans_for_lease_payment({
          residentId,
          leasepaymentId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  useChangePlanAvailablePaymentPlansForPaymentQuery: ({
    config = {},
    enabled,
    residentId,
    paymentId
  }) => {
    return useQuery(
      [
        "change-plan-available-payment-plans-for-payment",
        residentId,
        paymentId
      ],
      () =>
        APIhandler.plan.change_plan_get_available_payment_plans_for_payment({
          residentId,
          paymentId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- PAYMENT METHODS -----

export const usePaymentMethodQueries = {
  useBankAccountsQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["bank-accounts", residentId],
      () => APIhandler.payment_methods.get_bank_accounts({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  useBankAccountQuery: ({
    config = {},
    enabled,
    residentId,
    bankAccountId
  }) => {
    return useQuery(
      ["bank-account", residentId, bankAccountId],
      () =>
        APIhandler.payment_methods.get_bank_account({
          residentId,
          bankAccountId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useCardsQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["cards", residentId],
      () => APIhandler.payment_methods.get_cards({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useCardQuery: ({ config = {}, enabled, residentId, cardId }) => {
    return useQuery(
      ["card", residentId, cardId],
      () => APIhandler.payment_methods.get_card({ residentId, cardId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },

  useCardsPublicKeyQuery: ({ config = {}, enabled }) => {
    return useQuery(
      ["cards-public-key"],
      () => APIhandler.payment_methods.get_stripe_publickey(),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- WALLET / PAYMENT -----

export const useWalletPaymentQueries = {
  useWalletInformationQuery: ({
    config = {},
    enabled,
    residentId,
    leaseId
  }) => {
    return useQuery(
      ["wallet-information", residentId, leaseId],
      () => APIhandler.payment.get_wallet_information({ residentId, leaseId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  useWalletPaymentDetailsQuery: ({
    config = {},
    enabled,
    residentId,
    leaseId
  }) => {
    return useQuery(
      ["wallet-payment-details", residentId, leaseId],
      () =>
        APIhandler.payment.get_wallet_payment_details({ residentId, leaseId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  usePayOtherAmountInfoForAmountQuery: ({
    config = {},
    enabled,
    residentId,
    customerPaymentId,
    amount
  }) => {
    return useQuery(
      ["pay-other-amount-info", residentId, customerPaymentId, amount],
      () =>
        APIhandler.payment.get_pay_other_amount_info_for_amount({
          residentId,
          customerPaymentId,
          amount
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  usePaymentModifyInfoQuery: ({
    config = {},
    enabled,
    residentId,
    paymentId,
    paymentArrearsId
  }) => {
    return useQuery(
      ["payment-modify-info", residentId, paymentId, paymentArrearsId],
      () =>
        !!paymentArrearsId
          ? APIhandler.payment.get_payment_arrears_modify_info({
              residentId,
              paymentArrearsId
            })
          : APIhandler.payment.get_payment_modify_info({
              residentId,
              paymentId
            }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  useLateOrFailedPaymentModifyInfoQuery: ({
    config = {},
    enabled,
    residentId,
    paymentId,
    paymentArrearsId
  }) => {
    return useQuery(
      [
        "lateorfailed-payment-modify-info",
        residentId,
        paymentId,
        paymentArrearsId
      ],
      () =>
        !!paymentArrearsId
          ? APIhandler.payment.get_lateorfailed_payment_arrears_modify_info({
              residentId,
              paymentArrearsId
            })
          : APIhandler.payment.get_lateorfailed_payment_modify_info({
              residentId,
              paymentId
            }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  usePayOtherAmountInfoQuery: ({
    config = {},
    enabled,
    residentId,
    paymentId
  }) => {
    return useQuery(
      ["pay-other-amount-info", residentId, paymentId],
      () =>
        APIhandler.payment.get_payotheramount_info({
          residentId,
          paymentId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- RENTASSIST -----

export const useRentAssistQueries = {
  useRentAssistInfoQuery: ({ config = {}, enabled, residentId, leaseId }) => {
    return useQuery(
      ["rentassist-info", residentId, leaseId],
      () =>
        APIhandler.rentassist.get_rentassist_info({
          residentId,
          leaseId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  useRentAssistPlanQuery: ({
    config = {},
    enabled,
    residentId,
    leaseArrearId
  }) => {
    return useQuery(
      ["rentassist-plan", residentId, leaseArrearId],
      () =>
        APIhandler.rentassist.get_rentassist_plan({
          residentId,
          leaseArrearId
        }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- STATEMENTS -----

export const useStatementQueries = {
  useUpcomingPaymentsQuery: ({ config = {}, enabled, residentId, leaseId }) => {
    return useQuery(
      ["upcoming-payments", residentId, leaseId],
      () =>
        APIhandler.statements.get_upcoming_payments({ residentId, leaseId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  usePastPaymentsQuery: ({ config = {}, enabled, residentId, leaseId }) => {
    return useQuery(
      ["past-payments", residentId, leaseId],
      () => APIhandler.statements.get_past_payments({ residentId, leaseId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  usePaymentDetailsQuery: ({
    config = {},
    enabled,
    residentId,
    paymentId,
    arrearsPaymentId
  }) => {
    return useQuery(
      ["payment-details", residentId, paymentId, arrearsPaymentId],
      () =>
        !!arrearsPaymentId
          ? APIhandler.statements.get_arrear_payment_details({
              residentId,
              arrearsPaymentId
            })
          : APIhandler.statements.get_payment_details({
              residentId,
              paymentId
            }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- NOTIFICATIONS -----

export const useNotificationQueries = {
  useNotificationsQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["notifications", residentId],
      () => APIhandler.notifications.get_notifications({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  },
  useNotificationSettingsQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["notification-settings", residentId],
      () => APIhandler.notifications.get_notification_settings({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

// ----- MORE APIS -----

export const useMoreApiQueries = {
  useResidentReferralCodeQuery: ({ config = {}, enabled, residentId }) => {
    return useQuery(
      ["resident-referral-code", residentId],
      () => APIhandler.more_apis.get_resident_referral_code({ residentId }),
      {
        retry: false,
        refetchOnMount: false,
        enabled,
        ...config
      }
    )
  }
}

export const useTermsQuery = ({ config = {} }) => {
  return useQuery(["terms"], () => APIhandler.get_terms(), {
    retry: false,
    refetchOnMount: false,
    ...config
  })
}
