export const displayTpl = (data, entityType) => {
  let output = ""
  switch (entityType) {
    case "bank_account_display_name":
      output = data?.bankAccountNickName
        ? data?.bankAccountNickName
        : `Bank account ending ${data?.bankAccountNumber}`
      break
    case "lease_display_name":
      output = `${data?.unitPrimaryDisplayName} ${data?.unitDisplayName}`
      break
  }
  return output
}
