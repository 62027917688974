import React from "react"

import { logicPlan } from "../../../utils/logic-plan"

import { showModalModifyPayment } from "../payment/modify-payment"
import OrgnWizardChangePlan from "./wizard-change-plan"

import "./plan.scss"

export const showChangePlanModal = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  refetchDashboard,
  allUpcomingPayments,
  ...modifyPaymentParams
  /**
   * `modifyPaymentParams` contains the following:
   * - amount,
   * - customerPaymentArrearsId,
   * - customerPaymentId,
   * - date,
   * - isLatePayment,
   * - planTypeName
   **/
}) => {
  setModalClass("modal--change-plan narrow-content")
  setModalBreadcrumb([
    {
      label: "Payment Options",
      onClick: () => {
        showModalModifyPayment({
          setModalClass,
          setModalBreadcrumb,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          refetchDashboard,
          allUpcomingPayments,
          ...modifyPaymentParams
        })
      }
    },
    { label: "Modify Payment" }
  ])
  setModalComponent(() => (
    <OrgnWizardChangePlan
      modifyPaymentParams={modifyPaymentParams}
      refetchDashboard={refetchDashboard}
      allUpcomingPayments={allUpcomingPayments}
      wizardTitle={`Change Plan`}
      submitButtonText={`Change Plan`}
      onSubmit={props => {
        logicPlan.onSubmitChangePlan({
          ...props
        })
      }}
      setMessageBubbleComponent={setMessageBubbleComponent}
      setMessageBubbleVisibility={setMessageBubbleVisibility}
      onSuccess={() => {
        setModalVisibility(false)
        if (!!refetchDashboard) refetchDashboard()
      }}
    />
  ))
  setModalVisibility(true)
}
