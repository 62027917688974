import React from "react"
import { ALLROUTES } from "../../../routes"

import "./dashboard-progress.scss"

const DashboardProgress = ({ perc }) => {
  return (
    <>
      <div
        className={`dashboard-progress ${
          perc > 0 && perc < 100 ? "is-visible" : ""
        }`}
        data-perc={perc}
      >
        <div className="progress" style={{ width: `${perc}%` }}>
          <p>{perc}% complete</p>
        </div>
      </div>
    </>
  )
}
export default DashboardProgress
