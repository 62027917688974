import { MsalAuthProvider, LoginType } from "react-aad-msal"

import { APPCONFIG } from "./config"

// Msal Configurations
const signInConfig = {
  auth: {
    authority: APPCONFIG.msal.signInAuthority,
    clientId: APPCONFIG.msal.applicationID,
    redirectUri: APPCONFIG.msal.reactRedirectUri,
    postLogoutRedirectUri: APPCONFIG.msal.reactRedirectUri, //APPCONFIG.webAppBaseUrl,
    validateAuthority: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
}

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    `${APPCONFIG.msal.authScopesPrefix}user.read`,
    `${APPCONFIG.msal.authScopesPrefix}openid`,
    `${APPCONFIG.msal.authScopesPrefix}profile`
  ]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

export const signInAuthProvider = new MsalAuthProvider(
  signInConfig,
  authenticationParameters,
  options
)
