import React from "react"

import { showPlanModal } from "./plan"
import { showModalModifyPlanConfirm } from "./modify-plan-confirm"
import OrgnWizardPlan from "./wizard-plan"
import { showPreviewCalendarModal } from "./preview-calendar"

import "./plan.scss"

export const showModifyPlanModal = ({
  setModalClass,
  setModalBreadcrumb,
  setModalComponent,
  setModalVisibility,
  setMessageBubbleComponent,
  setMessageBubbleVisibility,
  leasePlanDetails,
  refetchLeasePlanDetails,
  refetchDashboard,
  selections
}) => {
  setModalClass("modal--modify-plan narrow-content")
  setModalBreadcrumb([
    {
      label: "Plan Summary",
      onClick: () => {
        showPlanModal({
          setModalClass,
          setModalBreadcrumb,
          setModalComponent,
          setModalVisibility,
          setMessageBubbleComponent,
          setMessageBubbleVisibility,
          leasePlanDetails,
          refetchLeasePlanDetails,
          refetchDashboard
        })
      }
    },
    { label: "Modify your plan" }
  ])
  setModalComponent(() => {
    return (
      <OrgnWizardPlan
        wizardTitle={`Modify your plan`}
        showYouPayOnThe={true}
        submitButtonText={`Save`}
        leasePlanDetails={leasePlanDetails}
        refetchLeasePlanDetails={refetchLeasePlanDetails}
        refetchDashboard={refetchDashboard}
        selections={selections}
        onSubmit={props => {
          showModalModifyPlanConfirm({
            setModalClass,
            setModalBreadcrumb,
            setModalComponent,
            setModalVisibility,
            setMessageBubbleComponent,
            setMessageBubbleVisibility,
            leasePlanDetails,
            refetchLeasePlanDetails,
            refetchDashboard,
            ...props
          })
        }}
        PostSubmitButtonComponent={props => (
          <div className="btns-wrap d-flex widget justify-content-center">
            <button
              className="btn btn-text text-only"
              onClick={() => {
                showPreviewCalendarModal({
                  setModalClass,
                  setModalBreadcrumb,
                  setModalComponent,
                  setModalVisibility,
                  setMessageBubbleComponent,
                  setMessageBubbleVisibility,
                  leasePlanDetails,
                  refetchLeasePlanDetails,
                  refetchDashboard,
                  ...props
                })
              }}
            >
              Preview Calendar
            </button>
          </div>
        )}
        setMessageBubbleComponent={setMessageBubbleComponent}
        setMessageBubbleVisibility={setMessageBubbleVisibility}
        onSuccess={() => {
          setModalVisibility(false)
          refetchLeasePlanDetails()
          refetchDashboard()
        }}
      />
    )
  })
  setModalVisibility(true)
}
