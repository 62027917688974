import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { CircaLoadingAnimationFS } from "../../../assets"
import { APPCONFIG } from "../../../config"

import { DashboardWrapperNoAuth } from "../../../components/wrappers/dashboard"
import FullScreenCentered from "../../../components/wrappers/full-screen-centered"

import { useTermsQuery } from "../../../utils/queries"

import "./terms-conditions.scss"

const ScreenTermsConditions = () => {
  const nav = useNavigate()

  const { data, isFetching, isError, error, refetch } = useTermsQuery({})

  useEffect(() => {
    if (APPCONFIG.debugGlobal) {
      console.log("data", data)
      console.log("error", error)
    }
  }, [data, error])

  if (isFetching) return <CircaLoadingAnimationFS />

  return (
    <>
      <DashboardWrapperNoAuth
        bodyClass={`screen__terms-conditions d-flex flex-row-reverse flex-wrap`}
      >
        <FullScreenCentered className={`w-100`} vw100={false} valign={"start"}>
          <div className="static-page">
            <h1>Terms of Use</h1>
            <div className="static-page__content">
              {/* Use `data` object to display ToS content here */}
              <p>
                Last Modified: (May 23, 2022) This is static content, needs
                update
              </p>
              <p>Acceptance of the Terms and Conditions</p>
              <p>
                Welcome to the website of Circa Labs Inc. (“Company”, “we”,
                “our” or “us”). The following terms and conditions, together
                with any documents they expressly incorporate by reference
                (collectively, these “Terms and Conditions”), govern your access
                to and use of the Circa App, including any content,
                functionality and services offered on or through wearecirca.com
                (the “Website”).
              </p>
              <p>
                Please read the Terms and Conditions carefully before you start
                to use the Website. By using the Website, you accept and agree
                to be bound and abide by these Terms and Conditions, our Privacy
                Policy (found at www.wearecirca.com/privacy), and our Copyright
                Policy (found at www.wearecirca.com/copyright). If you do not
                want to agree to these Terms and Conditions, the Privacy Policy,
                or the Copyright Policy, you must not access or use the Website.
              </p>
              <p>...</p>
            </div>
          </div>
        </FullScreenCentered>
      </DashboardWrapperNoAuth>
    </>
  )
}

export default ScreenTermsConditions
